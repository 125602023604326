import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, Redirect, Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import {
  cancelSubscription,
  getSubscriptionDetails,
} from "../../Redux/Actions/subscriptionAction";
import Profile_Sidebar from "../Profile-Side-Bar/Profile_Sidebar";
import "../Access-Product-Details/accessProductDetails.scss";
import Rating from "@material-ui/lab/Rating";
import { withStyles } from "@material-ui/core/styles";
import Thumbnail from "../../assets/images/atm.png";
import Blankpic_subscription_detail from "../../assets/images-2/Blankpic_subscription_detail.jpg"

import { fetchPrivateFiles } from "../../Redux/Actions/azureStorageAction";

import ZoomMeetingCalendar from "../Calendar/Calendar";
import MeetingCalendar from "../MeetingCalendar/MeetingCalendar";
import { logDOM } from "@testing-library/react";

const AccessProductDetails = (props) => {
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const userId = localStorage.getItem("userId");
  const dispatch = useDispatch();
  let { state } = useLocation();
  const [fileType, setFileType] = useState("");
  const [itemType, setItemType] = useState("image");
  let selectedVideoRef = useRef(null);
  const [isPreviewLoaded, setIsPreviewLoaded] = useState(false);

  const [currentFileView, setCurrentFileView] = useState({});
  const [showCalendar, setShowCalendar] = useState(false);
  const [showMeetingCalendar, setMeetingCalendar] = useState(false);
  const { documents, images, audios, videos } = useSelector(
    (state) => state.azureStorageReducer
  );

  useEffect(() => {
    fetchSubscriptionDetails();
  }, []);

  const StyledRating = withStyles({
    iconFilled: {
      color: "#ff6d75",
    },
    iconHover: {
      color: "#ff3d47",
    },
  })(Rating);

  const organizeAccessSubcategoryData = (productDetail) => {
    if (productDetail.access_cat_titles == null) {
      return [];
    }
    const titles = productDetail.access_cat_titles
      ? JSON.parse(productDetail.access_cat_titles)
      : "";
    const videos = JSON.parse(productDetail.access_cat_videos);
    const audios = JSON.parse(productDetail.access_cat_audios);
    const images = JSON.parse(productDetail.access_cat_images);
    const documents = JSON.parse(productDetail.access_cat_documents);
    const length = videos && videos.length > 0 ? videos.length : 0;
    let organizedData = [];
    for (let i = 0; i < length; i++) {
      organizedData.push({
        acsCatTitle:
          titles && titles.length > 0 && titles[i][i].length > 0
            ? titles[i][i]
            : "",
        acsCatVideoGallery:
          videos && videos.length > 0 && videos[i][i].length > 0
            ? videos[i][i].split(",")
            : [],
        acsCatAudioGallery:
          audios && audios.length > 0 && audios[i][i].length > 0
            ? audios[i][i].split(",")
            : [],
        acsCatImageGallery:
          images && images.length > 0 && images[i][i].length > 0
            ? images[i][i].split(",")
            : [],
        acsCatDocGallery:
          documents && documents.length > 0 && documents[i][i].length > 0
            ? documents[i][i].split(",")
            : [],
        isOpen: false,
        openTabs: [],
      });
    }

    return organizedData;
  };

  const fetchSubscriptionDetails = async () => {
    const res = await dispatch(
      getSubscriptionDetails(userId, state?.subscription_id)
    );
    if (res?.is_available === 1) {
      const accessSubCategory = await organizeAccessSubcategoryData(res);

      if(res){
        res.free_seminar_details = res?.free_seminar_details &&
        res.free_seminar_details.length
          ? JSON.parse(res.free_seminar_details)
          : null;
        res.booked_session_details = res?.booked_session_details && 
        res.booked_session_details.length 
          ? JSON.parse(res.booked_session_details)
          :null;
        res.group_meeting_link = res?.group_meeting_link && 
        res.group_meeting_link.length 
          ? JSON.parse(res.group_meeting_link)
          :null;
      }
      
      setSubscriptionDetails({ ...res, accessSubCategory });
      if (res.access_cat_images) {
        setIsPreviewLoaded(false);
        const fileUrl = res.access_cat_images?.split(",")[0];
        const fileRes = await dispatch(
          fetchPrivateFiles("images", fileUrl.trim(), {
            subscriptionId: res.subscription_id,
            url: fileUrl.trim(),
          })
        );
        await setCurrentFileView(fileRes);
        setIsPreviewLoaded(true);
      } else if (res.access_cat_videos) {
        setIsPreviewLoaded(false);
        const fileUrl = res.access_cat_videos?.split(",")[0];
        const fileRes = await dispatch(
          fetchPrivateFiles("videos", fileUrl.trim(), {
            subscriptionId: res.subscription_id,
            url: fileUrl.trim(),
          })
        );
        await setCurrentFileView(fileRes);
        setIsPreviewLoaded(true);
      } else if (res.access_cat_audios) {
        setIsPreviewLoaded(false);
        const fileUrl = res.access_cat_audios?.split(",")[0];
        const fileRes = await dispatch(
          fetchPrivateFiles("audios", fileUrl.trim(), {
            subscriptionId: res.subscription_id,
            url: fileUrl.trim(),
          })
        );
        await setCurrentFileView(fileRes);
        setIsPreviewLoaded(true);
      } else {
        setIsPreviewLoaded(true);
      }
    } else {
      props.history.push("/subscription");
    }
  };

  const onCancelSubscription = async (subscription_id, payment_mode) => {
    // alert(subscription_id);
    let res = await dispatch(
      cancelSubscription(userId, subscription_id, payment_mode)
    );
    if (res && res.data) {
      showResponseAlert(res.data.message);
    } else {
      // error to get respone
      showResponseAlert("Something went wrong. Please try again later.");
    }
  };

  const showResponseAlert = (message) => {
    confirmAlert({
      title: "",
      message: message,
      buttons: [
        {
          label: "Ok",
          onClick: async () => {
            fetchSubscriptionDetails();
          },
        },
      ],
    });
  };

  const updateSubscription = (subscription_id, payment_mode) => {
    confirmAlert({
      title: "",
      message: "Are you sure to cancel the subscription?",
      buttons: [
        {
          label: "Yes",
          onClick: () => onCancelSubscription(subscription_id, payment_mode),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const getFileNameFromUrl = (fileUrl) => {
    if (!fileUrl) {
      return "";
    }
    return fileUrl
      .split("?")[0]
      .substring(fileUrl.lastIndexOf("/") + 1)
      .split(".")[0];
  };

  const getFileSizeByByteLength = (byteLength) => {
    let size = 0;
    let unit = "Bytes";
    if (Math.round(byteLength) < 1000) {
      size = Math.round(byteLength);
      unit = "Bytes";
    } else if (Math.round(byteLength) > 999 && byteLength < 1000000) {
      size = Math.round(byteLength / 1000);
      unit = "KB";
    } else if (Math.round(byteLength) > 999999 && byteLength < 1000000000) {
      size = Math.round(byteLength / 1000000);
      unit = "MB";
    }
    return size + " " + unit;
  };
  const getFileSizeByUrl = (fileUrl) => {
    if (!fileUrl) {
      return "";
    }

    const urlQueries = fileUrl.split("?")[1]?.split("&");
    const queriesMap = {};
    for (const query of urlQueries) {
      const queryList = query.split("=");
      queriesMap[queryList[0]] = queryList[1];
    }

    return getFileSizeByByteLength(queriesMap.size);
  };

  const handleSelectFileForMainPlayer = async (e, type, item, catIndex) => {
    let fileRes = null;
    let subCategoryList = subscriptionDetails.accessSubCategory[catIndex];
    setIsPreviewLoaded(false);

    /*switch (type) {
          case "documents":
            fileRes = documents?.find((map) => map.url.trim() === item.trim());
            break;
          case "images":
            fileRes = images?.find((map) => map.url.trim() === item.trim());
            break;
          case "audios":
            fileRes = audios?.find((map) => map.url.trim() === item.trim());
            break;
          case "videos":
            fileRes = videos?.find((map) => map.url.trim() === item.trim());
            break;
        }*/

    if (!fileRes) {
      fileRes = await dispatch(
        fetchPrivateFiles(type, item.trim(), {
          subscriptionId: subscriptionDetails.subscription_id,
          url: item.trim(),
        })
      );
    }

    switch (type) {
      case "documents":
        const base64Response = await fetch(fileRes.stream);
        const blob = await base64Response.blob();
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", fileRes.fileName);
        document.body.appendChild(link);
        link.click();
        break;
      default:
        await setCurrentFileView(fileRes);

        if (selectedVideoRef && selectedVideoRef?.current) {
          selectedVideoRef.current.load();
        }

        setIsPreviewLoaded(true);
    }
  };

  const getVideoLength = (index) => {
    var myVideoPlayer = document.getElementById(`video_${index}`);
  };
  if (!state) {
    return <Redirect to={"/subscription"} />;
  }

  const openCategoryTab = (index) => {
    let subCategoryList = subscriptionDetails.accessSubCategory;
    subCategoryList[index].isOpen =
      !subscriptionDetails.accessSubCategory[index]["isOpen"];
    subCategoryList[index].openTabs = [];
    setSubscriptionDetails((prevState) => ({
      ...prevState,
      accessSubCategory: [...subCategoryList],
    }));
  };

  const openSubCategoryItem = (type, index) => {
    let subCategoryList = subscriptionDetails.accessSubCategory;
    subCategoryList[index].isOpen = true;
    if (subscriptionDetails.accessSubCategory[index].openTabs.includes(type)) {
      subCategoryList[index].openTabs.splice(
        subCategoryList[index].openTabs.indexOf(type),
        1
      );
    } else {
      subCategoryList[index].openTabs.push(type);
    }
    setSubscriptionDetails((prevState) => ({
      ...prevState,
      accessSubCategory: [...subCategoryList],
    }));
  };

  const isButtonEnable = () => {
    // const currDate = new Date();
    // // const dateObj = new Date();
    // const startEndTime = subscriptionDetails?.booked_session_details?.customerTime[0]
    // const selectedWeekDay = subscriptionDetails?.booked_session_details?.selectedWeekDay

    // const currWeekDay = currDate.toLocaleString('en-us', {  weekday: 'long' })

    // if (selectedWeekDay?.toLowerCase() == currWeekDay?.toLowerCase() && +currDate.getHours() >= +startEndTime[0].split(':')[0] 
    // && +currDate.getMinutes() >= +startEndTime[0].split(':')[1] && +currDate.getHours() <= +startEndTime[1].split(':')[0] &&
    // +currDate.getMinutes() <= +startEndTime[0].split(':')[1] && subscriptionDetails?.subscriptionStatus)
    return true;

    // else
    // return false;
  }

  // isButtonEnable()
  return (
    <main className="mainContainer">
      <section className="home-product-section mt-4">
        <div className="container">
          <div className="select-product-list">
            <div className="product-list-head product-list-border-bottom">
              <ul>
                <li>
                  <a>Order List</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="access-pro">
        <div className="container">
          <div className="row">
            <Profile_Sidebar />

            <div className="col-lg-9">
              <section className="access-product-new">
                <div className="container">
                  <h5>{state?.name}</h5>
                  <div className="item-rating">
                    <Rating
                      name="hover-feedback"
                      value={state?.rating}
                      precision={0.5}
                      readOnly
                    />
                  </div>

                  <div className="row">
                    <div className="col-md-8">
                      <div className="access-video-main">
                        {!isPreviewLoaded && (
                          <div className="access-alltype-file">
                            <p> Loading... </p>
                          </div>
                        )}
                        {isPreviewLoaded &&
                        currentFileView &&
                        (Object.keys(currentFileView).length > 0 && !currentFileView.error) ? (
                          <>
                            {currentFileView.type === "video" ? (
                              <div className="access-alltype-file">
                                <video
                                  ref={selectedVideoRef}
                                  controls
                                  poster=""
                                >
                                  <source
                                    src={currentFileView.stream}
                                    type={currentFileView.contentType}
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              </div>
                            ) : (
                              <>
                                {currentFileView.type === "audio" ? (
                                  <div className="access-alltype-file">
                                    <audio ref={selectedVideoRef} controls>
                                      <source
                                        src={currentFileView.stream}
                                        type={currentFileView.contentType}
                                      />
                                      Your browser does not support the audio
                                      element.
                                    </audio>
                                  </div>
                                ) : (
                                  <>
                                    {currentFileView.type === "image" ? (
                                      <div className="access-alltype-file">
                                        <img
                                          src={currentFileView.stream}
                                          alt=""
                                        />
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        ) : 
                        isPreviewLoaded && <div className="access-alltype-file">
                                        <img
                                          src={Blankpic_subscription_detail}
                                          alt=""
                                        />
                                      </div>
                        }
                        <div className="group-of-video-btn">
                          <div className="video-group-btn">
                            {subscriptionDetails.isChatActive === 1 ? (
                              <button
                                className="orange-btn mr-2"
                                onClick={() => {
                                  props.history.push("/messages", {
                                    message_source_type: "product",
                                    sender_type: "buyer",
                                    message_source_id:
                                      subscriptionDetails.productId,
                                    seller_id: subscriptionDetails.userId,
                                    source_detail: subscriptionDetails.name,
                                    storename: subscriptionDetails.storename,
                                    timezone: subscriptionDetails.timezone,
                                    availableFrom:
                                      subscriptionDetails.available_from,
                                    availableTo:
                                      subscriptionDetails.available_to,
                                  });
                                }}
                              >
                                Contact Vendor
                              </button>
                            ) : null}
                            {/*<Link to={}>*/}
                            <button
                              className="orange-btn mr-2"
                              onClick={() =>
                                props.history.push({
                                  pathname: `/product/${subscriptionDetails.slug}`,
                                  state: { spuId: subscriptionDetails.spu_id },
                                })
                              }
                            >
                              view product
                            </button>
                            {/*</Link>*/}

                            {subscriptionDetails.subscriptionStatus === 1 ? (
                              <>
                                <button
                                  className={
                                    subscriptionDetails.subscriptionStatus === 1
                                      ? "orange-btn"
                                      : "orange-outline-btn-font"
                                  }
                                  onClick={() =>
                                    updateSubscription(
                                      state.subscription_id,
                                      state.method
                                    )
                                  }
                                >
                                  Cancel Membership
                                </button>
                              </>
                            ) : (
                              <>
                                <button className="orange-outline-btn-font">
                                  {" "}
                                  Cancel Membership{" "}
                                </button>
                              </>
                            )}
                          </div>

                          <div className="btn-sub-video">
                          {/* {subscriptionDetails.free_seminar_details && subscriptionDetails.free_seminar_details?.host_link ? (
                              <a
                                href={subscriptionDetails.free_seminar_details.host_link}
                                target="_blank"
                                className="join-link"
                              >
                                <button
                                  className="orange-outline-btn"
                                  // onClick={() =>
                                  //   props.history.push("/zoom-meeting/join", {
                                  //     subscriptionId:
                                  //       subscriptionDetails.subscription_id,
                                  //   })
                                  // }
                                >
                                  Join Now
                                </button>
                              </a>
                            ) : null} */}
                          {subscriptionDetails.subscriptionStatus !==0 && subscriptionDetails.group_meeting_link && subscriptionDetails.group_meeting_link?.join_url && isButtonEnable() ? (
                              <a
                                href={subscriptionDetails.group_meeting_link.join_url}
                                target="_blank"
                                className="join-link"
                              >
                                <button
                                  className="orange-outline-btn"
                                  // onClick={() =>
                                  //   props.history.push("/zoom-meeting/join", {
                                  //     subscriptionId:
                                  //       subscriptionDetails.subscription_id,
                                  //   })
                                  // }
                                >
                                  Join Now
                                </button>
                              </a>
                            ) : null}

                            {subscriptionDetails &&
                            subscriptionDetails.productId ? (
                              <>
                                <button
                                  className="orange-outline-btn mr-1"
                                  onClick={() => setShowCalendar(true)}
                                >
                                  {" "}
                                  View Schedule{" "}
                                </button>
                                <ZoomMeetingCalendar
                                  {...props}
                                  show={showCalendar}
                                  data={{
                                    productId: subscriptionDetails.productId,
                                    sellerId: subscriptionDetails.userId,
                                  }}
                                  setShowCalendar={setShowCalendar}
                                  isPurchased={true}
                                />
                              </>
                            ) : null}

                            {subscriptionDetails &&
                            subscriptionDetails.productId ? (
                              <>
                                <button
                                  className={
                                    subscriptionDetails.subscriptionStatus === 1
                                      ? "orange-outline-btn"
                                      : "opacity-btn"
                                  }
                                  onClick={() => {
                                    props.history.push("/messages", {
                                      message_source_type: "product",
                                      sender_type: "buyer",
                                      message_source_id:
                                        subscriptionDetails.productId,
                                      seller_id: subscriptionDetails.userId,
                                      source_detail: subscriptionDetails.name,
                                      storename: subscriptionDetails.storename,
                                      timezone: subscriptionDetails.timezone,
                                      availableFrom:
                                        subscriptionDetails.available_from,
                                      availableTo:
                                        subscriptionDetails.available_to,
                                        sellerChatActive:
                                        subscriptionDetails.isChatActive,
                                    });
                                  }}
                                  disabled={
                                    subscriptionDetails.subscriptionStatus !== 1
                                  }
                                >
                                  {" "}
                                  Modify upcoming session{" "}
                                </button>
                                {showMeetingCalendar && (
                                  <>
                                    <MeetingCalendar
                                      {...props}
                                      show={showMeetingCalendar}
                                      data={{
                                        productId:
                                          subscriptionDetails.productId,
                                        userId: subscriptionDetails.user_id,
                                      }}
                                      setShowCalendar={setMeetingCalendar}
                                    />
                                  </>
                                )}
                              </>
                            ) : null}
                          </div>
                        </div>
                        <p style={{ whiteSpace: "pre-wrap" }}>
                          {subscriptionDetails?.access_cat_description}
                        </p>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      {subscriptionDetails.accessSubCategory &&
                        subscriptionDetails.accessSubCategory.length > 0 && (
                          <ul className="access-pro-list">
                            {subscriptionDetails.accessSubCategory.map(
                              (subCategory, index) => (
                                <li key={index}>
                                  <h5
                                    onClick={() => openCategoryTab(index)}
                                    className={
                                      subCategory.isOpen ? "active" : ""
                                    }
                                  >
                                    <span>
                                      <i
                                        className={
                                          subCategory.isOpen
                                            ? "fas fa-minus-circle"
                                            : "fas fa-plus-circle"
                                        }
                                      />
                                    </span>
                                    {subCategory.acsCatTitle}
                                  </h5>
                                  {
                                    // subcategory video list start
                                    subCategory.isOpen &&
                                      subCategory.acsCatVideoGallery &&
                                      subCategory.acsCatVideoGallery.length !==
                                        0 && (
                                        <div>
                                          <p
                                            className={
                                              subCategory.openTabs.includes(
                                                "videos"
                                              )
                                                ? "sub-select-list active"
                                                : "sub-select-list"
                                            }
                                            onClick={() =>
                                              openSubCategoryItem(
                                                "videos",
                                                index
                                              )
                                            }
                                          >
                                            <span>
                                              <i
                                                className={
                                                  subCategory.openTabs.includes(
                                                    "videos"
                                                  )
                                                    ? "fas fa-minus-circle"
                                                    : "fas fa-plus-circle"
                                                }
                                              />
                                            </span>
                                            Videos
                                          </p>
                                          {subCategory.openTabs.includes(
                                            "videos"
                                          ) &&
                                            subCategory.acsCatVideoGallery.map(
                                              (video, vIndex) => (
                                                <div
                                                  className="sub-class-list"
                                                  key={vIndex}
                                                  onClick={(e) =>
                                                    handleSelectFileForMainPlayer(
                                                      e,
                                                      "videos",
                                                      video,
                                                      index
                                                    )
                                                  }
                                                >
                                                  <div className="file-thumbnail-img">
                                                    <span>
                                                      <i className="fas fa-file-image"></i>
                                                    </span>
                                                  </div>
                                                  <div className="thumbnail-desc-video">
                                                    <p>
                                                      <b>
                                                        {getFileNameFromUrl(
                                                          video
                                                        )}
                                                      </b>
                                                    </p>
                                                    <span>
                                                      {getFileSizeByUrl(video)}
                                                    </span>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                        </div>
                                      )
                                    // subcategory video list end
                                  }
                                  {
                                    // subcategory audio list start
                                    subCategory.isOpen &&
                                      subCategory.acsCatAudioGallery &&
                                      subCategory.acsCatAudioGallery.length !==
                                        0 && (
                                        <div>
                                          <p
                                            className={
                                              subCategory.openTabs.includes(
                                                "audios"
                                              )
                                                ? "sub-select-list active"
                                                : "sub-select-list"
                                            }
                                            onClick={() =>
                                              openSubCategoryItem(
                                                "audios",
                                                index
                                              )
                                            }
                                          >
                                            <span>
                                              <i
                                                className={
                                                  subCategory.openTabs.includes(
                                                    "audios"
                                                  )
                                                    ? "fas fa-minus-circle"
                                                    : "fas fa-plus-circle"
                                                }
                                              />
                                            </span>
                                            Audios
                                          </p>
                                          {subCategory.openTabs.includes(
                                            "audios"
                                          ) &&
                                            subCategory.acsCatAudioGallery.map(
                                              (item, vIndex) => (
                                                <div
                                                  className="sub-class-list"
                                                  key={vIndex}
                                                  onClick={(e) =>
                                                    handleSelectFileForMainPlayer(
                                                      e,
                                                      "audios",
                                                      item
                                                    )
                                                  }
                                                >
                                                  <div className="file-thumbnail-img">
                                                    <span>
                                                      <i className="fas fa-file-image"></i>
                                                    </span>
                                                  </div>
                                                  <div className="thumbnail-desc-video">
                                                    <p>
                                                      <b>
                                                        {getFileNameFromUrl(
                                                          item
                                                        )}
                                                      </b>
                                                    </p>
                                                    <span>
                                                      {getFileSizeByUrl(item)}
                                                    </span>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                        </div>
                                      )
                                    // subcategory audio list end
                                  }

                                  {
                                    // subcategory images list start
                                    subCategory.isOpen &&
                                      subCategory.acsCatImageGallery &&
                                      subCategory.acsCatImageGallery.length !==
                                        0 && (
                                        <div>
                                          <p
                                            className={
                                              subCategory.openTabs.includes(
                                                "images"
                                              )
                                                ? "sub-select-list active"
                                                : "sub-select-list"
                                            }
                                            onClick={() =>
                                              openSubCategoryItem(
                                                "images",
                                                index
                                              )
                                            }
                                          >
                                            <span>
                                              <i
                                                className={
                                                  subCategory.openTabs.includes(
                                                    "images"
                                                  )
                                                    ? "fas fa-minus-circle"
                                                    : "fas fa-plus-circle"
                                                }
                                              />
                                            </span>
                                            Images
                                          </p>
                                          {subCategory.openTabs.includes(
                                            "images"
                                          ) &&
                                            subCategory.acsCatImageGallery.map(
                                              (item, vIndex) => (
                                                <div
                                                  className="sub-class-list"
                                                  key={vIndex}
                                                  onClick={(e) =>
                                                    handleSelectFileForMainPlayer(
                                                      e,
                                                      "images",
                                                      item
                                                    )
                                                  }
                                                >
                                                  <div className="file-thumbnail-img">
                                                    <span>
                                                      <i className="fas fa-file-image"></i>
                                                    </span>
                                                  </div>
                                                  <div className="thumbnail-desc-video">
                                                    <p>
                                                      <b>
                                                        {getFileNameFromUrl(
                                                          item
                                                        )}
                                                      </b>
                                                    </p>
                                                    <span>
                                                      {getFileSizeByUrl(item)}
                                                    </span>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                        </div>
                                      )
                                    // subcategory images list ends
                                  }

                                  {
                                    // subcategory document list start
                                    subCategory.isOpen &&
                                      subCategory.acsCatDocGallery &&
                                      subCategory.acsCatDocGallery.length !==
                                        0 && (
                                        <div>
                                          <p
                                            className={
                                              subCategory.openTabs.includes(
                                                "documents"
                                              )
                                                ? "sub-select-list active"
                                                : "sub-select-list"
                                            }
                                            onClick={() =>
                                              openSubCategoryItem(
                                                "documents",
                                                index
                                              )
                                            }
                                          >
                                            <span>
                                              <i
                                                className={
                                                  subCategory.openTabs.includes(
                                                    "documents"
                                                  )
                                                    ? "fas fa-minus-circle"
                                                    : "fas fa-plus-circle"
                                                }
                                              />
                                            </span>
                                            Documents
                                          </p>
                                          {subCategory.openTabs.includes(
                                            "documents"
                                          ) &&
                                            subCategory.acsCatDocGallery.map(
                                              (item, vIndex) => (
                                                <div
                                                  className="sub-class-list"
                                                  key={vIndex}
                                                  onClick={(e) =>
                                                    handleSelectFileForMainPlayer(
                                                      e,
                                                      "documents",
                                                      item
                                                    )
                                                  }
                                                >
                                                  <div className="file-thumbnail-img">
                                                    <span>
                                                      <i className="fas fa-file-image"></i>
                                                    </span>
                                                  </div>
                                                  <div className="thumbnail-desc-video">
                                                    <p>
                                                      <b>
                                                        {getFileNameFromUrl(
                                                          item
                                                        )}
                                                      </b>
                                                    </p>
                                                    <span>
                                                      {getFileSizeByUrl(item)}
                                                    </span>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                        </div>
                                      )
                                    // subcategory images list ends
                                  }
                                </li>
                              )
                            )}
                          </ul>
                        )}

                      {/*{subscriptionDetails?.access_cat_videos ? (
                        <ul className="access-pro-list">
                          videos
                          <li>
                            <h5 className="active">
                              <span>
                                <i class="fas fa-plus-circle"></i>
                              </span>
                              Shoulder
                            </h5>
                            <p className="sub-select-list active">
                              <span>
                                <i class="fas fa-plus-circle"></i>
                              </span>{" "}
                              Videos
                            </p>
                            {subscriptionDetails?.access_cat_videos
                              ?.split(",")
                              .map((video, index) => (
                                <div
                                  className="sub-class-list"
                                  key={index}
                                  onClick={(e) =>
                                    handleSelectFileForMainPlayer(
                                      e,
                                      "videos",
                                      video
                                    )
                                  }
                                >
                                  <div className="file-thumbnail-img">
                                    <span>
                                      <i className="fas fa-file-video"></i>
                                    </span>
                                  </div>
                                  <div className="thumbnail-desc-video">
                                    <p>
                                      <b>{getFileNameFromUrl(video)}</b>
                                    </p>
                                    <span>{getFileSizeByUrl(video)}</span>
                                  </div>
                                </div>
                              ))}
                          </li>
                        </ul>
                      ) : null}*/}

                      {/*{subscriptionDetails?.access_cat_audios ? (
                        <ul className="access-pro-list">
                          {subscriptionDetails?.access_cat_audios
                            ?.split(",")
                            .map((audio, index) => (
                              <li
                                key={index}
                                onClick={(e) =>
                                  handleSelectFileForMainPlayer(
                                    e,
                                    "audios",
                                    audio
                                  )
                                }
                              >
                                <div className="file-thumbnail-img">
                                  <span>
                                    <i class="fas fa-file-audio"></i>{" "}
                                  </span>
                                </div>
                                <div className="thumbnail-desc-video">
                                  <p>
                                    <b>{getFileNameFromUrl(audio)}</b>
                                  </p>
                                  <span>{getFileSizeByUrl(audio)}</span>
                                </div>
                              </li>
                            ))}
                        </ul>
                      ) : null}

                      {subscriptionDetails?.access_cat_documents ? (
                        <ul className="access-pro-list">
                          {subscriptionDetails?.access_cat_documents
                            ?.split(",")
                            .map((document, index) => (
                              <li
                                key={index}
                                onClick={(e) =>
                                  handleSelectFileForMainPlayer(
                                    e,
                                    "documents",
                                    document
                                  )
                                }
                              >
                                <div className="file-thumbnail-img">
                                  <span>
                                    <i className="fas fa-file-alt"></i>
                                  </span>
                                </div>
                                <div className="thumbnail-desc-video">
                                  <p>
                                    <b>{getFileNameFromUrl(document)}</b>
                                  </p>
                                  <span>{getFileSizeByUrl(document)}</span>
                                </div>
                              </li>
                            ))}
                        </ul>
                      ) : null}*/}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default AccessProductDetails;
