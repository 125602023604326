import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { getPageContent } from "../../Redux/Actions/pageAction";
import aboutBannerImg from "../../assets/images/about-banner-img.png";
import "./About_Us.scss";
import { MissionIcon } from "../../Components/svgElements/MissionIcon";
import { VisionIcon } from "../../Components/svgElements/VisionIcon";
import { InstagramIcon } from "../../Components/svgElements/InstagramIcon";
import { FacebookIcon } from "../../Components/svgElements/FacebookIcon";
import { LinkedinIcon } from "../../Components/svgElements/LinkedinIcon";
import doorstepBg from "../../assets/images/doorstep-bg.jpg";
import accessImg from "../../assets/images/access-img.jpg";
import replenishImg from "../../assets/images/replenish-img.jpg";
import customizeImg from "../../assets/images/customize-img.jpg";
import Logo from "../../assets/images-2/logo.png";


class About_Us extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: "",
    };
  }

  async componentDidMount() {
    this.fetchPageContent(this.props.match.params.pageSlug);
  }

  fetchPageContent = async (pageSlug) => {
    const result =
      pageSlug !== "about-us" && (await this.props.getPageContent(pageSlug));
    if (result?.data?.data) {
      this.setState({
        content: result.data.data.content,
        title: result.data.data.title,
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.pageSlug !== this.props.match.params.pageSlug) {
      this.fetchPageContent(nextProps.match.params.pageSlug);
    }
  }

  render() {
    return (
      <div className="aboutus-page">
        <main className="mainContainer">
          {this.props.match.params.pageSlug === "about-us" ? (
            <>
              <section className="aboutus-banner-section">
                <div className="container">
                  <div className="section-inner">
                    <div className="row align-items-center">
                      <div className="col-md-5">
                        <div className="section-content">
                          <h1>
                            About <br />
                            <img src={Logo} alt="Logo" />
                          </h1>
                          <p>
                            Discover Subsciety Marketplace - Where community, simplicity, and quality converge for a seamless shopping experience.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-7">
                        <div className="section-img">
                          <img src={aboutBannerImg} alt="aboutBannerImg" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="subsciety-marketplace-section">
                <div className="container">
                  <div className="section-inner">
                    <div className="section-heading">
                      <h3>Elevate Your Online Business</h3>
                      <h2>With Subsciety Marketplace</h2>
                    </div>
                    <div className="brief">
                      <p>
                        Founded in Cleveland OH, Subsciety is a centralized
                        ecommerce marketplace offering customers a single source
                        shopping experience. Connecting vendors with customers
                        and allowing vendors to start, build, or expand their
                        business through three cross functional categories.
                      </p>
                      <p>
                        We provide our users a more rewarding experience when
                        searching for and shopping multiple industry offerings,
                        by allowing them to easily track, interchange, and
                        manage needs from one place.
                      </p>
                    </div>
                  </div>
                </div>
              </section>

              <section className="strive-section">
                <div className="container">
                  <div className="section-inner">
                    <div className="common-heading">
                      <h2>What We Strive Towards</h2>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="strive-card strive-card-1">
                          <div className="card-inner">
                            <div className="name">
                              <MissionIcon />
                              <h3>Our Mission</h3>
                            </div>
                            <div className="brief">
                              <p>
                                To offer Top-Tier products, customized goods &
                                plans, and online one on one or group sessions.
                              </p>
                              <p>
                                We take pride in the services we offer to our
                                customers and the market-leading vendors that
                                make it all possible. We strive to grow together
                                as a network of high-quality products and
                                services.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="strive-card strive-card-2">
                          <div className="card-inner">
                            <div className="name">
                              <VisionIcon />
                              <h3>Our Vision</h3>
                            </div>
                            <div className="brief">
                              <p>
                                Our vision is to create a seamless online
                                marketplace where innovation meets convenience,
                                ensuring every purchase is not just a
                                transaction, but a step towards a more
                                sustainable and connected world.
                              </p>
                              <p>
                                We aim to redefine the shopping experience by
                                making it more personalized and accessible for
                                everyone.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="doorstep-section">
                <div className="container">
                  <div
                    className="section-inner"
                    style={{ backgroundImage: `url(${doorstepBg})` }}
                  >
                    <div className="row">
                      <div className="col-md-8 offset-md-4 col-lg-6 offset-lg-4">
                        <div className="section-contant">
                          <div className="name">
                            <h2>Bring Your Store To Customers’ Doorstep</h2>
                          </div>
                          <div className="brief">
                            <p>
                              Experience the convenience of connecting directly
                              with your customers by bringing your store to
                              their homes. Let's make shopping effortless and
                              enjoyable together. Reach out to us today to learn
                              more!
                            </p>
                          </div>
                          <div className="action-items">
                            <ul>
                              <li>
                              <a href="mailto:info@subsciety.com">
                                <button className="theme-button primary-button">
                                  Contact Us
                                </button>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://www.instagram.com/subsciety/"
                                  target="_blank"
                                >
                                  <InstagramIcon />
                                </a>
                                <a
                                  href="https://www.facebook.com/Subsciety.info"
                                  target="_blank"
                                >
                                  <FacebookIcon />
                                </a>
                                <a
                                  href="https://www.linkedin.com/company/subsciety-llc"
                                  target="_blank"
                                >
                                  <LinkedinIcon />
                                </a>
                              </li>
                              <li>
                              <a href="mailto:info@subsciety.com">
                                <span>Info@Subciety.com</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="about-vendor-section">
                <div className="container">
                  <div className="section-inner">
                    <div className="common-heading">
                      <h2 className="mb-3">Vendors</h2>
                      <div className="brief">
                        <p>
                          Our network of Subsciety Authorized Vendors are broken
                          down into 3 different categories.{" "}
                        </p>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-sm-6 col-md-6 col-lg-4">
                        <div className="about-vendor-card about-vendor-card-1">
                          <div className="card-img">
                            <img src={accessImg} ali="accessImg" />
                          </div>
                          <div className="card-content">
                            <h3>Access</h3>
                            <p>
                              Offerings include one on one or group classes,
                              lessons, clubs, guides, content, how-tos,
                              webinars, and much more. All through secure online
                              video conferencing with <a href="https://zoom.us/" target="_blank">Zoom Communications.</a>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-4">
                        <div className="about-vendor-card about-vendor-card-2">
                          <div className="card-img">
                            <img src={replenishImg} ali="replenishImg" />
                          </div>
                          <div className="card-content">
                            <h3>Replenish</h3>
                            <p>
                              Offerings include home goods, decor, beauty,
                              women’s, men’s, and baby care, clothing, pet
                              needs, health & wellness supplements, grocery, and
                              many more products.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-4">
                        <div className="about-vendor-card about-vendor-card-3">
                          <div className="card-img">
                            <img src={customizeImg} ali="customizeImg" />
                          </div>
                          <div className="card-content">
                            <h3>Customize</h3>
                            <p>
                              Offerings include customized accessories,
                              clothing, home goods, health & wellness plans,
                              content, recipes, how to’s, special occasion
                              gifts, and many more curated offerings.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          ) : (
            <section className="home-product-section pb-5 mt-4">
              <div className="container">
                <div>
                  {this.props.match.params.pageSlug === "faqs" && (
                    <>
                      <Link to={"/vendor"} className="link-custom-orange">
                        Vendor FAQs
                      </Link>
                    </>
                  )}
                  {this.props.match.params.pageSlug !== "about-us" && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.content
                          ? this.state.content
                          : `Coming soon!`,
                      }}
                    ></div>
                  )}

                  {/* {this.props.match.params.pageSlug === "faqs" && (
                    <>
                      <Link to={"/vendor"} className="link-custom-orange">
                        Vendor FAQs
                      </Link>
                    </>
                  )} */}
                </div>
              </div>
            </section>
          )}
        </main>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getPageContent }, dispatch);
};

export default connect(null, mapDispatchToProps)(About_Us);
