import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const getTax = (taxInfo) => async (dispatch) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${URL}/api/v1/tax`,
      data: taxInfo,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};
