import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const getPlans = ({
  trackingCompany,
  address: {
    firstName,
    telephone,
    addressLine1,
    addressLine2,
    city,
    postCode,
    state,
    countryId,
  },
  productInfo: {
    items: { quantityPerSet },
    productId
  },
}) => async (dispatch) => {
  try {
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/fedex/getrates`,
      data: {
        trackingCompany,
        address: {
          firstName,
          telephone,
          addressLine1,
          addressLine2,
          city,
          postCode,
          state,
          countryId,
        },
        productInfo: {
          items: { quantityPerSet },
          productId
        },
      },
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    console.log("service plans response->", res);
    return res;
  } catch (error) {
    console.log("wrong", error);
    dispatch(handleError(error));
  }
};


export const getUSPSPlanPrice = ({ address, trackingCompany, deliveryPlanName, productInfo }) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/fedex/getrates`,
      data: { address, trackingCompany, deliveryPlanName, productInfo },
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res;
  }
  catch (error) {
    console.log("wrong", error);
    dispatch(handleError(error));
  }
}

export const getUpsPlanPrice = (data) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/fedex/getUpsRates`,
      data: data,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res;
  }
  catch (error) {
    console.log("wrong", error);
    dispatch(handleError(error));
  }
}

export const getAddressValidation = (company,data) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/fedex/address-validation/${company}`,
      data: data,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res;
  }
  catch (error) {
    console.log("wrong", error);
    dispatch(handleError(error));
  }
}
