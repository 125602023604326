import React, { Component } from "react";

import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { getProductList } from "../../Redux/Actions/productAction";
import "./product-list.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import Rating from "@material-ui/lab/Rating";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
let source;

const StyledRating = withStyles({
  iconFilled: {
    color: "#ff6d75",
  },
  iconHover: {
    color: "#ff3d47",
  },
})(Rating);

class Product_Filter_List extends Component {
  catUpdated = false;
  limit = 3;
  start = 0;
  prevStart = 0;
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      hasMore: true,
      category: this.props.match.params.category,
      isLoading: false,
      sort_type: undefined,
    };
    source = axios.CancelToken.source();
  }

  componentDidMount() {
    this.fetchProducts(this.props.match.params.category, true);
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.match.params.category !== this.props.match.params.category) {
      if (source) {
        source.cancel("Landing Component got props changed");
      }
      source = axios.CancelToken.source();
      this.setState({
        items: [],
        hasMore: true,
        category: nextProps.match.params.category,
        isLoading: false,
      });
      this.start = 0;
      this.prevStart = 0;
      this.fetchProducts(nextProps.match.params.category, true);
      window.scrollTo(0, 0);
    }
  }

  async fetchProducts(category, firstLoad) {
    if (this.start !== this.prevStart || firstLoad) {
      await this.props.getProductList(
        category,
        this.start,
        this.limit,
        this.state.sort_type,
        null,
        null,
        source.token
      );
      this.prevStart = this.start;
      this.start += this.limit;

      if (this.props.productList.length > 0) {
        if (firstLoad) {
          this.setState((prevState) => {
            return {
              ...prevState,
              items: [...this.props.productList],
            };
          });
        } else {
          this.setState((prevState) => {
            return {
              ...prevState,
              items: prevState.items.concat(this.props.productList),
            };
          });
        }
      } else {
        this.setState({ hasMore: false });
      }
    }
  }

  fetchMoreData = async () => {
    this.fetchProducts(this.props.match.params.category, false);
  };

  handleProductSorting = async (event, category, isDropDown) => {
    this.limit = 3;
    this.start = 0;
    this.prevStart = 0;
    let event_value;
    if (isDropDown)
    event_value = event;
    else{
      event.preventDefault();

      event_value = event.target.value;
    }
    await this.props.getProductList(category, 0, this.limit, event_value);

    await this.setState({
      sort_type: event_value,
      items: this.props.productList,
      hasMore: true,
    });
    this.prevStart = this.start;
    this.start += this.limit;
  };

  render() {
    const productList =
      this.state.items.length > 0
        ? this.state.items.map((category, index) => {
            return (
              <div className="container-fluid" key={index}>
                {category?.prodList?.length > 0 ? (
                  <>
                    <div className="home-section-heading home-heading-mobile mb-5">
                      <ul>
                        <li>
                          <h2>{category.catName}</h2>
                        </li>
                        <li>|</li>
                        <li>
                          <Link
                            to={`/category/type/${this.props.match.params.category}/${category.slug}`}
                          >
                            See All
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="row">
                      {category.prodList.map((item, itemIndex) => {
                        let isInStock = !item?.isOutOfStock;

                        return (
                          <div className="col-md-3 col-6" key={itemIndex}>
                            <div className="home-product-item">
                              {item.is_sale !== 0 ? (
                                item.price_type === 1 ? (
                                  <div className="sale-label-star">
                                    ${item.sale_price} off
                                  </div>
                                ) : (
                                  <div className="sale-label-star">
                                    {" "}
                                    {Math.round(
                                      100 * (1 - item.sale_price / item.price)
                                    )}
                                    % off
                                  </div>
                                )
                              ) : null}
                              {!item.isAccessCategoryAvailable && item.is_shipping_free ? (
                                <div to="/" className="shipping-label-star">
                                  free shipping
                                </div>
                              ) : null}
                              <Link
                                to={{
                                  pathname: `${this.props.match.url}/${item.slug}`,
                                  product: item,
                                }}
                              >
                                <div className="home-product-box">
                                  <img src={item.imageName} alt="" />

                                  {isInStock ||
                                  item?.isAccessCategoryAvailable ? (
                                    <div className="quickviewbtn">
                                      <p>Quick View</p>
                                    </div>
                                  ) : (
                                    <div className="quickviewbtn">
                                      <p>Sold Out</p>
                                    </div>
                                  )}

                                  {/* <a href="#" className="quickviewbtn">
                                                            <p>Quick View</p>
                                                        </a> */}
                                </div>
                                <div className="home-productbox-detail">
                                  <div className="home-productbox-heading">
                                    <div className="price-inline-remove">
                                      <h2 title={item.name ? item.name : null}>
                                        {item.name ? item.name : `-`}
                                      </h2>
                                      {item.is_sale ? (
                                        <p>
                                          <del>
                                            {item.price
                                              ? `$${item.price
                                                  .toFixed(2)
                                                  .replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  )}`
                                              : `-`}
                                          </del>
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>
                                  <p className="product-price-mobile">
                    {item.is_sale !== 0
                            ? item.price_type === 1
                              ? `$${(item.price - item.sale_price)
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                              : `$${(
                                  item.price -
                                  (item.price *
                                    (100 *
                                      (1 - item.sale_price / item.price))) /
                                    100
                                )
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                            : `${!item.price ? `` : `$${item.price}`}`}
                             {item.is_sale ? (
                          <p>
                            <del>
                              {item.price
                                ? `$${item.price
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                : `-`}
                            </del>
                          </p>
                        ) : null}
                        </p>
                                  <div className="home-productbox-bottom">
                                    <div className="item-rating">
                                      <Rating
                                        name="hover-feedback"
                                        value={item.rating}
                                        precision={0.5}
                                        readOnly
                                      />
                                    </div>
                                    <div className="home-productbox-price">
                                      <p>
                                        {item.is_sale !== 0
                                          ? item.price_type === 1
                                            ? `$${(item.price - item.sale_price)
                                                .toFixed(2)
                                                .replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ","
                                                )}`
                                            : `$${(
                                                item.price -
                                                (item.price *
                                                  (100 *
                                                    (1 -
                                                      item.sale_price /
                                                        item.price))) /
                                                  100
                                              )
                                                .toFixed(2)
                                                .replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ","
                                                )}`
                                          : `${
                                              !item.price
                                                ? ``
                                                : `$${item.price}`
                                            }`}
                                      </p>
                                    </div>
                                  </div>
                                  {!item.isAccessCategoryAvailable && item.is_shipping_free ? (
                    <p className="shipping-mobile-label">FREE SHIPPING</p>
                    ) : null}
                                </div>
                              </Link>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : null}
              </div>
            );
          })
        : null;

    return (
      <main className="mainContainer mt-0">
        <section className="home-product-section pb-5 mt-4">
          <div className="container">
            <div className="select-product-list">
              <div className="product-list-head">
                <ul>
                  <li>
                    <i className="fas fa-plus"></i>{" "}
                    <a>{this.props.match.params.category.replace("-", " ")}</a>
                  </li>
                </ul>
              </div>

              <span data-toggle="dropdown">
                <i className="fas fa-sliders-h" />
              </span>

              <div className="dropdown-menu dropdown-menu-right" 
              onChange={(e) => this.handleProductSorting(e, this.props.match.params.category)
                }>
                <a className="dropdown-item" onClick={(e) => this.handleProductSorting("undefined", this.props.match.params.category,true)} href="#">
                  RANDOM
                </a>
                <a className="dropdown-item" onClick={(e) => this.handleProductSorting("price_h", this.props.match.params.category,true)} href="#">
                  PRICE HIGH TO LOW
                </a>
                <a className="dropdown-item" onClick={(e) => this.handleProductSorting("price_l", this.props.match.params.category,true)} href="#">
                  PRICE LOW TO HIGH
                </a>
                <a className="dropdown-item" onClick={(e) => this.handleProductSorting("rating", this.props.match.params.category,true)} href="#">
                  TOP RATED
                </a>
              </div>

              {/* {productList} */}
              <select
                onChange={(e) =>
                  this.handleProductSorting(e, this.props.match.params.category)
                }
              >
                <option value="undefined">Random</option>
                <option value={"price_h"}>Price High To Low</option>
                <option value={"price_l"}>Price Low To High</option>
                <option value={"rating"}>Top Rated</option>
              </select>
            </div>

            <InfiniteScroll
              dataLength={this.state.items.length}
              next={this.fetchMoreData}
              hasMore={this.state.hasMore}
              loader={(() => {
                if (this.state.isLoading) {
                  return <div className="loaing-area">Loading...</div>;
                }
              })()}
              scrollThreshold="500px"
            >
              {productList}
            </InfiniteScroll>
          </div>
        </section>
        <section className="home-product-section mb-5">
          <div className="container">
            <div className="home-section-heading home-heading-mobile mb-3">
              <ul>
                <li>
                  <h2>
                    Shop by category <span>-</span> all
                  </h2>
                </li>
              </ul>
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4">
                  <Link
                    to={{
                      pathname: "/product-list/category/access",
                      parentId: "3",
                      categoryById: "4",
                    }}
                  >
                    <div className="button-category-shop">access</div>
                  </Link>
                </div>

                <div className="col-md-4">
                  <Link
                    to={{
                      pathname: "/product-list/category/replenish",
                      state: this.state,
                      parentId: "101",
                      categoryById: "5",
                    }}
                  >
                    <div className="button-category-shop ">replenish</div>
                  </Link>
                </div>

                <div className="col-md-4">
                  <Link
                    to={{
                      pathname: "/product-list/category/customize",
                      parentId: "119",
                      categoryById: "6",
                    }}
                  >
                    <div className="button-category-shop ">customize</div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    categoryId: state.productReducer.categoryId,
    categoryName: state.productReducer.categoryName,
    productList: state.productReducer.productListByFilter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getProductList }, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Product_Filter_List)
);
