
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'

import "./login.css";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { socialLogin, socialSignup,updateLoginType } from '../../Redux/Actions/authAction';
import google from "../../assets/images/google.svg";
import { GoogleLogin } from "react-google-login";
const googleClientID = process.env.REACT_APP_GOOGLE_CLIENT_ID;


const GoogleLogin1 = (props) => {

  const [idToken, setIdToken] = useState("");

  React.useEffect(() => {
    if (!props.isForVerification && props.isLoggedIn) {
      localStorage.setItem("userId", props.authData ?.id);
      localStorage.setItem("userData", JSON.stringify(props.authData));
      localStorage.setItem("token", props.authData ?.token);
      localStorage.removeItem("companyId");
      props.history.push("/subscription");
    }

    if (props.isacceptedtnc) {
      props.socialSignup({ idToken, type: 'google' });
    }
  }, [props.isLoggedIn, props.isacceptedtnc]);




  /*const loadFunction = () => {
    console.log("inside load function");
    const successCallback = onSuccess.bind(this);
    const errorCallback = oneError.bind(this)
    window.gapi.load('auth2', () => {
      auth2 = window.gapi.auth2.init({
        client_id: googleClientID,
      })

      // this.auth2.attachClickHandler(document.querySelector('#loginButton'), {}, this.onLoginSuccessful.bind(this))

      auth2.then(() => {
        console.log('on init');
      }).catch(error => console.log("error is", error));
    });

    window.gapi.load('signin2', function () {
      // Method 3: render a sign in button
      // using this method will show Signed In if the user is already signed in
      var opts = {
        // width: 200,
        // height: 50,
        client_id: googleClientID,
        onsuccess: onSuccess,
        onfailure: errorCallback
      }
      window.gapi.signin2.render('loginButton', opts)
    })
  }*/


  const getGoogleIdToken = async (event) => {
    for (const [key, value] of Object.entries(event)) {
      if (Object.keys(value).includes('id_token') === true) {
        return value.id_token;
      }
    }
  }


  const onSuccess = async (event) => {

    let id_token = await getGoogleIdToken(event);

    if (id_token !== undefined) {
      setIdToken(id_token);
      if(props.isForVerification){
        props.socialVerification({ idToken: id_token, type: "google" });
      }else{
        await props.socialLogin({ idToken: id_token, type: "google" });
      }

    }

  }


  const oneError = (event) => {
    console.log("error google", event)
  }


  return (

    <div class="icon-btn-google">
      {/*<div className="icon-most">
        <img src={google} />
        <button
          color="none"
          id="loginButton"
          className="icon-fast"
        >
        </button>
      </div>*/}
      <GoogleLogin
          clientId={googleClientID}
          render={renderProps => (
              <button
                  type="button"
                  className="ui blue basic button button_icon google-custom"
                  disabled={renderProps.disabled}
                  onClick={renderProps.onClick}
              >
                <img src={google} />
                {/*<i class="fab fa-google"></i>*/}
              </button>
          )}
          buttonText="Login"
          onSuccess={onSuccess}
          onFailure={oneError}
          cookiePolicy={"single_host_origin"}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
    isLoggedIn: state.authReducer.isLoggedIn,
    errorMessage: state.commonReducer.errorMessage,
    authData: state.authReducer.authData,
    errorData: state.commonReducer.errorData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ socialLogin, socialSignup, updateLoginType }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GoogleLogin1));