import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import './GlobalCalendar.css'
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { Button, Modal } from "react-bootstrap";




moment.locale("en-GB");
const localizer = momentLocalizer(moment);

const eventStyleGetter = function () {
    const style = {
        background: "#e25e52",
        borderRadius: "10px",
        opacity: 1,
        color: "#fff",
        border: "0px",
        display: "block",
        padding: "2px 10px",
        textAlign: "center",
        margin: "3px",
        outline: "none",
    };
    return {
        style: style,
    };
};

const GlobalCalendar = (props) => {
    const [meetingDetails, setMeetingDetials] = useState({})
    const [showViewDetails, setShowViewDetails] = useState(false)

    const handleToolTip = (event) => {
        if (event) {
            setMeetingDetials(event)
            setShowViewDetails(true)
        }
    }

    const showDetails = () => {

        return <Modal
            show={showViewDetails}
            onHide={() => setShowViewDetails(false)}
            className="calendar-modal"
            centered
        >
            <Modal.Header
                closeButton
                onClick={() => setShowViewDetails(false)}>
                <b>Meeting Details</b>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <div>
                            <label>{meetingDetails?.title}</label>
                            <br />
                            {/* <label>Class Type: {meetingDetails?.start}</label>
                  <br />
                  <label>{meetingDetails.comment}</label> */}
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        {moment(meetingDetails.start).format('MMMM Do YYYY, h:mm a')}
                        {/* {meetingDetails?.start} */}
                    </div>
                </div>
                <div className="modal-btn-group">
                    {(new Date(meetingDetails.end) >= new Date()) ? <a href={meetingDetails.zoomLink} target="_blank"><Button variant="secondary"
                            //onClick={(e) => handleSetMeetingType(e, 0)}
                            className="orange-btn"
                        >
                            Join Now
                        </Button>
                    </a> : ""}
                </div>
            </Modal.Body>
        </Modal>
    }


    return (

        <div>
            <Calendar
                localizer={localizer}
                showMultiDayTimes={true}
                events={props.eventList}
                style={{ height: 700 }}
                selectable
                startAccessor="start"
                endAccessor="end"
                step={30}
                view="week"
                views={["week"]}
                onSelectEvent={(event) => handleToolTip(event)}
                // onSelectSlot={(event) => handleSelectSlot(event)}
                eventPropGetter={eventStyleGetter}
                onNavigate={(e)=>props.fetchAllMeetings(e)}
                // date={selectedDay}
                // onNavigate={(day) => {
                //     setSelectedDay(day);
                // }}
                dayLayoutAlgorithm="no-overlap"
                messages={{ previous: "Previous" }}
                popup={true}
            />
            {showDetails()}
        </div>
    );
};
export default GlobalCalendar


