import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonCalendar from "../../Components/CommonCalendar/CommonCalendar";
import Profile_Sidebar from "../../Containers/Profile-Side-Bar/Profile_Sidebar";
import { getAllMeetingsForCustomer } from "../../Redux/Actions/meetingActions";
import moment from "moment";
import axios from "axios";
let source;
source = axios.CancelToken.source();

function GlobalCalendar() {
  const userId = localStorage.getItem("userId");
  const dispatch = useDispatch();
  const { getAllMeetingsList } = useSelector((state) => state.meetingReducer);
  const { calendarLoading } = useSelector((state) => state.commonReducer);


  useEffect(() => {
    fetchAllMeetings();
  }, []);

  const fetchAllMeetings = (date = new Date()) => {
    if (source){
      source.cancel("Landing Component got props changed");
    }
    source = axios.CancelToken.source();

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const startOfWeekTime = moment(date)
      .startOf("week")
      .format("YYYY-MM-DD HH:mm:ss");
    const endOfWeekTime = moment(date).endOf("week").format("YYYY-MM-DD HH:mm:ss");
    dispatch(getAllMeetingsForCustomer(userId, startOfWeekTime, endOfWeekTime, timezone,source.token));
  };





  return (
    <main className="mainContainer">
      <section className="home-product-section mt-4">
        <div className="container">
          <div className="select-product-list">
            <div className="product-list-head product-list-border-bottom">
              <ul>
                <li>
                  <a>Global Calendar</a>
                </li>
                {calendarLoading && (
              <span className="spinner-border spinner-border-sm ml-2" style={{ minWidth: '1rem', minHeight: '1rem' }}></span>
            )}
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="profile">
        <div className="container">
          <div className="row">
            <Profile_Sidebar />
            <div className="col-lg-9">
              <section className="track-section">
                <div className="container">
                  <div className="custom-calendar-suggest">
                    <CommonCalendar
                      eventList={getAllMeetingsList}
                      fetchAllMeetings={(date) => fetchAllMeetings(date)}
                    />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default GlobalCalendar;
