import React from 'react';

export const InstagramIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <g clip-path="url(#clip0_1214_1013)">
            <path d="M23.9994 0H7.9998C3.6003 0 0 3.6003 0 7.9998V24.0002C0 28.3985 3.6003 32 7.9998 32H23.9994C28.3989 32 31.9992 28.3985 31.9992 24.0002V7.9998C31.9992 3.6003 28.3989 0 23.9994 0ZM29.3325 24.0002C29.3325 26.9401 26.941 29.3333 23.9994 29.3333H7.9998C5.05949 29.3333 2.66673 26.9401 2.66673 24.0002V7.9998C2.66673 5.0591 5.05949 2.66673 7.9998 2.66673H23.9994C26.941 2.66673 29.3325 5.0591 29.3325 7.9998V24.0002Z" fill="white"/>
            <path d="M24.6679 9.33584C25.7725 9.33584 26.6679 8.44043 26.6679 7.33589C26.6679 6.23135 25.7725 5.33594 24.6679 5.33594C23.5634 5.33594 22.668 6.23135 22.668 7.33589C22.668 8.44043 23.5634 9.33584 24.6679 9.33584Z" fill="white"/>
            <path d="M15.9998 8C11.5807 8 8 11.5811 8 15.9998C8 20.4169 11.5807 24.0004 15.9998 24.0004C20.4177 24.0004 23.9996 20.4169 23.9996 15.9998C23.9996 11.5811 20.4177 8 15.9998 8ZM15.9998 21.3337C13.0546 21.3337 10.6667 18.9457 10.6667 15.9998C10.6667 13.0539 13.0546 10.6667 15.9998 10.6667C18.945 10.6667 21.3329 13.0539 21.3329 15.9998C21.3329 18.9457 18.945 21.3337 15.9998 21.3337Z" fill="white"/>
        </g>
        <defs>
            <clipPath id="clip0_1214_1013">
            <rect width="32" height="32" fill="white"/>
            </clipPath>
        </defs>
    </svg>
  );
};