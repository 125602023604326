import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const getOrdersList = (userId, offset) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/orders/${userId}?offset=${offset}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
}
export const requestOrderReturn = (orderId, userId, message, sellerId) => async (dispatch) => {
    try {
        const res = await axios({
            method: "post",
            url: `${URL}/api/v1/orders/${userId}/request-order-return/${orderId}?sellerId=${sellerId}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            data: {message}
        });
        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
}


export const updateShippingCompanyAndTrackingId = (userId,orderId,body) => async (dispatch) => {
    try {
        const res = await axios({
            method: "post",
            url: `${URL}/api/v1/orders/${userId}/update-tracking-shipping-company/${orderId}`,
            data: body,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
}






export const getDisputesList = (userId, offset) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/dispute/${userId}?offset=${offset}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
}

export const getOrderDetail = (userId, orderId,timezone) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/orders/${userId}/${orderId}?timezone=${timezone}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
}

export const getMultipleOrderDetail = (orderIdArray,selectedCardId) => async (dispatch) => {
    if(selectedCardId){
        try {
            const res = await axios({
                method: "get",
                url: `${URL}/api/v1/orders/35/order-detail/${JSON.stringify(orderIdArray)}/${selectedCardId}`,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                }
            });
            return res;
        }
        catch (error) {
            dispatch(handleError(error));
        }
    }else {
        try {
            const res = await axios({
                method: "get",
                url: `${URL}/api/v1/orders/35/order-detail/${JSON.stringify(orderIdArray)}/${selectedCardId}`,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                }
            });
            return res;
        }
        catch (error) {
            dispatch(handleError(error));
        }
    }
}

export const getOrderTracking = (trackingId) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/fedex/gettracks/${trackingId}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });

        return res;
    }
    catch (error) {
        console.log("error while getting order details", error)
        dispatch(handleError(error));
    }
}
export const cancelOrderByOrderId = (orderId, type) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/update-order/cancel-order/${orderId}?type=${type}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });

        return res;
    }
    catch (error) {
        console.log("error while getting order details", error)
        dispatch(handleError(error));
    }
}


