import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { signup, vemail, login, logout } from "../../Redux/Actions/authAction";

import { getPageContent } from "../../Redux/Actions/pageAction";
import CryptoJS from "react-native-crypto-js";

import FacebookLogin from "../SocialLogin/facebookLogin";
import GoogleLogin1 from "../SocialLogin/googleLogin";

class Login extends Component {
  statePrevCopy = {};

  constructor(props) {
    super(props);
    this.state = {
      formValues: {
        email: "",
        password: "",
        rememberme: false,
        isacceptedtnc: false,
      },
      content: "",
      title: "",
      errorData: {},
      errorMessage: "",
      vcodeFlag: false,
      isLogedIn: false,
      showLoader: false,
    };
    let rememberme = localStorage.getItem("rememberme");

    if (rememberme) {
      rememberme = CryptoJS.AES.decrypt(rememberme, "remembermesecret$#");
      rememberme = rememberme.toString(CryptoJS.enc.Utf8);
      rememberme = JSON.parse(rememberme);
      this.state.formValues = rememberme;
    }
    this.openModal = React.createRef();
  }

  validateUserInfo = () => {
    let isFormValid = true;
    let formValues = this.state.formValues;
    this.state.errorData = {
      email: [],
      password: [],
    };

    if (formValues.email.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.email = ["Email field cannot be empty."];
    }

    if (formValues.password.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.password = ["Password field cannot be empty."];
    }

    let tempState = this.state;

    this.setState({
      ...tempState,
    });

    return isFormValid;
  };

  handleLogin = async (event) => {

    event.preventDefault();

    const productPage = this.props?.history?.location?.state

    await this.setState({ showLoader: true });

    if (this.validateUserInfo()) {
      if (this.state.formValues.rememberme) {
        const rememberme = CryptoJS.AES.encrypt(
          JSON.stringify(this.state.formValues),
          "remembermesecret$#"
        ).toString();
        localStorage.setItem("rememberme", rememberme);
      } else {
        localStorage.removeItem("rememberme");
      }

      await this.props.login({ ...this.state.formValues });
      if (this.props.isLoggedIn) {
        localStorage.setItem("userId", this.props.authData?.id);
        localStorage.setItem("userData", JSON.stringify(this.props.authData));
        localStorage.setItem("token", this.props.authData?.token);
        localStorage.removeItem("companyId");
        this.setState({ isLogedIn: true, showLoader: false });
        // add condition here to redirect again to product details page
        if (productPage?.state === 'productDetails') {
          this.props.history.push(productPage?.history)
        } else {
          this.props.history.push("/subscription");
        }
      } else {
        this.setState({
          errorData: this.props.errorData,
          errorMessage: this.props.errorMessage,
          showLoader: false,
        });
      }
    } else {
      await this.setState({ showLoader: false });
    }
  };

  handleChange = (event, field) => {
    if (
      this.state.errorData &&
      this.state.errorData[field] &&
      this.state.errorData[field][0]
    ) {
      this.state.errorData[field][0] = null;
    }
    const test = this.state;
    if (field === "rememberme") {
      test.formValues[field] = event.target.checked;
    } else {
      test.formValues[field] = event.target.value;
    }
    this.setState({ ...test });
  };

  onCancelTnC = () => {
    this.state.formValues.isacceptedtnc = false;
    this.setState({ ...this.state });
    this.props.logout();
  };

  onAcceptTnC = () => {
    this.state.formValues.isacceptedtnc = true;

    this.setState({ ...this.state });
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.isFound !== this.props.isFound &&
      nextProps.isFound === false
    ) {
      this.statePrevCopy = JSON.parse(JSON.stringify(this.state));
      // call the api for tnc content
      this.fetchPageContent("terms-and-condition-for-customer");
      this.openModal.current.click();
    }

    if (nextProps.errorMessage !== this.props.errorMessage) {
      this.setState({
        errorMessage: nextProps.errorMessage,
      });
      // this.props.logout();
    }
  }

  fetchPageContent = async (pageSlug) => {
    const result = await this.props.getPageContent(pageSlug);
    if (result?.data?.data) {
      this.setState({
        content: result.data.data.content,
        title: result.data.data.title,
      });
    }
  };

  // handleErrorMessageFromChild = () => {
  //     this.setState({
  //         errorMessage: this.props.errorMessage
  //     });
  // }

  render() {
    return (
      <div>
        <main className="mainContainer">
          <div className="container">
            <div className="signup">
              <form>
                <div className="signup-head" onSubmit={(event) => this.handleLogin(event)}>
                  <h2>SIGN IN</h2>
                  {/* <p>WELCOME BACK! LOGIN TO ACCESS HOME GROWN.</p> */}
                </div>
                {(() => {
                  if (this.state.errorMessage !== "") {
                    return (
                      <div className="alert alert-danger">
                        {this.state.errorMessage}
                      </div>
                    );
                  }
                })()}
                <div className="signup-mid">
                  {/* <h3>PERSONAL INFORMARTION</h3> */}
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email Address"
                          value={this.state.formValues.email}
                          onChange={(event) =>
                            this.handleChange(event, "email")
                          }
                        />
                        <span className="form-field-error">
                          {this.state.errorData?.email &&
                            this.state.errorData.email[0]}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Password"
                          value={this.state.formValues.password}
                          onChange={(event) =>
                            this.handleChange(event, "password")
                          }
                        />
                        <span className="form-field-error">
                          {this.state.errorData?.password &&
                            this.state.errorData.password[0]}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="signup-trums-check">
                    <div className="sign-check">
                      <label className="custom-check">
                        REMEMBER ME
                        <input
                          type="checkbox"
                          checked={this.state.formValues.rememberme}
                          onChange={(event) =>
                            this.handleChange(event, "rememberme")
                          }
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>

                <button
                  style={{ display: "none" }}
                  ref={this.openModal}
                  type="button"
                  data-toggle="modal"
                  data-target="#exampleModal"
                ></button>

                <div
                  className="modal fade modal-blog"
                  id="exampleModal"
                  data-backdrop="static"
                  data-keyboard="false"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog  modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Terms & Conditions
                        </h5>
                        <button
                          type="button"
                          onClick={this.onCancelTnC}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                      <div className="modal-blog-detail">
                        <div className="container-fluid row">
                          <h1>
                            <b>{this.state.title}</b>
                          </h1>
                          <div>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: this.state.content
                                  ? this.state.content
                                  : `-`,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>

                      <label
                        className="custom-check"
                        style={{ marginTop: "20px" }}
                      >
                        accept terms of use
                        <input type="checkbox" defaultChecked={true} />
                        <span className="checkmark"></span>
                      </label>
                      <div className="button-center">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={this.onCancelTnC}
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={this.onAcceptTnC}
                          data-dismiss="modal"
                        >
                          Accept
                        </button>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>

                <div className="signup-bottom">
                  <div className="signup-submit">
                    <button
                      onClick={(event) => this.handleLogin(event)}
                      type="submit"
                    >
                      Sign In
                      {(() => {
                        if (this.state.showLoader) {
                          return (
                            <span className="spinner-border spinner-border-sm ml-1"></span>
                          );
                        }
                      })()}
                    </button>
                    <div className="icon-same-line">
                      <GoogleLogin1
                        isacceptedtnc={
                          this.props.loginType === "google"
                            ? this.state.formValues.isacceptedtnc
                            : null
                        }
                      ></GoogleLogin1>
                      <FacebookLogin
                        isacceptedtnc={
                          this.props.loginType === "facebook"
                            ? this.state.formValues.isacceptedtnc
                            : null
                        }
                      ></FacebookLogin>
                    </div>
                  </div>
                  <div className="forgot-password">
                    <Link to={"/forgot-password"}>FORGOT YOUR PASSWORD?</Link>
                  </div>
                  <div className="already-account-link">
                    <p>
                      don't have an account?{" "}
                      <Link to={"/signup"}>register now</Link>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    isLoggedIn: state.authReducer.isLoggedIn,
    isFound: state.authReducer.isFound,
    loginType: state.authReducer.loginType,
    errorMessage: state.commonReducer.errorMessage,
    authData: state.authReducer.authData,
    errorData: state.commonReducer.errorData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { signup, vemail, login, logout, getPageContent },
    dispatch
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
