import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;


export const orderReturnRequestList = (userId, offset) => async (dispatch) => {
    try {
        const res = await axios({
          method: "get",
          url: `${URL}/api/v1/request-order-return/get-all/${userId}?offset=${offset}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        
        return res;
      } catch (error) {
        dispatch(handleError(error));
      }
}