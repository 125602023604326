import React, { useState, useEffect } from "react";
// import './App.css';
import { loadStripe } from "@stripe/stripe-js";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { checkoutItem } from "../../Redux/Actions/cartAction";
const URL = process.env.REACT_APP_API_URL;

// Make sure to call `loadStripe` outside of a component’s render to avoid
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
if (!process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) {
  console.error("**Stripe publishable key environment variable not set**");
  console.error(
    "**Add an environemnt variable REACT_APP_STRIPE_PUBLISHABLE_KEY**"
  );
  console.error("**Replace .env.example with .env and **");
}

const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  maxLength,
  autoComplete,
  value,
  onChange,
}) => (
  <div className="FormRow">
    <label htmlFor={id} className="FormRowLabel">
      {label}
    </label>
    <input
      className="FormRowInput"
      id={id}
      type={type}
      maxLength={maxLength}
      placeholder={placeholder}
      required={required}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
    />
  </div>
);

const CheckoutForm = ({
  productSelected,
  customer,
  cartItems,
  amount,
  handleStripeResponse,
  handleClickKey,
  handleRestForm,
  email,
  address,
  state,
  city,
  handleNewCardDetails
}) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [subscribing, setSubscribing] = useState(false);
  const [accountInformation, setAccountInformation] = useState(null);
  const [errorToDisplay, setErrorToDisplay] = useState("");
  const [errorToDisplayCard, setErrorToDisplayCard] = useState("");
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [isFormSubmited, setIsFormSubmited] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [errorDetail, setErrorDetail] = useState(null);
  const [ref, setRef] = useState(null)
  const userData = localStorage.getItem("userData");
  // const [isFormValidate , setIsFormValidate] = useState()

  const userId = localStorage.getItem("userData");

  const [billingDetails, setBillingDetails] = useState({
    email: userData?JSON.parse(userData).email:"",
    name: "",
    phone: userData?JSON.parse(userData).phonenumber:"",
    address: {
      city: "",
      state: "",
      postal_code: userData?JSON.parse(userData).zipCode:"",
      line1: "",
    },
  });
  // useEffect(() => {
  //   setBillingDetails({ ...billingDetails, email: email })
  // }, [email])

  function handlePaymentThatRequiresCustomerAction({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry,
  }) {
    if (subscription && subscription.status === "active") {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    const paymentIntent = invoice
      ? invoice.payment_intent
      : subscription.latest_invoice.payment_intent;

    if (
      paymentIntent.status === "requires_action" ||
      (isRetry === true && paymentIntent.status === "requires_payment_method")
    ) {
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            // start code flow to handle updating the payment details
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            throw result;
          } else {
            if (result.paymentIntent.status === "succeeded") {
              // There's a risk of the customer closing the window before callback
              // execution. To handle this case, set up a webhook endpoint and
              // listen to invoice.payment_succeeded. This webhook endpoint
              // returns an Invoice.
              return {
                priceId: priceId,
                subscription: subscription,
                invoice: invoice,
                paymentMethodId: paymentMethodId,
              };
            }
          }
        });
    } else {
      // No customer action needed
      return { subscription, priceId, paymentMethodId };
    }
  }

  function handleRequiresPaymentMethod({
    subscription,
    paymentMethodId,
    priceId,
  }) {
    if (subscription.status === "active") {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    } else if (
      subscription.latest_invoice.payment_intent.status ===
      "requires_payment_method"
    ) {
      // Using localStorage to store the state of the retry here
      // (feel free to replace with what you prefer)
      // Store the latest invoice ID and status
      localStorage.setItem("latestInvoiceId", subscription.latest_invoice.id);
      localStorage.setItem(
        "latestInvoicePaymentIntentStatus",
        subscription.latest_invoice.payment_intent.status
      );
      throw new Error("Your card was declined.");
    } else {
      return { subscription, priceId, paymentMethodId };
    }
  }

  function retryInvoiceWithNewPaymentMethod({ paymentMethodId, invoiceId }) {
    const priceId = productSelected.name.toUpperCase();
    return (
      fetch("/retry-invoice", {
        method: "post",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          customerId: customer.id,
          paymentMethodId: paymentMethodId,
          invoiceId: invoiceId,
        }),
      })
        .then((response) => {
          return response.json();
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the addional details we need.
        .then((result) => {
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            invoice: result,
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            isRetry: true,
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handlePaymentThatRequiresCustomerAction)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          setSubscribing(false);
          setErrorToDisplay(error && error.error && (error.error.decline_code || error.error.code));
        })
    );
  }

  function onSubscriptionComplete(result) {
    // Payment was successful. Provision access to your service.
    // Remove invoice from localstorage because payment is now complete.
    // clearCache();
    if (result && !result.subscription) {
      const subscription = { id: result.invoice.subscription };
      result.subscription = subscription;
      localStorage.clear();
    }

    setAccountInformation(result);
    // Change your UI to show a success message to your customer.
    // onSubscriptionSampleDemoComplete(result);
    // Call your backend to grant access to your service based on
    // the product your customer subscribed to.
    // Get the product by using result.subscription.price.product
  }

  function createSubscription({ paymentMethodId, product_id, cartItems }) {
    // const priceId = productSelected.name.toUpperCase();
    
    return (
      fetch(`${URL}/api/v1/stripe/createHocPrice`, {
        method: "post",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          // customerId: customer.id,
          paymentMethodId: paymentMethodId,
          // productId: product_id,
          cartItems,
        }),
      })
        .then((response) => {
          return response.json();
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned
        // by Stripe. Add the addional details we need.
        .then((result) => {
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            subscription: result,
            paymentMethodId: paymentMethodId,
            priceId: productSelected.name,
          };
        })
        // Some payment methods require a customer to do additional
        // authentication with their financial institution.
        // Eg: 2FA for cards.
        .then(handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail. You will
        // get a requires_payment_method error.
        .then(handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          setSubscribing(false);
          setErrorToDisplay(error.message || error.error.decline_code);
        })
    );
  }



  useEffect(() => {
    if (handleClickKey) {
    
      setIsFormSubmited(true);
      handleSubmit();
    }
  }, [handleClickKey]);


  useEffect(() => {
    if (handleRestForm) {
      setIsFormSubmited(false);
      if(ref){
        ref.clear()
      }
    }
  }, [handleRestForm]);

  useEffect(() => {
    handleNewCardDetails(billingDetails)
  },[billingDetails])

  const [stripePaymentResponse, setPaymentResponse] = useState(null);

  const handleSubmit = async (event) => {
    // Block native form submission.
    // event.preventDefault();

    setSubscribing(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);
    const payload = await stripe.createToken(cardElement);
    // If a previous payment was attempted, get the lastest invoice
    const latestInvoicePaymentIntentStatus = localStorage.getItem(
      "latestInvoicePaymentIntentStatus"
    );
    // Use your card Element with other Stripe.js APIs
    // billingDetails[`address`][`city`] = billingDetails.city
    // billingDetails[`address`][`state`] = billingDetails.state
    // billingDetails[`address`][`postal_code`] = billingDetails.zip
    // billingDetails[`address`][`line1`] = billingDetails.line1

    
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: billingDetails,
    });

    if (error) {
      setSubscribing(false);
      setErrorToDisplayCard(error && error.message);
      handleStripeResponse(null, {});
      console.log(error,'error');
      if (error.code == 'email_invalid') {
        setInvalidEmail(true);
      }else if (error.code === '"card_declined"') {
        setErrorToDisplayCard("Invalid Card Details!")
      }
      if (error.code) {
        setErrorDetail(error.code)
      }


      return;
    }
    setErrorToDisplay('')
    setErrorToDisplayCard('')
    setInvalidEmail(false);
    setErrorDetail(null)
    const paymentMethodId = paymentMethod.id;
    if (paymentMethodId) {
      setPaymentResponse(paymentMethod);
      handleStripeResponse(paymentMethod, payload);
      setSubscribing(false);
    }

    // if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
    // Update the payment method and retry invoice payment
    // const invoiceId = localStorage.getItem('latestInvoiceId');
    // retryInvoiceWithNewPaymentMethod({
    //     paymentMethodId: paymentMethodId,
    //     invoiceId: invoiceId,
    // });
    // return;

    // }

    // Create the subscription
    // createSubscription({
    //     paymentMethodId: paymentMethodId,
    //     cartItems: cartItems
    // });
  };

  const handleChange = (e , key)=>{
    let value = (key == "phone") ? e : e.target?.value;
    if (key == "postal_code" || key == "phone") {
      // value = value.replace(/[^a-zA-Z ]/g, "");
      value = value?.replace(/\D+/g, "");
    }

    if(key == "postal_code"){
      setBillingDetails((prevState) => ({
        ...prevState,
        address : {
          ...prevState.address , [key]: value,
        }
        
      }));
    }
    else{
      setBillingDetails((prevState) => ({
        ...prevState,
         [key]: value,
      }));
    }


  }
  const autoFillAddress = (e) =>{
   if(e.target.checked){
    setBillingDetails({...billingDetails,
      address:{
        ...billingDetails.address,
        line1 : address,
      state : state,
      city : city}
    })
   }else {
    setBillingDetails({...billingDetails,
      address:{
        ...billingDetails.address,
        line1 : "", state: "" , city: ""
      }
    })
  }
}

  const handlCardDetailChange = (e) => {
    setCardComplete(e.complete);

    if (e.empty === false) {
      if (stripePaymentResponse) {
        handleStripeResponse(null, {});
      } else {
        handleStripeResponse(false, {});
      }
    }
  };

  if (accountInformation) {
    return (
      <Redirect
        to={{
          pathname: "/account",
          state: { accountInformation: accountInformation },
        }}
      />
    );
  } else {
    return (
      <div id="payment-form" className="flex justify-center">
        <div className="w-full inline-block border p-2 rounded-md">
          <div className="w-full">
            <form id="payment-form">
              <div
                className="appearance-none block w-full bg-gray-200 text-gray-700 rounded-md py-3 px-2 leading-tight focus:outline-none focus:bg-white"
                id="card-element"
              >
                <div className="row">
                  <div className="col-md-12">
                    <Field
                      id="name"
                      type="text"
                      placeholder="Name"
                      required
                      autoComplete="name"
                      value={billingDetails.name}
                      onChange={(e) => {
                        setBillingDetails({
                          ...billingDetails,
                          name: e.target.value,
                        });
                      }}
                    />
                    <span className="form-field-error">
                      {
                        errorToDisplay && errorToDisplay?.length > 0 &&
                            <span>{errorToDisplay}</span>
                      }
                      {(!billingDetails.name && (isFormSubmited === true)) ? (
                        <span>Name cannot be empty.</span>  
                      ) : (isFormSubmited && errorDetail == 'name_invalid') ? (
                        <span>Please enter valid Name.</span>
                      ) : (billingDetails.name?.search("^[a-zA-Z ]+$") && (isFormSubmited === true)) ?
                          ( <span>Name contain only letters.</span>
                      ) : null}
                    </span>
                  </div>
                  <div className="col-md-6">
                    <Field
                      id="email"
                      type="text"
                      placeholder="Email"
                      required
                      autoComplete="email"
                      value={billingDetails.email}
                      onChange={(e) => {
                        setBillingDetails({
                          ...billingDetails,
                          email: e.target.value,
                        });
                        if (errorDetail == 'email_invalid') {
                          setErrorDetail(null);
                        }
                      }}
                    />
                    <span className="form-field-error">

                      {(isFormSubmited && (!billingDetails.email || errorDetail == 'email_invalid')) ? (
                        <span>Please enter valid email address</span>
                      ) : (billingDetails?.email?.search("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$") && (isFormSubmited === true)) ?
                      (<span>Enter correct Email Address</span>
                      ) : null}

                    </span>
                  </div>
                  <div className="col-md-6">
                    {/* <Field
                      id="phone"
                      type="text"
                      maxLength="13"
                      placeholder="Phone"
                      required
                      autoComplete="phone"
                      value={billingDetails.phone}
                      onChange={(e) => {
                        {setBillingDetails({
                          ...billingDetails,
                          phone: e.target.value,
                        });  handleChange(e , "phone")}
                      }}
                    /> */}
                    <PhoneInput
                     className="phone-input"
                      country={'us'}
                      value={billingDetails.phone}
                      onChange={(e) => {
                        {setBillingDetails({
                          ...billingDetails,
                          phone: e.target?.value,
                        });  handleChange(e , "phone")}
                      }}
                      placeholder="Phone"
                      onlyCountries={["us"]}
                    />
                    <span className="form-field-error">
                      {(!billingDetails.phone && (isFormSubmited === true)) ? (
                        <span>Phone cannot be empty.</span>
                      ) : (isFormSubmited && errorDetail == 'phone_invalid') ? (
                        <span>Please enter valid Phone.</span>
                      ) : ((billingDetails.phone?.length < 9) && (billingDetails.phone?.length > 13) && (isFormSubmited === true)) ?
                          ( <span>Please Enter correct phone Number </span>
                      ) : null }

                    </span>
                  </div>
                  <div className="col-md-12">
                    <Field
                      id="line1"
                      type="text"
                      placeholder="Address"
                      required
                      autoComplete="address"
                      value={billingDetails.address.line1}
                      onChange={(e) => {
                        setBillingDetails({
                          ...billingDetails,
                          address: {
                            ...billingDetails.address,
                            line1: e.target.value,
                          },
                        });
                      }}
                    />
                    {/* <span className="form-field-error">
                      {(!billingDetails.address.line1 && isFormSubmited === true) ? (
                        <span>Address cannot be empty.</span>
                      ) : (billingDetails.email.search("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$") && isFormSubmited === true) ?
                        (this.state.errorData.email = ["Enter correct Email Address"]
                      ) : null
                      }

                    </span> */}
                  </div>
                  <div className="col-md-4">
                    <Field
                      id="city"
                      type="text"
                      placeholder="City"
                      required
                      autoComplete="city"
                      value={billingDetails.address.city}
                      onChange={(e) => {
                        setBillingDetails({
                          ...billingDetails,
                          address: {
                            ...billingDetails.address,
                            city: e.target.value,
                          },
                        });
                      }}
                    />
                    {/* <span className="form-field-error">
                      {(!billingDetails.address.city && (isFormSubmited === true)) ? (
                        <span>City cannot be empty.</span>
                      ) :  (billingDetails.address.city.search("^[a-zA-Z]+$") && (isFormSubmited === true)) ?
                      ( <span>City contain only letters.</span>
                  ) : null}

                    </span> */}
                  </div>
                  <div className="col-md-4">
                    <Field
                      id="state"
                      type="text"
                      placeholder="State"
                      required
                      autoComplete="state"
                      value={billingDetails.address.state}
                      onChange={(e) => {
                        setBillingDetails({
                          ...billingDetails,
                          address: {
                            ...billingDetails.address,
                            state: e.target.value,
                          },
                        });
                      }}
                    />
                    {/* <span className="form-field-error">
                      {(!billingDetails.address.state && (isFormSubmited === true)) ? (
                        <span>State cannot be empty.</span>
                      ) :  (billingDetails.address.state.search("^[a-zA-Z]+$") && (isFormSubmited === true)) ?
                      ( <span>State contain only letters.</span>
                  ) : null}

                    </span> */}
                  </div>
                  <div className="col-md-4">
                    <Field
                      id="postal_code"
                      type="text"
                      maxLength="6"
                      placeholder="Zip"
                      required
                      autoComplete="postal_code"
                      value={billingDetails.address.postal_code}
                      onChange={(e) => {
                        {setBillingDetails({
                          ...billingDetails,
                          address: {
                            ...billingDetails.address,
                            postal_code: e.target.value,
                          },
                        }); handleChange(e , "postal_code")}
                      }}
                    />
                    <span className="form-field-error">
                      {(!billingDetails.address.postal_code && (isFormSubmited === true)) ? (
                        <span>Postal code cannot be empty.</span>
                      ) : (!((billingDetails.address.postal_code?.length > 4) && (billingDetails.address.postal_code?.length < 7)) && (isFormSubmited === true)) ?
                        (<span>please Enter Correct 5 or 6 digit code.</span>
                      ) : null
                    }

                    </span>
                  </div>

                  <div className="col-md-4">
                    <input 
                    type = "checkbox"
                    onClick={autoFillAddress}
                    
                    />
                    <span className="font12"> Same As Shipping Address</span> 
                  </div>

                  <div className="col-md-12">
                    <CardElement
                      className="border-card-color"
                      onChange={(e) => {
                        // setError(e.error);
                        // setCardComplete(e.complete);
                        handlCardDetailChange(e);
                      }}
                      onReady={e => setRef(e)}
                      options={{
                        style: {
                          base: {
                            fontSize: "12px",
                            color: "#32325d",
                            fontFamily:
                              "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif",
                            "::placeholder": {
                              color: "#a0aec0",
                            },
                          },
                          invalid: {
                            color: "#9e2146",
                            border: "1px solid",
                            backgroundColor: "#f5d4d3"
                          },
                        },
                      }}
                    />
                    <span className="form-field-error">
                                          {
                        errorToDisplayCard && errorToDisplayCard?.length > 0 &&
                            <span>{errorToDisplayCard}</span>
                      }
                      </span>
                  </div>
                </div>

                {/* <CardExpiryElement /> */}
              </div>
              {/* <button
                id="submit-premium"
                className="w-full bg-pasha hover:bg-white hover:shadow-outline hover:text-pasha hover:border hover:border-black focus:shadow-outline text-white focus:bg-white focus:text-pasha font-light py-2 px-4 rounded-md"
                type="submit"
              >
                <div className="">
                  <div>{subscribing ? "Subscribing..." : "Subscribe"}</div>
                </div>
              </button> */}
            </form>
          </div>
          {/* get saved cards here */}
        </div>
      </div>
    );
  }
};

const PaymentForm = (props) => (
  <Elements stripe={stripePromise}>
    <CheckoutForm {...props} />
  </Elements>
);

export default PaymentForm;
