import axios from 'axios';
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const getSellerStoreDetailById = (storeId,timezone) => async (dispatch) => {
    try {
        dispatch({ type: "LOADING_STARTED" });
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/seller-store/${storeId}?timezone=${timezone}`
        });
        dispatch({ type: "LOADING_COMPLETED" });
        return res.data
    } catch (error) {
        dispatch(handleError(error));
    }
}

export const getRatingForUser = (userId, storeId ) => async (dispatch) => {
    try {
        dispatch({ type: "LOADING_STARTED" });
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/seller-store/checkCustomerReviewWithUserId/${userId}/${storeId}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        dispatch({ type: "LOADING_COMPLETED" });
        return res.data;
    }
    catch (error) {
        dispatch(handleError(error));
    }
}

export const submitRatingReview = (data) => async (dispatch) => {
    try {
        const res = await axios({
            method: "post",
            url: `${URL}/api/v1/seller-store/createRating`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            data: data
        });

        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
}
export const getSellerByName = (searchText,category) => async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
  
      const res = await axios({
          method: "get",
          url: `${URL}/api/v1/seller/search-category/searchVendorByName/?searchText=${searchText}&${
            category && `category=${category}`
          }`,
          headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
          }
      });
      dispatch({ type: "LOADING_COMPLETED" });
  
      return res.data;
  }
  catch (error) {
      dispatch(handleError(error));
  }
  }