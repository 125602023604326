import React, { Component } from "react";
import "../../Styles/track-orders.scss";
import Profile_Sidebar from "../Profile-Side-Bar/Profile_Sidebar";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getOrderTracking } from "../../Redux/Actions/orderAction";

class Order_Track extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderDetail: {},
      orderTrackRecord: [],
      orderTrackStatusRecord: [],
      orderTrackingTemplate: [],
      orderTrackType: [],
      showLoader: false,
      shippingStatus: "",
      service: "",
    };
  }

  componentDidMount() {
    this.fetchOrdertrackInfo(true);
  }

  fetchOrdertrackInfo = async (firstFetch) => {
    this.setState({
      orderTrackRecord: [],
      orderTrackStatusRecord: [],
      orderTrackingTemplate: [],
      showLoader: firstFetch ? false : true,
    });
    const result = await this.props.getOrderTracking(
      this.props.match.params.trackId
    );
    if (result?.data && result?.data?.data) {
      if (result.data.data?.trackRes?.CompletedTrackDetails?.length > 0) {
        this.createFedexTrackingDetail(result);
      } else if (result.data.data.trackRes?.elements?.length > 0) {
        this.createUspsTrackingDetail(result.data.data);
      } else if (result.data.data && (result.data.data?.warnings || result.data.data?.package)) {
        this.createUpsTrackingDetail(result.data);
      } else {
        this.setState({
          orderDetail: result.data.data,
          showLoader: false,
        });
      }
    } else {
      this.setState({
        showLoader: false,
      });
    }
  };

  createFedexTrackingDetail = (trackdetail) => {
    let shippingStatus = trackdetail.data.data.Status;
    let trackDetail =
        trackdetail.data.data.trackRes.CompletedTrackDetails[0].TrackDetails;
    let trackStatus = [];
    trackStatus.push(trackDetail.status);
    let trackingDetails = trackDetail[0]?.Events?.map((trackItem, index) => {
      trackStatus.push(trackItem.EventDescription);
      if(trackStatus.includes("Delivered")){
        shippingStatus = "Delivery"
      }else if(trackStatus.includes("Picked up")){
        shippingStatus = "pickedUp"
      }else if(trackStatus.includes("In transit")){
        shippingStatus = "shipped"
      }
      const timestamp = trackItem.Timestamp
          ? new Date(trackItem.Timestamp).toLocaleString()
          : `-`;
      const address = trackItem.Address;
      return (
          <tr key={index}>
            <td>
              <div className="track-time"><p>{timestamp}</p></div>
            </td>
            <td>
              <div className="track-status">
                <p>{trackItem.EventDescription ? trackItem.EventDescription : "-"}</p>
              </div>
            </td>
          </tr>
      );
    });

    this.setState({
      shippingStatus: shippingStatus,
      trackDetail,
      orderTrackRecord: trackDetail,
      orderTrackStatusRecord: trackStatus,
      orderTrackingTemplate: trackingDetails,
      showLoader: false,
      service: "Fedex",
    });

    return;
  };

  createUspsTrackingDetail = (orderDetail) => {
    let Shippingstatus = orderDetail.Status;
    let trackDetail = orderDetail.trackRes;
    let trackStatus = [];
    const trackingDetails = trackDetail.elements.map((trackItem, index) => {
      trackStatus.push(trackItem.elements[0].text);
      return (
        <tr key={index}>
          <td>
            <div className="track-time"></div>
          </td>
          <td>
            <div className="track-status">
              <p>
                {trackItem.elements[0].text ? trackItem.elements[0].text : "-"}
              </p>
            </div>
          </td>
        </tr>
      );
    });
    for(let track of trackStatus){
      if(track.includes("delivered")){
        Shippingstatus = "Delivery"
        break;
      }else if(track.includes("Arrived at USPS Facility")){
        Shippingstatus = "pickedUp"
        break;
      }else if(track.includes("In Transit")){
        Shippingstatus = "shipped"
        break;
      }
    }
    this.setState({
      shippingStatus: Shippingstatus,
      orderDetail,
      orderTrackStatusRecord: trackStatus,
      orderTrackingTemplate: trackingDetails,
      showLoader: false,
      service: "Usps",
    });
  };

  createUpsTrackingDetail = (trackDetail) => {
    let shippingStatus = trackDetail.shippingStatus;

    let trackStatus = [];
    let trackType = [];
    let trackingDetails = []
    trackDetail.data.package.map((trackItem, index) => {
      trackItem.activity.map((i)=>{
        trackStatus.push(i.status.description)
      })
      if(trackStatus.includes("Delivered")){
        shippingStatus = "Delivery"
      }else if(trackStatus.includes("Pickup Scan ")){
        shippingStatus = "pickedUp"
      }else if(trackStatus.includes("Loaded on Delivery Vehicle ")){
        shippingStatus = "shipped"
      }
      trackingDetails = trackItem.activity.map((trackItem)=>{
        let timestamp = trackItem.date;

        if(timestamp[7]){
          timestamp = timestamp.slice(0,4)+"-"+timestamp.slice(4,6)+"-"+timestamp.slice(6,8)
        }

        const address = trackItem.location.address;
        return (
            <tr key={index}>
              <td>
                <div className="track-time">
                  {/* <p><span></span> Monday, June 8, 2020 04:07 PM, Seattle wa us</p> */}
                  <p>
                    <span></span> {timestamp}, {address.city ? address.city : null}{" "}
                    {address.stateProvince ? address.stateProvince : null}{" "}
                    {address.country ? address.country : null}
                  </p>
                </div>
              </td>
              <td>
                <div className="track-status">
                  <p>
                    {trackItem.status?.description
                        ? trackItem.status?.description
                        : null}
                  </p>
                </div>
              </td>
            </tr>
        );
      })
      // trackStatus.push(trackItem.status.description);
      trackType.push(trackItem.messages);
    });

    this.setState({
      orderTrackRecord: trackDetail.data.data,
      orderTrackStatusRecord: trackStatus,
      orderTrackingTemplate: trackingDetails,
      orderTrackType: trackType,
      showLoader: false,
      service: "Ups",
      shippingStatus: shippingStatus,
    });
  };

  render() {
    let Uspsstage4 =
      (this.state.service === "Usps" ||
        this.state.service === "Ups" ||
        this.state.service === "Fedex") &&
      this.state.shippingStatus === "Delivery"
        ? "Delivery"
        : null;

    let Uspsstage3 =
      (this.state.service === "Usps" ||
        this.state.service === "Ups" ||
        this.state.service === "Fedex") &&
      this.state.shippingStatus === "shipped"
        ? "shipped"
        : null;

    let Uspsstage2 =
      (this.state.service === "Usps" ||
        this.state.service === "Ups" ||
        this.state.service === "Fedex") &&
      this.state.shippingStatus === "processing"
        ? "processing"
        : null;

    let pickedUp = (this.state.service === "Usps" ||
        this.state.service === "Ups" ||
        this.state.service === "Fedex") &&
    this.state.shippingStatus === "pickedUp"
        ? "pickedUp"
        : null;

    return (
      <main className="mainContainer">
        <section className="home-product-section mt-4">
          <div className="container">
            <div className="select-product-list">
              <div className="product-list-head product-list-border-bottom">
                <ul>
                  <li>
                    <Link to={"/orders"}>Order list</Link>
                  </li>
                  <li>
                    <i className="fas fa-minus"></i>
                  </li>
                  <li>
                    <a href="javascript:;">Order track</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="profile">
          <div className="container">
            <div className="row">
              <Profile_Sidebar />

              <div className="col-lg-9">
                <section className="track-section">
                  <div className="container">
                    <div className="track-section-head">
                      <h2>track order</h2>
                      <p>We're processing your orders. Track your order now.</p>
                    </div>

                    <div className="track-box">
                      {this.state.orderDetail.imageName ||
                      this.state.orderDetail.name ||
                      this.state.orderDetail.orderedQuantity ||
                      this.state.orderDetail.total ? (
                        <div className="track-box-top">
                          <div className="track-box-detail">
                            <div className="track-box-img">
                              <img src={this.state.orderDetail.imageName} />
                            </div>
                            <div className="track-box-item-detail">
                              <h2 title={this.state.orderDetail.name}>
                                {this.state.orderDetail.name}
                              </h2>
                              {/* <p>
                                <span>Plant Type:</span>Decorative House Plant
                              </p>
                              <p>
                                <span>Duration:</span>Monthly
                              </p> */}
                            </div>
                          </div>
                          <div className="track-box-item">
                            <p>
                              {this.state.orderDetail.orderedQuantity
                                ? `Qty:${this.state.orderDetail.orderedQuantity}`
                                : "-"}
                            </p>
                          </div>
                          <div className="track-box-item-price">
                            <p>
                              {this.state.orderDetail.total
                                ? `$${this.state.orderDetail.total.toFixed(2)}`
                                : "-"}
                            </p>
                          </div>
                        </div>
                      ) : null}

                      {this.state.shippingStatus !== "canceled" ? (
                        <div className="order-tracker">
                          <div className="track-border"></div>
                          <ul>
                            <li className="active">
                              <div className="order-tracker-dots"></div>
                              <p>confirmed</p>
                            </li>
                            <li
                              className={
                                this.state.shippingStatus === "processing" ||
                                Uspsstage4 ||
                                Uspsstage3 ||
                                pickedUp
                                  ? "active"
                                  : null
                              }
                            >
                              <div className="order-tracker-dots"></div>
                              <p>picked up</p>
                            </li>
                            <li
                              className={
                                this.state.shippingStatus === "shipped" ||
                                Uspsstage4 ||
                                Uspsstage3
                                  ? "active"
                                  : null
                              }
                            >
                              <div className="order-tracker-dots"></div>
                              <p>shipped</p>
                            </li>
                            <li
                              className={
                                this.state.shippingStatus === "Delivered" ||
                                Uspsstage4
                                  ? "active"
                                  : null
                              }
                            >
                              <div className="order-tracker-dots"></div>
                              <p>delivered</p>
                            </li>
                          </ul>
                        </div>
                      ) : (
                        <div className="order-tracker">
                          <div className="track-border"></div>
                          <ul>
                            <li className="active">
                              <div className="order-tracker-dots"></div>
                              <p>Cancelled</p>
                            </li>
                          </ul>
                        </div>
                      )}

                      <div className="advanced-tracking">
                        <div className="advanced-tracking-table">
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <td>Tracking details</td>
                                  <td></td>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.orderTrackingTemplate?.length > 0 &&
                                this.state.shippingStatus !== "canceled"
                                  ? this.state.orderTrackingTemplate
                                  : "Tracking details not found"}
                              </tbody>
                            </table>
                          </div>
                          <div className="advanced-tracking-table-bottom">
                            <button
                              onClick={() => this.fetchOrdertrackInfo(false)}
                              type="button"
                              className="orange-outline-btn mt-2" 
                            >
                              retrack
                              {(() => {
                                if (this.state?.showLoader) {
                                  return (
                                    <span className="spinner-border spinner-border-sm ml-1"></span>
                                  );
                                }
                              })()}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getOrderTracking }, dispatch);
};

export default connect(null, mapDispatchToProps)(Order_Track);
