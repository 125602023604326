import React, { Component } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/images-2/logo.png";
import HomeHeader from "../../assets/images-2/home-header.jpg";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { logout } from "../../Redux/Actions/authAction";
import {
  changeCategory,
  searchProduct,
} from "../../Redux/Actions/productAction";
import { getCartItems } from "../../Redux/Actions/cartAction";
import { replace } from "../../Utils/helper";
import MenuIcon from "../../assets/images/menu-icon.svg";
import CartIcon from "../../assets/images/cart.svg";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogedIn: false,
      categorySelected: "",
      cartItems: [],
      searchText: "",
      searchList: [],
      showLoader: false,
      customerName: null,
      timeOutState: null,
      apiCounter: 0,
    };
    this.wrapperRef = React.createRef();
    // this.wrapperRef = React.createRef();
    this.wrapperRefMobile = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.confirmPopupRef = React.createRef();
  }
  handleClickOutside(event) {
    console.log(this.wrapperRefMobile, this.wrapperRef,"---------------asdasdasd")
    // if ((this.wrapperRef && !this.wrapperRef.current?.contains(event.target))) {
    //   this.setState({
    //     searchList: [],
    //   });
    // }
  }
  componentDidMount = () => {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.updateLogin();
    this.getCartItems();
  };
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  getCartItems() {
    let cartItems = JSON.parse(localStorage.getItem("cartItems"));
    this.setState({
      cartItems: cartItems ? cartItems : [],
    });
  }

  updateLogin = () => {
    if (localStorage.getItem("userId")) {
      this.setState({
        isLogedIn: true,
        customerName: JSON.parse(localStorage.getItem("updatedName")) ? JSON.parse(localStorage.getItem("updatedName")) : JSON.parse(localStorage.getItem("userData"))?.firstname,
      });
    } else {
      this.setState({ isLogedIn: false });
      // this.props.history.push("/");
    }
  };

  timeChoiceCallback = (time) => {
    this.setState({
      dateSelection: time,
    });
  };

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.isLoggedIn !== this.state.isLogedIn) {
      this.updateLogin();
    }
    this.getCartItems();
  }

  handleLogout = async (event) => {
    event.preventDefault();
    this.props.logout();
    this.updateLogin();
    this.props.history.push("/");
  };

  handleInputChange = (event) => {
    event.preventDefault();
    let searchKeyword = event.target.value;

    this.sortResultCategoryWise(searchKeyword);
    this.setState(
      {
        searchText: searchKeyword,
      },
      () => {
        if (this.state.timeOutState) {
          clearInterval(this.state.timeOutState);
        }
        let result = setTimeout(() => {
          this.getSearchResult(searchKeyword);
        }, 200);
        this.setState({
          timeOutState: result,
        });
      }
    );

    if (searchKeyword.length === 0) {
      this.setState({
        searchList: [],
      });
    }
  };
  onlyUnique(value, index, self) {
    return (
      self.indexOf(value) === index && value && value.toString().length !== 0
    );
  }

  getSearchResult = () => {
    if (this.state.searchText.length !== 0) {
      let response;
      this.setState(
        (prevState) => ({
          apiCounter: prevState.apiCounter + 1,
        }),
        async () => {
          response = await this.props.searchProduct(
            this.state.searchText.trim(),
            this.state.apiCounter
          );
          if (response && response.apiCounter) {
            if (response.apiCounter == this.state.apiCounter) {
              const responseData = response.data
                .map((i) => JSON.stringify(i))
                .filter(this.onlyUnique);
              this.setState({
                searchList: this.state.searchText.length
                  ? responseData.map((i) => JSON.parse(i))
                  : [],
              });
            } else {
              const stateSearchList = [...this.state.searchList];
              let searchCategoryProductList = [
                ...stateSearchList.filter(
                  (data) => data.categoryId && data.categoryId != 0
                ),
                ...response.data.filter(
                  (data) => data.categoryId && data.categoryId != 0
                ),
              ];
              let productList = [
                ...stateSearchList.filter((data) => !data.categoryId),
                ...response.data.filter((data) => !data.categoryId),
              ];
              let uniqueSearchCategoryProductList =
                await searchCategoryProductList
                  .map((i) => JSON.stringify(i))
                  .filter(this.onlyUnique);
              uniqueSearchCategoryProductList =
                uniqueSearchCategoryProductList.map((i) => JSON.parse(i));

              let uniqueProductList = await productList
                .map((i) => JSON.stringify(i))
                .filter(this.onlyUnique);
              uniqueProductList = uniqueProductList.map((i) => JSON.parse(i));

              uniqueSearchCategoryProductList = await this.sortSearchResult(
                this.state.searchText,
                uniqueSearchCategoryProductList
              );
              uniqueProductList = await this.sortSearchResult(
                this.state.searchText,
                uniqueProductList
              );

              let searchResult = [
                ...uniqueSearchCategoryProductList,
                ...uniqueProductList,
              ];

              this.setState({
                searchList: searchResult,
              });
            }
          }
        }
      );
    }
  };

  sortResultCategoryWise = async (searchKeyword) => {
    if (
      searchKeyword.length === 0 ||
      searchKeyword.trim() === this.state.searchText.trim()
    ) {
      return;
    }

    const searchCategoryProductList = this.state.searchList.filter(
      (data) => data.categoryId && data.categoryId != 0
    );
    const ProductList = this.state.searchList.filter(
      (data) => !data.categoryId
    );
    const sortedSearchCategoryProductList = await this.sortSearchResult(
      searchKeyword,
      searchCategoryProductList
    );
    const sortedProductList = await this.sortSearchResult(
      searchKeyword,
      ProductList
    );
    this.setState({
      searchList: [...sortedSearchCategoryProductList, ...sortedProductList],
    });
  };

  sortSearchResult = (searchKeyword, responseData) => {
    const splitedSearchText = searchKeyword.toLowerCase().split("");

    let sortedResponseData = responseData
      .map((data) => {
        let points = 0;
        for (let text of splitedSearchText) {
          if (data.name.toLowerCase().includes(text)) {
            points++;
          }
          if (data.name.toLowerCase().includes(text + " ")) {
            points++;
          }
        }
        return { ...data, points };
      })
      .sort((a, b) => b.points - a.points);

    sortedResponseData = sortedResponseData.map((i) => {
      delete i.points;
      return i;
    });

    return sortedResponseData;
  };

  handleCategoryChange = (categoryName) => {
    if (this.state.categorySelected !== categoryName) {
      // this.setState({
      //     ...this.state,
      //     categorySelected: categoryName
      // })
      // this.props.history.push('/category/new-arrivals', { categoryById: "1" });
      // this.props.changeCategory(categoryName);
      //  this.props.getProductList(categoryName);
    }
  };
  onclickAction = () => {
    this.setState({
      searchList: [],
      searchText: "",
    });
  };

  removeSpaces = (string) => {
    let runLoop = true;
    while (runLoop) {
      string = string.replace(" ", "-");
      if (!string.includes(" ")) {
        runLoop = false;
      }
    }
    return string;
  };

  render() {
    const searchList = this.state.searchList.map((item, index) => {
      if (item.description && item.description.includes("<p")) {
        let div = document.createElement("div");
        div.innerHTML = item.description;

        item.description = div.textContent || div.innerText || "";
      }
      return (
        <div
          onClick={() => this.onclickAction(item)}
          className="search-filter-item"
          key={index}
        >
          <Link
            to={{
              pathname: item.categoryId
                ? `/category/search/${this.removeSpaces(item.key)}`
                : `/product/${item.slug}`,
              state: { spuId: item.spu_id },
            }}
          >
            <div className="search-filter-item-img">
              {item.imageName ? (
                <img src={item.imageName} />
              ) : (
                <i className="fas fa-search" />
              )}
            </div>
            <div className="search-filter-item-detail">
              <h3>{item.name}</h3>
              <p>{item.description} </p>
            </div>
          </Link>
        </div>
      );
    });

    return (
      <div>
        <header className="header">
          <div className="container">
            <div className="header-top">
              <div className="row">
                <div className="col-lg-3 col-md-3 col-6">
                  <div className="header-logo">
                    <Link to={"/"}>
                      <img src={Logo} />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-5 col-md-3 col-6">
                  {/* <div class="header-top-search">
                                        <button type="button"><i class="fas fa-search"></i></button>
                                        <input className="form-control header-top-search-input" value={this.state.searchText} onChange={(e) => this.handleInputChange(e)} type="text" placeholder="SEARCH" />
                                    </div> */}
                  <div className="header-search" ref={this.wrapperRef}>
                    <div className="header-top-search">
                      {(() => {
                        if (this.state.showLoader) {
                          return (
                            <span
                              style={{
                                color: "#E25E52",
                                marginRight: "10px",
                                height: "23px",
                                width: "24px",
                              }}
                              className="spinner-border spinner-border-sm ml-1"
                            ></span>
                          );
                        } else {
                          return (
                            <button type="button">
                              <i className="fas fa-search"></i>
                            </button>
                          );
                        }
                      })()}

                      <input
                        className="form-control"
                        value={this.state.searchText}
                        onChange={(e) => this.handleInputChange(e)}
                        onClick={this.getSearchResult}
                        type="text"
                        placeholder="SEARCH"
                      />
                    </div>

                    {this.state.searchList.length > 0 ? (
                      <div
                        className="search-filter-drop"
                        style={{
                          wordBreak: "break-word",
                          maxWidth: "500px",
                          width: "100%",
                        }}
                      >
                        {searchList}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="header-top-menu">
                    <ul>
                      <li>
                        <Link to={"/page/about-us"}>ABOUT US</Link>
                      </li>
                      {/* <li>
                        <Link to={"/blog"}>BLOG</Link>
                      </li> */}

                      <li>
                        <Link to={"/vendor"}>VENDORS</Link>
                        {/* <a href={"https://subsciety-vendor.azurewebsites.net"}  target="_blank" >VENDORS</a> */}
                        {/* <a href="#">VENDORS</a> */}
                      </li>
                    </ul>
                    <ul>
                      {(() => {
                        if (this.state.isLogedIn) {
                          return (
                            <li className="dropdown">
                              <a
                                className="dropdown-toggle header-cart"
                                style={{ cursor: "pointer" }}
                              >
                                <i className="fas fa-user"></i>{" "}
                                {this.state.customerName?.length > 5
                                  ? `${this.state.customerName.slice(0, 5)}..`
                                  : this.state.customerName}
                              </a>
                              <div className="dropdown-menu">
                                <Link
                                  to={"/subscription"}
                                  className="dropdown-item"
                                >
                                  Profile
                                </Link>
                                <Link
                                  to={"/wishlist"}
                                  className="dropdown-item"
                                >
                                  Wishlist
                                </Link>
                                <Link
                                  to={"/change-password"}
                                  className="dropdown-item"
                                >
                                  Change Password
                                </Link>
                                <a
                                  onClick={(event) => this.handleLogout(event)}
                                  className="dropdown-item"
                                  href="#"
                                >
                                  Logout
                                </a>
                              </div>
                            </li>
                          );
                        } else {
                          return (
                            <Link to={"/signup"}>
                              <li>
                                <p className="header-cart">
                                  <i className="fas fa-user"></i> SIGN UP
                                </p>
                              </li>
                            </Link>
                          );
                        }
                      })()}
                      <li>
                        <Link to={"/cart"} className="header-cart">
                          <i className="fas fa-shopping-cart"></i>
                          {this.state.cartItems &&
                          this.state.cartItems.length > 0 ? (
                            <span className="add-digit">
                              {this.state.cartItems.length}
                            </span>
                          ) : null}{" "}
                          CART
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="header-bottom">
              <nav className="navbar navbar-expand-md ">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapsibleNavbar"
                >
                  <i _ngcontent-mrf-c57="" className="fas fa-bars"></i>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="collapsibleNavbar"
                >
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <Link
                        to={{
                          pathname: "/product-list/new-arrivals",
                          categoryById: "1",
                        }}
                        onClick={() =>
                          this.handleCategoryChange("new-arrivals")
                        }
                        className="nav-link"
                        href="#"
                      >
                        <i className="fas fa-plus"></i> New Arrivals
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={{
                          pathname: "/product-list/best-sellers",
                          categoryById: "2",
                        }}
                        onClick={() =>
                          this.handleCategoryChange("best-sellers")
                        }
                        className="nav-link"
                        href="#"
                      >
                        <i className="fas fa-plus"></i> Best Sellers
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={{
                          pathname: "/product-list/featured",
                          categoryById: "3",
                        }}
                        onClick={() => this.handleCategoryChange("featured")}
                        className="nav-link"
                        href="#"
                      >
                        <i className="fas fa-plus"></i> Featured
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={{
                          pathname: "/product-list/category/access",
                          parentId: "3",
                          categoryById: "4",
                        }}
                        className="nav-link"
                        href="#"
                      >
                        <i className="fas fa-plus"></i> access
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={{
                          pathname: "/product-list/category/replenish",
                          state: this.state,
                          parentId: "101",
                          categoryById: "5",
                        }}
                        className="nav-link"
                        href="#"
                      >
                        <i className="fas fa-plus"></i> replenish
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={{
                          pathname: "/product-list/category/customize",
                          parentId: "119",
                          categoryById: "6",
                        }}
                        className="nav-link"
                        href="#"
                      >
                        <i className="fas fa-plus"></i> customize
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to={{
                          pathname: "/shop-by-vendor",
                        }}
                        className="nav-link"
                        href="#"
                      >
                        <i className="fas fa-plus"></i> Shop by vendor
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </header>

        <header className="mobile-header">
          <div className="header-desc">
            <img
              src={MenuIcon}
              alt="icon"
              className="menu-icon"
              data-toggle="modal"
              data-target="#myModal"
              ref={this.confirmPopupRef}
            />
             <Link to={"/"}>
             <img src={Logo} alt="logo" className="logo" />
              </Link>
            <div className="cart-count">
            <Link to={"/cart"}><img src={CartIcon} alt="icon" className="menu-icon" /></Link>
            <p>{this.state.cartItems &&
                          this.state.cartItems.length > 0 ? (
                            <span className="add-digit">
                              {this.state.cartItems.length}
                            </span>
                          ) : 0}{" "}</p>
            </div>
          </div>
          <div className="form-group header-search-mobile" ref={this.wrapperRefMobile}>
            <i className="fa fa-search" aria-hidden="true" />
            <input type="text" className="form-control" 
            value={this.state.searchText}
            onChange={(e) => this.handleInputChange(e)}
            onClick={this.getSearchResult}placeholder="Search" />
          {this.state.searchList.length > 0 ? (
                      <div
                        className="search-filter-drop"
                        style={{
                          wordBreak: "break-word",
                          maxWidth: "500px",
                          width: "100%",
                        }}
                      >
                        {searchList}
                      </div>
                    ) : null}
          </div>
        </header>

        <div className="modal menu-mobile-show" id="myModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" class="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <div>
                {(() => {
                if (this.state.isLogedIn) {
                          return (
                            <button className="menu-button-show">
                                <i className="fas fa-user"></i>
                                {this.state.customerName?.length > 5
                                  ? `${this.state.customerName}`
                                  : this.state.customerName}
                              </button>
                          );
                        } else {
                          return(
                            <button className="menu-button-show">
                            <Link to = "/signup" className="colorWhite" onClick={()=> this.confirmPopupRef.current.click()}>
                            <i className="fa fa-user" aria-hidden="true"></i> SIGN UP
                             </Link>
                             
                          </button>
                          );
                        }
                         })()}
                      
                  <ul>
                    { this.state.isLogedIn &&
                    <>
                    <li>
                        <Link to="/subscription" onClick={()=> this.confirmPopupRef.current.click()}>
                          <i className="fa fa-plus" aria-hidden="true"></i> Profile
                        </Link>
                      </li>
                      <li>
                          <Link to="/wishlist"  onClick={()=> this.confirmPopupRef.current.click()}>
                            <i className="fa fa-plus" aria-hidden="true"></i> Wishlist
                          </Link>
                        </li>
                        <li>
                        <Link to={"/change-password"} onClick={()=> this.confirmPopupRef.current.click()}>
                        <i className="fa fa-plus" aria-hidden="true"></i>Change 
                        Password
                      </Link>
                        </li>
                        </>
                    }
                    <li>
                      <Link to="/product-list/new-arrivals" onClick={()=> this.confirmPopupRef.current.click()}>
                        <i className="fa fa-plus" aria-hidden="true"></i> NEW
                        ARRIVALS
                      </Link>
                    </li>
                    <li>
                      <Link to="/product-list/best-sellers" onClick={()=> this.confirmPopupRef.current.click()}>
                        <i className="fa fa-plus" aria-hidden="true"></i> BEST
                        SELLERS
                      </Link>
                    </li>
                    <li>
                      <Link to="/product-list/featured" onClick={()=> this.confirmPopupRef.current.click()}>
                        <i className="fa fa-plus" aria-hidden="true"></i>{" "}
                        FEATURED
                      </Link>
                    </li>
                    <li>
                      <Link to="/product-list/category/access" onClick={()=> this.confirmPopupRef.current.click()}>
                        <i className="fa fa-plus" aria-hidden="true"></i> ACCESS
                      </Link>
                    </li>
                    <li>
                      <Link to="/product-list/category/replenish" onClick={()=> this.confirmPopupRef.current.click()}>
                        <i className="fa fa-plus" aria-hidden="true"></i>{" "}
                        REPLENISH
                      </Link>
                    </li>
                    <li>
                      <Link to="/product-list/category/customize" onClick={()=> this.confirmPopupRef.current.click()}>
                        <i className="fa fa-plus" aria-hidden="true"></i>{" "}
                        CUSTOMIZE
                      </Link>
                    </li>
                    <li>
                      <Link to="/shop-by-vendor" onClick={()=> this.confirmPopupRef.current.click()}>
                        <i className="fa fa-plus" aria-hidden="true" ></i> SHOP
                        BY VENDOR
                      </Link>
                    </li>
                  </ul>

                  <div className="bottom-menu-mobile">
                    <p>
                      <Link to="/page/about-us" onClick={()=> this.confirmPopupRef.current.click()}>ABOUT US</Link>
                    </p>
                    <p>
                      <Link to="/vendor" onClick={()=> this.confirmPopupRef.current.click()}>VENDOR</Link>
                    </p>
                    { this.state.isLogedIn &&
                    <p>
                        <a onClick={(event) =>{this.confirmPopupRef.current.click(); this.handleLogout(event)} } href="#">
                       Logout
                          </a>
                          </p>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    isLoggedIn: state.authReducer.isLoggedIn,
    cartItems: state.cartReducer.cartItems,
    searchList: state.productReducer.searchList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { logout, changeCategory, getCartItems, searchProduct },
    dispatch
  );
};
//export default Header;
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
