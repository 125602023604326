import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./VendorHeader.scss";
import Logo from "../../assets/images-2/logo.png";
import Header from "../Header/Header";
import { getCartItems } from "../../Redux/Actions/cartAction";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { logout } from "../../Redux/Actions/authAction";
import { searchProduct } from "../../Redux/Actions/productAction";
import axios from "axios";
import { getChildCategoryList } from "../../Redux/Actions/productAction";
import { DownArrow } from "../svgElements/DownArror";
import { SearchIcon } from "../svgElements/SearchIcon";
import { Cart } from "../svgElements/Cart";

let source;
class VendorHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogedIn: false,
      categorySelected: "",
      cartItems: [],
      searchText: "",
      searchList: [],
      accessParentCat: [],
      replenishParentCat: [],
      customizedParentCat: [],
      showLoader: false,
      customerName: null,
      timeOutState: null,
      apiCounter: 0,
    };
    source = axios.CancelToken.source();
    this.wrapperRef = React.createRef();
    this.wrapperRefMobile = React.createRef();
    this.confirmPopupRef = React.createRef();
  }

  componentDidMount = () => {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.updateLogin();
    this.getCartItems();
  };

  componentDidMount = async () => {
    const accessParentCat = await this.props.getChildCategoryList(
      "access",
      0,
      source.token
    );
    if (accessParentCat) {
      this.setState({
        accessParentCat: accessParentCat.data,
      });
    }
    const replenishParentCat = await this.props.getChildCategoryList(
      "replenish",
      0,
      source.token
    );
    if (replenishParentCat) {
      this.setState({
        replenishParentCat: replenishParentCat.data,
      });
    }
    const customizedParentCat = await this.props.getChildCategoryList(
      "customize",
      0,
      source.token
    );
    if (customizedParentCat) {
      this.setState({
        customizedParentCat: customizedParentCat.data,
      });
    }
  };

  updateLogin = () => {
    if (localStorage.getItem("userId")) {
      this.setState({
        isLogedIn: true,
        customerName: JSON.parse(localStorage.getItem("updatedName"))
          ? JSON.parse(localStorage.getItem("updatedName"))
          : JSON.parse(localStorage.getItem("userData"))?.firstname,
      });
    } else {
      this.setState({ isLogedIn: false });
    }
  };

  componentWillReceiveProps(nextProps) {
    const checkLogin = localStorage.getItem("userId");
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.isLoggedIn !== this.state.isLogedIn || checkLogin) {
      this.updateLogin();
    }
    this.getCartItems();
  }

  getCartItems() {
    let cartItems = JSON.parse(localStorage.getItem("cartItems"));
    this.setState({
      cartItems: cartItems ? cartItems : [],
    });
  }

  handleLogout = async (event) => {
    event.preventDefault();
    this.props.logout();
    this.updateLogin();
    this.props.history.push("/");
  };

  sortResultCategoryWise = async (searchKeyword) => {
    if (
      searchKeyword.length === 0 ||
      searchKeyword.trim() === this.state.searchText.trim()
    ) {
      return;
    }

    const searchCategoryProductList = this.state.searchList.filter(
      (data) => data.categoryId && data.categoryId != 0
    );
    const ProductList = this.state.searchList.filter(
      (data) => !data.categoryId
    );
    const sortedSearchCategoryProductList = await this.sortSearchResult(
      searchKeyword,
      searchCategoryProductList
    );
    const sortedProductList = await this.sortSearchResult(
      searchKeyword,
      ProductList
    );
    this.setState({
      searchList: [...sortedSearchCategoryProductList, ...sortedProductList],
    });
  };

  sortSearchResult = (searchKeyword, responseData) => {
    const splitedSearchText = searchKeyword.toLowerCase().split("");

    let sortedResponseData = responseData
      .map((data) => {
        let points = 0;
        for (let text of splitedSearchText) {
          if (data.name.toLowerCase().includes(text)) {
            points++;
          }
          if (data.name.toLowerCase().includes(text + " ")) {
            points++;
          }
        }
        return { ...data, points };
      })
      .sort((a, b) => b.points - a.points);

    sortedResponseData = sortedResponseData.map((i) => {
      delete i.points;
      return i;
    });

    return sortedResponseData;
  };

  handleInputChange = (event) => {
    event.preventDefault();
    let searchKeyword = event.target.value;

    this.sortResultCategoryWise(searchKeyword);
    this.setState(
      {
        searchText: searchKeyword,
      },
      () => {
        if (this.state.timeOutState) {
          clearInterval(this.state.timeOutState);
        }
        let result = setTimeout(() => {
          this.getSearchResult(searchKeyword);
        }, 200);
        this.setState({
          timeOutState: result,
        });
      }
    );

    if (searchKeyword.length === 0) {
      this.setState({
        searchList: [],
      });
    }
  };

  onlyUnique(value, index, self) {
    return (
      self.indexOf(value) === index && value && value.toString().length !== 0
    );
  }

  getSearchResult = () => {
    if (this.state.searchText.length !== 0) {
      let response;
      this.setState(
        (prevState) => ({
          apiCounter: prevState.apiCounter + 1,
        }),
        async () => {
          response = await this.props.searchProduct(
            this.state.searchText.trim(),
            this.state.apiCounter
          );
          if (response && response.apiCounter) {
            if (response.apiCounter == this.state.apiCounter) {
              const responseData = response?.data
                ?.map((i) => JSON.stringify(i))
                .filter(this.onlyUnique);
              this.setState({
                searchList: this.state.searchText.length
                  ? responseData.map((i) => JSON.parse(i))
                  : [],
              });
            } else {
              const stateSearchList = [...this.state.searchList];
              let searchCategoryProductList = [
                ...stateSearchList.filter(
                  (data) => data.categoryId && data.categoryId != 0
                ),
                ...response.data.filter(
                  (data) => data.categoryId && data.categoryId != 0
                ),
              ];
              let productList = [
                ...stateSearchList.filter((data) => !data.categoryId),
                ...response.data.filter((data) => !data.categoryId),
              ];
              let uniqueSearchCategoryProductList =
                await searchCategoryProductList
                  .map((i) => JSON.stringify(i))
                  .filter(this.onlyUnique);
              uniqueSearchCategoryProductList =
                uniqueSearchCategoryProductList.map((i) => JSON.parse(i));

              let uniqueProductList = await productList
                .map((i) => JSON.stringify(i))
                .filter(this.onlyUnique);
              uniqueProductList = uniqueProductList.map((i) => JSON.parse(i));

              uniqueSearchCategoryProductList = await this.sortSearchResult(
                this.state.searchText,
                uniqueSearchCategoryProductList
              );
              uniqueProductList = await this.sortSearchResult(
                this.state.searchText,
                uniqueProductList
              );

              let searchResult = [
                ...uniqueSearchCategoryProductList,
                ...uniqueProductList,
              ];

              this.setState({
                searchList: searchResult,
              });
            }
          }
        }
      );
    }
  };

  onclickAction = () => {
    this.setState({
      searchList: [],
      searchText: "",
    });
  };
  removeSpaces = (string) => {
    let runLoop = true;
    while (runLoop) {
      string = string.replace(" ", "-");
      if (!string.includes(" ")) {
        runLoop = false;
      }
    }
    return string;
  };

  render() {
    const searchList = this.state.searchList.map((item, index) => {
      if (item.description && item.description.includes("<p")) {
        let div = document.createElement("div");
        div.innerHTML = item.description;

        item.description = div.textContent || div.innerText || "";
      }
      return (
        <div
          onClick={() => this.onclickAction(item)}
          className="search-filter-item"
          key={index}
        >
          <Link
            to={{
              pathname: item.categoryId
                ? `/category/search/${this.removeSpaces(item.key)}`
                : `/product/${item.slug}`,
              state: { spuId: item.spu_id },
            }}
          >
            <div className="search-filter-item-img">
              {item.imageName ? (
                <img src={item.imageName} />
              ) : (
                <i className="fas fa-search" />
              )}
            </div>
            <div className="search-filter-item-detail">
              <h3>{item.name}</h3>
              <p>{item.description} </p>
            </div>
          </Link>
        </div>
      );
    });
    return (
      <>
        <div className="vendor-header-perent">
          <Header />

          <div className="vendor-header">
            <div className="header-top">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-4 col-lg-3">
                    <div className="header-logo">
                      <Link to="/">
                        <img src={Logo} alt="logo" />
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-5">
                    <div className="header-search">
                      <div className="header-search-input">
                        <SearchIcon />
                        <input
                          className="form-control"
                          value={this.state.searchText}
                          onChange={(e) => this.handleInputChange(e)}
                          onClick={this.getSearchResult}
                          type="text"
                          placeholder="Search"
                        />
                      </div>

                      {this.state.searchList.length > 0 ? (
                        <div
                          className="search-filter-drop"
                          style={{
                            wordBreak: "break-word",
                            maxWidth: "500px",
                            width: "100%",
                          }}
                        >
                          {searchList}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-4">
                    <div className="header-top-menu">
                      <ul>
                        <li>
                          <Link to="/page/about-us" className="top-menu-link">
                            About Us
                          </Link>
                        </li>
                        <li>
                          <Link to="/vendor" className="top-menu-link">
                            Vendors
                          </Link>
                        </li>
                        {/* <ul> */}
                        {(() => {
                          if (this.state.isLogedIn) {
                            return (
                              <li className="dropdown">
                                <a
                                  className="dropdown-toggle header-cart"
                                  style={{ cursor: "pointer" }}
                                >
                                  <i className="fas fa-user"></i>{" "}
                                 <span> {this.state.customerName?.length > 5
                                    ? `${this.state.customerName.slice(0, 5)}..`
                                    : this.state.customerName} </span>
                                </a>
                                <div className="dropdown-menu">
                                  <Link
                                    to={"/subscription"}
                                    className="dropdown-item"
                                  >
                                    Profile
                                  </Link>
                                  <Link
                                    to={"/wishlist"}
                                    className="dropdown-item"
                                  >
                                    Wishlist
                                  </Link>
                                  <Link
                                    to={"/change-password"}
                                    className="dropdown-item"
                                  >
                                    Change Password
                                  </Link>
                                  <a
                                    onClick={(event) =>
                                      this.handleLogout(event)
                                    }
                                    className="dropdown-item"
                                    href="#"
                                  >
                                    Logout
                                  </a>
                                </div>
                              </li>
                            );
                          } else {
                            return (
                              <Link to={"/signup"}>
                                <li>
                                  <button className="theme-button primary-button radius-btn">
                                    Sign Up
                                  </button>
                                </li>
                              </Link>
                            );
                          }
                        })()}
                        <li>
                          <div className="top-header-cart">
                            <Link to="/cart">
                              <Cart />
                              Cart
                              {this.state.cartItems &&
                              this.state.cartItems.length > 0 ? (
                                <span className="add-digit">
                                  {this.state.cartItems.length}
                                </span>
                              ) : null}{" "}
                            </Link>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="header-mid">
              <div className="container">
                <nav className="navbar navbar-expand-md">
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapsibleNavbar"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-menu-2"
                      width="44"
                      height="44"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#2c3e50"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M4 6l16 0" />
                      <path d="M4 12l16 0" />
                      <path d="M4 18l16 0" />
                    </svg>
                  </button>

                  <div
                    className="collapse navbar-collapse"
                    id="collapsibleNavbar"
                  >
                    <ul className="navbar-nav">
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="/product-list/new-arrivals"
                        >
                          New Arrivals
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="/product-list/best-sellers"
                        >
                          Best Sellers
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/product-list/featured">
                          Featured
                        </Link>
                      </li>
                      <li className="nav-item">
                        <div className="dropdown nav-dropdown">
                          <button
                            className="dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Access
                            <DownArrow />
                          </button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            {this.state.accessParentCat?.length > 0 &&
                              this.state.accessParentCat
                                .slice(0, 8)
                                .map((item) => (
                                  <a
                                    key={item.id}
                                    className="dropdown-item"
                                    href={`/product-by/category/access/${item.id}?cat=${item.name}`}
                                  >
                                    {item.name}
                                  </a>
                                ))}
                            {this.state.accessParentCat.length > 8 && (
                              <a
                                className="dropdown-item"
                                href="/product-list/category/access"
                              >
                                Explore more...
                              </a>
                            )}
                          </div>
                        </div>
                      </li>
                      <li className="nav-item">
                        <div className="dropdown nav-dropdown">
                          <button
                            className="dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Replenish
                            <DownArrow />
                          </button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            {this.state.replenishParentCat?.length > 0 &&
                              this.state.replenishParentCat
                                .slice(0, 8)
                                .map((item) => (
                                  <a
                                    key={item.id}
                                    className="dropdown-item"
                                    href={`/product-by/category/replenish/${item.id}?cat=${item.name}`}
                                  >
                                    {item.name}
                                  </a>
                                ))}
                            {this.state.replenishParentCat.length > 8 && (
                              <a
                                className="dropdown-item"
                                href="/product-list/category/replenish"
                              >
                                Explore more...
                              </a>
                            )}
                          </div>
                        </div>
                      </li>
                      <li className="nav-item">
                        <div className="dropdown nav-dropdown">
                          <button
                            className="dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Customize
                            <DownArrow />
                          </button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            {this.state.customizedParentCat?.length > 0 &&
                              this.state.customizedParentCat
                                .slice(0, 8)
                                .map((item) => (
                                  <a
                                    key={item.id}
                                    className="dropdown-item"
                                    href={`/product-by/category/customize/${item.id}?cat=${item.name}`}
                                  >
                                    {item.name}
                                  </a>
                                ))}
                            {this.state.customizedParentCat.length > 8 && (
                              <a
                                className="dropdown-item"
                                href="/product-list/category/customize"
                              >
                                Explore more...
                              </a>
                            )}
                          </div>
                        </div>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/shop-by-vendor">
                          Shop By Vendor
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    isLoggedIn: state.authReducer.isLoggedIn,
    cartItems: state.cartReducer.cartItems,
    searchList: state.productReducer.searchList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getCartItems, getChildCategoryList, logout, searchProduct },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VendorHeader)
);

