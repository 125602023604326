import React from 'react';

export const Cart = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
  >
    <g opacity="0.8">
      <path d="M10.3101 21.1877H10.3115C10.3127 21.1877 10.314 21.1875 10.3152 21.1875H27.3125C27.7309 21.1875 28.0989 20.9099 28.2139 20.5076L31.9639 7.38257C32.0447 7.09961 31.988 6.79541 31.811 6.56054C31.6338 6.32568 31.3567 6.1875 31.0625 6.1875H8.14794L7.47778 3.17163C7.38232 2.74268 7.00195 2.4375 6.5625 2.4375H0.9375C0.419677 2.4375 0 2.85718 0 3.375C0 3.89282 0.419677 4.3125 0.9375 4.3125H5.81054C5.9292 4.84692 9.01757 18.7449 9.19531 19.5444C8.19897 19.9775 7.5 20.9709 7.5 22.125C7.5 23.6758 8.76171 24.9375 10.3125 24.9375H27.3125C27.8303 24.9375 28.25 24.5178 28.25 24C28.25 23.4822 27.8303 23.0625 27.3125 23.0625H10.3125C9.79565 23.0625 9.375 22.6418 9.375 22.125C9.375 21.6089 9.79418 21.189 10.3101 21.1877ZM29.8196 8.0625L26.6052 19.3125H11.0644L8.56445 8.0625H29.8196Z" />
      <path d="M9.375 27.75C9.375 29.3008 10.6367 30.5625 12.1875 30.5625C13.7383 30.5625 15 29.3008 15 27.75C15 26.1992 13.7383 24.9375 12.1875 24.9375C10.6367 24.9375 9.375 26.1992 9.375 27.75ZM12.1875 26.8125C12.7043 26.8125 13.125 27.2332 13.125 27.75C13.125 28.2668 12.7043 28.6875 12.1875 28.6875C11.6707 28.6875 11.25 28.2668 11.25 27.75C11.25 27.2332 11.6707 26.8125 12.1875 26.8125Z" />
      <path d="M22.625 27.75C22.625 29.3008 23.8867 30.5625 25.4375 30.5625C26.9883 30.5625 28.25 29.3008 28.25 27.75C28.25 26.1992 26.9883 24.9375 25.4375 24.9375C23.8867 24.9375 22.625 26.1992 22.625 27.75ZM25.4375 26.8125C25.9543 26.8125 26.375 27.2332 26.375 27.75C26.375 28.2668 25.9543 28.6875 25.4375 28.6875C24.9207 28.6875 24.5 28.2668 24.5 27.75C24.5 27.2332 24.9207 26.8125 25.4375 26.8125Z" />
    </g>
  </svg>
  );
};