import React, { Component } from "react";
import "../../Styles/order-detail.scss";
import Profile_Sidebar from "../Profile-Side-Bar/Profile_Sidebar";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getOrderDetail,
  cancelOrderByOrderId,
  requestOrderReturn,
  updateShippingCompanyAndTrackingId,
} from "../../Redux/Actions/orderAction";
import { confirmAlert } from "react-confirm-alert";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { logDOM } from "@testing-library/react";
import { weekDayValue } from "../../Constants/constant";
import moment from "moment";
class Order_Detail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderDetail: {},
      customerTrackingDetails: {},
      sellerDetail: {},
      sellerAddressDetail: {},
      shippingAddress: {},
      sessionDetails: null,
      billingAddress: {},
      newCardDetails: {},
      isShippingChargeEnable: false,
      showRefundableBar: false,
      refundableAmount: null,
      showLoader: false,
      errorMessage: "",
      showHideReturnOrderModal: false,
      shipping_company_by_customer: "",
      customer_trackingId: "",
      showModal: false
    };
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  componentDidMount() {
    this.fetchOrderDetail();
  }

  fetchOrderDetail = async () => {
    const result = await this.props.getOrderDetail(
      localStorage.getItem("userId"),
      this.props.match.params.orderId,
      this.timezone
    );
    if (result?.data?.data) {
      const sellerMap = result.data.data.items[0]?.sellerMap
        ? JSON.parse(result.data.data.items[0]?.sellerMap)
        : {};
      await this.setState({
        digitalUrl : result.data.data.digital_product_url ? JSON.parse(result.data.data.digital_product_url) : null,
        sessionDetails: result.data.data.booked_session_details ? JSON.parse(result.data.data.booked_session_details): null,
        orderDetail: result.data.data,
        returnAddress: result?.data?.data?.return_shipment_address ? 
        JSON.parse(result?.data?.data?.return_shipment_address) 
        : null,
        customerTrackingDetails: result.data.data.trackingDetails
          ? JSON.parse(result.data.data.trackingDetails)
          : null,
        sellerDetail: sellerMap,
        shippingAddress: result.data.data.address
          ? JSON.parse(result.data.data.address)
          : null,
          billingAddress: result.data.data.billing_Info
          ? JSON.parse(result.data.data.billing_Info)
          : null,
          newCardDetails: localStorage.getItem("new_billing_address") ? JSON.parse(localStorage.getItem("new_billing_address")) : null,
          zoom_link: !!result.data.data.seminar_date_time ?
          JSON.parse(result.data.data.seminar_date_time).meeting_link : null,
          startTime: !!result.data.data.seminar_date_time ?
          JSON.parse(result.data.data.seminar_date_time).start : null,
          endTime: !!result.data.data.seminar_date_time ?
          JSON.parse(result.data.data.seminar_date_time).end : null,
          eventDate: result.data.data.seminar_date_time ? 
          JSON.parse(result.data.data.seminar_date_time).date : null ,
          price: result.data.data.seminar_date_time ?
          JSON.parse(result.data.data.seminar_date_time).price : null,
        showRefundableBar: result.data.data.orders_status_history
          ? JSON.parse(result.data.data.orders_status_history).some((status) =>
            [`canceled`, `returned`].includes(status.status)
          )
          : false,
        isShippingChargeEnable: result.data.data.orders_status_history
          ? JSON.parse(result.data.data.orders_status_history).some((status) =>
            [`shipped`, "completed"].includes(status.status)
          )
          : false,

        refundableAmount: result.data.data.refunded_response
          ? result.data.data.paymentMethod === "STRIPE"
            ? (
              JSON.parse(result.data.data.refunded_response)
                ?.amount_refunded / 100
            )?.toFixed(2)
            : JSON.parse(result.data.data.refunded_response)?.amount?.total
          : null,
      });
    }
    this.hideButton()
  };


  getDatesArrayOccuringOnSameDayOfMonth = (day) => {
    // day is long day string
    let dateArray = [];
    const date = moment(new Date()).format("YYYY-MM-DD");
    let weekDay = moment(date).startOf("month").day(day);

    if (weekDay.date() > 7) weekDay.add(7, "d");
    let month = weekDay.month();
    while (month === weekDay.month()) {
      dateArray.push(
        moment(
          weekDay.year().toString() +
            "-" +
            (weekDay.month() + 1).toString() +
            "-" +
            weekDay.date().toString(),
          "YYYY-MM-DD"
        ).format("YYYY-MM-DD")
      );
      weekDay.toString();
      weekDay.add(7, "d");
    }
    return dateArray;
  };


  hideButton = () => {
    const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
    const currentTime = new Date()
    const convertObj = new Date()
    let currentDay = weekday[currentTime.getDay()].slice(0,3)

    this.state.sessionDetails &&  Object.entries(this.state.sessionDetails?.customerTime).map(([weekDay, slotsArray]) => {
          slotsArray && slotsArray.map((slot) => {
            if(this.state.sessionDetails.type == "group" && this.state.sessionDetails.sessionFrequency === "Monthly"){
              const dateArray =this.getDatesArrayOccuringOnSameDayOfMonth(weekDayValue[weekDay]);
              const selectedDayOfMonth = slot[2] == "First" 
                                                 ? 0 
                                                 : slot[2] == "Second"
                                                    ? 1 
                                                    : 2
              const startTime = new Date(dateArray[selectedDayOfMonth].split("-")[0],dateArray[selectedDayOfMonth].split("-")[1]-1,dateArray[selectedDayOfMonth].split("-")[2],
              slot[0].split(":")[0],slot[0].split(":")[1])
              const endTime = new Date(dateArray[selectedDayOfMonth].split("-")[0],dateArray[selectedDayOfMonth].split("-")[1]-1,dateArray[selectedDayOfMonth].split("-")[2],
              slot[1].split(":")[0],slot[1].split(":")[1])

              if (currentTime.getTime() < startTime.getTime() || currentTime.getTime() > endTime.getTime()){
                this.setState({
                  hideButton: true
                })
               }
            } else {
              convertObj.setHours(slot[0].split(":")[0])
              convertObj.setMinutes(slot[0].split(":")[1])
  
              const startTime = convertObj.getTime()
              convertObj.setHours(slot[1].split(":")[0])
              convertObj.setMinutes(slot[1].split(":")[1])
              const endTime = convertObj.getTime()
             if ((currentDay !== weekDay)||(currentDay === weekDay && (currentTime.getTime() < startTime || currentTime.getTime() > endTime))){
              this.setState({
                hideButton: true
              })
             }
            }
          })
      })
  }

  download = (url) => {
    axios({
      url,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.jpg");
      document.body.appendChild(link);
      link.click();
    });
  };

  getFileName = (url) => {
    let name = url.split("/");
    return name[4] + ".jpg";
  };

  onDisputeClick = async () => { };

  handleCancelOrder = async () => {
    this.setState({ showLoader: true });
    const result = await this.props.cancelOrderByOrderId(
      this.props.match.params.orderId
    );
    if (result?.data?.data?.statusCode == 201) {
      this.fetchOrderDetail();
      this.setState({ showLoader: false });
    } else if (result.data.isError) {
      this.setState({
        errorMessage: "Something went wrong. Please try again later.",
      });
      setTimeout(() => {
        this.setState({
          errorMessage: "",
        });
      }, 3000);
    }
  };

  handleChange = (e) => {
    if (e.target.value === "Leave a review") {
      const slug = this.state.orderDetail.items[0].slug;
      this.props.history.push(`/product/${slug}`);
    } else if (e.target.value === "Order support") {
      this.props.history.push(`/dispute/${this.props.match.params.orderId}`);
    }
  };

  updateCancelStatus = () => {
    confirmAlert({
      title: "",
      message: "Are you sure you want to cancel your order?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.handleCancelOrder(),
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  groupTimeSlotsModal = () => (
    <Modal show={this.state.showModal} centered>
      <Modal.Header
        closeButton
        onClick={() => {
          this.setState({
            showModal: false,
          });
        }}
      >
        <b>Time slots{this.state.sessionDetails?.vendorTimeZone ? ` (${this.state.sessionDetails?.vendorTimeZone})` : null}</b>
      </Modal.Header>
      <Modal.Body className="time-slot-modal">
      <div>
      {
          <>

{this.state.sessionDetails && this.state.sessionDetails.type == "group" && this.state.sessionDetails?.customerTime ? (
                                    <>
                                      {
                                        Object.entries(this.state.sessionDetails?.customerTime).map(([weekDay, slotsArray]) => {
                                          return <>
                                          <p className="mb-2"><strong>{weekDayValue[weekDay]}</strong></p>
                                          <ul>{
                                            slotsArray && slotsArray.map((slot) => {
                                              return <li>{moment(slot[0],"HH:mm").format("hh:mm A")} 
                                              {" "}to {moment(slot[1], "HH:mm").format("hh:mm A")} {slot[2] ? `(Every ${slot[2]} ${weekDayValue[weekDay]} Of The Month)` : null}</li>
                                            })
                                          }
                                          </ul>
                                          </>
                                        
                                        
                                        
                                        })

                                      }
                                  </>
                                  ) : null}
          </>
      }
      </div>
      </Modal.Body>
    </Modal>
  );

  returnOrderModal = () => (
    <Modal
      show={this.state.showHideReturnOrderModal}
      onHide={this.handleReturnProductModalShowHide}
      className="calendar-modal"
      centered
    >
      <Modal.Header className="calendar-header">
        <span onClick={this.handleReturnProductModalShowHide}>
          <i className="fas fa-times"></i>
        </span>
        <h5 className="text-center w-100 m-0">Return order request</h5>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              {this.state.successMessage && (
                <div className="success-msg">
                  <p>{this.state.successMessage}</p>
                </div>
              )}
              <p className="text-center">
                Are you sure to request return for this purchase ?
              </p>
              <label>
                <b>Reason</b>
              </label>
              <textarea
                className="form-control"
                placeholder="Enter text message for vendor"
                onChange={(e) =>
                  this.setState({ returnOrderMessage: e.target.value })
                }
              />
              <span className="form-field-error">
              {this.state.returnMessage}
              </span>
            </div>
          </div>
        </div>
        <div className="button-center">
          <button
            onClick={() => this.handleReturnRequestStatus()}
            className="orange-btn"
          >
            yes
            {(() => {
              if (this.state?.showDenyLoader) {
                return (
                  <span class="spinner-border spinner-border-sm ml-1"></span>
                );
              }
            })()}
          </button>
          <button
            onClick={() => this.handleReturnProductModalShowHide()}
            className="orange-btn"
          >
            no
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );


  downloadImage = (url,name) => {
    fetch(url, {
      // mode: "cors",
      cache: "no-cache",
      headers: {
        "Access-Control-Allow-Headers": " X-PINGOTHER, Content-Type",
        "x-amz-acl": "public-read",
        "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
        "Content-Type": "image/pdf",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const blobURL = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = blobURL;
        a.setAttribute("style", "display: none");

        if (name && name.length) a.download = name;
        document.body.appendChild(a);
        a.click();
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  handleReturnProductModalShowHide = () => {
    this.setState({
      showHideReturnOrderModal: false,
      returnOrderMessage: "",
    });
  };

  handleReturnOrderAlert = () => {
    this.setState({
      showHideReturnOrderModal: true,
    });
  };

  handleReturnRequestStatus = async () => {
    if (!!!this.state.returnOrderMessage) {
      this.setState({
        returnMessage : "Please enter the return reason."
      })
      return;
    }
    this.setState({
      showDenyLoader: true,
      returnMessage: ""
    });
    const res = await this.props.requestOrderReturn(
      this.props.match.params.orderId,
      localStorage.getItem("userId"),
      this.state.returnOrderMessage,
      this.state.orderDetail.items[0]?.sellerId
    );
    if (!res?.data?.isError) {
      this.fetchOrderDetail();
      this.setState({
        successMessage: "Request sent successfully!!!",
      });
    }
    this.setState({
      showDenyLoader: false,
    });
    setTimeout(() => {
      this.setState({
        showHideReturnOrderModal: false,
        successMessage: "",
      });
    }, 2000);
  };

  handleShippingCompanyAndTrackingIdByCustomer(e, type) {
    if (type == "shippingCompany") {
      this.setState({
        shipping_company_by_customer: e.target.value,
      });
    } else if (type == "trackingId") {
      this.setState({
        customer_trackingId: e.target.value,
      });
    }
  }

  async submitTrackingIdAndShippingCompany() {
    this.setState({
      submitLoader: true,
    });

    const body = {
      customer_trackingId: this.state.customer_trackingId,
      shipping_company_by_customer: this.state.shipping_company_by_customer,
    };

    const result = await this.props.updateShippingCompanyAndTrackingId(
      localStorage.getItem("userId"),
      this.props.match.params.orderId,
      body
    );

    await this.fetchOrderDetail();
    this.setState({
      submitLoader: false,
      shipping_company_by_customer: "",
      customer_trackingId: "",
    });
  }

render() {
  // let seminar = localStorage.getItem("cartItems") ? JSON.parse(localStorage.getItem("cartItems")) : null
    const currentTime = new Date().getTime()
     const seminardate = this.state.eventDate
     const seminarTime = this.state.startTime
     const seminarEndTime = this.state.endTime
    const parsedTime = new Date(seminardate?.split('-')[0],
    seminardate?.split('-')[1] - 1,
    seminardate?.split('-')[2],
    seminarTime?.split(':')[0],
    seminarTime?.split(':')[1]).getTime()
    const parsedEndTime = new Date(seminardate?.split('-')[0],
    seminardate?.split('-')[1] - 1,
    seminardate?.split('-')[2],
    seminarEndTime?.split(':')[0],
    seminarEndTime?.split(':')[1]).getTime()
 
    const date = this.state.orderDetail.createTS
      ? new Date(this.state.orderDetail.createTS).toISOString().substring(0, 10)
      : null;
    let variationKey = [];
    let variationValue = [];
    if (this.state.orderDetail.variant_values) {
      const variants = JSON.parse(this.state.orderDetail.variant_values);
      for (let key in variants) {
        variationKey.push(key);
        variationValue.push(variants[key]);
      }
    }
    const orderItems =
      this.state.orderDetail.items &&
        this.state.orderDetail.items.length > 0 ? (
        this.state.orderDetail.items.map((i) => {
          
          return (
            <div key={i.id} className="cart-box w-100">
              <div className="cart-box-detail w-100">
                <div className="cart-box-img">
                  <img src={i.imageName} />
                </div>
                <div className="cart-box-item-detail w-100">
                  <h2>{i.name}</h2>
                  {/* <p>
                    <strong>Frequency: </strong>{" "}
                    <span>
                      {this.state.orderDetail.order_type === "one_time"
                        ? "one time"
                        : this.state.orderDetail.subscription_timeframe}
                    </span>
                  </p> */}
                  {i.customizationNote && i.customizationNote.length !== 0 && (
                    <p>
                      <strong>Customization Note:</strong>
                      <span className="item-custom-desc">
                        {i.customizationNote}
                      </span>
                    </p>
                  )}
                  {i.customizationImage && i.customizationImage.length !== 0 && (
                    <p className="d-flex align-items-center justify-content-between">
                      <div>
                        <strong>Customization Image:</strong>{" "}
                        <span>{this.getFileName(i.customizationImage)}</span>
                      </div>
                      <span>
                        <a href={i.customizationImage} target="_blank">
                          View
                        </a>
                        <a onClick={() => this.download(i.customizationImage)}>
                          Download
                        </a>
                      </span>
                    </p>
                  )}
                  {variationKey.length > 0 && (
                    <p>
                      <strong>Variation:- </strong> <br />
                      {variationKey.map((key, index) => (
                        <div className="margin-variation">
                          <strong>{key}: </strong>{" "}
                          <span>{variationValue[index]}</span>
                        </div>
                      ))}
                    </p>
                  )}
                </div>
              </div>
              {/* <div className="cart-box-item-price">
                            <p>${(i.pricePerUnit).toFixed(2)} x <span>{i.orderedQuantity}</span></p>
                        </div> */}
            </div>
          );
        })
      ) : (
        <h3>Loading...</h3>
      );
      
    let tempDate = new Date();
    tempDate.setDate(tempDate.getDate() - 10); //10 days previous date

    const showDisputeButton =
      this.state.orderDetail.disputeId ||
      (this.state.orderDetail.order_complete_ts &&
        new Date(tempDate).getTime()) <
      new Date(this.state.orderDetail.order_complete_ts).getTime()
    // const showDisputeButton =
    //   this.state.orderDetail.disputeId ||
    //   !this.state.orderDetail.order_complete_ts ||
    //   Date.now() -
    //   new Date(this.state.orderDetail.order_complete_ts).getTime() <
    //   2592000000;

    const showOrderReturns =
    (this.state.orderDetail.order_complete_ts &&
      new Date(tempDate).getTime()) <
    new Date(this.state.orderDetail.order_complete_ts).getTime() &&
      !this.state?.orderDetail?.orderReturnId &&
      this.state.orderDetail.categoryId !== 3 &&
      !this.state.orderDetail.is_digital;

    const showCancelOrderButton = this.state.orderDetail.status === "confirmed";
    return (
      <main className="mainContainer">
        <section className="home-product-section mt-4">
          <div className="container">
            <div className="select-product-list">
              <div className="product-list-head product-list-border-bottom">
                <ul>
                  {/* <li><a href="javascript:;">Profile</a></li>
                                <li><i className="fas fa-minus"></i></li> */}
                  <li>
                    <Link to={"/orders"}>Order List</Link>
                  </li>
                  <li>
                    <i className="fas fa-minus"></i>
                  </li>
                  <li>
                    <a>Order Detail</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="profile">
          <div className="container">
            <div className="row">
              <Profile_Sidebar />

              <div className="col-lg-9">
                <section className="track-section">
                  <div className="container">
                    {this.state.errorMessage !== "" && (
                      <div className="alert alert-danger">
                        {this.state.errorMessage}
                      </div>
                    )}
                    <div className="track-section-head sub-heading-track">
                      <div>
                        <h2>Order Detail</h2>
                        <p>Check Your Order Details.</p>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="dropdown dropleft order-dropdown mr-2">
                          <button
                            type="button"
                            className="dropdown-toggle"
                            data-toggle="dropdown"
                          >
                            <i class="fas fa-ellipsis-v"></i>
                          </button>
                          <div className="dropdown-menu">
                            <Link
                              className="dropdown-item"
                              to={`/product/${this.state?.orderDetail?.items
                                  ? this.state?.orderDetail?.items[0].slug
                                  : ``
                                }`}
                            >
                              Leave A Review
                            </Link>

                            {/* <a className="dropdown-item" href="#">
                              Add To Favorites
                            </a> */}
                            {showDisputeButton && (
                              <Link
                                className="dropdown-item"
                                to={`/dispute/${this.props?.match?.params?.orderId}`}
                              >
                                Order Support
                              </Link>
                            )}
                            {showOrderReturns && (
                              <a
                                className="dropdown-item"
                                onClick={this.handleReturnOrderAlert}
                              >
                                Return Order
                              </a>
                            )}
                          </div>
                        </div>

                        {/*<select onChange={this.handleChange}>
                        <option hidden disabled selected value> -- select an option -- </option>
                          <option>Leave a review</option>
                          <option>Add to favorites</option>
                          <option>Order support</option>
                              </select>*/}

                        {/* {
                          showDisputeButton && <Link
                            to={`/dispute/${this.props.match.params.orderId}`}
                          >
                            <button className="orange-outline-btn">
                              {this.state.orderDetail.disputeId ? "View" : "Raise"} Dispute
                            </button>
                          </Link>
                        } */}
                        {/* {showCancelOrderButton && (
                          <button
                            className="orange-outline-btn"
                            onClick={this.handleCancelOrder}
                          >
                            Cancel order
                            {(() => {
                              if (this.state.showLoader) {
                                return (
                                  <span className="spinner-border spinner-border-sm ml-1"></span>
                                );
                              }
                            })()}
                          </button>
                        )} */}

                        {this.state.orderDetail.id
                          ? this.state.sellerDetail.isChatActive == 1 && (
                            <button
                              onClick={() => {
                                this.props.history.push("/messages", {
                                  message_source_type: "order",
                                  message_source_id:
                                    this.state.orderDetail.id,
                                  source_detail:
                                    this.state.orderDetail.orderNumber,
                                  seller_id:
                                    this.state.orderDetail.items[0]?.sellerId,
                                  storename:
                                    this.state.sellerDetail.storename,
                                  timezone: this.state.sellerDetail.timezone,
                                  availableFrom:
                                    this.state.sellerDetail.availableFrom,
                                  availableTo:
                                    this.state.sellerDetail.availableTo,
                                    sellerChatActive:
                                    this.state?.sellerDetail?.isChatActive == 1 ? 1 : 2
                                });
                              }}
                            >
                              <span>Contact Vendor</span>
                            </button>
                          )
                          : null}
                      </div>
                    </div>

                    {orderItems}

                    {/* <div class="cart-box">
                                            <div class="cart-box-detail">
                                                <div class="cart-box-img">
                                                    <img src="images/footer-product-item.png" />
                                                </div>
                                                <div class="cart-box-item-detail">
                                                    <h2>home grown</h2>
                                                    <p><span>Plant Type:</span>Decorative House Plant</p>
                                                    <p><span>Duration:</span>Monthly</p>
                                                </div>
                                            </div>
                                            <div class="cart-box-item-price">
                                                <p>$45</p>
                                            </div>
                                        </div> */}

                    <div className="order-detail">
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="order-detail-left">
                            <div className="table-responsive">
                              <table className="table">
                                <tbody>
                                  <tr>
                                    <td>Order No.:</td>
                                    <td>
                                      #
                                      {this.state.orderDetail.orderNumber
                                        ? this.state.orderDetail.orderNumber
                                        : this.state.orderDetail.id}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Recipient:</td>
                                    <td>
                                      {this.state.shippingAddress.firstname
                                        ? `${this.state.shippingAddress.firstname} ${this.state.shippingAddress.lastname}`
                                        : `-`}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Shipping Address:</td>
                                    <td>
                                      {this.state.shippingAddress.address
                                        ? `${this.state.shippingAddress.address_2
                                          ? this.state.shippingAddress
                                            .address_2 + ", "
                                          : ""
                                        } ${this.state.shippingAddress.address
                                        }, ${this.state.shippingAddress.city
                                        }, ${this.state.shippingAddress.state
                                        }, ${this.state.shippingAddress.country
                                        } - ${this.state.shippingAddress.zipCode
                                        }`
                                        : `-`}
                                    </td>
                                  
                                  </tr>
                                  <tr>
                                    <td>Billing Recipient:</td>
                                    <td>
                                      {this.state?.billingAddress?.name
                                        ? `${this.state.billingAddress?.name}`
                                        : this.state?.newCardDetails?.name ? `${this.state?.newCardDetails?.name}`
                                        :  this.state.shippingAddress?.firstname
                                          ? `${this.state.shippingAddress?.firstname} ${this.state.shippingAddress?.lastname}`
                                          : `-`}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Billing Address:</td>
                                    <td>
                                      {this.state.billingAddress?.address
                                        ? `${
                                            this.state.billingAddress?.billingAddress_2
                                              ? this.state.billingAddress
                                                  ?.billingAddress_2 + ", "
                                              : ""
                                          } ${
                                            this.state.billingAddress?.address
                                          }, ${
                                            this.state.billingAddress?.city
                                          }, ${
                                            this.state.billingAddress?.state
                                          }, 
                                          ${
                                            this.state.billingAddress?.zipCode
                                          }`
                                          : this.state?.newCardDetails?.address ? `${this.state?.newCardDetails.address}, ${this.state?.newCardDetails.city}, ${this.state?.newCardDetails.state}, ${this.state?.newCardDetails.zipCode}`

                                        : this.state.shippingAddress?.address
                                          ? `${
                                              this.state.shippingAddress?.address_2
                                                ? this.state?.shippingAddress
                                                    ?.address_2 + ", "
                                                : ""
                                            } ${
                                              this.state.shippingAddress?.address
                                            }, ${
                                              this.state.shippingAddress?.city
                                            }, ${
                                              this.state.shippingAddress?.state
                                            }, ${
                                              this.state.shippingAddress?.country
                                            } - ${
                                              this.state.shippingAddress?.zipCode
                                            }`
                                          : `-`}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>Process Date:</td>
                                    <td>{date ? date : `-`}</td>
                                  </tr>
                                  {this.state.sessionDetails && this.state.sessionDetails.type !== "group" && this.state.sessionDetails?.customerTime ? (
                                    <>
                                      <tr>
                                        <td>Time Slot</td>
                                        <td>{`${moment(this.state.sessionDetails?.customerTime[0][0],"HH:mm").format("hh:mm A")}-${moment(this.state.sessionDetails.customerTime[0][1],"HH:mm").format("hh:mm A")} ${this.state.sessionDetails.vendorTimeZone ? `(${this.state.sessionDetails.vendorTimeZone})` : ``}`}</td>
                                      </tr>
                                      <tr>
                                        <td>Frequency</td>
                                        <td>
                                          {
                                            this.state.sessionDetails
                                              .sessionFrequency
                                          }
                                        </td>
                                      </tr>
                                      {/* <tr>
                                        <td>Type</td>
                                        <td>{this.state.sessionDetails.type?.charAt(0).toUpperCase() + this.state.sessionDetails.type?.slice(1)}</td>
                                      </tr> */}
                                    </>
                                  ) : null}

                                  {/* {this.state.sessionDetails && this.state.sessionDetails.type == "group" && this.state.sessionDetails?.customerTime ? (
                                    <>
                                    <td>Time Slot:</td>
                                      {
                                        Object.entries(this.state.sessionDetails?.customerTime).map(([weekDay, slotsArray]) => {
                                          return <tr>
                                          <td>
                                          {[weekDay]}
                                          </td>
                                          {
                                            slotsArray && slotsArray.map((slot) => {
                                              return <td>{moment(slot[0],"HH:mm").format("hh:mm A")} to {moment(slot[1], "HH:mm").format("hh:mm A")}</td>
                                            })
                                          }
                                          </tr>
                                        
                                        
                                        
                                        })

                                      }
                                  </>
                                  ) : null} */}
                                  {!this.state.orderDetail.is_seminar_event &&
                                    this.state.sessionDetails?.customerTime && this.state.sessionDetails.type == "group" ? 
                                    <tr>
                                    <td>Time Slot: </td>
                                      <div className="month-freq">
                                        <div onClick={() => this.setState({ showModal: true})}>
                                        <button className="orange-outline-btn mt-2">view details</button>
                                                    </div>
                                      </div>
                                      </tr>
                                      :
                                      null
                                  }
                                  {
                                    !this.state.orderDetail.is_seminar_event && this.state.orderDetail?.join_url && 
                                    this.state.sessionDetails?.customerTime && this.state.sessionDetails.type == "group"
                                     && !this.state.hideButton 
                                     ? 
                                    <tr>
                                    <td>Zoom link</td>
                                    <a href={JSON.parse(this.state.orderDetail?.join_url)} target="_blank">
                                      <Button className="orange-outline-btn my-2">Join Now</Button>
                                    </a>
                                   </tr> : null
                                  }

                                  {this.state.orderDetail.is_seminar_event ? 
                                  <tr>
                                    <td>Time Slot:</td>
                                    <td>{`${moment(this.state.startTime,"HH:mm").format("hh:mm A")} to ${moment(this.state.endTime,"HH:mm").format("hh:mm A")}`}</td>
                                  </tr> : null }
                           
                                  {this.state.orderDetail.is_seminar_event ? 
                                  <tr>
                                    <td>Event Date:</td>
                                    <td> {this.state.eventDate}</td>
                                  </tr> : null }
                                  {/* {this.state.orderDetail.is_seminar_event ? 
                                  <tr>
                                    <td>Time Slot:</td>
                                    <td> {this.state.startTime} to {this.state.endTime}</td>
                                  </tr> : null } */}
                                  <tr>
                                    <td>Order Status:</td>
                                    <td>
                                      {this.state.orderDetail.status
                                        ? this.state.orderDetail.status
                                          .charAt(0)
                                          .toUpperCase() +
                                        this.state.orderDetail.status.slice(1)
                                        : `-`}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Payment Status:</td>
                                    <td>
                                      {this.state.orderDetail.paymentStatus
                                        ? this.state.orderDetail.paymentStatus
                                          .charAt(0)
                                          .toUpperCase() +
                                        this.state.orderDetail.paymentStatus.slice(
                                          1
                                        )
                                        : `-`}
                                    </td>
                                  </tr>
                                  {this.state.orderDetail.is_seminar_event?
                                  <tr>
                                    <td>Zoom link</td>
                                    <a href={this.state.zoom_link} target="_blank">
                                    <Button className="orange-outline-btn"
                                     disabled ={currentTime < parsedTime || currentTime > parsedEndTime}>Join Now</Button>
                                    </a>
                                  </tr> : null }
                                  {this.state.sessionDetails?.link &&
                                  <tr>
                                    <td>Zoom link</td>
                                    <a href={this.state.sessionDetails?.link} target="_blank">
                                      <Button className="orange-outline-btn">Join Now</Button>
                                    </a>
                                  </tr>}
                                  {this.state.orderDetail.canceled_by && (
                                    <tr>
                                      <td>Canceled by:</td>
                                      <td>
                                        {this.state.orderDetail.canceled_by}
                                      </td>
                                    </tr>
                                  )}
                                  <tr>
                                    <td>Receipts Voucher:</td>
                                    <td>-</td>
                                  </tr>
                                  {this.state.orderDetail.trackingCompany && (
                                    <>
                                      {/*<tr>
                                      <td>Tracking Company:</td>
                                      <td>
                                        {this.state.orderDetail.trackingCompany
                                          ? this.state.orderDetail
                                            .trackingCompany
                                          : `-`}
                                      </td>
                                    </tr>*/}

                                      <tr>
                                        <td>Delivery Plan:</td>
                                        <td>
                                          {this.state.orderDetail.delivery_plan
                                            ? this.state.orderDetail.delivery_plan
                                              .split("_")
                                              .join(" ")
                                            : `-`}
                                        </td>
                                      </tr>
                                      {!this.state.orderDetail?.customize_delivery_time==0 && 
                                      <tr>
                                        <td>Product Creation Time:</td>
                                        <td>
                                          {this.state.orderDetail?.customize_delivery_time == 1
                                            ? `${this.state.orderDetail?.customize_delivery_time} Day`
                                            : `${this.state.orderDetail?.customize_delivery_time} Days`}
                                        </td>
                                      </tr>}

                                      <tr>
                                {this.state.orderDetail.customize_questions && this.state.orderDetail.customize_questions !== "undefined" && JSON.parse(this.state.orderDetail.customize_questions)?.length ? (
                                  <>
                                    <td>Customize Questions:</td>
                                    <ol>
                                      {JSON.parse(this.state.orderDetail.customize_questions).map(
                                        (item, index) => (
                                          <li>{item}</li>
                                        )
                                      )}
                                    </ol>
                                  </>
                                ) : null}
                              </tr>

                                      {/*<tr>
                                      <td>Tracking Number:</td>
                                      <td>
                                        {this.state.orderDetail.trackingId
                                          ? this.state.orderDetail.trackingId
                                          : `-`}
                                      </td>
                                    </tr>*/}
                                    </>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="order-detail-right">
                            <div className="table-responsive">
                              <table className="table">
                                <tbody>
                                  <tr>
                                    <td>Item Price</td>
                                    <td>
                                      {this.state.orderDetail?.items?.length > 0
                                        ? `$${this.state.orderDetail.items[0].pricePerUnit
                                          .toFixed(2)
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}`
                                        : "-"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Quantity</td>
                                    <td>
                                      {this.state.orderDetail?.items?.length > 0
                                        ? `${this.state.orderDetail.items[0].orderedQuantity}`
                                        : "-"}
                                    </td>
                                  </tr>                   
                                  {this.state.orderDetail.trackingCompany && (
                                    <tr>
                                      <td>Shipping</td>
                                      <td>
                                        {this.state.orderDetail.shipping_amount
                                          ? `$${this.state.orderDetail.shipping_amount
                                            .toFixed(2)
                                            .replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ","
                                            )}`
                                          : "Free"}
                                      </td>
                                    </tr>
                                  )}
                                  {/* <tr>
                                                                    <td>Discount</td>
                                                                    <td>{this.state.orderDetail.discount ? `-$${(+this.state.orderDetail.discount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : "-"}</td>
                                                                </tr> */}
                                  {this.state.orderDetail.discount &&
                                    +this.state.orderDetail.discount > 0 ? (
                                    <tr>
                                      <td>Discount</td>
                                      <td>{`-$${(+this.state.orderDetail
                                        .discount)
                                        .toFixed(2)
                                        .replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ","
                                        )}`}</td>
                                    </tr>
                                  ) : null}
                                   <tr>
                                    <td>Subtotal</td>
                                    <td>
                                      {this.state.orderDetail?.items?.length > 0
                                        ? `$${(
                                          this.state.orderDetail.items[0]
                                            .pricePerUnit *
                                          this.state.orderDetail?.items[0]
                                            ?.orderedQuantity
                                        )
                                          .toFixed(2)
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}`
                                        : "-"}
                                      {/* {this.state.orderDetail.subTotal
                                        ? `$${this.state.orderDetail.subTotal
                                            .toFixed(2)
                                            .replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ","
                                            )}`
                                        : "-"} */}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Tax</td>
                                    <td>
                                      {this.state.orderDetail.tax
                                        ? `$${this.state.orderDetail.tax
                                          .toFixed(2)
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}`
                                        : "$0.00"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Total</b>
                                    </td>
                                    <td>
                                      <b>
                                        {this.state.orderDetail?.items?.length >
                                          0
                                          ? `$${(
                                            this.state.orderDetail.items[0]
                                              .pricePerUnit *
                                            this.state.orderDetail?.items[0]
                                              ?.orderedQuantity +
                                            this.state?.orderDetail
                                              ?.shipping_amount -
                                            parseFloat(
                                              this.state?.orderDetail
                                                ?.discount
                                            ) +
                                            this.state.orderDetail?.tax
                                          )
                                            .toFixed(2)
                                            .replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ","
                                            )}`
                                          : "-"}
                                        {/* {this.state.orderDetail.total
                                          ? `$${this.state.orderDetail.total
                                              .toFixed(2)
                                              .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )}`
                                          : "-"} */}
                                      </b>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            {this.state.showRefundableBar && (
                              <div className="d-flex justify-content-between align-items-center ">
                                <h5 className="mb-0">Refunded Amount</h5>
                                <p className="mb-0">
                                  <b>
                                    {this.state.refundableAmount &&
                                      `$${this.state.refundableAmount}`}
                                  </b>
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="text-right">
                        {(() => {
                          if (
                            !this.state.isEditForm &&
                            this.state.orderDetail.trackingId
                          ) {
                            return (
                              <Link
                                to={`/orders/track/${this.state.orderDetail.id}`}
                                type="text"
                                className="orange-outline-btn"
                              >
                                <i className="far fa-edit mb-3"></i> Track this order
                              </Link>
                            );
                          }
                        })()}
                      </div>
                      {this.state?.orderDetail?.orderReturnId &&
                        this.state?.orderDetail?.orderReturnStatus ==
                        "pending" && (
                          <h5 className="colorGreen">
                            You have successfully requested for order return!!
                          </h5>
                        )}
                    </div>
                    {this.state.orderDetail?.orderReturnId && (
                      <div className="table-responsive border-table-status">
                        <table className="table">
                          <tr>
                            <td className="status-label-width">
                              <strong>Order Return Status:</strong>
                            </td>
                            <td
                              className={`${this.state.orderDetail.orderReturnStatus ==
                                  "pending"
                                  ? `pendingColor`
                                  : this.state.orderDetail.orderReturnStatus ==
                                    "rejected"
                                    ? `rejectedColor`
                                    : `approvedColor`
                                }`}
                            >
                              {this.state.orderDetail.orderReturnStatus
                                ? this.state.orderDetail.orderReturnStatus
                                  .charAt(0)
                                  .toUpperCase() +
                                this.state.orderDetail.orderReturnStatus.slice(
                                  1
                                )
                                : "-"}
                            </td>
                          </tr>

                          {this.state.orderDetail?.message && (
                            <tr>
                              <td>
                                <strong>Message:</strong>
                              </td>
                              <td className="word-table-show">
                                {this.state.orderDetail.message}
                              </td>
                            </tr>
                          )}
                          {this.state.orderDetail?.vendor_message && (
                            <tr>
                              <td>
                                <strong>Vendor's Message:</strong>
                              </td>
                              <td>{this.state.orderDetail.vendor_message}</td>
                            </tr>
                          )}
                          {this.state.orderDetail?.orderReturnStatus ==
                            "accepted" &&
                            this.state.customerTrackingDetails
                              ?.shipping_company_by_customer && (
                              <tr>
                                <td>
                                  <strong>Shipping Company:</strong>
                                </td>
                                <td>
                                  {
                                    this.state.customerTrackingDetails
                                      ?.shipping_company_by_customer
                                  }
                                </td>
                              </tr>
                            )}
                          {this.state.orderDetail?.orderReturnStatus ==
                            "accepted" &&
                            !this.state.customerTrackingDetails
                              ?.shipping_company_by_customer && (
                              <tr>
                                <td>
                                  <strong>Shipping Company:</strong>
                                </td>

                                <td>
                                  <input
                                    type={`text`}
                                    onChange={(e) =>
                                      this.handleShippingCompanyAndTrackingIdByCustomer(
                                        e,
                                        "shippingCompany"
                                      )
                                    }
                                    placeholder={`Enter shipping company`}
                                  ></input>
                                </td>
                              </tr>
                            )}
                          {this.state.orderDetail?.orderReturnStatus ==
                            "accepted" &&
                            this.state.customerTrackingDetails
                              ?.customer_trackingId && (
                              <tr>
                                <td>
                                  <strong>Tracking Id:</strong>
                                </td>
                                <td>
                                  {
                                    this.state.customerTrackingDetails
                                      ?.customer_trackingId
                                  }
                                </td>
                              </tr>
                            )}
                          {this.state.orderDetail?.orderReturnStatus ==
                            "accepted" &&
                            !this.state.customerTrackingDetails
                              ?.customer_trackingId && (
                              <tr>
                                <td>
                                  <strong>Tracking Id:</strong>
                                </td>

                                <td>
                                  <input
                                    type={`text`}
                                    onChange={(e) =>
                                      this.handleShippingCompanyAndTrackingIdByCustomer(
                                        e,
                                        "trackingId"
                                      )
                                    }
                                    placeholder={`Enter tracking id`}
                                  ></input>
                                  {this.state.orderDetail?.orderReturnStatus ==
                                    "accepted" &&
                                    this.state.customer_trackingId &&
                                    this.state.shipping_company_by_customer && (
                                      <button
                                        className="ml-2"
                                        onClick={() =>
                                          this.submitTrackingIdAndShippingCompany()
                                        }
                                        disabled={
                                          !!!this.state.customer_trackingId ||
                                          !!!this.state
                                            .shipping_company_by_customer
                                        }
                                      >
                                        update
                                        {(() => {
                                          if (this.state.submitLoader) {
                                            return (
                                              <span className="spinner-border spinner-border-sm ml-1"></span>
                                            );
                                          }
                                        })()}
                                      </button>
                                    )}
                                </td>
                              </tr>
                            )}
                          {this.state.orderDetail?.orderReturnStatus ==
                            "accepted" &&
                            this.state.orderDetail.return_shipment_address && (
                              <tr>
                                <td>
                                  <strong>Return shipment address:</strong>
                                </td>
                                <td>{
                                  `${this.state.returnAddress?.return_shipment_address}
                                  ${this.state.returnAddress?.returnShipmentAddress_2 ? `,${this.state.returnAddress?.returnShipmentAddress_2}`: ``}
                                  ${this.state.returnAddress?.returnCountry ? `,${this.state.returnAddress?.returnCountry}`:``},
                                  ${this.state.returnAddress?.returnState},
                                  ${this.state.returnAddress?.returnCity},
                                  ${this.state.returnAddress?.returnShipmentZipcode}
                                  `}
                                </td>
                              </tr>
                            )}
                          {/* {this.state.orderDetail?.orderReturnStatus ==
                            "accepted" &&
                            this.state.sellerAddressDetail && (
                              <tr>
                                <td><strong>Vendor's Address:</strong></td>
                                <td>
                                  {this.state?.sellerAddressDetail?.address + " " +
                                  this.state.sellerAddressDetail.address_2 + " " +
                                      this.state.sellerAddressDetail.city + " " +
                                      this.state.sellerAddressDetail.country + " " +
                                      this.state.sellerAddressDetail.zipCode}
                                </td>
                              </tr>
                            )} */}
                        </table>
                      </div>
                    )}
                    <div className="upload-gallery mb-4">
                      <div className="d-flex">                      
                        {this.state.orderDetail.is_digital && this.state.digitalUrl ? this.state.digitalUrl.map((item, index) => {
                          if (item.URL.includes("video_gallery/")) {
                            return <div className="upload-gallery-card">
                              <span className="download-btn" onClick={() => this.downloadImage(item.URL, "video_gallery")}><i class="fa fa-download" aria-hidden="true"></i></span>
                          <video width="200" height="150" controls>
                            <source src={item.URL} />
                          </video>
                          </div>
                        } else if (item.URL.includes("image_gallery/")) {
                          return <div className="upload-gallery-card">                          
                         <span className="download-btn" onClick={() => this.downloadImage(item.URL, "image_gallery")}><i class="fa fa-download" aria-hidden="true"></i></span> 
                          <a href={item.URL} target="blank"><img width="200" height="150" src={item.URL} /></a>
                          </div>
                        } else if (item.URL.includes("doc/")) {
                          const docNameArray = item.URL.split("/")
                          const docName = docNameArray[docNameArray.length - 1].split("?")
                          return <div className="upload-gallery-card">
                            <span className="download-btn" onClick={() => this.downloadImage(item.URL, "doc")}><i class="fa fa-download" aria-hidden="true"></i></span>
                           <a href={item.URL} target="blank">
                           <div className="file-name">
                          <span>
                          {docName[0]} 
                          </span>
                        </div>   
                          </a>
                           </div>
                        }
                      }) : null}
                    </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
        {this.returnOrderModal()}
        {this.groupTimeSlotsModal()}
      </main>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getOrderDetail,
      cancelOrderByOrderId,
      requestOrderReturn,
      updateShippingCompanyAndTrackingId,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(Order_Detail);
