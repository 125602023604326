import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import Footer from '../Components/Footer/Footer'
import VendorHeader from "../Components/VendorHeader/VendorHeader";


const WithHeaderAndFooter = (props) => {
  return (
    <div>
      <div className="PrivateArea__content">
        <VendorHeader />
        <div className="main_sec">
        <Route {...props} />
          <Footer />
        </div>
      </div>
    </div>
  );

}

class PrivateGuard extends React.Component {
  dontShowAfterLogin=[
    '/signup',
    '/signUp',
    '/login',
    '/reset-password',
    '/forgot-password'

  ]
  render() {
    const route = this.props.location?.pathname;
    let returnData = "";

    
    if (localStorage.getItem("userId")) {
     
    if(!this.dontShowAfterLogin.includes(route)){
          returnData = <WithHeaderAndFooter {...this.props} />;
    }else{
      returnData = (
        <Redirect
          to={{
            pathname: "/",
            state: { from: this.props.location },
          }}
        />
      );
    }
     
    }else{
      if(this.dontShowAfterLogin.includes(route)){
        returnData = <WithHeaderAndFooter {...this.props} />;
      }else{
      returnData = (
        <Redirect
          to={{
            pathname: "/signup",
            state: { from: this.props.location },
          }}
        />
      );
        }
        }

    return <div>{returnData}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    authData: state.authReducer.authData,
  };
};

export default connect(mapStateToProps)(PrivateGuard);
