import React, { Component } from "react";
import "../../Styles/order-detail.scss";
import Profile_Sidebar from "../Profile-Side-Bar/Profile_Sidebar";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getOrderDetail,
  cancelOrderByOrderId,
} from "../../Redux/Actions/orderAction";
import {confirmAlert} from "react-confirm-alert";
import axios from "axios";

class Order_Return extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderDetail: {},
      sellerDetail: {},
      shippingAddress: {},
      isShippingChargeEnable: false,
      showRefundableBar: false,
      refundableAmount: null,
      showLoader: false,
      errorMessage: ''
    };
  }

  componentDidMount() {
    this.fetchOrderDetail();
  }

  fetchOrderDetail = async () => {
    const result = await this.props.getOrderDetail(
      localStorage.getItem("userId"),
      this.props.match.params.orderId
    );
    if (result?.data?.data) {
      const sellerMap = result.data.data.items[0]?.sellerMap
        ? JSON.parse(result.data.data.items[0]?.sellerMap)
        : {};
      this.setState({
        orderDetail: result.data.data,
        sellerDetail: sellerMap,
        shippingAddress: result.data.data.address
          ? JSON.parse(result.data.data.address)
          : null,
        showRefundableBar: result.data.data.orders_status_history
          ? JSON.parse(result.data.data.orders_status_history).some((status) =>
              [`canceled`].includes(status.status)
            )
          : false,
        isShippingChargeEnable: result.data.data.orders_status_history
          ? JSON.parse(result.data.data.orders_status_history).some((status) =>
              [`shipped`, "completed"].includes(status.status)
            )
          : false,

        refundableAmount: result.data.data.refunded_response
          ? result.data.data.paymentMethod === "STRIPE"
            ? (
                JSON.parse(result.data.data.refunded_response)
                  ?.amount_refunded / 100
              )?.toFixed(2)
            : JSON.parse(result.data.data.refunded_response)?.amount?.total
          : null,
      });
    }
  };

  download = (url) => {
    axios({
      url,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.jpg");
      document.body.appendChild(link);
      link.click();
    });
  };

  getFileName = (url) => {
    let name = url.split("/");
    return name[4] + ".jpg";
  };
  
  handleCancelOrder = async () => {
    this.setState({ showLoader: true });
    const result = await this.props.cancelOrderByOrderId(
      this.props.match.params.orderId
    );
    if (result?.data?.data?.statusCode == 201) {
      this.fetchOrderDetail();
      this.setState({ showLoader: false });
    } else if (result.data.isError){
      this.setState({
        errorMessage: "Something went wrong. Please try again later.",
    })
    setTimeout(() => {
      this.setState({
        errorMessage: "",
    })
    }, 3000);
    }
  };

  handleChange = (e) => {
    if (e.target.value === "Leave a review") {
      const slug = this.state.orderDetail.items[0].slug;
      this.props.history.push(`/product/${slug}`);
    } else if (e.target.value === "Order support") {
      this.props.history.push(`/dispute/${this.props.match.params.orderId}`);
    }
  };

  updateCancelStatus = () => {
    confirmAlert({
      title: "",
      message: "Are you sure you want to cancel your order?",
      buttons: [
          {
              label: "Yes",
              onClick: () => this.handleCancelOrder(),
          },
          {
              label: "No",
              onClick: () => {
              },
          },
      ],
  });
  }

  render() {
    const date = this.state.orderDetail.createTS
      ? new Date(this.state.orderDetail.createTS).toISOString().substring(0, 10)
      : null;
    let variationKey = [];
    let variationValue = [];
    if (this.state.orderDetail.variant_values) {
      const variants = JSON.parse(this.state.orderDetail.variant_values);
      for (let key in variants) {
        variationKey.push(key);
        variationValue.push(variants[key]);
      }
    }
    const orderItems =
      this.state.orderDetail.items &&
      this.state.orderDetail.items.length > 0 ? (
        this.state.orderDetail.items.map((i) => {
          return (
            <div key={i.id} className="cart-box w-100">
              <div className="cart-box-detail w-100">
                <div className="cart-box-img">
                  <img src={i.imageName} />
                </div>
                <div className="cart-box-item-detail w-100">
                  <h2>{i.name}</h2>
                  <p>
                    <strong>Frequency: </strong>{" "}
                    <span>
                      {this.state.orderDetail.order_type === "one_time"
                        ? "one time"
                        : this.state.orderDetail.subscription_timeframe}
                    </span>
                  </p>
                  {i.customizationNote && i.customizationNote.length !== 0 && (
                    <p>
                      <strong>Customization Note:</strong>
                      <span className="item-custom-desc">
                        {i.customizationNote}
                      </span>
                    </p>
                  )}
                  {i.customizationImage && i.customizationImage.length !== 0 && (
                    <p className="d-flex align-items-center justify-content-between">
                      <div>
                        <strong>Customization Image:</strong>{" "}
                        <span>{this.getFileName(i.customizationImage)}</span>
                      </div>
                      <span>
                        <a href={i.customizationImage} target="_blank">
                          View
                        </a>
                        <a onClick={() => this.download(i.customizationImage)}>
                          Download
                        </a>
                      </span>
                    </p>
                  )}
                  {variationKey.length > 0 && (
                    <p>
                      <strong>Variation:- </strong> <br />
                      {variationKey.map((key, index) => (
                        <div className="margin-variation">
                          <strong>{key}: </strong>{" "}
                          <span>{variationValue[index]}</span>
                        </div>
                      ))}
                    </p>
                  )}
                </div>
              </div>
              {/* <div className="cart-box-item-price">
                            <p>${(i.pricePerUnit).toFixed(2)} x <span>{i.orderedQuantity}</span></p>
                        </div> */}
            </div>
          );
        })
      ) : (
        <h3>Loading...</h3>
      );

    const showDisputeButton =
      this.state.orderDetail.disputeId ||
      !this.state.orderDetail.order_complete_ts ||
      Date.now() -new Date(this.state.orderDetail.order_complete_ts).getTime() <
        2592000000;


    const showOrderReturns = (Date.now() - new Date(this.state.orderDetail.order_complete_ts).getTime() ) <
    2592000000;
       
    const showCancelOrderButton =
      this.state.orderDetail.status === "confirmed";
    return (
      <main className="mainContainer">
        <section className="home-product-section mt-4">
          <div className="container">
            <div className="select-product-list">
              <div className="product-list-head product-list-border-bottom">
                <ul>
                  {/* <li><a href="javascript:;">Profile</a></li>
                                <li><i className="fas fa-minus"></i></li> */}
                  <li>
                    <Link to={"/orders"}>Order list</Link>
                  </li>
                  <li>
                    <i className="fas fa-minus"></i>
                  </li>
                  <li>
                    <a>Order detail</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="profile">
          <div className="container">
            <div className="row">
              <Profile_Sidebar />

              <div className="col-lg-9">
                <section className="track-section">
                  <div className="container">
                    {this.state.errorMessage !== "" && <div className="alert alert-danger">{this.state.errorMessage}</div>}
                    <div className="track-section-head sub-heading-track">
                      <div>
                        <h2>Order Return</h2>
                        <p>Return Shipment</p>
                      </div>
                    </div>

                    {orderItems}
                    <div className="order-detail">
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="order-detail-left">
                            <div className="table-responsive">
                              <table className="table">
                                <tbody>
                                  <tr>
                                    <td>Order No.:</td>
                                    <td>
                                      #
                                      {this.state.orderDetail.orderNumber
                                        ? this.state.orderDetail.orderNumber
                                        : this.state.orderDetail.id}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Recipient:</td>
                                    <td>
                                      {this.state.shippingAddress.firstname
                                        ? `${this.state.shippingAddress.firstname} ${this.state.shippingAddress.lastname}`
                                        : `-`}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Shipping Address:</td>
                                    <td>
                                      {this.state.shippingAddress.address
                                        ? `${
                                            this.state.shippingAddress.address_2
                                              ? this.state.shippingAddress
                                                  .address_2 + ", "
                                              : ""
                                          } ${
                                            this.state.shippingAddress.address
                                          }, ${
                                            this.state.shippingAddress.city
                                          }, ${
                                            this.state.shippingAddress.state
                                          }, ${
                                            this.state.shippingAddress.country
                                          } - ${
                                            this.state.shippingAddress.zipCode
                                          }`
                                        : `-`}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>Process Date:</td>
                                    <td>{date ? date : `-`}</td>
                                  </tr>
                                  <tr>
                                    <td>Order Status:</td>
                                    <td>
                                      {this.state.orderDetail.status
                                        ? this.state.orderDetail.status
                                            .charAt(0)
                                            .toUpperCase() +
                                          this.state.orderDetail.status.slice(1)
                                        : `-`}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Payment Status:</td>
                                    <td>
                                      {this.state.orderDetail.paymentStatus
                                        ? this.state.orderDetail.paymentStatus
                                            .charAt(0)
                                            .toUpperCase() +
                                          this.state.orderDetail.paymentStatus.slice(
                                            1
                                          )
                                        : `-`}
                                    </td>
                                  </tr>
                                  {
                                    this.state.orderDetail.canceled_by &&
                                  <tr>
                                    <td>Canceled by:</td>
                                    <td>
                                      {this.state.orderDetail.canceled_by}
                                    </td>
                                  </tr>
                                  }
                                  <tr>
                                    <td>Receipts Voucher:</td>
                                    <td>-</td>
                                  </tr>
                                  {
                                        this.props.location.state.message &&
                                        <tr>
                                          <td>Message:</td>
                                          <td>{this.props.location.state.message}</td>
                                        </tr>
                                      }
                                  {this.state.orderDetail.trackingCompany && (
                                    <>
                                      {/*<tr>
                                      <td>Tracking Company:</td>
                                      <td>
                                        {this.state.orderDetail.trackingCompany
                                          ? this.state.orderDetail
                                            .trackingCompany
                                          : `-`}
                                      </td>
                                    </tr>*/}

                                      <tr>
                                        <td>Delivery Plan:</td>
                                        <td>
                                          {this.state.orderDetail.delivery_plan
                                            ? this.state.orderDetail.delivery_plan
                                                .split("_")
                                                .join(" ")
                                            : `-`}
                                        </td>
                                      </tr>
                                      {/*<tr>
                                      <td>Tracking Number:</td>
                                      <td>
                                        {this.state.orderDetail.trackingId
                                          ? this.state.orderDetail.trackingId
                                          : `-`}
                                      </td>
                                    </tr>*/}
                                    </>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="order-detail-right">
                            <div className="table-responsive">
                              <table className="table">
                                <tbody>
                                  <tr>
                                    <td>Item Price</td>
                                    <td>
                                      {this.state.orderDetail?.items?.length > 0
                                        ? `$${this.state.orderDetail.items[0].pricePerUnit
                                            .toFixed(2)
                                            .replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ","
                                            )}`
                                        : "-"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Quantity</td>
                                    <td>
                                      {this.state.orderDetail?.items?.length > 0
                                        ? `${this.state.orderDetail.items[0].orderedQuantity}`
                                        : "-"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Subtotal</td>
                                    <td>
                                      {this.state.orderDetail.subTotal
                                        ? `$${this.state.orderDetail.subTotal
                                            .toFixed(2)
                                            .replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ","
                                            )}`
                                        : "-"}
                                    </td>
                                  </tr>
                                  {this.state.orderDetail.trackingCompany && (
                                    <tr>
                                      <td>Shipping</td>
                                      <td>
                                        {this.state.orderDetail.shipping_amount
                                          ? `$${this.state.orderDetail.shipping_amount
                                              .toFixed(2)
                                              .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )}`
                                          : "Free"}
                                      </td>
                                    </tr>
                                  )}
                                  {/* <tr>
                                                                    <td>Discount</td>
                                                                    <td>{this.state.orderDetail.discount ? `-$${(+this.state.orderDetail.discount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : "-"}</td>
                                                                </tr> */}
                                  {this.state.orderDetail.discount &&
                                  +this.state.orderDetail.discount > 0 ? (
                                    <tr>
                                      <td>Discount</td>
                                      <td>{`-$${(+this.state.orderDetail
                                        .discount)
                                        .toFixed(2)
                                        .replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ","
                                        )}`}</td>
                                    </tr>
                                  ) : null}
                                  <tr>
                                    <td>Tax</td>
                                    <td>
                                      {this.state.orderDetail.tax
                                        ? `$${this.state.orderDetail.tax
                                            .toFixed(2)
                                            .replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ","
                                            )}`
                                        : "$0.00"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Total</b>
                                    </td>
                                    <td>
                                      <b>
                                        {this.state.orderDetail.total
                                          ? `$${this.state.orderDetail.total
                                              .toFixed(2)
                                              .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )}`
                                          : "-"}
                                      </b>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            {this.state.showRefundableBar && (
                              <div className="d-flex justify-content-between align-items-center ">
                                <h5 className="mb-0">Refunded Amount</h5>
                                <p className="mb-0">
                                  <b>
                                    {this.state.refundableAmount &&
                                      `$${this.state.refundableAmount}`}
                                  </b>
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="text-right">
                        {(() => {
                          if (
                            !this.state.isEditForm &&
                            this.state.orderDetail.trackingId
                          ) {
                            return (
                              <Link
                                to={`/orders/track/${this.state.orderDetail.id}`}
                                type="text"
                                className="orange-outline-btn"
                              >
                                <i className="far fa-edit"></i> Track this order
                              </Link>
                            );
                          }
                        })()}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getOrderDetail, cancelOrderByOrderId }, dispatch);
};

export default connect(null, mapDispatchToProps)(Order_Return);
