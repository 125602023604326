import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import TimePicker from "react-time-picker";
import DatePicker from "react-date-picker";
import { getProductDetail } from "../../Redux/Actions/productAction";
import {
  addToBuyNow,
  oneOnOneMeetingDetail,
  withdrawOneOnOneMeeting,
} from "../../Redux/Actions/oneOnOneMeetings";
import { createOneOnOneMeeting } from "../../Redux/Actions/meetingActions";
import { ONE_TIME, WEEKLY } from "../../Constants/constant";
import { Link } from "react-router-dom";
import Profile_Sidebar from "../Profile-Side-Bar/Profile_Sidebar";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "../../Styles/profile.scss";

const OneOnOneMeetingsDetail = (props) => {
  const dispatch = useDispatch();
  const [orderDetail, setOrderDetail] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [showWithdrawLoader, setShowWithdrawLoader] = useState(false);
  const [showPayNowLoader, setShowPayNowLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [showHide, setShowHide] = useState(false);
  let [eventSelectedDay, setEventSelectedDay] = useState(new Date());
  const [meetingType, setMeetingType] = useState(undefined); // meeting type = 0 for recurring and 1 for one time
  const [reqMessage, setreqMessage] = useState("");
  const [errorMsg, setErrorMsg] = useState({
    time: "",
    date: "",
  });
  const [isDisableButton, setIsDisableButton] = useState(false);
  const [meetingDurationType, setMeetingDurationType] = useState(WEEKLY);
  const [oneOnOneMeetingPrice, setOneOnOneMeetingPrice] = useState();
  const [productTimeFrame, setProductTimeFrame] = useState();
  const [formData, setFormData] = useState({
    start: "",
    end: "",
    meetingDate: moment().format("YYYY-MM-DD"),
  });
  const [error, setError] = useState("");

  const [productVariantPrice, setProductVariantPrice] = useState();

  const [meetingMessage, setMeetingMessage] = useState();
  const history = useHistory();

  useEffect(() => {
    fetchOneOnOneMeetingDetails();
  }, []);

  const fetchOneOnOneMeetingDetails = async () => {
    const res = await dispatch(
      oneOnOneMeetingDetail(props.match.params.meetingId)
    );
    setOrderDetail(res?.data?.data);
    setProductTimeFrame(res.data.data[0]?.meeting_type);
    const item = await dispatch(getProductDetail(res.data.data[0].slug));
    if (
      item[`one_on_one_meeting_price`] === 0 ||
      item[`isActive`] === 0 ||
      item.isError
    ) {
      setError("Product is not available now. Please contact admin!");
      setShowPayNowLoader(false);
      return null;
    }
    const meetingObj = res.data.data[0];
    setOneOnOneMeetingPrice(item[`one_on_one_meeting_price`]);
    let price = item[`one_on_one_meeting_price`];
    if (
      meetingObj &&
      meetingObj["variation_combination"]?.length > 1 &&
      item["productVariation"]
    ) {
      item["productVariation"].forEach((variant) => {
        if (variant.variant_value === meetingObj["variation_combination"]) {
          price = variant.one_on_one_meeting_price;
          setProductVariantPrice(price);
        }
      });
    }
  };
  const handleRequestMeeting = () => {
    setShowHide(true);
    setMeetingMessage();
  };

  const meetingHeader = () => {
    if (orderDetail && orderDetail.length > 0) {
      return (
        <> 
        <div className="cart-box w-100 mb-0">
          <div className="cart-box-detail w-100">
            <div className="cart-box-img">
              <img src={orderDetail[0].imageName} />
            </div>
            <div className="cart-box-item-detail w-100">
              <h2>{orderDetail[0].productName}</h2>
            </div>
          </div>
          {/* {(orderDetail[orderDetail.length - 1].status == "pending" &&
            orderDetail[orderDetail.length - 1].sender == "user") ||
            (orderDetail[orderDetail.length - 1].sender == "user" &&
              !orderDetail[orderDetail.length - 1].order_id && (
                <button
                  className="orange-btn white-nowrap"
                  onClick={handleRequestMeeting}
                >
                  Request new time
                </button>
              ))} */}
          {/* {(orderDetail[orderDetail.length - 1].status == "pending" &&
            orderDetail[orderDetail.length - 1].sender == "user") ||
            (orderDetail[orderDetail.length - 1].sender == "vendor" &&
              orderDetail[orderDetail.length - 1].status !== "rejected") ||
            (!orderDetail[orderDetail.length - 1].order_id && (
              <button
                className="orange-btn white-nowrap"
                onClick={handleRequestMeeting}
              >
                Request new time
              </button>
            ))} */}

          {((error.length === 0 &&
            orderDetail[orderDetail.length - 1].sender == "user" &&
            orderDetail[orderDetail.length - 1].status == "pending") ||
            (orderDetail[orderDetail.length - 1].sender == "vendor" &&
              orderDetail[orderDetail.length - 1].status !== "rejected")) &&
            !orderDetail[orderDetail.length - 1].order_id && (
              <button
                className="orange-btn white-nowrap mr-2"
                onClick={handleRequestMeeting}
              >
                Request new time
              </button>
            )}
          {/* {((error.length === 0 && orderDetail[orderDetail.length - 1].sender == "user" &&
              orderDetail[orderDetail.length - 1].status !== "rejected") ||
              (orderDetail[orderDetail.length - 1].sender == "vendor" &&
                orderDetail[orderDetail.length - 1].status !== "rejected")) &&
              !orderDetail[orderDetail.length - 1].order_id && (
              <button
                className="orange-btn white-nowrap mr-2"
                onClick={handleRequestMeeting}
              >
                Request new time
              </button>
            )} */}
          {error.length === 0 &&
            orderDetail[orderDetail.length - 1].status == "approved" &&
            error.length === 0 &&
            !orderDetail[orderDetail.length - 1].order_id && (
              <div className="button-approved-note">
              <p>Note* <span className="colorGreen">Meeting approved</span></p>
              <button
                className="orange-btn white-nowrap"
                onClick={() =>
                  handlePayNow(orderDetail[orderDetail.length - 1].slug)
                }
              >
                Pay Now
                {(() => {
                  if (showPayNowLoader) {
                    return (
                      <span className="spinner-border spinner-border-sm ml-1"></span>
                    );
                  }
                })()}
              </button>
              </div>
            )}
        </div>

{error.length > 0 && (
  <span className="form-field-error">{error}</span>
)}
</>
      );
    }
  };
  const handleModalShowHide = () => {
    setShowHide(false);
    setFormData({ start: "", end: "" });
    setMeetingType(undefined);
  };
  const handleSetMeetingEvent = (e, value) => {
    e.preventDefault();
    if (value) {
      setMeetingDurationType(ONE_TIME);
    } else {
      setMeetingDurationType(WEEKLY);
    }
    setMeetingType(+value);
  };

  const handlePayNow = async (product) => {
    setShowPayNowLoader(true);
    const item = await dispatch(getProductDetail(product));
    if (
      item[`one_on_one_meeting_price`] === 0 ||
      item[`isActive`] === 0 ||
      item.isError
    ) {
      setError("Product is not available now. Please contact admin!");
      setShowPayNowLoader(false);
      return null;
    }
    const discountPrecentage = 0;

    let paymentOptionType;
    const meetingObj = orderDetail[orderDetail.length - 1];
    meetingObj?.meeting_type !== "one time"
      ? (paymentOptionType = "recurring")
      : (paymentOptionType = "one time");
    let price = item[`one_on_one_meeting_price`];

    if (meetingObj["variation_combination"]?.length > 1) {
      item["productVariation"].forEach((variant) => {
        if (variant.variant_value === meetingObj["variation_combination"]) {
          price = variant.one_on_one_meeting_price;
        }
      });
    }
    await dispatch(
      addToBuyNow({
        ...item,
        sale_price: discountPrecentage,
        actualPrice: item.price,
        price,
        paymentOptionType: paymentOptionType,
        customizationNote: "",
        customizationImage: "",
        selectedQuantity: 1,
        oneOnOneMeeting: true,
        productVariation: item["productVariation"],
        selectedPair: meetingObj["variation_combination"]
          ? JSON.parse(meetingObj["variation_combination"])
          : null,
        oneOnOneMeetingId: meetingObj["meeting_id"],
        oneOnOneMeetingHistoryId: meetingObj["id"],
      })
    );
    setShowPayNowLoader(false);
    history.push(`/checkout/paynow`);
  };

  const handleMeetingWithdrawl = async () => {
    // const lastElementId = orderDetail
    confirmAlert({
      title: "",
      message: `Do you want to withdraw meeting request?`,
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            setShowWithdrawLoader(true);
            const res = await dispatch(
              withdrawOneOnOneMeeting(
                orderDetail[orderDetail.length - 1].id,
                orderDetail[orderDetail.length - 1].meeting_id
              )
            );
            if (!res.data.isError) {
              await fetchOneOnOneMeetingDetails();
            }
            setShowWithdrawLoader(false);
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const createNewEvent = async () => {
    const startTimeDate = new Date(
      moment(new Date(eventSelectedDay)).format("MM-DD-YYYY") +
        " " +
        formData["start"]
    );
    const endTimeDate = new Date(
      moment(new Date(eventSelectedDay)).format("MM-DD-YYYY") +
        " " +
        formData["end"]
    );
    if (
      startTimeDate.getTime() <= Date.now() ||
      formData["start"] == formData["end"]
    ) {
      setErrorMsg({
        time: "Enter valid time",
        date: "",
      });
      setTimeout(() => {
        setErrorMsg({ time: "" });
      }, 2000);
      return;
    }
    if (startTimeDate.getTime() > endTimeDate.getTime()) {
      setErrorMsg({
        time: "Enter valid start date",
        date: "",
      });
      setTimeout(() => {
        setErrorMsg({ time: "" });
      }, 2000);
      return;
    }
    setIsDisableButton(true);
    setShowLoader(true);
    // if (props.match.params.meetingId) {
    //   isExistingMeeting = true;
    // }
    let body = {
      productId: orderDetail[0].product_id,
      userId: localStorage.getItem("userId"),
      sender: "user",
      meeting_type: meetingDurationType,
      meeting_date: moment(new Date(eventSelectedDay)).format("YYYY-MM-DD"),
      start_time: formData["start"],
      end_time: formData["end"],
      meeting_day: moment(new Date(eventSelectedDay)).day(),
      meetingId: props.match.params.meetingId,
      message: meetingMessage,
      storeId: orderDetail[0]?.sellerId,
    };
    const result = await dispatch(createOneOnOneMeeting(body));
    if (!result?.isError) {
      setreqMessage("request submitted successfully");
      setIsDisableButton(false);
      setShowLoader(false);
      setTimeout(() => {
        setShowHide(false);
        setreqMessage("");
      }, 1000);
      setIsDisableButton(false);
    }
    await fetchOneOnOneMeetingDetails();
  };

  const OrderDetail = () => {
    const date = orderDetail.createTS
      ? new Date(orderDetail.createTS).toISOString().substring(0, 10)
      : null;
    let variationKey = [];
    let variationValue = [];
    if (orderDetail.variant_values) {
      const variants = JSON.parse(orderDetail.variant_values);
      for (let key in variants) {
        variationKey.push(key);
        variationValue.push(variants[key]);
      }
    }
    const orderItems =
      orderDetail && orderDetail.length > 0 ? (
        orderDetail.map((i, index) => {
          return (
            <>
              <div
                key={i.id}
                className={`meeting-main ${
                  i.sender == "user" ? "right-meeting" : null
                }`}
              >
                <p>
                  <strong>Sender: </strong>
                  {i.sender}
                </p>
                <p>
                  <strong>Timeframe : </strong>
                  {i.timeframe.replace("_", " ")}
                </p>
                {i.sender == "user" && (
                  <p
                    className={`${
                      i.status == "pending"
                        ? "pendingColor"
                        : i.status == "approved"
                        ? "approvedColor"
                        : "rejectedColor"
                    }`}
                  >
                    <strong>status: </strong>
                    {i.status}
                  </p>
                )}
                <p>
                  <strong>Meeting Type: </strong>
                  {i.meeting_type ? i.meeting_type.replace("_", " ") : "-"}
                </p>
                {i.message && (
                  <p>
                    <strong>Message: </strong>
                    {i.message}
                  </p>
                )}
                <p>
                  <strong>Meeting date: </strong>
                  {i.meeting_date?.slice(0, 10)}
                  <span className="item-custom-desc"></span>
                </p>
                <p>
                  <strong>Meeting day: </strong>
                  {i.meeting_day}
                </p>
                <p className="colorTheme">
                  <strong>Meeting time: </strong>
                  <span className="f600">
                    {i.start_time?.slice(0, 5)} - {i.end_time?.slice(0, 5)}
                  </span>
                </p>
                <p className="time-meeting">
                  {moment(i.created_ts).format("MM-DD-YYYY HH:mm A")}
                </p>
              </div>
              <div className="chating-meeting-btn">
              {error.length === 0 &&
                index == orderDetail.length - 1 &&
                ((i.sender == "vendor" && i.status == "approved") ||
                  (i.sender == "user" && i.status == "approved")) &&
                !orderDetail[orderDetail.length - 1].order_id && (
                    <button
                      className="orange-btn mr-2"
                      onClick={() => handlePayNow(i.slug)}
                    >
                      pay now $
                      {productVariantPrice
                        ? productVariantPrice
                        : oneOnOneMeetingPrice}
                      {(() => {
                        if (showPayNowLoader) {
                          return (
                            <span className="spinner-border spinner-border-sm ml-1"></span>
                          );
                        }
                      })()}
                    </button>
                )}

                {index == orderDetail.length - 1 &&
                i.sender == "user" &&
                i.status !== "rejected" &&
                !orderDetail[orderDetail.length - 1].order_id && (
                    <button
                      className="orange-btn"
                      onClick={() => handleMeetingWithdrawl()}
                    >
                      {" "}
                      withdraw meeting request
                      {(() => {
                        if (showWithdrawLoader) {
                          return (
                            <span className="spinner-border spinner-border-sm ml-1"></span>
                          );
                        }
                      })()}
                    </button>
                )}
                </div>
              {/* {error.length === 0 &&
                index == orderDetail.length - 1 &&
                i.sender == "user" &&
                i.status == "approved" &&
                !orderDetail[orderDetail.length - 1].order_id && (
                  <div className="chating-meeting-btn">
                    <button
                      className="orange-btn mr-2"
                      onClick={() => handlePayNow(i.slug)}
                    >
                      {" "}
                      pay now $
                      {productVariantPrice
                        ? productVariantPrice
                        : oneOnOneMeetingPrice}
                      {(() => {
                        if (showPayNowLoader) {
                          return (
                            <span className="spinner-border spinner-border-sm ml-1"></span>
                          );
                        }
                      })()}
                    </button>
                  </div>
                )} */}
            </>
          );
        })
      ) : (
        <h3>Loading...</h3>
      );
    return orderItems;
  };

  const handleChange = async (e, name) => {
    formData[name] = e ? e : "";
    if (name == "meetingDate") {
      setEventSelectedDay(e);
    }
    setFormData({ ...formData });
  };

  const modal = () => (
    <Modal
      show={showHide}
      onHide={handleModalShowHide}
      className="calendar-modal"
      centered
    >
      <Modal.Header className="calendar-header">
        <span onClick={() => handleModalShowHide()}>
          <i className="fas fa-times"></i>
        </span>
        <h5 className="text-center w-100 m-0">Request One-on-One Meeting</h5>
      </Modal.Header>
      <Modal.Body>
        <div className="details-slot">
          {reqMessage && (
            <div className="success-msg">
              <p>{reqMessage}</p>
            </div>
          )}
          {/* TODO */}
          <p>Frequency : {!meetingType ? productTimeFrame : "one time"}</p>
          <div className="d-flex align-items-center justify-content-center">
            <button
              className={`${
                meetingType === 0 ? "orange-btn" : "orange-outline-btn-nohover"
              } mr-2`}
              onClick={(e) => handleSetMeetingEvent(e, 0)}
            >
              Recurring
            </button>
            <button
              className={
                meetingType === 1 ? "orange-btn" : "orange-outline-btn-nohover"
              }
              onClick={(e) => handleSetMeetingEvent(e, 1)}
            >
              One Time
            </button>
          </div>
          <h6 className="mt-3 text-center">
            {moment(new Date(eventSelectedDay)).format("MMMM Do YYYY")}
          </h6>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              {/* <label>
                <b>PRODUCT NAME</b>
              </label> */}
              <p>{props.product?.title}</p>
            </div>
          </div>

          {/* {!meetingType && (
            <div className="col-md-12">
              <div className="product-detail-radio">
                <div className="form-check">
                  <label className="form-check-label">
                    <input
                      value={WEEKLY}
                      checked={meetingDurationType === WEEKLY}
                      type="radio"
                      onChange={(e) => handleSetMeetingDurationType(e)}
                      className="form-check-input"
                    />
                    Weekly
                  </label>
                </div>
                <div className="form-check">
                  <label className="form-check-label">
                    <input
                      value={BI_WEEKLY}
                      checked={meetingDurationType === BI_WEEKLY}
                      onChange={(e) => handleSetMeetingDurationType(e)}
                      type="radio"
                      className="form-check-input"
                    />
                    Bi Weekly
                  </label>
                </div>
                <div className="form-check">
                  <label className="form-check-label">
                    <input
                      value={MONTHLY}
                      checked={meetingDurationType === MONTHLY}
                      onChange={(e) => handleSetMeetingDurationType(e)}
                      type="radio"
                      className="form-check-input"
                    />
                    Monthly
                  </label>
                </div>
              </div>
            </div>
          )} */}
          <div className="col-md-12">
            <div className="form-group">
              <label>
                <b>DATE</b>
              </label>
              {/* <input
                                    type="date"
                                    // name="date"
                                    required
                                    format="yyyy-mm-dd"
                                    value={this.state.meetingDate}
                                    onChange={(e) => this.handleChangeMeetingDetails(e, "meetingDate")}
                                    className="form-control"
                                />*/}
              <DatePicker
                name="date"
                dayPlaceholder={"dd"}
                monthPlaceholder={"mm"}
                yearPlaceholder={"yyyy"}
                required
                clearIcon={null}
                onChange={(e) => handleChange(e, "meetingDate")}
                className="form-control"
                // value={new Date(formData["meetingDate"])}
                value={eventSelectedDay}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>
                <b>START TIME</b>
              </label>
              <TimePicker
                value={formData["start"]}
                format="hh:mm a"
                name="start"
                disableClock={true}
                onChange={(e) => handleChange(e, "start")}
                required={true}
                clearIcon={null}
                className="form-control"
              />
              <span className="clock-icon">
                <i className="far fa-clock"></i>
              </span>
              <span className="form-field-error">{errorMsg.time}</span>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>
                <b>END TIME</b>
              </label>
              <TimePicker
                value={formData["end"]}
                format="hh:mm a"
                name="end"
                disableClock={true}
                onChange={(e) => handleChange(e, "end")}
                required={true}
                clearIcon={null}
                className="form-control"
              />
              <span className="clock-icon">
                <i className="far fa-clock"></i>
              </span>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label>
                <b>Message</b>
              </label>
              <textarea
                className="form-control"
                placeholder="Enter text message"
                onChange={(e) => setMeetingMessage(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="button-center">
          <button
            onClick={() => createNewEvent()}
            className="orange-btn"
            disabled={isDisableButton}
          >
            Save Changes
            {(() => {
              if (showLoader) {
                return (
                  <span className="spinner-border spinner-border-sm ml-1"></span>
                );
              }
            })()}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );

  return (
    <main className="mainContainer">
      <section className="home-product-section mt-4">
        <div className="container">
          <div className="select-product-list">
            <div className="product-list-head product-list-border-bottom">
              <ul>
                {/* <li><a href="javascript:;">Profile</a></li>
                                <li><i className="fas fa-minus"></i></li> */}
                <li>
                  <Link to={"/one-on-one-meetings"}>
                    One On One Session List
                  </Link>
                </li>
                <li>
                  <i className="fas fa-minus"></i>
                </li>
                <li>
                  <a>One On One Session Detail</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="profile">
        <div className="container">
          <div className="row">
            <Profile_Sidebar />
            <div className="col-lg-9">
              <section className="track-section">
                <div className="container">
                  {!!errorMessage.success && (
                    <div className="alert alert-success">
                      {errorMessage.success}
                    </div>
                  )}
                  {!!errorMessage.error && (
                    <div className="alert alert-danger">
                      {errorMessage.error}
                    </div>
                  )}
                  <div className="track-section-head sub-heading-track">
                    <div>
                      <h2>One On One Session Detail</h2>
                      <p>One On One Session Detail</p>
                    </div>
                  </div>
                  {meetingHeader()}
                  <div className="one-one-metting">
                    <OrderDetail />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
      {modal()}
    </main>
  );
};
export default OneOnOneMeetingsDetail;
