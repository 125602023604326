import React, { Component } from 'react'
import '../../Styles/order-list.scss'
import Profile_Sidebar from '../Profile-Side-Bar/Profile_Sidebar';
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getDisputesList } from '../../Redux/Actions/orderAction';

const style = {
    height: 30,
    border: "1px solid green",
    margin: 6,
    padding: 8
};

class Dispute_List extends Component {
    constructor(props) {
        super(props);
        this.state = {
            offset: 0,
            prevOffset: 0,
            items: [],
            hasMore: true
        };
    }

    componentDidMount() {
        this.fetchMoreData(true);
    }


    fetchMoreData = async (firstLoad) => {
        if (this.state.offset !== this.state.prevOffset || firstLoad) {
            let result = await this.props.getDisputesList(localStorage.getItem("userId"), this.state.offset);
            if (result ?.data ?.data ?.length > 0) {
                let newData = [...result.data.data]
                let oldDataIdList = this.state.items.map((i) =>i.id)
                newData = newData.filter((i)=> !oldDataIdList.includes(i.id))
                this.setState(prevState => {
                    return {
                        ...prevState,
                        prevOffset: prevState.offset,
                        offset: prevState.offset + 10,
                        items: prevState.items.concat(newData)
                    }
                })
            }
            else {
                this.setState({ hasMore: false });
            }
        }
    };


    render() {

        const orderItems = this.state.items.map(i => {
            const fullAddress = i.address ? JSON.parse(i.address) : null;
            const date = i.created_ts ? new Date(i.created_ts).toISOString().substring(0, 10) : null;
            return (
                <tr key={i.id}>
                    <td><Link to={{ pathname: `${process.env.REACT_APP_HOME_URL}/product/${i.slug}`, state : {spuId : i.spu_id} }} target="_blank"><img title={i.name} style={{ width: "50px" }} src={i.imageName} /></Link></td>
                    <td align="center">#{i.orderNumber ? i.orderNumber : i.id}</td>
                    {/* <td>{fullAddress.firstname && fullAddress.lastname ? `${fullAddress.firstname} ${fullAddress.lastname}` : "-"}</td> */}
                    <td>{i.status}</td>
                    <td align="center">{date}</td>
                    {/* <td align="center">${(i.total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    <td align="center"><p className="orange">{i.status}</p></td> */}
                    <td>
                        <div className="order-action">

                            <Link className="orange" to={`/dispute/${i.order_id}`}>View</Link>
                            {
                                !!i.unreadMessages && <div className="notify-dispute"></div>
                            }
                            {/* {i.trackingId ? <Link className="orange" to={`/orders/track/${i.id}`} style={{wordBreak:"break-word"}}>track this order</Link> : null} */}
                            {/* <Link className="orange" to={`/orders/${i.id}`}>track this order</Link> */}
                        </div>
                    </td>
                </tr>
            )
        })
        return (
            <main className="mainContainer">

                <section className="home-product-section mt-4">
                    <div className="container">
                        <div className="select-product-list">
                            <div className="product-list-head product-list-border-bottom">
                                <ul>
                                    <li><a>Order List</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="profile">
                    <div className="container">
                        <div className="row">
                            <Profile_Sidebar />

                            <div className="col-lg-9">
                                <section className="track-section">
                                    <div className="container">
                                        <div className="track-section-head">
                                            <h2>Dispute List</h2>
                                            <p>Check your dispute detail.</p>
                                        </div>

                                        {this.state.items.length > 0 ? (
                                            <div className="track-box">

                                                <div className="vendor-table">

                                                    {/* <div className="vendor-table-head">
                                                    <div className="row">
                                                        <div class="col-lg-4 col-md-4">
                                                            <div class="entries-drop">
                                                                <label>show</label>
                                                                <select class="entries-controls">
                                                                    <option value="10">10</option>
                                                                    <option value="20">20</option>
                                                                    <option value="50">50</option>
                                                                    <option value="100">100</option>
                                                                </select>
                                                                <p>Entries</p>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-8 col-md-8">
                                                            <div class="vendor-table-head-right">
                                                                <div class="vendor-table-search mr-2">
                                                                    <input class="form-control" type="text" placeholder="search" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}

                                                    <div className="vendor-table-body">
                                                        <div className="table-responsive">
                                                            <InfiniteScroll
                                                                dataLength={this.state.items.length}
                                                                next={() => this.fetchMoreData(false)}
                                                                hasMore={this.state.hasMore}
                                                                scrollableTarget="scrollableDiv"
                                                            >
                                                                <table className="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <td align="center">Image</td>
                                                                            <td align="center">Order No.</td>
                                                                            {/* <td>recipient</td> */}
                                                                            <td>status</td>
                                                                            <td>date</td>
                                                                            {/* <td>total</td>
                                                                            <td>order status</td> */}
                                                                            <td align="center">action</td>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody id="scrollableDiv" className="tbodyOrderList" style={{overflow: "auto"}}>
                                                                        {orderItems}
                                                                        {/* {this.state.items.map((i) => (
                                                                        <tr key={i.id}>
                                                                            <td key={i.id} align="center">{i.id}</td>
                                                                            <td>Loren ipsum</td>
                                                                            <td>{i.createTS}</td>
                                                                            <td>{i.address}</td>
                                                                            <td>${i.total}</td>
                                                                            <td><p className="orange">{i.status}</p></td>
                                                                            <td align="center">
                                                                                <a className="orange" href="javascript:;">order detail</a>
                                                                            </td>
                                                                        </tr>
                                                                    ))}  <tr>
                                                                    <td align="center">5420</td>
                                                                    <td>Loren ipsum</td>
                                                                    <td>Nov 04, 2019</td>
                                                                    <td>new york</td>
                                                                    <td>$499.00</td>
                                                                    <td><p className="orange">Due</p></td>
                                                                    <td align="center">
                                                                        <a className="orange" href="javascript:;">order detail</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td align="center">5420</td>
                                                                    <td>Loren ipsum</td>
                                                                    <td>Nov 04, 2019</td>
                                                                    <td>new york</td>
                                                                    <td>$499.00</td>
                                                                    <td><p class="black">paid</p></td>
                                                                    <td align="center">
                                                                        <a class="orange" href="javascript:;">order detail</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td align="center">5420</td>
                                                                    <td>Loren ipsum</td>
                                                                    <td>Nov 04, 2019</td>
                                                                    <td>new york</td>
                                                                    <td>$499.00</td>
                                                                    <td><p class="black">paid</p></td>
                                                                    <td align="center">
                                                                        <a class="orange" href="javascript:;">order detail</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td align="center">5420</td>
                                                                    <td>Loren ipsum</td>
                                                                    <td>Nov 04, 2019</td>
                                                                    <td>new york</td>
                                                                    <td>$499.00</td>
                                                                    <td><p class="black">paid</p></td>
                                                                    <td align="center">
                                                                        <a class="orange" href="javascript:;">order detail</a>
                                                                    </td>
                                                                </tr> */}
                                                                    </tbody>

                                                                </table>
                                                            </InfiniteScroll>
                                                        </div>
                                                    </div>

                                                </div>



                                            </div>
                                        ) : (<p className="no-order">You have no order.</p>)}

                                    </div>
                                </section>
                            </div>

                        </div>
                    </div>
                </section>




            </main>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getDisputesList }, dispatch);
};

export default connect(null, mapDispatchToProps)(Dispute_List)