import React from "react";
import Logo from "../../assets/images-2/footer-product-item.png";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { subscribeNewsLetter } from "../../Redux/Actions/userAction";
import { FacebookIcon } from "../../Components/svgElements/FacebookIcon";
import LogoWhite from "../../assets/images/footer-logo.png";
import { InstagramIcon } from "../svgElements/InstagramIcon";
import { LinkedinIcon } from "../svgElements/LinkedinIcon";
import { UserIcon } from "../svgElements/UserIcon";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      successMsg: "",
      errorMsg: "",
      showLoader: false,
    };
  }

  validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  handleChange = (event) => {
    event.preventDefault();
    this.setState({
      email: event.target.value,
    });
  };

  handleSubmit = async () => {
    this.setState({
      showLoader: true,
      successMsg: "",
      errorMsg: "",
    });

    if (this.validateEmail(this.state.email)) {
      const result = await this.props.subscribeNewsLetter(this.state.email);

      if (result && result.data) {
        if (!result.data.isError) {
          this.setState({
            email: "",
            showLoader: false,
            successMsg: result.data.message,
          });
        } else {
          this.setState({
            email: "",
            showLoader: false,
            errorMsg: result.data.message,
          });
        }
      } else {
        this.setState({
          showLoader: false,
          errorMsg: "Something went wrong.",
        });
      }
      // if (result && result.data && !result.data.isError) {
      //     this.setState({
      //         email: "",
      //         showLoader: false,
      //         successMsg: result.data.message
      //     });
      // }
      // else {
      //     this.setState({
      //         showLoader: false,
      //         errorMsg: "Something went wrong."
      //     });
      // }
    } else if (!this.state.email) {
      this.state.errorMsg = "Please enter email address!";
      this.setState({
        showLoader: false,
        errorMsg: "Please enter email address!",
      });
    } else {
      this.state.errorMsg = "Email is invalid!";
      this.setState({
        showLoader: false,
        errorMsg: "Email is invalid!",
      });
    }

    setTimeout(() => {
      this.setState({
        successMsg: "",
        errorMsg: "",
      });
    }, 3000);
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <div>
        <footer className="main-footer">
          <div className="container">
            <div className="footer-top">
              <div className="row">
                <div className="col-md-6">
                  <div className="newsletter-heading">
                    <h2>Join our newsletter to keep up to date with us!</h2>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="footer-newsletter">
                    <div className="foote-newsletter-detail">
                      <div className="newsletter-input">
                        <UserIcon />
                        <input
                          type="text"
                          value={this.state.email}
                          onChange={(e) => this.handleChange(e)}
                          className="form-control"
                          placeholder="Enter your email"
                        />
                      </div>
                      <button
                        className="theme-button primary-button"
                        type="button"
                        onClick={this.handleSubmit}
                      >
                        Subscribe
                        {(() => {
                          if (this.state.showLoader) {
                            return (
                              <span className="spinner-border spinner-border-sm ml-1"></span>
                            );
                          }
                        })()}
                      </button>
                    </div>
                    <span className="form-field-error news-msg">
                      {(() => {
                        if (
                          this.state.successMsg !== "" &&
                          this.state.successMsg !== undefined
                        ) {
                          return (
                            <div className="alert alert-success">
                              {this.state.successMsg}
                            </div>
                          );
                        } else if (
                          this.state.errorMsg !== "" &&
                          this.state.errorMsg !== undefined
                        ) {
                          return (
                            <div className="alert alert-danger">
                              {this.state.errorMsg}
                            </div>
                          );
                        }
                      })()}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-mid">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="footer-about">
                    <div className="footer-logo">
                      <img src={LogoWhite} alt="logo" />
                    </div>
                    <div className="footer-social-links">
                      <ul>
                        <li>
                          <a
                            href="https://www.facebook.com/Subsciety.info"
                            target="_blank"
                          >
                            <FacebookIcon />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.linkedin.com/company/subsciety-llc"
                            target="_blank"
                          >
                            <LinkedinIcon />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/subsciety/"
                            target="_blank"
                          >
                            <InstagramIcon />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-2">
                  <div className="footer-link footer-navigation">
                    <h3>Quick Links</h3>
                    <div className="footer-nav">
                      <ul>
                        {/* <li>
                       <Link
                         onClick={this.scrollToTop}
                         to={"/product-list/annual"}
                       >
                         annual
                       </Link>
                     </li> */}
                        <li>
                          <Link
                            onClick={this.scrollToTop}
                            to={"/product-list/best-sellers"}
                          >
                            best sellers
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={this.scrollToTop}
                            to={"/product-list/featured"}
                          >
                            featured
                          </Link>
                        </li>
                        {/* <li>
                       <Link
                         onClick={this.scrollToTop}
                         to={"/product-list/monthly"}
                       >
                         monthly
                       </Link>
                     </li> */}
                        <li>
                          <Link
                            onClick={this.scrollToTop}
                            to={"/product-list/new-arrivals"}
                          >
                            new arrivals
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={this.scrollToTop}
                            to={"/shop-by-vendor"}
                          >
                            shop by vendor
                          </Link>
                        </li>
                        {/* <li>
                       <Link
                         onClick={this.scrollToTop}
                         to={"/product-list/weekly"}
                       >
                         weekly
                       </Link>
                     </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2">
                  <div className="footer-link">
                    <h3>Information</h3>
                    <div className="footer-nav">
                      <ul>
                        <li>
                          <Link
                            onClick={this.scrollToTop}
                            to={"/page/about-us"}
                          >
                            about us
                          </Link>
                        </li>
                        <li>
                          <Link onClick={this.scrollToTop} to={"/page/faqs"}>
                            FAQs
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathname: `${process.env.REACT_APP_VENDOR_URL}`,
                            }}
                            target="_blank"
                          >
                            Vendors
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2">
                  <div className="footer-link">
                    <h3>Categories</h3>
                    <div className="footer-nav">
                      <ul>
                        <li>
                          <Link onClick={this.scrollToTop} to="/product-list/category/access">
                            Access
                          </Link>
                        </li>
                        <li>
                          <Link onClick={this.scrollToTop} to="/product-list/category/replenish">
                            Replenish
                          </Link>
                        </li>
                        <li>
                          <Link onClick={this.scrollToTop} to="/product-list/category/customize">
                            Customize
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-bottom">
              <div className="row">
                <div className="col-md-6">
                  <div className="rights-text">
                    <p>
                      {new Date().getFullYear()} © Subs<span>c</span>iety. All
                      Rights Reserved.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="footer-bottom-links">
                    <Link onClick={this.scrollToTop} to={"/page/terms-of-use"}>
                      Terms of Service
                    </Link>
                    <Link
                      onClick={this.scrollToTop}
                      to={"/page/privacy-policy"}
                    >
                      Privacy Policy
                    </Link>
                  </div>
                </div>
              </div>

              {/* <button onClick={this.scrollToTop} className="scrollToTopBtn" title="Go to top"><i className="fas fa-arrow-up"></i></button> */}
            </div>
          </div>

          {/* <div className="footer-newsletter">
            <div className="container">
              <h2>NEWSLETTER</h2>
              <p>
                Sign up below to receive updates on new offerings, discounts,
                and more!
              </p>
              <div className="foote-newsletter-detail">
                <div className="newsletter-input">
                  <i className="far fa-envelope"></i>
                  <input
                    type="text"
                    value={this.state.email}
                    onChange={(e) => this.handleChange(e)}
                    className="form-control"
                    placeholder="Sign up for our newsletter"
                  />
                </div>
                <div className="newsletter-subs">
                  <button type="button" onClick={this.handleSubmit}>
                    Subscribe
                    {(() => {
                      if (this.state.showLoader) {
                        return (
                          <span className="spinner-border spinner-border-sm ml-1"></span>
                        );
                      }
                    })()}
                  </button>
                </div>
              </div>
              <span className="form-field-error news-msg">
                {(() => {
                  if (
                    this.state.successMsg !== "" &&
                    this.state.successMsg !== undefined
                  ) {
                    return (
                      <div className="alert alert-success">
                        {this.state.successMsg}
                      </div>
                    );
                  } else if (
                    this.state.errorMsg !== "" &&
                    this.state.errorMsg !== undefined
                  ) {
                    return (
                      <div className="alert alert-danger">
                        {this.state.errorMsg}
                      </div>
                    );
                  }
                })()}
              </span>
            </div>
          </div> */}
        </footer>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ subscribeNewsLetter }, dispatch);
};

export default connect(null, mapDispatchToProps)(Footer);
