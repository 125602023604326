import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMeetingRequestList,
  createMeeting,
} from "../../Redux/Actions/meetingActions";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Modal } from "react-bootstrap";
import './meetingCalendar.css'
import TimePicker from 'react-time-picker';

moment.locale("en-GB");
const localizer = momentLocalizer(moment);

const eventStyleGetter = function (event, start, end, isSelected) {
  const style = {
    background: "#e25e52",
    borderRadius: "10px",
    opacity: 1,
    color: "#fff",
    border: "0px",
    display: "block",
    padding: "2px 10px",
    textAlign: "center",
    margin: "3px",
    outline: "none",
  };
  if (event.status === "pending") {
    style["background"] = "#ccc";
    style["color"] = "#000";
  }
  return {
    style: style,
  };
};

const MeetingCalendar = (props) => {
  const dispatch = useDispatch();
  let userId = props.data.userId;
  let product_id = props.data.productId;
  let { meetingRequestList: eventList, meetingExists } = useSelector(
    (state) => state.meetingReducer
  );
  let [events, setEvents] = useState([]);
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [eventSelectedDay, setEventSelectedDay] = useState(new Date());
  const [tooltip, setTooltip] = useState(false);
  const [formData, setFormData] = useState({
    start_time: "",
    end_time: "",
    comment: "",
    customer_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
  const [btnDisable, setButtonDisable] = useState(false)
  const [modalState, setModalState] = useState({ open : false, data : {}})

  useEffect(() => {
    fetchMeetingList();
  }, []);

  useEffect(() => {
    fetchMeetingList();
  }, [selectedDay]);

  useEffect(() => {
    setEvents(eventList);
  }, [eventList]);

  const fetchMeetingList = () => {
    const startOfWeekTime = moment(selectedDay)
      .startOf("week")
      .format("YYYY-MM-DD HH:mm:ss");
    const endOfWeekTime = moment(selectedDay)
      .endOf("week")
      .format("YYYY-MM-DD HH:mm:ss");
    dispatch(getMeetingRequestList(userId, product_id, startOfWeekTime, endOfWeekTime));
  };

  const handleToolTip = (event) => {
    if (event) {
      setModalState({
        open: true,
        data : {...event}
      })
    }
  };


  const meetingDetailModal = () => (
      <Modal
          show={modalState.open}
          centered
      >
        <Modal.Header
            closeButton
            onClick={() => {
              setModalState({open : false, data : {}})
            }}
        ><b>Meeting Details</b>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p><b>Meeting Status :</b> {modalState.data?.status?.toUpperCase()}</p>
            <p> <b>Start Time :</b> { new Date(modalState.data?.start).toLocaleDateString(undefined,{ weekday: 'long', year: 'numeric', month: 'short', day: 'numeric', hour: "numeric", minute: "numeric", hour12 : true })}</p>
            <p><b>End Time :</b> { new Date(modalState.data?.end).toLocaleDateString(undefined,{ weekday: 'long', year: 'numeric', month: 'short', day: 'numeric', hour: "numeric", minute: "numeric", hour12 : true })}</p>
          </div>
          {
            (modalState.data.status === "accepted" && (new Date(modalState.data?.end) > new Date())) &&
            <a href={modalState.data.zoom_link} target="_blank"><button>Join Now</button></a>
          }

        </Modal.Body>

      </Modal>
  )

  const handleModalShowHide = () => {
    setTooltip(false);
    setFormData((prevState => ({...prevState,
      start_time: "",
      end_time: "",
      comment: ""})))
    setButtonDisable(false)
  };

  const handleChange = (event, name) => {
    if(name === "comment"){
      formData[name] = event.target.value;
      setFormData({ ...formData });
      return
    }
    let date = new Date(eventSelectedDay)
    date.setHours(event.split(':')[0])
    date.setMinutes(event.split(':')[1])
    formData[name] = event;
    setFormData({ ...formData });

    // =============================edit=============================
    if (date.getTime() < Date.now()){
      setButtonDisable(true)
    }else{
      setButtonDisable(false)
    }
  };

  const validNewEvent = (eventSelectedDayStr) => {
    let isValidEvent = true;
    const newEventStart = new Date(eventSelectedDayStr)
    const newEventEnd = new Date(eventSelectedDayStr.split(" ")[0] + " " + formData.end_time)
    
    for (let event of events) {
      const eventStartTime = new Date(event.start)
      const eventEndTime = new Date(event.end)
      
      if (newEventStart.getTime() >= eventStartTime.getTime() && newEventStart.getTime() <= eventEndTime.getTime()) {
        isValidEvent = false;
      } else if (eventStartTime.getTime() >= newEventStart.getTime() && eventStartTime.getTime() <= newEventEnd.getTime()) {
        isValidEvent = false;
      } else if (newEventStart.getTime() <= eventStartTime.getTime() && eventEndTime.getTime() <= newEventEnd.getTime()) {
        isValidEvent = false;
      }

      if (newEventEnd.getTime() <= eventEndTime.getTime() && newEventEnd.getTime() >= eventStartTime.getTime()) {
        isValidEvent = false;
      } else if (eventEndTime.getTime() <= newEventEnd.getTime() && eventEndTime.getTime() >= newEventStart.getTime()) {
        isValidEvent = false;
      }

      if (!isValidEvent) {
        break;
      }
    }

    return isValidEvent;
  }

  const createEvent = async () => {
    setButtonDisable(true)
    const eventSelectedDayStr =
      eventSelectedDay.getFullYear() +
      "-" +
      (eventSelectedDay.getMonth() + 1) +
      "-" +
      eventSelectedDay.getDate() + " " + formData.start_time;

    if (!validNewEvent(eventSelectedDayStr)) {
      alert("Meeting already exist!!");
      setButtonDisable(false)
      return;
    }
    await dispatch(
      createMeeting(product_id, userId, formData, eventSelectedDayStr)
    );
    await fetchMeetingList();
    setFormData({
      start_time: "",
      end_time: "",
      comment: "",
      customer_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    })
    setTooltip(false);
    setButtonDisable(false)
  };
  const modal = () => (
    <Modal
      show={tooltip}
      onHide={handleModalShowHide}
      className="calendar-modal"
      centered
    >
      <Modal.Header closeButton onClick={() => handleModalShowHide()}>
        <b>Modify upcoming session</b>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>
                <strong>WHEN</strong>
              </label>
             {/* <input
                type="time"
                name="start_time"
                // min={`${moment(new Date(), "hh:mm A").format("HH:mm")}`}
                min="00:00"
                max="23:59"
                value={formData["start_time"]}
                required
                onChange={(e) => handleChange(e)}
                className="form-control"
              />*/}
              <TimePicker
                  value={formData["start_time"]}
                  format="hh:mm a"
                  name="start"
                  disableClock={true}
                  clearIcon={null}
                  onChange={(e) => handleChange(e,"start_time")}
                  required={true}
                  className="form-control"
              />
              <span className="clock-icon"><i className="far fa-clock"></i></span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>
                <strong>THEN</strong>
              </label>
              {/*<input
                type="time"
                min="00:00"
                max="23:59"
                name="end_time"
                placeholder="end date"
                value={formData.end_time}
                onChange={handleChange}
                className="form-control"
              />*/}
              <TimePicker
                  value={formData["end_time"]}
                  format="hh:mm a"
                  name="end_time"
                  disableClock={true}
                  clearIcon={null}
                  onChange={(e) => handleChange(e,"end_time")}
                  required={true}
                  className="form-control"
              />
              <span className="clock-icon"><i className="far fa-clock"></i></span>
            </div>
          </div>

          <div className="col-md-12">
            <div className="form-group">
              <label>
                <strong>CONTENT</strong>
              </label>
              <textarea
                name="comment"
                placeholder="comment"
                value={formData.comment}
                onChange={(e)=>handleChange(e,"comment")}
                className="form-control"
                rows="5"
              />
            </div>
          </div>
        </div>
        <div className="text-center">
          <button disabled={btnDisable} onClick={createEvent} className="orange-btn">
            Send Request
          </button>
        </div>
        {/*<button onClick={handleModalShowHide}>No</button>*/}
      </Modal.Body>
    </Modal>
  );

  const handleSelectSlot = (event) => {
    if (event) {
      let { start, end } = event;
      let currentdate = new Date()
      let diff = moment(start).diff(new Date(), "days");
      // let diff = moment(start.getTime()).diff(new Date().getTime(), "days");
      if (diff >= 0 && start && end && start.getTime() > currentdate.getTime()) {
        let error = false;
        for (const event of events) {
          if (((start.getTime() >= event.start.getTime()) && (start.getTime() < event.end.getTime()))
            || ((end.getTime() > event.start.getTime()) && (end.getTime() <= event.end.getTime()))) {
            error = true;
            break;
          }
        }
        if (error) {
          alert(`Meeting already exist!`);
        } else {
          setTooltip(true);
          setEventSelectedDay(start);
          let startTime = moment(new Date(start), "hh:mm A").format("HH:mm");
          // let currentStartTime = moment(new Date(), "hh:mm A").format("HH:mm");
          let endTime = moment(new Date(end), "hh:mm A").format("HH:mm");
          setFormData({ ...formData, start_time: startTime, end_time: endTime });
          // setFormData({ ...formData, start_time: start.getTime() > currentdate.getTime() ? startTime : currentStartTime, end_time: endTime });
        }
      } else {
        alert(`You cannot add events to past date!`);
      }
    }
  };

  return (
    <Modal
      className="modal admin-modal edit-user-modal"
      id="ZoomMeetingCalendarModal"
      show={props.show}
      onHide={() => props.setShowCalendar(false)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ color: "#250C6D" }}>Custom Meetings</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ color: "#9285B6" }}>
        <div
          className="button-modal-div"
          style={{
            margin: "0 auto",
            textAlign: "center",
            marginTop: "20px",
          }}
        >
          <div>
            <Calendar
              localizer={localizer}
              showMultiDayTimes={true}
              events={eventList}
              style={{ height: 700 }}
              selectable
              startAccessor="start"
              endAccessor="end"
              step={30}
              view="week"
              views={["week"]}
              onSelectEvent={(event) => handleToolTip(event)}
              onSelectSlot={(event) => handleSelectSlot(event)}
              eventPropGetter={eventStyleGetter}
              date={selectedDay}
              onNavigate={(day) => {
                setSelectedDay(day);
              }}
              dayLayoutAlgorithm="no-overlap"
              messages={{ previous: "Previous" }}
              popup={true}
            />
          </div>
        </div>
        {modal()}
        {meetingDetailModal()}
      </Modal.Body>
    </Modal>
  );
};

export default MeetingCalendar;
