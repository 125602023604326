import React from "react";

export const UserIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
    >
      <g id="vuesax/linear/user">
        <g id="user">
          <path
            id="Vector"
            d="M13.3349 13.3377C16.4031 13.3377 18.8904 10.8504 18.8904 7.78212C18.8904 4.71387 16.4031 2.22656 13.3349 2.22656C10.2666 2.22656 7.7793 4.71387 7.7793 7.78212C7.7793 10.8504 10.2666 13.3377 13.3349 13.3377Z"
            stroke="white"
            stroke-opacity="0.72"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            id="Vector_2"
            d="M22.8779 24.4497C22.8779 20.1497 18.6002 16.6719 13.3335 16.6719C8.06684 16.6719 3.78906 20.1497 3.78906 24.4497"
            stroke="white"
            stroke-opacity="0.72"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};
