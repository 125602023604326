import React, { Component } from "react";
import "../../Styles/order-confirm.scss";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { getCartItems } from "../../Redux/Actions/cartAction";
import { getMultipleOrderDetail } from "../../Redux/Actions/orderAction";
import CryptoJS from "crypto-js";
import axios from "axios";
import moment from "moment";

class OrderConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderDetail: [],
      itemDetail: [],
    };
  }

  componentDidMount() {
    localStorage.removeItem("cartItems");
    this.props.getCartItems();
    this.fetchMultipleOrderDetail();
  }

  fetchMultipleOrderDetail = async () => {
    // if (localStorage.getItem("userId")) {
    let selectedCardId = this.props?.location?.state?.selectedCardId
    let res;
    let encryptOrderId = this.props.match.params.orderId;
    encryptOrderId = encryptOrderId.replace(/@@@/g, "/");
    let decryptOrderId = CryptoJS.AES.decrypt(
      encryptOrderId,
      "itemsOrderId!@#$%"
    );
    decryptOrderId = decryptOrderId.toString(CryptoJS.enc.Utf8);
    decryptOrderId = decryptOrderId ? JSON.parse(decryptOrderId) : null;
    if (selectedCardId) {
      res = await this.props.getMultipleOrderDetail(decryptOrderId, selectedCardId);
    } else {
      res = await this.props.getMultipleOrderDetail(decryptOrderId)
    }

    if (res && res.data && res.data && !res.data.isError) {
      this.setState({
        orderDetail: res?.data?.data?.orderDetail,
        itemDetail: res?.data?.data?.itemDetail,
      });
    } else {
      // something went wrong
    }
    /*} else {
      // user is not logged in
      this.props.history.push("/signup");
    }*/
  };

  download = (url) => {
    axios({
      url,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.jpg');
      document.body.appendChild(link);
      link.click();
    });
  }

  getFileName = (url) => {
    let name = url.split("/")
    return name[4] + ".jpg"
  }

  render() {
    const itemDetail = this.state.itemDetail;
    // const freeSeminarDetails = itemDetail.length > 0 ? JSON.parse(itemDetail[0]?.free_seminar_details) : null
    const newCardDetails = localStorage.getItem("new_billing_address") ? JSON.parse(localStorage.getItem("new_billing_address")) : null
    const orderDetail =
      this.state.orderDetail && this.state.orderDetail.length > 0
        ? this.state.orderDetail.map((order, index) => {
          let freeSeminarDetails = order.seminar_date_time
            ? JSON.parse(order.seminar_date_time)
            : null;
          let shippingAddress = order.address
            ? JSON.parse(order.address)
            : null;
          let billingAddress = order.billing_Info ?
            JSON.parse(order.billing_Info)
            : null;
          let date = order.createTS
            ? new Date(order.createTS).toISOString().substring(0, 10)
            : null;
          let variationKey = [];
          let variationValue = [];
          if (order.variant_values) {
            const variants = JSON.parse(order.variant_values)
            for (let key in variants) {
              variationKey.push(key)
              variationValue.push(variants[key])
            }
          }
          return (
            <div key={order.id} className="purchase-cartGroup">
              <div className="cart-box m-3">
                <div className="cart-box-detail w-100">
                  <div className="cart-box-img">
                    <img src={itemDetail[index]?.imageName} />
                  </div>
                  <div className="cart-box-item-detail w-100">
                    <h2>{itemDetail[index]?.name}</h2>
                    <p>
                      OrderID:
                      <span>
                        {" "}
                        {order.orderNumber ? `#${order.orderNumber}` : "-"}
                      </span>
                    </p>
                    {order.is_seminar_event ?
                      <>
                        <div className="d-flex align-items-center flex-wrap font14">
                          <span>Date: </span>
                          {freeSeminarDetails?.date}
                        </div>
                        <div className="d-flex align-items-center flex-wrap font14">
                          <span>Time Slots: </span>
                          {`${moment(freeSeminarDetails?.start, "hh:mm").format("hh:mm A")} to ${moment(freeSeminarDetails?.end, "hh:mm").format("hh:mm A")}`}
                        </div>
                      </>
                      : null}

                    {
                      this.state.orderDetail[index]?.booked_session_details &&
                      JSON.parse(this.state.orderDetail[index]?.booked_session_details).type !== "group" &&
                      JSON.parse(this.state.orderDetail[index]?.booked_session_details).timeSlots &&
                      <p>
                        Time slots:
                        <span>
                          <div className="d-flex align-items-center flex-wrap">
                            {
                              this.state.orderDetail[index]?.booked_session_details &&
                              JSON.parse(this.state.orderDetail[index]?.booked_session_details).type !== "group" &&
                              JSON.parse(this.state.orderDetail[index]?.booked_session_details).customerTime?.map((slot) => {
                                return <p className="mr-1">
                                  {moment(slot[0], ["HH:mm"]).format("h:mm A")} to {moment(slot[1], ["HH:mm"]).format("h:mm A")}
                                </p>
                              })}
                          </div>
                        </span>
                      </p>
                    }
                    {
                      itemDetail[index]?.customizationNote && itemDetail[index]?.customizationNote.trim().length !== 0 && <p>
                        Customization Note:
                        <span className="item-custom-desc">
                          {" "}
                          {itemDetail[index]?.customizationNote}
                        </span>
                      </p>
                    }
                    {
                      itemDetail[index]?.customizationImage && itemDetail[index]?.customizationImage.length !== 0 &&
                      <p className="d-flex align-items-center">
                        <span>
                        Customization Image: {this.getFileName(itemDetail[index].customizationImage)} 
                        </span>
                        <span className="ml-4">
                        <a href={itemDetail[index]?.customizationImage} target="_blank">View</a>
                          <a onClick={() => this.download(itemDetail[index].customizationImage)}>Download</a>
                        </span>
                      </p>
                    }
                    {
                      variationKey.length > 0 &&
                      <p>
                        Variation:-  <br />
                        {variationKey.map((key, index) => <div className="margin-variation">{key}:<span>{variationValue[index]}</span></div>)}
                      </p>
                    }
                  </div>
                </div>
                <div className="cart-box-item-price">
                  <p>
                    $
                    {order.total
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </p>
                </div>
              </div>

              <div className="order-detail text-left p-3">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="order-detail-left">
                      <div className="table-responsive">
                        <table className="table">
                          <tr>
                            <td>Recipient:</td>
                            <td>
                              {shippingAddress.firstname
                                ? `${shippingAddress.firstname} ${shippingAddress.lastname}`
                                : `-`}
                            </td>
                          </tr>
                          <tr>
                            <td>Shipping Address:</td>
                            <td>
                              {shippingAddress.address
                                ? `${shippingAddress.address_2
                                  ? shippingAddress.address_2 + ", "
                                  : ""
                                } ${shippingAddress.address}, ${shippingAddress.city
                                }, ${shippingAddress.state}, ${shippingAddress.country
                                } - ${shippingAddress.zipCode}`
                                : `-`}
                            </td>
                          </tr>
                          <tr>
                            <td>Billing Recipient:</td>
                            <td>
                              {billingAddress?.name ? `${billingAddress?.name}` :
                                newCardDetails?.name ? `${newCardDetails?.name}` :
                                  `${shippingAddress?.firstname} ${shippingAddress?.lastname}`}
                            </td>
                          </tr>
                          <tr>
                            <td>Billing Address:</td>
                            <td>
                              {billingAddress?.address
                                ? `${billingAddress?.billingAddress_2
                                  ? billingAddress?.billingAddress_2 + ", "
                                  : ""
                                } ${billingAddress?.address}, ${billingAddress?.city
                                }, ${billingAddress?.state},
                                     ${billingAddress?.zipCode}`
                                : newCardDetails?.address ? `${newCardDetails.address}, ${newCardDetails.city},${newCardDetails.state},${newCardDetails.zipCode}`
                                  : `${shippingAddress.address_2
                                    ? shippingAddress.address_2 + ", "
                                    : ""
                                  } ${shippingAddress.address}, ${shippingAddress.city
                                  }, ${shippingAddress.state}, ${shippingAddress.country
                                  } - ${shippingAddress.zipCode}`}
                            </td>
                          </tr>

                          <tr>
                            <td>Process Date:</td>
                            <td>{date ? date : `-`}</td>
                          </tr>
                          <tr>
                            <td>Order Status:</td>
                            <td>{order.status ? order.status : `-`}</td>
                          </tr>
                          <tr>
                            <td>Payment Status:</td>
                            <td>
                              {order.paymentStatus
                                ? order.paymentStatus
                                : `-`}
                            </td>
                          </tr>
                          <tr>
                            <td>Receipts Voucher:</td>
                            <td>-</td>
                          </tr>
                          {order.trackingCompany && (
                            <>
                              {" "}
                              <tr>
                                <td>Tracking Company:</td>
                                <td>
                                  {order.trackingCompany
                                    ? order.trackingCompany
                                    : `-`}
                                </td>
                              </tr>
                              <tr>
                                <td>Delivery Plan:</td>
                                <td>
                                  {order.delivery_plan
                                    ? order.delivery_plan.split("_").join(" ")
                                    : `-`}
                                </td>
                              </tr>
                              {!order.customize_delivery_time == 0 &&
                                <tr>
                                  <td>Product Creation Time:</td>
                                  <td>
                                    {order?.customize_delivery_time == 1
                                      ? `${order?.customize_delivery_time} Day`
                                      : `${order?.customize_delivery_time} Days`}
                                  </td>
                                </tr>}

                              <tr>
                                {order.customize_questions && order.customize_questions !== "undefined" && JSON.parse(order.customize_questions)?.length ? (
                                  <>
                                    <td>Customize Questions:</td>
                                    <ol>
                                      {JSON.parse(order.customize_questions).map(
                                        (item, index) => (
                                          <li>{item}</li>
                                        )
                                      )}
                                    </ol>
                                  </>
                                ) : null}
                              </tr>
                            </>
                          )}
                          {order.productCategoryId === 3 ? 
                              <tr>
                                <td colSpan="2">
                             
                              <span className="note-ecom">  
                                 <strong>
                                    Note<sup>** </sup> 
                                  </strong>
                                  Booked sessions will be reflected on calendar pending vendor confirmation.
                              </span>
                             
                              </td>
                              </tr>
                               : null}
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="order-detail-right">
                      <div className="table-responsive">
                        <table className="table">
                          <tr>
                            <td>Item Price</td>
                            <td>
                              {itemDetail[index]
                                ? `$${itemDetail[index].pricePerUnit
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td>Quantity</td>
                            <td>
                              {itemDetail[index]
                                ? `${itemDetail[index].orderedQuantity}`
                                : "-"}
                            </td>
                          </tr>
                          {order.trackingCompany && (
                            <tr>
                              <td>Shipping</td>
                              <td>
                                {order.shipping_amount
                                  ? `$${order.shipping_amount
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                  : "Free"}
                              </td>
                            </tr>
                          )}
                          {order.discount && +order.discount > 0 ? (
                            <tr>
                              <td>Discount</td>
                              <td>{`-$${(+order.discount)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</td>
                            </tr>
                          ) : null}
                          <tr>
                            <td>Subtotal</td>
                            <td>
                              {order.subTotal
                                ? `$${order.subTotal
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td>Tax</td>
                            <td>
                              ${order.tax
                                ? `${order.tax
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                : `0.00`}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Total</b>
                            </td>
                            <td>
                              <b>
                                {order.total
                                  ? `$${order.total
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                  : "-"}
                              </b>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }).reverse()
        : "...";

    return (
      <main className="mainContainer">
        <section className="home-product-section mt-4">
          <div className="container">
            <div className="select-product-list">
              <div className="product-list-head product-list-border-bottom">
                <ul>
                  <li>
                    <Link to={"/"}>Home</Link>
                  </li>
                  <li>
                    <i className="fas fa-minus"></i>
                  </li>
                  <li>
                    <a>order confirm</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="cart-section">
          <div className="container">
            <div className="cart-section-head">
              <h2>ORDER PLACED</h2>
            </div>

            <div className="checkout-body">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="thankyou-box">
                    <div className="thankyou-box-detail">
                      <i className="far fa-check-circle"></i>
                      <h3>Thank you for your purchase!</h3>
                      {/* <p>A confirmation email has been sent to John@gmail.com</p> */}
                      {/* <p className="order-number">OrderID: <b>#{this.props.match.params.orderId}</b></p> */}
                      <section className="track-section">
                        <div className="container">
                          {orderDetail}

                          {/* <div className="cart-box">
                                                        <div className="cart-box-detail">
                                                            <div className="cart-box-img">
                                                                <img src="images/footer-product-item.png" />
                                                            </div>
                                                            <div className="cart-box-item-detail">
                                                                <h2>home grown</h2>
                                                                <p><span>Plant Type:</span>Decorative House Plant</p>
                                                                <p><span>Duration:</span>Monthly</p>
                                                            </div>
                                                        </div>
                                                        <div className="cart-box-item-price">
                                                            <p>$45</p>
                                                        </div>
                                                    </div>
                                                    <div className="cart-box">
                                                        <div className="cart-box-detail">
                                                            <div className="cart-box-img">
                                                                <img src="images/footer-product-item.png" />
                                                            </div>
                                                            <div className="cart-box-item-detail">
                                                                <h2>home grown</h2>
                                                                <p><span>Plant Type:</span>Decorative House Plant</p>
                                                                <p><span>Duration:</span>Monthly</p>
                                                            </div>
                                                        </div>
                                                        <div className="cart-box-item-price">
                                                            <p>$45</p>
                                                        </div>
                                                    </div> */}
                        </div>
                      </section>
                    </div>
                    {/* <div className="print-receipt">
                                            <a href="javascript:;"><i classNameclassName="fas fa-print"></i> Print receipt</a>
                                        </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    checkoutRes: state.cartReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getCartItems, getMultipleOrderDetail }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderConfirmation);
