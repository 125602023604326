import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;


//add wishlist api
export const generateToken = (data) => async (dispatch) => {

    try {
        dispatch({type: "LOADING_STARTED"});
        const res = await axios({
            method: 'post',
            url: `${URL}/api/v1/zoom-service/generate-token`,
            data: data,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        })
        dispatch({type: "LOADING_COMPLETED"});

        return res;

    } catch (error) {
        dispatch(handleError(error));
    }
}