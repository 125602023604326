import React, { Component } from "react";
import "../../Styles/order-list.scss";
import Profile_Sidebar from "../Profile-Side-Bar/Profile_Sidebar";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getSubscriptionList,
  cancelSubscription,
  getSubscriptionListForAccessCategory,
  stopSubscription,
  resumeSubscription,
} from "../../Redux/Actions/subscriptionAction";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { addToCart } from "../../Redux/Actions/cartAction";
import { logDOM } from "@testing-library/react";

const style = {
  height: 30,
  border: "1px solid green",
  margin: 6,
  padding: 8,
};

class Subscription_List extends Component {
  userId = localStorage.getItem("userId");

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      prevOffset: 0,
      filter: "active",
      items: [],
      hasMore: true,
      accessoffset: 0,
      accessprevOffset: 0,
      accessfilter: "active",
      accessitems: [],
      accesshasMore: true,
      isAddedToCart: false,
      paymentOptionType: "recurring",
      quantity: 1,
      selectedStructureId: null,
    };
  }

  componentDidMount() {
    this.fetchMoreData(true);
    this.fetchAccessCategorySubscription(true);
  }

  fetchMoreData = async (firstLoad) => {
    if (this.state.offset !== this.state.prevOffset || firstLoad) {
      let result = await this.props.getSubscriptionList(
        this.userId,
        this.state.filter,
        this.state.offset
      );
      if (result?.data?.data?.length > 0) {
        this.setState((prevState) => {
          return {
            ...prevState,
            prevOffset: prevState.offset,
            offset: prevState.offset + 10,
            items: firstLoad
              ? result.data.data
              : prevState.items.concat(result.data.data),
          };
        });
      } else {
        this.setState({ hasMore: false });
      }
    }
  };


  fetchAccessCategorySubscription = async (firstLoad) => {
    if (this.state.accessoffset !== this.state.accessprevOffset || firstLoad) {
      let result = await this.props.getSubscriptionListForAccessCategory(
        this.userId,
        this.state.accessfilter,
        this.state.accessoffset
      );
      let seminarPrice = result.data.data.free_seminar_details ? JSON.parse(result.data.data.free_seminar_details).price : null
      this.setState((prevState) => {
        return {
          ...prevState,
          accessprevOffset: prevState.accessoffset,
          accessoffset: prevState.accessoffset + 10,
          accessitems: firstLoad
            ? result.data.data
            : prevState.accessitems.concat(result.data.data),
        };
      });
      if (!(result?.data?.data?.length > 0)) {
        this.setState({ accesshasMore: false });
      }
    }
   
  };

  updateSubscription(subscription_id, payment_mode, data) {
    confirmAlert({
      title: "",
      message: `Are you sure to ${data.type} the subscription?`,
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            this.onCancelSubscription(subscription_id, payment_mode, data),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  showResponseAlert(message) {
    confirmAlert({
      title: "",
      message: message,
      buttons: [
        {
          label: "Ok",
          onClick: async () => {
            await this.onClearState();
            this.fetchMoreData(true);
            this.fetchAccessCategorySubscription(true);
          },
        },
      ],
    });
  }

  async onCancelSubscription(subscription_id, payment_mode, data) {
    // alert(subscription_id);
    let res;
    if (data.type === "cancel") {
      res = await this.props.cancelSubscription(
        this.userId,
        subscription_id,
        payment_mode
      );
    } else if (data.type === "stop") {
      res = await this.props.stopSubscription(
        this.userId,
        subscription_id,
        payment_mode
      );
    } else if (data.type === "resume") {
      res = await this.props.resumeSubscription(
        this.userId,
        subscription_id,
        payment_mode,
        data.productId
      );
    }

    if (res && res.data) {
      this.showResponseAlert(res.data.message);
      // this.fetchMoreData(true);
      // this.fetchAccessCategorySubscription(true);
    } else {
      // error to get respone
      this.showResponseAlert("Something went wrong. Please try again later.");
    }
  }

  async onClearState() {
    await this.setState({
      offset: 0,
      prevOffset: 0,
      accessprevOffset: 0,
      accessoffset: 0,
      items: [],
      hasMore: true,
    });
  }

  handleFilterChange = (event) => {
    event.preventDefault();
    this.setState(
      {
        offset: 0,
        prevOffset: 0,
        filter: event.target.value,
        items: [],
        hasMore: true,
      },
      () => this.fetchMoreData(true)
    );
    // this.fetchMoreData(true);
  };

  handleFilterChangeForAccessCategory = (event) => {
    event.preventDefault();
    this.setState(
      {
        accessoffset: 0,
        accessprevOffset: 0,
        accessfilter: event.target.value,
        accessitems: [],
        accesshasMore: true,
      },
      () => this.fetchAccessCategorySubscription(true)
    );
  };

  copyItems = (item, index) => {
    this.props.addToCart(
      {
        ...item,
        selectedPair:
          JSON.parse(item.variant_values) !== null
            ? JSON.parse(item.variant_values)
            : {},
        price: item?.sale_price != 0 ? parseFloat(item.sale_price).toFixed(2) :  parseFloat(item.price).toFixed(2),
        actualPrice: parseFloat(item.price).toFixed(2),
        salePrice:parseFloat(item.sale_price).toFixed(2),
        id: item.product_id,
        paymentOptionType: this.state.paymentOptionType,
        selectedStructureId: this.state.selectedStructureId,
      },
      this.state.quantity
    );

    this.setState({
      [`${index}`]: true,
    });
    setTimeout(() => {
      this.setState({
        [`${index}`]: false,
      });
    }, 2000);
  };

  render() {
    const subscriptionItems = this.state.items.map((i, index) => {
      const fullAddress = i.address ? JSON.parse(i.address) : null;

      const date = i.created_ts
        ? new Date(i.created_ts).toISOString().substring(0, 10)
        : null;
      let timeFrame = i.subscription_timeframe ? i.subscription_timeframe : "";
      const productStatus =
        !i.status || i.status === 2
          ? i.status === 0
            ? "Cancelled"
            : "Inactive"
          : i.status === 3
          ? "Stopped"
          : "Active";
      return (
        <tr key={i.id}>
          <td key={i.id} align="center" className="custom-width">
            {index + 1}
          </td>
          <td>
            <img style={{ width: "50px" }} src={i.imageName} />
          </td>
          <td>
            <Link to={`/product-list/detail/${i.slug}`}>
              {i.name ? i.name : null}
            </Link>
          </td>
          <td>{timeFrame}</td>
          <td>
            <p>
              {fullAddress.firstname && fullAddress.lastname
                ? `${fullAddress.firstname} ${fullAddress.lastname}`
                : "-"}
            </p>
          </td>
          {/* <td>{i.subscription_id ? i.subscription_id :null}</td> */}
          <td>{productStatus}</td>

          <td align="center">
            <div className="button-click-group">
              {i.status ? (
                <button
                  onClick={() =>
                    this.updateSubscription(i.subscription_id, i.method, {
                      type: "cancel",
                    })
                  }
                  className="orange-outline-btn"
                >
                  Cancel
                </button>
              ) : null}
              {i.status !== 0 && i.status === 3 && i.isActive !== 2 && (
                <div>
                  {" "}
                  <button
                    onClick={() =>
                      this.updateSubscription(i.subscription_id, i.method, {
                        type: "resume",
                        productId: i.productId,
                      })
                    }
                    className="orange-outline-btn"
                  >
                    Resume
                  </button>
                </div>
              )}
              {i.status !== 0 && i.status === 1 && (
                <div>
                  {" "}
                  <button
                    onClick={() =>
                      this.updateSubscription(i.subscription_id, i.method, {
                        type: "stop",
                      })
                    }
                    className="orange-outline-btn"
                  >
                    Pause
                  </button>
                </div>
              )}
              {!i.status && (
                <div>
                  {" "}
                  <button
                    onClick={() => this.copyItems(i, index)}
                    className="orange-outline-btn"
                  >
                    {this.state[`${index}`] ? (
                      <i className="far fa-check-circle"></i>
                    ) : null}{" "}
                    re-order
                  </button>
                </div>
              )}
            </div>
          </td>
        </tr>
      );
    });

    const accesssubscriptionItems = this.state.accessitems.map((i, index) => {
      const fullAddress = i.address ? JSON.parse(i.address) : null;
      const seminarPrice= i.free_seminar_details ? JSON.parse(i.free_seminar_details).price : null
      const date = i.created_ts
        ? new Date(i.created_ts).toISOString().substring(0, 10)
        : null;
      let timeFrame = i.subscription_timeframe ? i.subscription_timeframe : "";
      const productStatus =
        !i.status || i.status === 2
          ? i.status === 0
            ? "Cancelled"
            : "Inactive"
          : i.status === 3
          ? "Stopped"
          : "Active";
      return (
        // !!!seminarPrice ?
         <tr key={i.id}>
          <td key={i.id} align="center" className="custom-width">
            {index + 1}
          </td>
          <td>
            <img style={{ width: "50px" }} src={i.imageName} />
          </td>
          <td>
            <Link to={`/product-list/detail/${i.slug}`}>
              {i.product_name ? i.product_name : null}
            </Link>
          </td>
          <td>{timeFrame}</td>
          <td>
            <p>
              {fullAddress.firstname && fullAddress.lastname
                ? `${fullAddress.firstname} ${fullAddress.lastname}`
                : "-"}
            </p>
          </td>
          {/* <td>{i.subscription_id ? i.subscription_id :null}</td> */}
          <td>{productStatus}</td>

          <td align="center">
            <div className="button-click-group">
              {i.status ? (
                <button
                  onClick={() =>
                    this.updateSubscription(i.subscription_id, i.method, {
                      type: "cancel",
                    })
                  }
                  className="orange-outline-btn"
                >
                  Cancel
                </button>
              ) : null}
              {i.status !== 0 && i.status === 3 && i.isActive !== 2 && (
                <div>
                  {" "}
                  <button
                    onClick={() =>
                      this.updateSubscription(i.subscription_id, i.method, {
                        type: "resume",
                        productId: i.productId,
                      })
                    }
                    className="orange-outline-btn"
                  >
                    Resume
                  </button>
                </div>
              )}
              {i.status === 1 && (
                <div>
                  {" "}
                  <button
                    onClick={() =>
                      this.updateSubscription(i.subscription_id, i.method, {
                        type: "stop",
                      })
                    }
                    className="orange-outline-btn"
                  >
                    Stop
                  </button>
                </div>
              )}
              {i.status || i.is_available ? (
                <div>
                  {" "}
                  <Link
                    to={{
                      pathname: `/access-product-details`,
                      state: {
                        subscription_id: i.subscription_id,
                        method: i.method,
                        name: i.product_name,
                        rating: i.rating,
                      },
                    }}
                  >
                    <button className="orange-outline-btn">View</button>
                  </Link>
                </div>
              ) : null}
            </div>
          </td>
        </tr>
        //  : null
         )
    });
    return (
      <main className="mainContainer">
        <section className="home-product-section mt-4">
          <div className="container">
            <div className="select-product-list">
              <div className="product-list-head product-list-border-bottom">
                <ul>
                  <li>
                    <a>Subscription List</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="profile">
          <div className="container">
            <div className="row">
              <Profile_Sidebar />

              <div className="col-lg-9">
                <section className="track-section">
                  <div className="container">
                    <div className="track-section-head">
                      <h2>Subscription List</h2>
                      <p>Check your Subscription detail.</p>
                    </div>

                    <h6>Replenish and Customize Subscriptions</h6>
                    <select
                        className="custom-select"
                      // defaultValue={"active"}
                      value={this.state.filter}
                      onChange={(event) => this.handleFilterChange(event)}
                    >
                      <option value="active">Active Subscriptions</option>
                      <option value="inactive">Inactive Subscriptions</option>
                    </select>

                    {this.state.items.length > 0 ? (
                      <div className="track-box">
                        <div className="vendor-table">
                          {/* <div className="vendor-table-head">
                                                    <div class="row">
                                                        <div class="col-lg-4 col-md-4">
                                                            <div class="entries-drop">
                                                                <label>show</label>
                                                                <select class="entries-controls">
                                                                    <option value="10">10</option>
                                                                    <option value="20">20</option>
                                                                    <option value="50">50</option>
                                                                    <option value="100">100</option>
                                                                </select>
                                                                <p>Entries</p>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-8 col-md-8">
                                                            <div class="vendor-table-head-right">
                                                                <div class="vendor-table-search mr-2">
                                                                    <input class="form-control" type="text" placeholder="search" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}

                          <div className="vendor-table-body" >
                            <div className="table-responsive"  >
                              <InfiniteScroll
                                dataLength={this.state.items.length}
                                next={() => this.fetchMoreData(false)}
                                hasMore={this.state.hasMore}
                                scrollableTarget="scrollableDiv"
                              >
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <td
                                        align="center"
                                        className="custom-width"
                                      >
                                        S. No.
                                      </td>
                                      <td>Image</td>
                                      <td>Product</td>
                                      <td>timeFrame</td>
                                      <td>User</td>
                                      {/* <td>Subscription Id</td> */}
                                      <td>Status</td>
                                      <td align="center">action</td>
                                    </tr>
                                  </thead>
                                  <tbody id="scrollableDiv" style={{overflow: "auto"}}>{subscriptionItems}</tbody>
                                </table>
                              </InfiniteScroll>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <p className="no-order">You have no subscription.</p>
                    )}

                    <h6>Access subscriptions</h6>
                    <select
                        className="custom-select"
                      defaultValue={"active"}
                      onChange={(event) =>
                        this.handleFilterChangeForAccessCategory(event)
                      }
                    >
                      <option value="active">Active Subscriptions</option>
                      <option value="inactive">Inactive Subscriptions</option>
                    </select>

                    {this.state.accessitems.length > 0 ? (
                      <div className="track-box">
                        <div className="vendor-table">
                          <div className="vendor-table-body">
                            <div className="table-responsive">
                              <InfiniteScroll
                                dataLength={this.state.accessitems.length}
                                next={() =>
                                  this.fetchAccessCategorySubscription(false)
                                }
                                hasMore={this.state.accesshasMore}
                                scrollableTarget="scrollableDiv"
                              >
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <td
                                        align="center"
                                        className="custom-width"
                                      >
                                        S. No.
                                      </td>
                                      <td>Image</td>
                                      <td>Product</td>
                                      <td>timeFrame</td>
                                      <td>User</td>
                                      {/* <td>Subscription Id</td> */}
                                      <td>Status</td>
                                      <td align="center">action</td>
                                    </tr>
                                  </thead>
                                  <tbody id="scrollableDiv" style={{overflow: "auto"}}>{accesssubscriptionItems}</tbody>
                                </table>
                              </InfiniteScroll>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <p className="no-order">You have no subscription.</p>
                    )}
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getSubscriptionList,
      cancelSubscription,
      getSubscriptionListForAccessCategory,
      stopSubscription,
      resumeSubscription,
      addToCart,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(Subscription_List);
