import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

//add wishlist api
export const addWishlist = (data) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/wishlist`,
      data: data,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });

    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

//get wish list api
export const getWishlist = (userid) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/wishlist/${userid}`,

      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    // if (res.data.data && res.data.data.length > 0) {
    //   //setDataInLocalstorage("cartItems", res.data.data);
    // }

    // dispatch({
    //   type: "GETWISHLISTITEMS",
    //   payload: { data: res.data },
    // });
    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

// delete wishlist api
export const deleteWishlistItem = (userid, wishlsitid) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "delete",
      url: `${URL}/api/v1/wishlist/${userid}/${wishlsitid}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};
