import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

function getCategoryID(categoryName) {
  let categoryId;
  switch (categoryName) {
    case "new-arrivals":
      categoryId = 1;
      break;
    case "best-sellers":
      categoryId = 2;
      break;
    case "featured":
      categoryId = 3;
      break;
    case "weekly":
      categoryId = 4;
      break;
    case "monthly":
      categoryId = 5;
      break;
    case "annual":
      categoryId = 6;
      break;
    default:
      categoryId = 1;
  }
  return categoryId;
}

export const changeCategory = (categoryName) => async (dispatch) => {
  let categoryId = getCategoryID(categoryName);

  dispatch({
    type: "CATEGORY_CHANGE",
    payload: { categoryId, categoryName },
  });
};

// called from home page
export const getProductListByFilter =
  (filterName, storeId, limit = 0, start = 0) =>
    async (dispatch) => {
      limit = filterName === "best-sellers" ? 8 : 4
      try {
        dispatch({ type: "LOADING_STARTED" });
        const res = await axios({
          method: "get",
          url: `${URL}/api/v1/product/by/${filterName}?limit=${limit}&start=${start}${storeId ? `&storeId=${storeId}` : ""
            }`,
        });
        dispatch({ type: "LOADING_COMPLETED" });
        dispatch({
          type: `${filterName}-list`,
          payload: { data: res.data },
        });
        return res.data.data;
      } catch (error) {
        dispatch(handleError(error));
      }
    };


export const getSessionFrequency = (productId, session, timezone) => async (dispatch) => {

  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/product/get-session/${productId}?session=${session.trim()}&timezone=${timezone}`,
    });

    dispatch({ type: "LOADING_COMPLETED" });

    return res.data.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};
export const getOneTimeSlots = (productId, session, date) => async (dispatch) => {

  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/product/get-one-time-slots/${productId}?session=${session.trim()}&date=${date}`,
    });

    dispatch({ type: "LOADING_COMPLETED" });

    return res.data.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};


export const getSessionSlot = (productId, session, sessionFrequency) => async (dispatch) => {

  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/product/get-session-slot/${productId}?session=${session}&sessionFrequency=${sessionFrequency}`,
    });

    dispatch({ type: "LOADING_COMPLETED" });

    return res.data.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};

// called when clicking any filter type
export const getProductList =
  (categoryName, start, limit, sort_type,vendorId,categoryId, cancelToken) => async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/product/by-cat/${categoryName}?limit=${limit}&start=${start}&sort_type=${sort_type}${vendorId?`&vendorId=${vendorId}`:``}${categoryId ? `&categoryId=${categoryId}`:""}
        `,
        cancelToken: cancelToken
      });
      dispatch({ type: "LOADING_COMPLETED" });
      dispatch({
        type: "PRODUCT_LIST_BY_FILTER",
        payload: { data: res.data },
      });
      return res.data
    } catch (error) {
      dispatch(handleError(error));
    }
  };
// called when clicking any feature type
export const getProductListbyFeature =
  (categoryName, start, limit, sort_type,cancelToken) => async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/product/by-feature/${categoryName}?limit=${limit}&start=${start}&sort_type=${sort_type}`,
        cancelToken: cancelToken
      });
      dispatch({ type: "LOADING_COMPLETED" });
      dispatch({
        type: "PRODUCT_LIST_BY_FILTER",
        payload: { data: res.data },
      });
    } catch (error) {
      dispatch(handleError(error));
    }
  };

// Child categoryList  by parentId
export const getChildCategoryList = (slug, parentId, cancelToken) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/productCategory/${slug}/${parentId}`,
      cancelToken: cancelToken
    });
    dispatch({ type: "LOADING_COMPLETED" });
    dispatch({
      type: "PRODUCT_LIST_BY_CATEGORY",
      payload: { data: res.data },
    });
    return res.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};

// called when clicked on any category
// category-replanish,customise,access
export const getProductListByCategoryWithHierarchy =
  (categoryName, start, limit, sort_type, hierarchy) => async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      // let categoryId = getCategoryID(categoryName);
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/product/category-hierarchy/${categoryName}?start=${start}&limit=${limit}&sort_type=${sort_type}`,
        data: hierarchy,
      });
      dispatch({ type: "LOADING_COMPLETED" });
      dispatch({
        type: "PRODUCT_LIST_BY_CATEGORY",
        payload: { data: res.data },
      });
      // return res.data
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const getProductListByCategory =
  (categoryName, start, limit, sort_type) => async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      // let categoryId = getCategoryID(categoryName);
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/product/category/${categoryName}?start=${start}&limit=${limit}&sort_type=${sort_type}`,
      });
      dispatch({ type: "LOADING_COMPLETED" });
      dispatch({
        type: "PRODUCT_LIST_BY_CATEGORY",
        payload: { data: res.data },
      });
    } catch (error) {
      dispatch(handleError(error));
    }
  };

// export const updateProductListAction = (productList)=> async (dispatch) => {
//     dispatch({
//         type: "PRODUCT_LIST_BY_CATEGORY",
//         payload: { data: productList },
//     });
// }

// called when clicked on any filter's category's see all
export const getProductListByFilternameCategoryAndHierarchy =
  (filtername, categoryName, start, limit, sort_type, hierarchy) =>
    async (dispatch) => {
      try {
        dispatch({ type: "LOADING_STARTED" });
        // let categoryId = getCategoryID(categoryName);
        const res = await axios({
          method: "post",
          url: `${URL}/api/v1/product/category-hierarchy/${filtername}/${categoryName}?start=${start}&limit=${limit}&sort_type=${sort_type}`,
          data: hierarchy,
        });
        dispatch({ type: "LOADING_COMPLETED" });
        dispatch({
          type: "PRODUCT_LIST_BY_CATEGORY",
          payload: { data: res.data },
        });
        // dispatch({
        //     type: "PRODUCT_LIST_BY_FILTERNAME_AND_CATEGORY",
        //     payload: { data: res.data },
        // });
      } catch (error) {
        dispatch(handleError(error));
      }
    };
export const getProductListByFilternameAndCategory =
  (filtername, categoryName, start, limit, sort_type) => async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      // let categoryId = getCategoryID(categoryName);
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/product/category/${filtername}/${categoryName}?start=${start}&limit=${limit}&sort_type=${sort_type}`,
      });
      dispatch({ type: "LOADING_COMPLETED" });
      dispatch({
        type: "PRODUCT_LIST_BY_CATEGORY",
        payload: { data: res.data },
      });
      // dispatch({
      //     type: "PRODUCT_LIST_BY_FILTERNAME_AND_CATEGORY",
      //     payload: { data: res.data },
      // });
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const getProductListBySearchCategory =
  (categoryName, start, limit, sort_type) => async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/product/search-category/${categoryName}?start=${start}&limit=${limit}&sort_type=${sort_type}`,
      });
      dispatch({ type: "LOADING_COMPLETED" });
      dispatch({
        type: "PRODUCT_LIST_BY_CATEGORY",
        payload: { data: res.data },
      });
      return res.data;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const getProductDetail =
  (itemId, preview = false, userId = 0, timezone) =>
    async (dispatch) => {
      try {
        let url = `${URL}/api/v1/product/detail/${itemId}?userId=${userId}&timezone=${timezone}`;

        if (preview === true) {
          url = url + `&preview=true`;
        }


        dispatch({ type: "LOADING_STARTED" });
        const res = await axios({
          method: "get",
          url: url,
        });
        dispatch({ type: "LOADING_COMPLETED" });
        dispatch({
          type: "PRODUCT_DETAIL",
          payload: { data: res.data },
        });
        return res.data.data;
      } catch (error) {
        dispatch(handleError(error));
      }
    };

export const searchProduct =
  (searchKeyword, apiCounter) => async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/product/search?limit=5&start=0&search=${searchKeyword}&apiCounter=${apiCounter}`,
      });

      dispatch({ type: "LOADING_COMPLETED" });
      dispatch({
        type: "SEARCH_LIST",
        payload: { data: res.data },
      });
      return res.data;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const getProductPrice =
  (selectedPair, productId) => async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/product/get-price/${productId}`,
        data: selectedPair,
      });
      dispatch({ type: "LOADING_COMPLETED" });
      dispatch({
        type: "PRODUCT_PRICE",
        payload: { data: res.data },
      });
      return res.data;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const submitRatingReview = (data) => async (dispatch) => {
  try {
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/rating/product/createRating`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: data,
    });

    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getWishlistProductPrice =
  (selectedPair, productId) => async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/product/get-price/${productId}`,
        data: selectedPair,
      });
      dispatch({ type: "LOADING_COMPLETED" });
      return res;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const getRatingForUser =
  ({ userId, productId }) =>
    async (dispatch) => {
      try {
        dispatch({ type: "LOADING_STARTED" });
        const res = await axios({
          method: "get",
          url: `${URL}/api/v1/rating/product/checkCustomerReviewWithUserId/${userId}/${productId}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        dispatch({ type: "LOADING_COMPLETED" });
        dispatch({
          type: "GET_PRODUCT_RATING_FOR_USER",
          payload: { data: res.data },
        });
      } catch (error) {
        dispatch(handleError(error));
      }
    };
export const getOtherComment =
  ({ productId, limitPerClick }) =>
    async (dispatch) => {
      try {
        const res = await axios({
          method: "get",
          url: `${URL}/api/v1/rating/product/getOtherComments/${productId}?limit=${limitPerClick}`,
        });
        return res;
      } catch (error) {
        dispatch(handleError(error));
      }
    };

export const clearStateProductState = () => async (dispatch) => {
  dispatch({
    type: "RESET_PRODUCT_DETAIL",
    payload: {},
  });
};

export const getUpdatedDobaProduct = (slug, spuId) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/product/update-product?slug=${slug}&spuId=${spuId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({
      type: "PRODUCT_DETAIL",
      payload: { data: res.data },
    });
    return res.data.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const uploadImage = (data) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/product/upload-image`,
      data: { base64: data },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    dispatch({
      type: "PRODUCT_PRICE",
      payload: { data: res.data },
    });
    return res.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getSuggestedProductList =
  (productId, limit) => async (dispatch) => {
    try {
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/product/${productId}/suggested-products?limit=${limit}`,
      });
      return res;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const bookFreeSeminarSlot = (productId, userId, seminarDetails) => async (dispatch) => {

  try {
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/product/book-free-seminar/${productId}/${userId}`,
      data: seminarDetails
    });
    return res;
  } catch (err) {
    dispatch(handleError(err));
  }
}


/**
  * Get vendor list by product category id
  */

export const getVendorListbyProductCategoryId =
  (start, limit,categoryId) => async (dispatch) => {
    try {
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/product/get-vendor-list/?limit=${limit}&start=${start} ${categoryId ? `&categoryId=${categoryId}`:""}`,
      });
      return res;
     
    } catch (error) {
      dispatch(handleError(error));
    }
  };


  export const getTopBrands = (limit) => async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/product/top-brands?limit=${limit}`,
      });
      dispatch({ type: "LOADING_`COMPLETED" });
      return res.data;
    } catch (error) {
      dispatch(handleError(error));
    }
  };  

  export const getDataByCategory = (type, limit) => async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/product/data/by/category?type=${type}&limit=${limit}`,
      });
      dispatch({ type: "LOADING_`COMPLETED" });
      return res.data;
    } catch (error) {
      dispatch(handleError(error));
    }
  };  