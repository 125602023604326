import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

function setDataInLocalstorage(paramName, paramValue) {
  localStorage.setItem(paramName, JSON.stringify(paramValue));
  return;
}

export const getOneOnOneMeetingsList = (userId, offset) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/one-on-one-meetings/get-all/${userId}?offset=${offset}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};
export const oneOnOneMeetingDetail = (meetingId) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/one-on-one-meetings/${meetingId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};
export const withdrawOneOnOneMeeting = (id, meetingId) => async (dispatch) => {
  try {
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/one-on-one-meetings/withdraw/${id}?meetingId=${meetingId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const addToBuyNow = (item) => (dispatch) => {
  let buyNowItems = [item];
  setDataInLocalstorage("buyNowItems", buyNowItems);
  dispatch({
    type: "BUYNOWITEMS",
    payload: buyNowItems,
  });
};

export const getMessageCountByBuyerId = (buyerId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/one-on-one-meetings/customer/getMessagesCountByBuyerId/${buyerId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    dispatch({
      type: "UNREAD_MESSAGE_COUNT",
      payload: { data: res.data },
    });
    return res.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};
