import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMeetings,
  getGlobalMeetings,
  createOneOnOneMeeting,
  updateOneOnOneMeeting,
} from "../../Redux/Actions/meetingActions";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./calendar.scss";
import { Modal } from "react-bootstrap";
import axios from "axios";
import {
  BI_WEEKLY,
  MONTHLY,
  WEEKLY,
  ONE_TIME,
  ANNUAL,
} from "../../Constants/constant";
import TimePicker from "react-time-picker";
import AlertModal from "../../Components/AlertModal/AlertModal";
let source;

moment.locale("en-GB");
const localizer = momentLocalizer(moment);

const eventStyleGetter = function () {
  const style = {
    background: "#e25e52",
    borderRadius: "10px",
    opacity: 1,
    color: "#fff",
    border: "0px",
    display: "block",
    padding: "2px 10px",
    textAlign: "center",
    margin: "3px",
    outline: "none",
  };
  return {
    style: style,
  };
};

const ZoomMeetingCalendar = (props) => {
  const dispatch = useDispatch();
  const { calendarLoading } = useSelector((state) => state.commonReducer);
  let sellerId = props?.data?.sellerId;
  let productId = props.data.productId;
  let productTimeFrame = props?.data?.productTimeFrame;
  const userId = localStorage.getItem("userId");

  let { meetingList: eventList, meetingExists } = useSelector(
    (state) => state.meetingReducer
  );
  let [events, setEvents] = useState([]);
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [view, setView] = useState("week");
  const [showHide, setShowHide] = useState(false);
  const [modalData, setModalData] = useState({ open: false, data: {} });
  const [formData, setFormData] = useState({
    start: "",
    end: "",
  });
  let [eventSelectedDay, setEventSelectedDay] = useState(new Date());
  const [meetingType, setMeetingType] = useState(undefined); // meeting type = 0 for recurring and 1 for one time
  const [meetingDurationType, setMeetingDurationType] = useState();
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    singleButton: true,
  });
  const [isDisableButton, setIsDisableButton] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [reqMessage, setreqMessage] = useState("");

  useEffect(() => {
    let meetingList = [];
    if (props.data.isMeetingOneOnOne) {
      setMeetingDurationType(productTimeFrame);
      eventList = eventList.map((data) => {
        return {
          ...data,
          slot: [
            {
              start: data.start,
              end: data.end,
              meetingDurationType: data.meetingDurationType,
              type: data.type,
            },
          ],
        };
      });
    }
    for (const map of eventList) {
      if (!!map.slot) {
        for (const slot of map.slot) {
          if (slot.type && slot.type === "reschedule") {
            meetingList.push({
              start: new Date(slot.start_slot?.replace(/-/g, "/")),
              end: new Date(slot.end_slot?.replace(/-/g, "/")),
              title:
                view === "week"
                  ? `vendor ${map.firstname} ${map.lastname}\ product ${map.name}`
                  : "",
              meetingDurationType: slot.meetingDurationType,
              zoomLink: map.zoomLink,
            });
          } else if (slot.type && slot.type === "cancel") {
          } else {
            meetingList.push({
              start: new Date(slot.start?.replace(/-/g, "/")),
              end: new Date(slot.end?.replace(/-/g, "/")),
              // title:
              //   view === "week"
              //     ? `vendor ${map.firstname} ${map.lastname}\ product ${map.name}`
              //     : "",
              // title: "BOOKED",
              meetingDurationType: slot.meetingDurationType,
              zoomLink: map.zoomLink,
            });
          }
        }
      }
    }
    setEvents(meetingList);
  }, [eventList]);

  useEffect(() => {
    setEvents([]);
    fetchMeetingList();
  }, [selectedDay, props.show, view]);

  const fetchMeetingList = () => {
    if (source){
      source.cancel("Landing Component got props changed");
    }
    source = axios.CancelToken.source();
    if (props.show) {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let startOfWeekTime, endOfWeekTime;
      if (view === "week") {
        startOfWeekTime = moment(selectedDay)
          .startOf("week")
          .format("YYYY-MM-DD HH:mm:ss");
        endOfWeekTime = moment(selectedDay)
          .endOf("week")
          .format("YYYY-MM-DD HH:mm:ss");
      } else if (view === "month") {
        startOfWeekTime = moment(selectedDay)
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss");
        endOfWeekTime = moment(selectedDay)
          .endOf("month")
          .format("YYYY-MM-DD HH:mm:ss");
      }

      if (props.data.isMeetingOneOnOne) {
        dispatch(
          getGlobalMeetings(
            productId,
            sellerId,
            startOfWeekTime,
            endOfWeekTime,
            timezone,
            view,
            userId,
            props.isPurchased,
            
          )
        );
      } else {
        dispatch(
          getMeetings(
            productId,
            sellerId,
            startOfWeekTime,
            endOfWeekTime,
            timezone,
            view,
            userId,
            props.isPurchased,
            source.token
          )
        );
      }
    }
  };

  const handleToolTip = (event) => {
    setModalData({
      open: true,
      data: {
        title: event.title,
        start: new Date(event.start).toLocaleDateString(undefined, {
          weekday: "long",
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }),
        end: new Date(event.end).toLocaleDateString(undefined, {
          weekday: "long",
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }),
        zoomLink: event.zoomLink,
      },
    });
  };

  const handleSelectSlot = (event) => {
    if (!props.data.isMeetingOneOnOne) {
      return null;
    }
    if (!props.product) {
      return null;
    }
    let { start, end } = event;

    const startDate = new Date(start);
    const endtDate = new Date(end);
    if (startDate.getTime() < Date.now() || endtDate.getTime() < Date.now()) {
      return null;
    }
    let isOverlap = false;
    for (let e of events) {
      const eStart = new Date(e.start);
      const eEnd = new Date(e.end);
      if (
        startDate.getTime() <= eStart.getTime() &&
        endtDate.getTime() >= eStart.getTime()
      ) {
        isOverlap = true;
        break;
      } else if (
        startDate.getTime() >= eStart.getTime() &&
        endtDate.getTime() <= eStart.getTime()
      ) {
        isOverlap = true;
        break;
      } else if (
        startDate.getTime() <= eEnd.getTime() &&
        endtDate.getTime() >= eEnd.getTime()
      ) {
        isOverlap = true;
        break;
      } else if (
        startDate.getTime() >= eEnd.getTime() &&
        endtDate.getTime() <= eEnd.getTime()
      ) {
        isOverlap = true;
        break;
      } else if (
        startDate.getTime() >= eStart.getTime() &&
        endtDate.getTime() <= eEnd.getTime()
      ) {
        isOverlap = true;
        break;
      }
    }

    if (isOverlap) {
      return null;
    }

    let diff = moment(start).diff(new Date(), "days");
    if (diff >= 0 && start && end) {
      let isError = false;
      let meetingStartTimeStamp = start.getTime();
      let meetingEndTimeStamp = end.getTime();
      for (const event of events) {
        let startTimeStamp = event.start.getTime();
        let endTimeStamp = event.end.getTime();
        if (
          meetingStartTimeStamp >= startTimeStamp &&
          meetingStartTimeStamp <= endTimeStamp
        ) {
          isError = true;
          break;
        } else if (
          startTimeStamp >= meetingStartTimeStamp &&
          startTimeStamp <= meetingEndTimeStamp
        ) {
          isError = true;
          break;
        }
        if (
          meetingEndTimeStamp <= endTimeStamp &&
          meetingEndTimeStamp >= startTimeStamp
        ) {
          isError = true;
          break;
        } else if (
          endTimeStamp <= meetingEndTimeStamp &&
          endTimeStamp >= meetingStartTimeStamp
        ) {
          isError = true;
          break;
        }
      }
      setFormData({
        start: "",
        end: "",
      });
      setShowHide(true);
      setEventSelectedDay(new Date(start));
      let startTime = moment(new Date(start), "hh:mm A").format("HH:mm");
      let endTime = moment(new Date(end), "hh:mm A").format("HH:mm");
      formData["start"] = view === "week" ? startTime : "";
      formData["end"] = view === "week" ? endTime : "";
      setFormData({ ...formData });
    } else {
      alertModal({
        message: `You cannot add events to past date!`,
        singleButton: true,
      });
    }
  };

  const detailModal = () => (
    <Modal show={modalData.open} centered>
      <Modal.Header
        closeButton
        onClick={() => {
          setModalData({ open: false, data: {} });
        }}
      >
        <b>Meeting Details</b>
      </Modal.Header>
      <Modal.Body>
        <div>
          {modalData.data.title ? (
            <p>
              <b>Description :</b> {modalData.data.title}
            </p>
          ) : null}
          <p>
            {" "}
            <b>Start Time :</b> {modalData.data.start}
          </p>
          <p>
            <b>End Time :</b> {modalData.data.end}
          </p>
        </div>
        {new Date(modalData.data.end) > new Date() && props.isPurchased && (
          <a href={modalData.data.zoomLink} target="_blank">
            <button>Join Now</button>
          </a>
        )}
      </Modal.Body>
    </Modal>
  );

  const handleModalShowHide = () => {
    setShowHide(false);
    setFormData({ start: "", end: "" });
    setMeetingType(undefined);
  };

  const handleSetMeetingEvent = (e, value) => {
    e.preventDefault();
    if (value) {
      setMeetingDurationType(ONE_TIME);
    } else {
      setMeetingDurationType(productTimeFrame);
    }
    setMeetingType(+value);
  };

  const handleSetMeetingDurationType = (e) => {
    const { value } = e.target;
    setMeetingDurationType(value);
  };

  const handleChange = (e, name) => {
    formData[name] = e ? e : "";
    setFormData({ ...formData });
  };

  const createNewEvent = async () => {
    setIsDisableButton(true);
    setShowLoader(true);
    const startTimeDate = new Date(
      moment(new Date(eventSelectedDay)).format("MM-DD-YYYY") +
        " " +
        formData["start"]
    );
    const endTimeDate = new Date(
      moment(new Date(eventSelectedDay)).format("MM-DD-YYYY") +
        " " +
        formData["end"]
    );

    if (startTimeDate.getTime() > endTimeDate.getTime()) {
      setErrorMsg("Enter valid start date");
      setTimeout(() => {
        setErrorMsg("");
      }, 2000);
      return;
    }
    let body = {
      productId: productId,
      userId: userId,
      sender: "user",
      timeframe: meetingDurationType,
      meeting_date: moment(new Date(eventSelectedDay)).format("YYYY-MM-DD"),
      start_time: formData["start"],
      end_time: formData["end"],
      meeting_day: moment(new Date(eventSelectedDay)).day(),
      storeId: props.data.storeId,
      productVariation: props.data.productVariation,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    const result = await dispatch(createOneOnOneMeeting(body));
    if (!result?.isError) {
      props.handleSlotBook();
      setreqMessage("request submitted successfully");
      setIsDisableButton(false);
      setShowLoader(false);
      setTimeout(() => {
        setShowHide(false);
        props.setShowCalendar(false);
        setreqMessage("");
      }, 2000);
    }
  };

  const alertModal = (data) => {
    setAlertState({ ...data, open: true });
  };

  const closeAlertModal = () => {
    setAlertState({
      open: false,
      message: "",
      singleButton: true,
    });
  };

  const handleAlertResponse = (type, id, data) => {
    closeAlertModal();
  };

  const modal = () => (
    <Modal
      show={showHide}
      onHide={handleModalShowHide}
      className="calendar-modal"
      centered
    >
      <Modal.Header className="calendar-header">
        <span onClick={() => handleModalShowHide()}>
          <i className="fas fa-times"></i>
        </span>
        <h5 className="text-center w-100 m-0">Request One-on-One Meeting</h5>
      </Modal.Header>
      <Modal.Body>
        <div className="details-slot">
          {reqMessage && (
            <div className="success-msg">
              <p>{reqMessage}</p>
            </div>
          )}
          <p>Frequency : {!meetingType ? productTimeFrame : "one time"}</p>
          <div className="d-flex align-items-center justify-content-center">
            <button
              className={`${
                meetingType === 0 ? "orange-btn" : "orange-outline-btn-nohover"
              } mr-2`}
              onClick={(e) => handleSetMeetingEvent(e, 0)}
            >
              Recurring
            </button>
            <button
              className={
                meetingType === 1 ? "orange-btn" : "orange-outline-btn-nohover"
              }
              onClick={(e) => handleSetMeetingEvent(e, 1)}
            >
              One Time
            </button>
          </div>
          <h6 className="mt-3 text-center">
            {moment(new Date(eventSelectedDay)).format("MMMM Do YYYY")}
          </h6>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <p>{props.product?.title}</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>
                <b>START TIME</b>
              </label>
              <TimePicker
                value={formData["start"]}
                format="hh:mm a"
                name="start"
                disableClock={true}
                onChange={(e) => handleChange(e, "start")}
                required={true}
                clearIcon={null}
                className="form-control"
                disabled={true}
              />
              <span className="clock-icon">
                <i className="far fa-clock"></i>
              </span>
              <span style={{ color: "red" }}>{errorMsg}</span>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>
                <b>END TIME</b>
              </label>
              <TimePicker
                value={formData["end"]}
                format="hh:mm a"
                name="end"
                disableClock={true}
                onChange={(e) => handleChange(e, "end")}
                required={true}
                clearIcon={null}
                className="form-control"
                disabled={true}
              />
              <span className="clock-icon">
                <i className="far fa-clock"></i>
              </span>
            </div>
          </div>
        </div>
        <div className="button-center">
          <button
            onClick={() => createNewEvent()}
            className="orange-btn"
            disabled={isDisableButton}
          >
            Save Changes
            {(() => {
              if (showLoader) {
                return (
                  <span className="spinner-border spinner-border-sm ml-1"></span>
                );
              }
            })()}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );

  return (
    <>
      <Modal
        className="modal admin-modal edit-user-modal"
        id="ZoomMeetingCalendarModal"
        show={props.show}
        onHide={() => props.setShowCalendar(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#250C6D" }}>
            Schedule - {new Date().toString().match(/\(([A-Za-z\s].*)\)/)[1]}
            {calendarLoading && (
              <span className="spinner-border spinner-border-sm ml-2" style={{ minWidth: '1rem', minHeight: '1rem' }}></span>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: "#9285B6" }}>
          <div
            className="button-modal-div"
            style={{
              margin: "0 auto",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            <div>
              <Calendar
                localizer={localizer}
                showMultiDayTimes={true}
                events={events}
                style={{ height: 700 }}
                selectable
                startAccessor="start"
                endAccessor="end"
                step={30}
                view={view}
                onView={(view) => setView(view)}
                views={["week", "month"]}
                eventPropGetter={eventStyleGetter}
                date={selectedDay}
                onNavigate={(day) => {
                  setSelectedDay(day);
                }}
                dayLayoutAlgorithm="no-overlap"
                messages={{ previous: "Previous" }}
                onSelectEvent={(e) => handleToolTip(e)}
                onSelectSlot={(event) => handleSelectSlot(event)}
                popup={true}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <AlertModal
        data={alertState}
        closeAlertModal={closeAlertModal}
        handleAlertResponse={(type, id, data) =>
          handleAlertResponse(type, id, data)
        }
      />
      {modal()}
      {detailModal()}
    </>
  );
};

export default ZoomMeetingCalendar;
