import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const getSubscriptionList = (userId, status, offset) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/subscription/${userId}?status=${status}&offset=${offset}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
}


export const getSubscriptionListForAccessCategory = (userId, status, offset) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/subscription/${userId}/accessCategory?status=${status}&offset=${offset}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
}




export const cancelSubscription = (userId, subscriptionId, payment_mode) => async (dispatch) => {
    try {
        const res = await axios({
            method: "post",
            url: `${URL}/api/v1/subscription/${userId}/cancel`,
            data: { subscriptionId, payment_mode },
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
}


export const getSubscriptionDetails = (userId, subscriptionId) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/subscription/${userId}/getSubscriptionDetail/${subscriptionId}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        return res?.data;
    }
    catch (error) {
        dispatch(handleError(error));
    }
}


export const stopSubscription = (userId, subscriptionId, payment_mode) => async (dispatch) => {
    try {
        const res = await axios({
            method: "post",
            url: `${URL}/api/v1/subscription/${userId}/stop`,
            data: { subscriptionId, payment_mode },
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
}
export const resumeSubscription = (userId, subscriptionId, payment_mode, productId, vendorId) => async (dispatch) => {
    try {
        const res = await axios({
            method: "post",
            url: `${URL}/api/v1/subscription/${userId}/resume`,
            data: { subscriptionId, payment_mode, productId, vendorId },
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
}
