
import React from 'react';

export const LinkedinIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path d="M28.7979 28.8009V19.4249C28.7979 14.8169 27.8059 11.2969 22.4299 11.2969C19.8379 11.2969 18.1099 12.7049 17.4059 14.0489H17.3419V11.7129H12.2539V28.8009H17.5659V20.3209C17.5659 18.0809 17.9819 15.9369 20.7339 15.9369C23.4539 15.9369 23.4859 18.4649 23.4859 20.4489V28.7689H28.7979V28.8009Z" fill="white"/>
      <path d="M3.61523 11.7109H8.92723V28.7989H3.61523V11.7109Z" fill="white"/>
      <path d="M6.27122 3.20312C4.57522 3.20312 3.19922 4.57913 3.19922 6.27513C3.19922 7.97113 4.57522 9.37912 6.27122 9.37912C7.96722 9.37912 9.34322 7.97113 9.34322 6.27513C9.34322 4.57913 7.96722 3.20312 6.27122 3.20312Z" fill="white"/>
    </svg>
  );
};