import axios from "axios";
import { handleError } from "./commonAction";
import { sortObject, matchTwoObjects } from "../../Utils/helper";

const URL = process.env.REACT_APP_API_URL;

function setDataInLocalstorage(paramName, paramValue) {
  localStorage.setItem(paramName, JSON.stringify(paramValue));
  return;
}

function dispatchCartItems(dispatch, cartItems) {
  dispatch({
    type: "CARTITEMS",
    payload: cartItems,
  });
}

export const getCartItems = () => (dispatch) => {
  let cartItems = JSON.parse(localStorage.getItem("cartItems"));
  if (cartItems) {
    dispatchCartItems(dispatch, cartItems);
  } else {
    dispatchCartItems(dispatch, []);
  }
};

export const addToCart = (item, selectedQuantity) => (dispatch) => {
  let cartItems = JSON.parse(localStorage.getItem("cartItems"));

  if (cartItems) {
    let itemIndex = cartItems.findIndex(
      (elem) =>
        elem.id === item.id &&
        elem.paymentOptionType == item.paymentOptionType &&
        item.customizationImage === elem.customizationImage &&
        item.customizationNote === elem.customizationNote
    );
    if (itemIndex >= 0) {
      let hasSameVariant = true;
      let itemInCart = cartItems[itemIndex];


      if (
        itemInCart.selectedPair == undefined ||
        item.selectedPair == undefined
      ) {
      } else {
        const itemInCartSelectedPairKeys = sortObject(itemInCart.selectedPair);
        const incomingItemSelectedPairKeys = sortObject(item.selectedPair);

        if (
          itemInCartSelectedPairKeys.length !=
          incomingItemSelectedPairKeys.length
        ) {
          hasSameVariant = false;
        } else {
          var count = 0;
          cartItems.map((items) => {
            const finalItemInCartSelectedPairKeys = sortObject(
              items.selectedPair
            );
            if (
              items.id == item.id && items.paymentOptionType == item.paymentOptionType && item.price == items.price &&
              `${item.timeSlots}` == `${items.timeSlots}` &&
              // (item.isAccessCategoryAvailable && items?.timeSlots && item?.timeSlots &&
              //    items?.timeSlots?.length === item?.timeSlots?.length &&
              //     items?.timeSlots?.every((value, index) => (
              //     +value[0]?.split(':')[0] == +item?.timeSlots[index][0]?.split(":")[0] && 
              //     +value[0]?.split(':')[1] == +item?.timeSlots[index][0]?.split(":")[1] &&
              //     +value[1]?.split(':')[0] == +item?.timeSlots[index][1]?.split(":")[0] &&
              //     +value[1]?.split(':')[1] == +item?.timeSlots[index][1]?.split(":")[1]))) &&
              matchTwoObjects(
                finalItemInCartSelectedPairKeys,
                incomingItemSelectedPairKeys
              ) &&
              finalItemInCartSelectedPairKeys.length ===
                incomingItemSelectedPairKeys.length
            ) {
              count = 1;
              if (item.isAccessCategoryAvailable){
                items.selectedQuantity = selectedQuantity
              }else{
                items.selectedQuantity += !!!item.isSeminarEvent ? selectedQuantity : 0

              }
              cartItems[itemIndex] = itemInCart;
            } else {
              count = count;
            }
          });

          if (count == 1) {
            hasSameVariant = true;
          } else {
            hasSameVariant = false;
          }
        }

        if (!hasSameVariant) {
          item["selectedQuantity"] = selectedQuantity;
          cartItems.push(item);
        }

        setDataInLocalstorage("cartItems", cartItems);
        dispatchCartItems(dispatch, cartItems);
      }
    } else {
      item["selectedQuantity"] = selectedQuantity;
      cartItems.push(item);
      setDataInLocalstorage("cartItems", cartItems);
      dispatchCartItems(dispatch, cartItems);
    }
  } else {
    item["selectedQuantity"] = selectedQuantity;
    let cartItems = [item];
    setDataInLocalstorage("cartItems", cartItems);
    dispatchCartItems(dispatch, cartItems);
  }
};

export const changeItemQuantity =
  (item, newQuantity, itemIndex) => (dispatch) => {
    item["selectedQuantity"] = newQuantity;
    let cartItems = JSON.parse(localStorage.getItem("cartItems"));
    if (cartItems) {
      // let itemIndex = cartItems.findIndex((elem) => elem.id === item.id);
      cartItems[itemIndex] = item;
      setDataInLocalstorage("cartItems", cartItems);
      dispatchCartItems(dispatch, cartItems);
    }
  };

export const removeItemFromCart =
  (item, itemIndex, cartType = "cartItems") =>
  (dispatch) => {
    let cartItems = JSON.parse(localStorage.getItem(cartType));
    cartItems.splice(itemIndex, 1);
    setDataInLocalstorage(cartType, cartItems);
    cartType === "cartItems"
      ? dispatchCartItems(dispatch, cartItems)
      : dispatch({
          type: "BUYNOWITEMS",
          payload: cartItems,
        });
  };

export const validateAppliedCoupon =
  (total, couponCode) => async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/cart/coupon?total=${total}&code=${couponCode}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      dispatch({ type: "LOADING_COMPLETED" });
      return res;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const paypalSubscriptionCreate =
  ({
    productAmount,
    shippingAmount,
    taxAmount,
    timeFrame,
    discountInfo,
    productTitle,
    productDesc,
  }) =>
  async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/cart/paypal-subscription-create`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        data: {
          productAmount,
          shippingAmount,
          taxAmount,
          timeFrame,
          discountInfo,
          productTitle,
          productDesc,
        },
      });

      dispatch({ type: "LOADING_COMPLETED" });
      return res.data;
    } catch (error) {
      dispatch(handleError(error));
    }
  };
export const getDobaDeliveryOption = (body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/cart/doba-delivery-options`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: body,
    });

    dispatch({ type: "LOADING_COMPLETED" });
    return res.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const paypalSubscriptionUpdate =
  ({ billingPlan, userData, productTitle, productDesc }) =>
  async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/cart/paypal-subscription-update`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        data: {
          billingPlan,
          userData,
          productTitle,
          productDesc,
        },
      });

      dispatch({ type: "LOADING_COMPLETED" });
      return res.data;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const paypalSubscriptionBillCreate =
  ({ billingAgreementAttributes }) =>
  async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/cart/paypal-subscription-bill-create`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        data: {
          billingAgreementAttributes,
        },
      });

      dispatch({ type: "LOADING_COMPLETED" });

      return res.data;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const paypalSubscriptionExecute =
  ({ token }) =>
  async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/cart/paypal-subscription-execute`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        data: {
          token,
        },
      });

      dispatch({ type: "LOADING_COMPLETED" });
      return res.data;
    } catch (error) {
      console.log(error,"error ")
      dispatch(handleError(error));
    }
  };

export const createOrder =
  ({
    id,
    firstname,
    lastname,
    email,
    address,
    address_2,
    city,
    state,
    country,
    zipCode,
    phonenumber,
    paymentType,
    cartItems,
    discountCode,
    discountId,
    discountPercent,
    transication_id,
    billingFirstName,
    billingLastName,
    billingEmail,
    billingAddress,
    billingAddress_2,
    billingCity,
    billingState,
    billingCountry,
    billingZipCode,
    billingPhonenumber,
  }) =>
  async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/cart`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        data: {
          id,
          firstname,
          lastname,
          email,
          address,
          address_2,
          city,
          state,
          country,
          zipCode,
          phonenumber,
          paymentType,
          cartItems,
          discountCode,
          discountId,
          discountPercent,
          transication_id,
          billingFirstName,
          billingLastName,
          billingEmail,
          billingAddress,
          billingAddress_2,
          billingCity,
          billingState,
          billingCountry,
          billingZipCode,
          billingPhonenumber,
        },
      });

      dispatch({ type: "LOADING_COMPLETED" });
      return res.data;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const getProductsCurrentStatus =
  (cartItems, cartType
    // ,customerDetails
    ) => async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/cart/check-item-status`,
        data: { cartItems
          // ,customerDetails 
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      dispatch({ type: "LOADING_COMPLETED" });
      if (res.data.data && res.data.data.length > 0) {
        setDataInLocalstorage(cartType, res.data.data);
      }
      dispatch({
        type: "PRODUCTCURRENTSTATUS",
        payload: { data: res.data },
      });
      // return res;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const checkoutItem = (checkoutData) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/cart`,
      data: checkoutData,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    if (
      res.data.data &&
      res.data.data.length > 0 &&
      !res.data.data[0]?.errorMsg
    ) {
      setDataInLocalstorage("cartItems", res.data.data);
    }
    dispatch({
      type: "CHECKOUT",
      payload: { data: res.data },
    });
  } catch (error) {
    console.log("error while checkout", error);
    dispatch(handleError(error));
  }
};

export const validateUserCheckoutInfo = (info) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/cart/validate`,
      data: info,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res;
  } catch (error) {
    console.log("error while checkout", error);
    dispatch(handleError(error));
  }
};

export const getCountriesList = () => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/cart/get-countries-list`,
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getStatesList = (countryId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/cart/get-states-list/${countryId}`,
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res;
  } catch (error) {
    console.log("error to get states list", error);
    dispatch(handleError(error));
  }
};

export const getCitiesList = (stateId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/cart/get-cities-list/${stateId}`,
    });
    dispatch({ type: "LOADING_COMPLETED" });
    console.log("res from get cities list", res);
    return res;
  } catch (error) {
    console.log("error to get cities list", error);
    dispatch(handleError(error));
  }
};

export const getSavedCardsList = (userId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/cart/get-saved-card/${userId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res;
  } catch (error) {
    console.log("error to get saved cards list", error);
    dispatch(handleError(error));
  }
};

export const deleteSavedCard = (userId, cardId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "delete",
      url: `${URL}/api/v1/cart/delete-saved-card/${userId}/${cardId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res;
  } catch (error) {
    console.log("error to delete saved cards", error);
    dispatch(handleError(error));
  }
};

export const getSavedCardsFromStripe = (customerId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/stripe/getSavedCardsFromStripe/${customerId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    dispatch({
      type: "GET_SAVED_CARD_LIST",
      payload: res.data,
    });
    return res;
  } catch (error) {
    console.log("error to get saved cards list", error);
    dispatch(handleError(error));
  }
};
