import React from "react";
import "./home.scss";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  getProductListByFilter,
  getTopBrands,
  getDataByCategory,
} from "../../Redux/Actions/productAction";
import Rating from "@material-ui/lab/Rating";
import homeBanner from "../../assets/images-2/home-banner.jpg";
import artworkAccessoriesImg from "../../assets/images/artwork-accessories-img.png";

import listProductBg from "../../assets/images/list-product-bg-2.png";
import subscietyLogo from "../../assets/images-2/subscietyLogo.png";
import replenishTabImg from "../../assets/images/replenish-tab-img.jpg";
import accessTabImg from "../../assets/images/access-tab-img.png";
import customizeTabImg from "../../assets/images/customiz-tab-img.png";
import customize2 from "../../assets/images/customize-2.png";
import customize3 from "../../assets/images/customize-3.png";

import replenish1 from "../../assets/images/replenish-1.png";
import replenish2 from "../../assets/images/replenish-2.png";
import replenish3 from "../../assets/images/replenish-3.png";

import access1 from "../../assets/images/access-1.png";
import access2 from "../../assets/images/access-2.png";
import access3 from "../../assets/images/access-3.png";

import LogoWhite from "../../assets/images/footer-logo.png";

import { CheckIconPrimary } from "../../Components/svgElements/CheckIconPrimary";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

let homeInstance = null;

window.acceptUIResponseHandler = (response) => {
  homeInstance.createSubscriptionPlan(response);
  if (response.messages.resultCode === "Error") {
    var i = 0;
    while (i < response.messages.message.length) {
      i = i + 1;
    }
  }
};

class Home extends React.Component {
  toAssignState = 0;

  productList = {
    "new-arrivals": [],
    "best-sellers": [],
    featured: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      "new-arrivals": [],
      "best-sellers": [],
      featured: [],
      topBrand: [],
      categoryData: [],
      categoryType: "replenish",
    };
    homeInstance = this;
    this.getProductList();
  }

  filterList = ["new-arrivals", "best-sellers", "featured"];

  async getProductList() {
    this.filterList.forEach(async (filter) => {
      await this.props.getProductListByFilter(filter).then((data) => {
        let newFilter = filter;

        this.productList[newFilter] = data;
        this.toAssignState++;

        if (this.toAssignState === 3) {
          this.setState({
            ...this.productList,
          });
        }
      });
    });
    const response = await this.props.getDataByCategory("replenish",4);
    if (response.data) {
      this.setState({
        categoryData: response.data,
      });
    } else {
      console.error("Data not available for one of the requests");
      this.setState({
        categoryData: [],
      });
    }
  }

  async changeCategory(value) {
    const response = await this.props.getDataByCategory(value, 4);
    if (response?.data) {
      this.setState({
        categoryData: response.data,
      });
      if (this.state.categoryData) {
        this.forceUpdate();
      }
    } else {
      console.error("Data not available for one of the requests");
      this.setState({
        categoryData: [],
      });
    }
  }

  async componentDidMount() {
    this.loadScript("https://jstest.authorize.net/v3/AcceptUI.js");
    try {
      const res = await this.props.getTopBrands(6);
      if (res.data) {
        this.setState({
          topBrand: res.data,
        });
      } else {
        console.error("Data not available for one of the requests");
        this.setState({
          topBrand: [],
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  createSubscriptionPlan = (response) => {
    console.log("response is", response);
  };

  loadScript = (src) => {
    var tag = document.createElement("script");
    tag.src = src;
    tag.id = "custom-response-script";
    tag.type = "text/javascript";
    document.body.appendChild(tag);
  };

  render() {
    const newArrivalList = this.productList["new-arrivals"]?.map((item) => {
      let isInStock = !item?.isOutOfStock;
      return (
        <div className="col-6 col-sm-6 col-md-4 col-lg-3" key={item.id}>
          <Link
            to={{
              pathname: `/product/${item.slug}`,
              state: { spuId: item.spu_id },
            }}
          >
            <div className="product-card">
              <div className="card-img">
                <img src={item.imageName} alt="" />
                <div className="card-tags">
                  {/* <span>New</span> */}
                  {!item.isAccessCategoryAvailable && item.is_shipping_free ? (
                    <span>Free shipping</span>
                  ) : null}
                </div>
                <div className="image-status-label">
                  {isInStock || item?.isAccessCategoryAvailable ? (
                    <div className="status-label">
                      <p>Quick View</p>
                    </div>
                  ) : (
                    <div className="status-label">
                      <p>Sold Out</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="card-content">
                <div className="name">
                  <h3 title={item.name ? item.name : null}>
                    {item.name ? item.name : `-`}
                  </h3>
                </div>
                <div className="price-with-offer">
                  <div className="price">
                    <p>
                      {item.is_sale !== 0
                        ? item.price_type === 1
                          ? `$${(item.price - item.sale_price)
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                          : `$${(
                              item.price -
                              (item.price *
                                (100 * (1 - item.sale_price / item.price))) /
                                100
                            )
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                        : `${!item.price ? `` : `$${item.price}`}`}{" "}
                      {item.is_sale ? (
                        <span>
                          <del>
                            {item.price
                              ? `$${item.price
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                              : `-`}
                          </del>
                        </span>
                      ) : null}
                    </p>
                  </div>
                  {item.is_sale !== 0 ? (
                    item.price_type === 1 ? (
                      <div className="offer-tag">
                        <span>${item.sale_price} off</span>
                      </div>
                    ) : (
                      <div className="offer-tag">
                        <span>
                          {Math.round(100 * (1 - item.sale_price / item.price))}
                          % off
                        </span>
                      </div>
                    )
                  ) : null}
                </div>

                <div className="rating-info">
                  <Rating
                    name="hover-feedback"
                    value={item.rating}
                    precision={0.5}
                    readOnly
                  />
                </div>
                <div className="product-owner-info">
                  <div className="product-owner-img">
                    <img
                      src={item.sellerImage ? item.sellerImage : homeBanner}
                      alt="homeBanner"
                    />
                  </div>
                  <div className="product-owner-name">
                    <p>
                      By <span>{item.storename} </span> For You
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      );
    });

    const bestSellersList = this.productList["best-sellers"]?.map((item) => {
      let isInStock = !item?.isOutOfStock;
      const diffTime = Math.abs(new Date() - new Date(item.createTS));
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

      return (
        <div className="col-6 col-sm-6 col-md-4 col-lg-3" key={item.id}>
          {/* new card start */}
          <Link
            to={{
              pathname: `/product/${item.slug}`,
              state: { spuId: item.spu_id },
            }}
          >
            <div className="product-card">
              <div className="card-img">
                <img src={item.imageName} alt="" />
                <div className="card-tags">
                  {diffDays < 10 && <span>New</span>}
                  {!item.isAccessCategoryAvailable && item.is_shipping_free ? (
                    <span>Free shipping</span>
                  ) : null}
                </div>
                <div className="image-status-label">
                  {isInStock || item?.isAccessCategoryAvailable ? (
                    <div className="status-label">
                      <p>Quick View</p>
                    </div>
                  ) : (
                    <div className="status-label">
                      <p>Sold Out</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="card-content">
                <div className="name">
                  <h3 title={item.name ? item.name : null}>
                    {item.name ? item.name : `-`}
                  </h3>
                </div>
                <div className="price-with-offer">
                  <div className="price">
                    <p>
                      {item.is_sale !== 0
                        ? item.price_type === 1
                          ? `$${(item.price - item.sale_price)
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                          : `$${(
                              item.price -
                              (item.price *
                                (100 * (1 - item.sale_price / item.price))) /
                                100
                            )
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                        : `${!item.price ? `` : `$${item.price}`}`}{" "}
                      {item.is_sale ? (
                        <span>
                          <del>
                            {item.price
                              ? `$${item.price
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                              : `-`}
                          </del>
                        </span>
                      ) : null}
                    </p>
                  </div>
                  <div className="offer-tag">
                    {item.is_sale !== 0 ? (
                      item.price_type === 1 ? (
                        <span>${item.price - item.sale_price} off</span>
                      ) : (
                        <span>
                          {" "}
                          {Math.round(100 * (1 - item.sale_price / item.price))}
                          % off
                        </span>
                      )
                    ) : null}
                  </div>
                </div>

                <div className="rating-info">
                  <Rating
                    name="hover-feedback"
                    value={item.rating}
                    precision={0.5}
                    readOnly
                  />
                </div>
                <div className="product-owner-info">
                  <div className="product-owner-img">
                    <img
                      src={item.sellerImage ? item.sellerImage : homeBanner}
                      alt="homeBanner"
                    />
                  </div>
                  <div className="product-owner-name">
                    <p>
                      By <span>{item.storename} </span> For You
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Link>
          {/* new card end */}
        </div>
      );
    });

    const featuredList = this.productList["featured"]?.map((item) => {
      let isInStock = !item?.isOutOfStock;
      const diffTime = Math.abs(new Date() - new Date(item.createTS));
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

      return (
        <div className="col-6 col-sm-6 col-md-4 col-lg-3" key={item.id}>
          {/* new card start */}
          <Link
            to={{
              pathname: `/product/${item.slug}`,
              state: { spuId: item?.spu_id },
            }}
          >
            <div className="product-card">
              <div className="card-img">
                <img src={item.imageName} alt="" />
                <div className="card-tags">
                  {diffDays < 10 && <span>New</span>}
                  {!item.isAccessCategoryAvailable && item.is_shipping_free ? (
                    <span>free shipping</span>
                  ) : null}
                </div>
                <div className="image-status-label">
                  {isInStock || item?.isAccessCategoryAvailable ? (
                    <div className="status-label">
                      <p>Quick View</p>
                    </div>
                  ) : (
                    <div className="status-label">
                      <p>Sold Out</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="card-content">
                <div className="name">
                  <h3 title={item.name ? item.name : null}>
                    {item.name ? item.name : `-`}
                  </h3>
                </div>
                <div className="price-with-offer">
                  <div className="price">
                    <p>
                      {item.is_sale !== 0
                        ? item.price_type === 1
                          ? `$${(item.price - item.sale_price)
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                          : `$${(
                              item.price -
                              (item.price *
                                (100 * (1 - item.sale_price / item.price))) /
                                100
                            )
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                        : `${!item.price ? `` : `$${item.price}`}`}{" "}
                      {item.is_sale ? (
                        <span>
                          <del>
                            {item.price
                              ? `$${item.price
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                              : `-`}
                          </del>
                        </span>
                      ) : null}
                    </p>
                  </div>
                  <div className="offer-tag">
                    {item.is_sale !== 0 ? (
                      item.price_type === 1 ? (
                        <span>${item.sale_price} off</span>
                      ) : (
                        <span>
                          {" "}
                          {Math.round(100 * (1 - item.sale_price / item.price))}
                          % off
                        </span>
                      )
                    ) : null}
                  </div>
                </div>

                <div className="rating-info">
                  <Rating
                    name="hover-feedback"
                    value={item.rating}
                    precision={0.5}
                    readOnly
                  />
                </div>
                <div className="product-owner-info">
                  <div className="product-owner-img">
                    <img
                      src={item.sellerImage ? item.sellerImage : homeBanner}
                      alt="homeBanner"
                    />
                  </div>
                  <div className="product-owner-name">
                    <p>
                      By <span>{item?.storename} </span> For You
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Link>
          {/* new card end */}
        </div>
      );
    });
    const categoriesSliderOptions = {
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 2,
        },
        1000: {
          items: 2,
        },
      },
    };

    return (
      <div className="home-page">
        <section className="home-hero-section">
          <div className="container-fluid">
            <div className="section-inner">
              <div className="row">
                <div className="col-md-12 col-lg-7">
                  {/* card1 */}
                  <div className="hero-product-card hero-product-card-1">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="card-content">
                          <h6>Customize</h6>
                          <h2>Clothing, Artwork & Accessories</h2>
                          <p>
                            Explore Unique Personalized Goods, Plans, and
                            Content
                          </p>
                          <div className="action-btn">
                            <Link to="/product-list/category/customize">
                              <button className="theme-button dark-button-outline">
                                Explore Customize
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <OwlCarousel
                          className="owl-theme"
                          margin={20}
                          nav={false}
                          items={1}
                          loop={true}
                          dots={true}
                          autoplay={true}
                          autoplayTimeout={3000} // 3 seconds
                          autoplayHoverPause={true}
                        >
                          <div className="item">
                            <div className="card-img">
                              <img
                                src={artworkAccessoriesImg}
                                alt="artworkAccessoriesImg"
                              />
                            </div>
                          </div>
                          <div className="item">
                            <div className="card-img">
                              <img src={customize2} alt="customize2" />
                            </div>
                          </div>
                          <div className="item">
                            <div className="card-img">
                              <img src={customize3} alt="customize3" />
                            </div>
                          </div>
                        </OwlCarousel>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 col-lg-5">
                  {/* card2 */}
                  <div className="hero-product-card hero-product-card-2">
                    <div className="row">
                      <div className="col-md-7">
                        <div className="card-content">
                          <h6>Replenish</h6>
                          <h2>Jewelry, Grocery, Pet Needs & More</h2>
                          <p>
                            Life Essentials Products For Your Everyday Needs
                          </p>
                          <div className="action-btn">
                            <Link to="/product-list/category/replenish">
                              <button className="theme-button dark-button-outline">
                                Explore Replenish
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <OwlCarousel
                          className="owl-theme"
                          margin={20}
                          nav={false}
                          items={1}
                          loop
                          dots={true}
                          autoplay={true}
                          autoplayTimeout={3000} // 3 seconds
                          autoplayHoverPause={true}
                        >
                          <div className="item">
                            <div className="card-img">
                              <img src={replenish1} alt="replenish1" />
                            </div>
                          </div>
                          <div className="item">
                            <div className="card-img">
                              <img src={replenish2} alt="replenish2" />
                            </div>
                          </div>
                          <div className="item">
                            <div className="card-img">
                              <img src={replenish3} alt="replenish3" />
                            </div>
                          </div>
                        </OwlCarousel>
                      </div>
                    </div>
                  </div>
                  {/* card3 */}
                  <div className="hero-product-card hero-product-card-3">
                    <div className="row">
                      <div className="col-md-7">
                        <div className="card-content">
                          <h6>Access</h6>
                          <h2>Coaching, Webinars, Classes & More</h2>
                          <p>Leran Something New Today</p>
                          <div className="action-btn">
                            <Link to="/product-list/category/access">
                              <button className="theme-button dark-button-outline">
                                Explore Access
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <OwlCarousel
                          className="owl-theme"
                          margin={20}
                          nav={false}
                          items={1}
                          loop
                          dots={true}
                          autoplay={true}
                          autoplayTimeout={3000} // 3 seconds
                          autoplayHoverPause={true}
                        >
                          <div className="item">
                            <div className="card-img">
                              <img src={access1} alt="access1" />
                            </div>
                          </div>
                          <div className="item">
                            <div className="card-img">
                              <img src={access2} alt="access2" />
                            </div>
                          </div>
                          <div className="item">
                            <div className="card-img">
                              <img src={access3} alt="access3" />
                            </div>
                          </div>
                        </OwlCarousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* TODO IN FUTURE */}

        {/* <section className="landing-product-section">
          <div className="container">
            <div className="section-heading-inline">
              <div className="common-heading mb-0">
                <h2>What People Are Viewing Right Now</h2>
              </div>
              <div className="see-all-link">
                <Link to="/product-list">See All</Link>
              </div>
            </div>
            <div className="section-inner">
              <div className="row justify-content-center">
                <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                  <div className="product-card">
                    <div className="card-img">
                      <img src={homeBanner} alt="homeBanner" />
                      <div className="card-tags">
                        <span>New</span>
                        <span>Free shipping</span>
                      </div>
                    </div>
                    <div className="card-content">
                      <div className="name">
                        <h3>Gold Plated Intertwined Earrings</h3>
                      </div>
                      <div className="price-with-offer">
                        <div className="price">
                          <p>
                            $24.99{" "}
                            <span>
                              <del>$34.99</del>
                            </span>
                          </p>
                        </div>
                        <div className="offer-tag">
                          <span>30% OFF</span>
                        </div>
                      </div>

                      <div className="rating-info">
                        <Rating
                          name="hover-feedback"
                          value={4.5}
                          precision={0.5}
                          readOnly
                        />
                      </div>
                      <div className="product-owner-info">
                        <div className="product-owner-img">
                          <img src={homeBanner} alt="homeBanner" />
                        </div>
                        <div className="product-owner-name">
                          <p>
                            By <span>Emma Shop For You</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                  <div className="product-card">
                    <div className="card-img">
                      <img src={homeBanner} alt="homeBanner" />
                      <div className="card-tags">
                        <span>New</span>
                        <span>Free shipping</span>
                      </div>
                    </div>
                    <div className="card-content">
                      <div className="name">
                        <h3>Gold Plated Intertwined Earrings</h3>
                      </div>
                      <div className="price-with-offer">
                        <div className="price">
                          <p>
                            $24.99{" "}
                            <span>
                              <del>$34.99</del>
                            </span>
                          </p>
                        </div>
                        <div className="offer-tag">
                          <span>30% OFF</span>
                        </div>
                      </div>

                      <div className="rating-info">
                        <Rating
                          name="hover-feedback"
                          value={4.5}
                          precision={0.5}
                          readOnly
                        />
                      </div>
                      <div className="product-owner-info">
                        <div className="product-owner-img">
                          <img src={homeBanner} alt="homeBanner" />
                        </div>
                        <div className="product-owner-name">
                          <p>
                            By <span>Emma Shop For You</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* TOP BRANDS */}
        <section className="home-top-brands-section">
          <div className="container">
            <div className="section-heading-inline">
              <div className="common-heading mb-0">
                <h2>Top Brands</h2>
              </div>
              <div className="see-all-link">
                <Link to="/shop-by-vendor">See All</Link>
              </div>
            </div>
            <div className="section-inner">
              <div className="row justify-content-center">
                {this.state.topBrand &&
                  this.state.topBrand.map((item) => {
                    return (
                      <div className="col-6 col-sm-3 col-md-3 col-lg-2">
                        <Link to={`/store/${item.storeId}/${item.sellerId}`}>
                          <div className="brands-card">
                            <div className="brand-img">
                              <img
                                src={
                                  item.imagename
                                    ? item.imagename
                                    : subscietyLogo
                                }
                                alt="subscietyLogo"
                              />
                            </div>
                            <div className="brand-name">
                              <p>{item.storename}</p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </section>

        {/* CATEGORIES */}
        <section className="home-page-categories">
          <div className="container">
            <div className="row mb-4">
              <div className="col-md-6">
                <div className="common-heading mb-0">
                  <h2>Categories</h2>
                </div>
              </div>
              <div className="col-md-6">
                <div className="categories-tab-menu">
                  <span
                    onClick={() => {
                      this.changeCategory("replenish");
                      this.setState({
                        categoryType: "replenish",
                      });
                    }}
                    className={
                      this.state.categoryType === "replenish" ? "active" : ""
                    }
                  >
                    Replenish
                  </span>
                  <span
                    onClick={() => {
                      this.changeCategory("access");
                      this.setState({
                        categoryType: "access",
                      });
                    }}
                    className={
                      this.state.categoryType === "access" ? "active" : ""
                    }
                  >
                    Access
                  </span>
                  <span
                    onClick={() => {
                      this.changeCategory("customize");
                      this.setState({
                        categoryType: "customize",
                      });
                    }}
                    className={
                      this.state.categoryType === "customize" ? "active" : ""
                    }
                  >
                    Customize
                  </span>
                </div>
              </div>
            </div>

            <div className="section-inner">
              <div className="categories-tab-content">
                <div className="row">
                  <div className="col-md-12 col-lg-5">
                    <div className="replenish-card">
                      {this.state.categoryType === "replenish" ? (
                        <div className="card-img">
                          <img src={replenishTabImg} alt="replenishTabImg" />
                          <div className="card-content">
                            <div className="name">
                              <h3>Replenish</h3>
                            </div>
                            <div className="brief">
                              <p>
                                Life Essential Products For Your Everyday Needs
                              </p>
                            </div>
                            <div className="action-btn">
                              <Link to="/product-list/category/replenish">
                                <button
                                  class="theme-button black-button"
                                  type="button"
                                >
                                  Explore Now
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      ) : this.state.categoryType === "access" ? (
                        <div className="card-img">
                          <img src={accessTabImg} alt="replenishTabImg" />
                          <div className="card-content">
                            <div className="name">
                              <h3>Access</h3>
                            </div>
                            <div className="brief">
                              <p>Learn Something New Today!</p>
                            </div>
                            <div className="action-btn">
                              <Link to="/product-list/category/access">
                                <button
                                  class="theme-button black-button"
                                  type="button"
                                >
                                  Explore Now
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="card-img">
                          <img src={customizeTabImg} alt="replenishTabImg" />
                          <div className="card-content">
                            <div className="name">
                              <h3>Customize</h3>
                            </div>
                            <div className="brief">
                              <p>
                                Explore Unique Personalized Goods, Plans, and
                                content
                              </p>
                            </div>
                            <div className="action-btn">
                              <Link to="/product-list/category/customize">
                                <button
                                  class="theme-button black-button"
                                  type="button"
                                >
                                  Explore Now
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-7">
                    <OwlCarousel
                      className="owl-theme"
                      nav={false}
                      items={2}
                      loop
                      dots={true}
                      margin={10}
                      options={categoriesSliderOptions}
                      autoplay={true}
                      autoplayTimeout={3000} // 3 seconds
                      autoplayHoverPause={true}
                    >
                      {this.state.categoryData.length > 0
                        ? this.state.categoryData.map((item) => {
                            const diffTime = Math.abs(
                              new Date() - new Date(item.createTs)
                            );
                            const diffDays = Math.floor(
                              diffTime / (1000 * 60 * 60 * 24)
                            );
                            return (
                              <div className="item">
                                <div className="product-card">
                                  <Link to={`/product/${item.productslug}`}>
                                    <div className="card-img">
                                      <img
                                        src={
                                          item.productImage
                                            ? item.productImage
                                            : homeBanner
                                        }
                                        alt="homeBanner"
                                      />
                                      <div className="card-tags">
                                        {diffDays < 10 && <span>New</span>}
                                        {item.is_shipping_free ? (
                                          <span>Free shipping</span>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </Link>
                                  <div className="card-content">
                                    <div className="name">
                                      <h3>{item.name}</h3>
                                    </div>
                                    <div className="price-with-offer">
                                      <div className="price">
                                        <p>
                                          {item.is_sale !== 0
                                            ? item.price_type === 1
                                              ? `$${(
                                                  item.price - item.sale_price
                                                )
                                                  .toFixed(2)
                                                  .replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  )}`
                                              : `$${(
                                                  item.price -
                                                  (item.price *
                                                    (100 *
                                                      (1 -
                                                        item.sale_price /
                                                          item.price))) /
                                                    100
                                                )
                                                  .toFixed(2)
                                                  .replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  )}`
                                            : `${
                                                !item.price
                                                  ? ``
                                                  : `$${item.price}`
                                              }`}{" "}
                                          {item.is_sale ? (
                                            <span>
                                              <del>
                                                {item.price
                                                  ? `$${item.price
                                                      .toFixed(2)
                                                      .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                      )}`
                                                  : `-`}
                                              </del>
                                            </span>
                                          ) : null}
                                        </p>
                                      </div>
                                      {item.is_sale !== 0 ? (
                                        item.price_type === 1 ? (
                                          <div className="offer-tag">
                                            <span>${item.sale_price} off</span>
                                          </div>
                                        ) : (
                                          <div className="offer-tag">
                                            <span>
                                              {Math.round(
                                                100 *
                                                  (1 -
                                                    item.sale_price /
                                                      item.price)
                                              )}
                                              % off
                                            </span>
                                          </div>
                                        )
                                      ) : null}
                                    </div>

                                    <div className="rating-info">
                                      <Rating
                                        name="hover-feedback"
                                        value={item.productRating}
                                        precision={0.5}
                                        readOnly
                                      />
                                    </div>
                                    <div className="product-owner-info">
                                      <div className="product-owner-img">
                                        <img
                                          src={
                                            item.sellerImage
                                              ? item.sellerImage
                                              : homeBanner
                                          }
                                          alt="homeBanner"
                                        />
                                      </div>
                                      <div className="product-owner-name">
                                        <p>
                                          By <span>{item.storename}</span> For
                                          You
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : "No data found"}
                    </OwlCarousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* NEW ARRIVALS */}
        <section className="landing-product-section">
          <div className="container">
            <div className="section-heading-inline">
              <div className="common-heading mb-0">
                <h2> New Arrivals</h2>
              </div>
              <div className="see-all-link">
                <Link to={"/product-list/new-arrivals"}>See All</Link>
              </div>
            </div>
            <div className="section-inner">
              <div className="row justify-content-center">{newArrivalList}</div>
            </div>
          </div>
        </section>

        <section
          className="home-list-product"
          //style={{ backgroundImage: `url(${listProductBg})`}}
        >
          <img
            className="list-product-bgimg"
            src={listProductBg}
            alt="listProductBg"
          />
          <div className="section-inner">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-6">
                  <div className="section-contant">
                    <div className="name">
                      <h2>
                        Want to list your product on <img src={LogoWhite} alt="logo" /><span>?</span>
                      </h2>
                    </div>
                    <div className="brief">
                      <ul>
                        <li>
                          <CheckIconPrimary /> Gain initial visibility by
                          featuring on our homepage.
                        </li>
                        <li>
                          <CheckIconPrimary /> No Setup or Recurring Fees.
                        </li>
                        <li>
                          <CheckIconPrimary /> Free Assigned Account Manager
                        </li>
                      </ul>
                    </div>
                    <div className="action-btn">
                      <Link
                        to={{
                          pathname: `${process.env.REACT_APP_VENDOR_URL}`,
                        }}
                        target="_blank"
                      >
                        <button className="theme-button primary-button">
                          Get Started
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <main className="mainContainer mt-5">
          {/* FEATURED */}
          <section className="landing-product-section">
            <div className="container">
              <div className="section-heading-inline">
                <div className="common-heading mb-0">
                  <h2>Featured Products</h2>
                </div>
                <div className="see-all-link">
                  <Link to={"/product-list/featured"}>See All</Link>
                </div>
              </div>
              <div className="section-inner">
                <div className="row justify-content-center">{featuredList}</div>
              </div>
            </div>
          </section>

          {/* BEST SELLER */}
          <section className="landing-product-section">
            <div className="container">
              <div className="section-heading-inline">
                <div className="common-heading mb-0">
                  <h2>Best Sellers</h2>
                </div>
                <div className="see-all-link">
                  <Link to={"/product-list/best-sellers"}>See All</Link>
                </div>
              </div>
              <div className="section-inner">
                <div className="row justify-content-center">
                  {bestSellersList}
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getProductListByFilter, getTopBrands, getDataByCategory },
    dispatch
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
