import React from "react";
import "../../Styles/profile.scss";
import Profile_Sidebar from "../Profile-Side-Bar/Profile_Sidebar";
import client from "../../assets/images-2/client.png";
import profileAvtar from "../../assets/images/profile-img.png";
import { updateProfile, getUserDetail } from "../../Redux/Actions/authAction";
import { setUserInfo } from "../../Redux/Actions/userAction";
import { getCountriesList, getCitiesList, getStatesList } from "../../Redux/Actions/cartAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Select from "react-select";
import { logDOM } from "@testing-library/react";

class Profile extends React.Component {
  userData = localStorage.getItem("userData");
  monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  fieldsList = {
    address: "",
    address_2: "",
    addressId: null,
    city: "",
    country: "",
    createts: "",
    email: "",
    firstname: "",
    id: 0,
    imagename: "",
    isacceptedtnc: 0,
    isactive: 0,
    lastname: "",
    phonenumber: "",
    role: "",
    state: "",
    type: "",
    updatedTS: "",
    updatedts: "",
    userId: 0,
    zipCode: "",
    successMessage: "",
    errorMessage: "",
    selectedImg: null,
    authData: {
      name: "",
      image: "",
    },
    errorData: {},
    showLoader: false,
    src: null,
    crop: {
      unit: "%",
      width: 30,
      aspect: 1,
    },
  };
  timestamp = "?time=" + new Date().getTime();
  constructor(props) {
    super(props);
    this.state = { ...this.fieldsList };
    this.fileSelectHandler = this.fileSelectHandler.bind(this);
    // this.saveUserDetails = this.saveUserDetails.bind(this);
    this.handleDrop = this.handleDrop.bind(this);

    this.fileUpload = React.createRef();
    // this.myAsyncEffect();
  }

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageURL = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({
        croppedImageUrl: croppedImageURL,
        selectedImg: croppedImageURL,
      });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      var dataURL = canvas.toDataURL();
      resolve(dataURL);
      // canvas.toBlob(blob => {
      //     if (!blob) {
      //         //reject(new Error('Canvas is empty'));
      //         console.error('Canvas is empty');
      //         return;
      //     }
      //     blob.name = fileName;
      //     window.URL.revokeObjectURL(this.fileUrl);
      //     this.fileUrl = window.URL.createObjectURL(blob);
      //     resolve({url: this.fileUrl, blob});
      // }, 'image/jpeg');
    });
  }

  onInputChange(event) {
    const authData = this.state.authData;
    authData.name = event.target.value;
    this.setState({ authData: this.props.authData });
  }

  async fileSelectHandler(e) {
    let imgStatus = false;
    if (e.target.files[0] !== undefined)
      if (e.target.files[0].type !== undefined) {
        let imgType = e.target.files[0].type.split("/");
        let MimeTypeArray = ["jpg", "jpeg", "png", "gif"];
        if (imgType[1] !== undefined) {
          if (MimeTypeArray.includes(imgType[1])) {
            if (e.target && e.target.files[0]) {
              let img = e.target.files[0];
              this.setState({
                selectedImg: img,
              });
              this.readFile(img);
            }
          } else {
            imgStatus = true;
          }
        }
      }
    this.setState({ invalidImage: imgStatus });
  }

  componentDidMount() {
    if (this.props.authData) {
      let authData = this.props.authData;
      authData.name = this.props.authData?.name;
      authData.image = this.props.authData?.image;
      this.setUserState(authData);
    }
  }

  async setUserState(userInfo) {
    this.setState({
      ...userInfo,
    },async ()=>{
      await this.fetchCountriesList();
      if(userInfo.country && userInfo.country.length !== 0 ){
        const countryId = this.state.countriesObjectList?.filter((c) => c.countryName === userInfo.country)?.[0]?.['countryId']
        let statesList = await this.props.getStatesList(countryId);
        statesList =statesList && statesList.data.data.map((state) => ({
          value: `${JSON.stringify({
            stateId: state.id,
            stateName: state.state_name,
          })}`,
          label: `${state.state_name}`,
        }));
        let stateId = 0;
        let cityId = 0;
        let citiesList = []
        if(userInfo.state && userInfo.state.length !== 0){
          stateId = statesList.filter((s)=> s.label === userInfo.state )?.[0]
          if(stateId){
            stateId = JSON.parse(stateId['value'])['stateId']
            citiesList = await this.props.getCitiesList(stateId);
        citiesList = citiesList.data.data.map((city) => ({
          value: `${JSON.stringify({ cityId: city.id, cityName: city.city })}`,
          label: `${city.city}`,
        }));
        let cityId = citiesList.filter((s)=> s.label === userInfo.city )?.[0]
        if(cityId){
          cityId = JSON.parse(cityId['value'])['cityId']
        }
          }
        
        }
        
        
        this.setState({
          country: userInfo.country,
          statesList,
          citiesList,
          state: '',
          city: '',
          defaultSelectedState: '',
          defaultSelectedCity: '',
        })
        setTimeout(() => {
          this.setState({
            state: userInfo.state,
            city: userInfo.city,
            defaultSelectedState: {
              value: `${JSON.stringify({
                stateId,
                stateName: userInfo.state,
              })}`,
              label: `${userInfo.state}`,
            },
            defaultSelectedCity: {
              value: `${JSON.stringify({ cityId: cityId ? cityId : 0, cityName: userInfo.city })}`,
              label: `${userInfo.city}`,
            }
          })
        }, 100)
      }
    });
  }

  componentWillReceiveProps(nextProps, prevState) {
    if (Object.keys(this.state).length > 0) {
      if (nextProps.authData?.timestamp !== this.state.timestamp) {
        this.setUserState(nextProps.authData);
        // this.setState({
        //     ...nextProps.authData
        // })
      }
    } else if (nextProps.authData !== null) {
      this.setUserState(nextProps.authData);
      // this.props.userInfo = {...nextProps.userInfo}
      // this.setState({
      //     ...nextProps.authData
      // })
    }
  }



  handleDrop = (files) => {
    if (files.length > 1) {
      //  toast.error("You can add only one file");
      return;
    }
    this.setState({
      selectedImg: files[0],
    });
  };

  readFile(file) {
    //Create an instance of FileReader
    const fileReader = new FileReader();
    //Implement onLoad function
    fileReader.onloadend = (event) =>
      this.setState({
        authData: {
          ...this.state.authData,
          image: [fileReader.result],
        },
      });
    //Start reading the file
    fileReader.readAsDataURL(file);
  }

  async fetchCountriesList() {
    let countries = await this.props.getCountriesList();
    if (countries?.data?.data?.length > 0) {
      // this.createPlacesOption(countries.data.data, "countriesList")
      let countriesObjectList = countries.data.data.map((country) => ({
        countryId: country.id,
        countryName: country.name,
      }))
      countries = countries.data.data.map((country) => (
        <option
          key={country.id}
          value={JSON.stringify({
            countryId: country.id,
            countryName: country.name,
          })}
        >
          {country.name}
        </option>
      ));
      this.setState({
        countriesList: countries,
        statesList: [],
        citiesList: [],
        countriesObjectList
      });
    }
  }

  handleCountryChange = async (event) => {
    event.preventDefault();
    const countryValue = JSON.parse(event.target.value);
    let statesList = await this.props.getStatesList(countryValue.countryId);
    if (statesList?.data?.data?.length > 0) {
      statesList = statesList.data.data.map((state) => ({
        value: `${JSON.stringify({
          stateId: state.id,
          stateName: state.state_name,
        })}`,
        label: `${state.state_name}`,
      }));

      if (
        this.state.errorData &&
        this.state.errorData["country"] &&
        this.state.errorData["country"][0]
      ) {
        this.state.errorData["country"][0] = null;
      }

      this.setState((prevState) => ({
        ...prevState,
        country: countryValue.countryName,
        statesList: statesList,
        defaultSelectedState: [],
        citiesList: [],
        defaultSelectedCity: [],
      }));
    }
  };

  handleStateChange = async (event) => {
    const stateValue = JSON.parse(event.value);
    this.setState({
      defaultSelectedState: event,
      state: stateValue.stateName,
    });
    let citiesList = await this.props.getCitiesList(stateValue.stateId);
    if (citiesList?.data?.data?.length > 0) {
      // this.createPlacesOption(citiesList.data.data, "citiesList")
      // citiesList = citiesList.data.data.map((city) => (
      //   <option
      //     key={city.id}
      //     value={JSON.stringify({ cityId: city.id, cityName: city.city })}
      //   >
      //     {city.city}
      //   </option>
      // ));

      citiesList = citiesList.data.data.map((city) => ({
        value: `${JSON.stringify({ cityId: city.id, cityName: city.city })}`,
        label: `${city.city}`,
      }));

      if (
        this.state.errorData &&
        this.state.errorData["state"] &&
        this.state.errorData["state"][0]
      ) {
        this.state.errorData["state"][0] = null;
      }

      this.setState((prevState) => ({
        ...prevState,
        citiesList: citiesList,
        defaultSelectedCity: [],
      }));
    }
  };

  handleCityChange = (event) => {
    const cityValue = JSON.parse(event.value);
    if (
      this.state.errorData &&
      this.state.errorData["city"] &&
      this.state.errorData["city"][0]
    ) {
      this.state.errorData["city"][0] = null;
    }

    this.setState((prevState) => ({
      ...prevState,
      defaultSelectedCity: event,
      city: cityValue.cityName,
    }));
  };

  handleChange = (e, key) => {
    if (
      this.state.errorData &&
      this.state.errorData[key] &&
      this.state.errorData[key][0]
    ) {
      this.state.errorData[key][0] = null;
    }

    let value  = (key === "phonenumber") ? e : e.target?.value;

    if (key === "phonenumber" || key === "zipCode") {
      // value = value.replace(/[^a-zA-Z ]/g, "");
      value = value.replace(/\D+/g, "");
    }

    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  validateUserInfo = () => {
    let isFormValid = true;
    this.state.errorData = {
      firstname: [],
      lastname: [],
      phonenumber: [],
      address: [],
      zipCode: [],
      city: [],
      state: [],
      country: [],
    };

    if (this.state.firstname.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.firstname = ["Firstname field cannot be empty."];
    }
    // else if (this.state.firstname.search("^[a-zA-Z ]+$")) {
    //   isFormValid = false;
    //   this.state.errorData.firstname = ["Firstname only contain Characters"];
    // }
    else if (this.state.firstname.search("^[a-zA-Z]+$")) {
      isFormValid = false;
      this.state.errorData.firstname = ["First Name only contain Characters"];
  }
    // else if (this.state.firstname.includes(`"`) || this.state.firstname.includes(`'`) || this.state.firstname.includes("`")) {
    //   isFormValid = false;
    //   this.state.errorData.firstname = ["This field cannot contain quotes"];
    // }

    if (this.state.lastname.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.lastname = ["Lastname field cannot be empty."];
    }
    // else if (this.state.lastname.search("^[a-zA-Z]+$")) {
    //   isFormValid = false;
    //   this.state.errorData.lastname = ["Lastname only contain Characters"];
    // }
    else if (this.state.lastname.search("^[a-zA-Z]+$")) {
      isFormValid = false;
      this.state.errorData.lastname = ["Last Name only contain Characters"];
  }
    // else if (this.state.lastname.includes(`"`) || this.state.lastname.includes(`'`) || this.state.lastname.includes("`")) {
    //   isFormValid = false;
    //   this.state.errorData.lastname = ["This field cannot contain quotes"];
    // }

    if (!this.state.phonenumber || this.state.phonenumber.trim().length < 10) {
      isFormValid = false;
      this.state.errorData.phonenumber = [
        "Please Enter Correct Phone Number.",
      ];
    }

    if (!this.state.address || this.state.address.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.address = ["Address field cannot be empty."];
    }
    else if (this.state.address.includes(`"`) || this.state.address.includes(`'`) || this.state.address.includes("`")) {
      isFormValid = false;
      this.state.errorData.address = ["This field cannot contain quotes"];
    }

    if (!this.state.zipCode || this.state.zipCode.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.zipCode = ["Zip code field cannot be empty."];
    }
    else if (this.state.zipCode.length < 5) {
      isFormValid = false;
      this.state.errorData.zipCode = [
        "Please Enter correct zipCode Number.",
      ];
    }

    if (!this.state.city || this.state.city.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.city = ["City field cannot be empty."];
    }
    else if (this.state.city.search("^[a-zA-Z_ ]+$")) {
      isFormValid = false;
      this.state.errorData.city = ["Please Enter correct City Name."];
    }
    else if (this.state.state.search("^[a-zA-Z]+$")) {
      isFormValid = false;
      this.state.errorData.state = ["Please Enter correct State Name."];
    }

    if (!this.state.state || this.state.state.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.state = ["State field cannot be empty."];
    }

    if (!this.state.country || this.state.country.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.country = ["Country field cannot be empty."];
    }
    let tempState = this.state;
    this.setState({
      ...tempState,
    });

    return isFormValid;
  };

  handleSubmit = async () => {
    let st = this.state;
    await this.setState((prevState) => ({
      ...st,
      successMessage: "",
      errorMessage: "",
      showLoader: true,
    }));

    localStorage.setItem("updatedName", JSON.stringify(this.state.firstname))
    if (this.validateUserInfo()) {
      const profile = this.state;
      const result = await this.props.updateProfile({ ...profile });

      if (result.status === 201) {
        this.setState((prevState) => ({
          ...prevState,
          timestamp: Date.now(),
          successMessage: "Profile updated successfully!",
          showLoader: false,
          src: null,
        }));
      } else {
        this.setState((prevState) => ({
          ...prevState,
          errorMessage: "Something went wrong! Images should be less than 8 MB",
          showLoader: false,
          src: null,
        }));
      }
    } else {
      this.setState({
        showLoader: false,
      });
    }
  };

  triggerClick = () => {
    this.fileUpload.current.click();
  };

  render() {
    const { crop, croppedImageUrl, src } = this.state;
    return (
      <main className="mainContainer">
        <section className="home-product-section mt-4">
          <div className="container">
            <div className="select-product-list">
              <div className="product-list-head product-list-border-bottom">
                <ul>
                  <li>
                    <a>Profile </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="profile">
          <div className="container">
            <div className="row">
              <Profile_Sidebar />
              {/* <div className="col-lg-3">
                                <div className="profile-tab">
                                    <div className="user-tab-detail">
                                        <div className="user-tab-detail-img">
                                            <img src={this.state.imagename && this.state.imagename !== "undefined" ? this.state.imagename : profileAvtar} />
                                        </div>
                                        <h2>{this.state ?.firstname} {this.state ?.lastname}</h2>
                                        <h3>Member since</h3>
                                        <p>{this.state ?.memberSince}</p>
                                    </div>

                                    <div className="profile-tab-nav">
                                        <ul>
                                            <li>
                                                <a href="javascript:;" className="active">
                                                    <h4>Login/Security</h4>
                                                    <p>Edit or Update information</p>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="javascript:;">
                                                    <h4>Subscriptions</h4>
                                                    <p>View or update subscription plans</p>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="javascript:;">
                                                    <h4>Gift Cards</h4>
                                                    <p>Reedem or view balance on card(s)</p>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="javascript:;">
                                                    <h4>Track</h4>
                                                    <p>Track your subscription orders</p>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}

              <div className="col-lg-9">
                <section className="cart-section">
                  <div className="container">
                    <div className="cart-section-head">
                      <h2>profile</h2>
                      <p>Setup your general profile details.</p>
                    </div>
                    {(() => {
                      if (
                        this.state.successMessage !== "" &&
                        this.state.successMessage !== undefined
                      ) {
                        return (
                          <div className="alert alert-success">
                            {this.state.successMessage}
                          </div>
                        );
                      } else if (
                        this.state.errorMessage !== "" &&
                        this.state.errorMessage !== undefined
                      ) {
                        return (
                          <div className="alert alert-danger">
                            {this.state.errorMessage}
                          </div>
                        );
                      }
                    })()}
                    <div className="profile-body">
                      <div className="row">
                        <div className="col-lg-8 col-md-7 order-md-1 order-2">
                          <div className="profile-form">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="form-group">
                                  <label>
                                    first name
                                    <sup>*</sup>
                                  </label>
                                  <input
                                    type="text"
                                    maxLength="15"
                                    className="form-control"
                                    placeholder="First Name"
                                    value={this.state?.firstname}
                                    onChange={(event) =>
                                      this.handleChange(event, "firstname")
                                    }
                                  />
                                  <span className="form-field-error">
                                    {this.state.errorData?.firstname &&
                                      this.state.errorData.firstname[0]}
                                  </span>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="form-group">
                                  <label>
                                    last name
                                    <sup>*</sup>
                                  </label>
                                  <input
                                    type="text"
                                    maxLength="15"
                                    className="form-control"
                                    placeholder="Last Name"
                                    value={this.state?.lastname}
                                    onChange={(event) =>
                                      this.handleChange(event, "lastname")
                                    }
                                  />
                                  <span className="form-field-error">
                                    {this.state.errorData?.lastname &&
                                      this.state.errorData.lastname[0]}
                                  </span>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <label>
                                  email address
                                  <sup>*</sup>
                                </label>
                                <div className="form-group">
                                  <input
                                    disabled={true}
                                    type="text"
                                    className="form-control"
                                    placeholder="Email Address"
                                    value={this.state?.email}
                                    onChange={(event) =>
                                      this.handleChange(event, "email")
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="form-group">
                                  <label>
                                    phone number
                                    <sup>*</sup>
                                  </label>
                                  {/* <input
                                    type="text"
                                    maxLength="12"
                                    className="form-control"
                                    placeholder="Phone Number"
                                    value={this.state?.phonenumber}
                                    onChange={(event) =>
                                      this.handleChange(event, "phonenumber")
                                    }
                                  /> */}
                                  <PhoneInput
                                  className="phone-input"
                                    country={'us'}
                                    value={this.state?.phonenumber}
                                    onChange={(event) => this.handleChange(event, "phonenumber")}
                                    placeholder="Phone Number"
                                    onlyCountries={["us"]}
                                  />
                                  <span className="form-field-error">
                                    {this.state.errorData?.phonenumber &&
                                      this.state.errorData.phonenumber[0]}
                                  </span>
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="form-group">
                                  <label>
                                    address
                                    <sup>*</sup>
                                  </label>
                                  <input
                                    type="text"
                                    maxLength="50"
                                    className="form-control"
                                    placeholder="Address"
                                    value={this.state?.address}
                                    onChange={(event) =>
                                      this.handleChange(event, "address")
                                    }
                                  />
                                  <span className="form-field-error">
                                    {this.state.errorData?.address &&
                                      this.state.errorData.address[0]}
                                  </span>
                                </div>
                              </div>

                              <div className="col-lg-12">
                                <div className="form-group">
                                  <label>address line 2</label>
                                  <input
                                    type="text"
                                    maxLength="50"
                                    className="form-control"
                                    placeholder="Address line 2"
                                    value={this.state?.address_2}
                                    onChange={(event) =>
                                      this.handleChange(event, "address_2")
                                    }
                                  />
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="form-group">
                                  <label>
                                    Country
                                    <sup>*</sup>
                                  </label>
                                  <select
                                    name="country"
                                    className="form-control"
                                    onChange={(event) =>
                                      this.handleCountryChange(event)
                                    }
                                    value={this.state.country}
                                  >
                                    <option value="" selected disabled>
                                      Select Country
                                    </option>
                                    {this.state.countriesList}
                                    {/* <option value="volvo">country</option>
                            <option value="saab">country1</option>
                            <option value="mercedes">country2</option>
                            <option value="audi">countr3</option> */}
                                  </select>
                                  {/* <input
                            type="text"
                            maxLength="15"
                            className="form-control"
                            placeholder="Country"
                            value={this.state ?.country}
                            onChange={(event) =>
                              this.handleChange(event, "country")
                            }
                          /> */}
                                  <span className="form-field-error">
                                    {this.state.errorData?.country &&
                                      this.state.errorData.country[0]}
                                  </span>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="form-group">
                                  <label>
                                    State
                                    <sup>*</sup>
                                  </label>
                                  {/* <select
                            name="state"
                            className="form-control"
                            onChange={(event) => this.handleStateChange(event)}
                          >
                            <option value="" selected disabled>
                              Select State
                            </option>
                            {this.state.statesList}
                          </select> */}

                                  <Select
                                    value={[this.state.defaultSelectedState]}
                                    name="value"
                                    options={this.state.statesList}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    defaultValue={this.state.state}
                                    onChange={(event) => this.handleStateChange(event)}
                                  />
                                  {/* <input
                            type="text"
                            maxLength="15"
                            className="form-control"
                            placeholder="State"
                            value={this.state ?.state}
                            onChange={(event) =>
                              this.handleChange(event, "state")
                            }
                          /> */}
                                  <span className="form-field-error">
                                    {this.state.errorData?.state &&
                                      this.state.errorData.state[0]}
                                  </span>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="form-group">
                                  <label>
                                    City
                                    <sup>*</sup>
                                  </label>
                                  {/* <select
                            name="country"
                            className="form-control"
                            onChange={(event) => this.handleCityChange(event)}
                          >
                            <option value="" selected disabled>
                              Select City
                            </option>
                            {this.state.citiesList}
                          </select> */}

                                  <Select
                                    value={this.state.defaultSelectedCity}
                                    name="value"
                                    options={this.state.citiesList}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(event) => this.handleCityChange(event)}
                                  />
                                  {/* <input
                            type="text"
                            maxLength="15"
                            className="form-control"
                            placeholder="City"
                            value={this.state ?.city}
                            onChange={(event) =>
                              this.handleChange(event, "city")
                            }
                          /> */}
                                  <span className="form-field-error">
                                    {this.state.errorData?.city &&
                                      this.state.errorData.city[0]}
                                  </span>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="form-group">
                                  <label>
                                    zip code
                                    <sup>*</sup>
                                  </label>
                                  <input
                                    type="text"
                                    maxLength="6"
                                    className="form-control"
                                    placeholder="Zip Code"
                                    value={this.state?.zipCode}
                                    onChange={(event) =>
                                      this.handleChange(event, "zipCode")
                                    }
                                  />
                                  <span className="form-field-error">
                                    {this.state.errorData?.zipCode &&
                                      this.state.errorData.zipCode[0]}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="checkout-btn">
                              <button type="button" onClick={this.handleSubmit}>
                                save
                                {(() => {
                                  if (this.state?.showLoader) {
                                    return (
                                      <span className="spinner-border spinner-border-sm ml-1"></span>
                                    );
                                  }
                                })()}
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-5 order-md-2 order-1">
                          <div className="profile-picture">
                            <div className="profile-picture-head">
                              <h3>profile picture</h3>
                            </div>
                            <div className="user-profile-img">
                              {croppedImageUrl ? (
                                <img alt="Crop" src={croppedImageUrl} />
                              ) : (
                                <img
                                  src={
                                    this.state?.imagename && this.state?.imagename !== "undefined"
                                    ? this.state.imagename
                                    : profileAvtar
                                  }
                                />
                              )}
                            </div>
                            <div className="upload-btn mb-2">
                              <button onClick={this.triggerClick} type="button">
                                upload image
                              </button>
                            </div>

                            <div
                              style={{ display: src ? "block" : "none" }}
                              className="profile-picture"
                            >
                              <div>
                                <input
                                  ref={this.fileUpload}
                                  style={{ display: "none" }}
                                  type="file"
                                  accept="image/*"
                                  onChange={this.onSelectFile}
                                />
                              </div>
                              {src && (
                                <ReactCrop
                                  src={src}
                                  crop={crop}
                                  ruleOfThirds
                                  onImageLoaded={this.onImageLoaded}
                                  onComplete={this.onCropComplete}
                                  onChange={this.onCropChange}
                                />
                              )}
                            </div>

                            <span className="form-field-error">
                              {this.state.errorData?.productImage &&
                                this.state.errorData.productImage[0]}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </main>
      // <div className="main-section">
      //   <div className="main-heading">
      //     <h2>My Account</h2>
      //     <ul>
      //       <Link to={`/auth/myAccount`}>
      //         {" "}
      //         <li>
      //           <a>Settings</a>
      //         </li>
      //       </Link>
      //     </ul>
      //   </div>

      //   <div className="main-body">
      //     <div className="card">
      //       <div className="card-header">
      //         <h2>Settings</h2>
      //       </div>
      //       <div className="card-body">
      //         <form>
      //           <div className="form-group edit-account-input">
      //             <label>User Name:</label>
      //             <input
      //               type="text"
      //               className="form-control"
      //               name="companyName"
      //               value={this.state.authData?.name}
      //               onChange={(event) => this.onInputChange(event)}
      //             ></input>
      //           </div>

      //           <div className="drop-heading">
      //             <h2>User Profile</h2>
      //           </div>
      //           <DragAndDrop handleDrop={this.handleDrop}>
      //             <div className="drop-section-box">
      //               <div className="drop-img">
      //                 <img src={this.state.authData?.image} />
      //                 <p>Drag and Drop to upload</p>
      //               </div>
      //               <div className="droup-btn">
      //                 <button type="button">Update Image</button>
      //                 <input
      //                   className="droup-file-input"
      //                   type="file"
      //                   onChange={this.fileSelectHandler}
      //                 ></input>
      //               </div>
      //               <span>(up to 10MB)</span>
      //               <div className="drop-type-box">
      //                 <p>This image can be PNG, JPG or JPEG format.</p>
      //               </div>
      //             </div>
      //           </DragAndDrop>
      //           <div className="text-center">
      //             <button
      //               className="btn-orange"
      //               type="button"
      //               onClick={this.saveUserDetails}
      //             >
      //               Save Changes
      //             </button>
      //             <button
      //               className="btn-null"
      //               type="button"
      //               onClick={() => this.props.history.push("/")}
      //             >
      //               Cancel
      //             </button>
      //           </div>
      //         </form>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    errorData: state.commonReducer.errorData,
    authData: state.userReducer.userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { updateProfile, getUserDetail, setUserInfo, getCountriesList, getCitiesList, getStatesList },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Profile)
);
