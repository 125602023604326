import React, { useEffect, useState } from 'react'
import * as productAction from "../../Redux/Actions/productAction";
import { useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import { Link, useLocation } from "react-router-dom";
import profileAvtar from "../../assets/images/profile-img.png";
import { getProductList } from "../../Redux/Actions/productAction";
import InfiniteScroll from "react-infinite-scroll-component";
import* as userAction from "../../Redux/Actions/storeAction";

const ShopByVendorList = (props) => {
    const StyledRating = withStyles({
        iconFilled: {
            color: "#ff6d75",
        },
        iconHover: {
            color: "#ff3d47",
        },
    })(Rating);
    const dispatch = useDispatch()
    const [vendorData, setVendorData] = useState([])
    const [loadState, setLoadState] = useState({
        offset: 0,
        prevOffset: 0,
        hasMore: true,
    });

    let location = useLocation();
    const [searchList, setSearchList] = useState([]);
    const [searchText, setSearchText] = useState();
    const [timeOutState, setTimeOutState] = useState(null);



    const handleSearch = async (searchText) => {
        if(searchText.length===0){
            setSearchList([]);
            setSearchText("")
            return null
          }
          setSearchText(searchText);

        if (timeOutState) {
          clearInterval(timeOutState);
        }
        setSearchText(searchText);
        let result = setTimeout(() => {
          handleInputChange()
        }, 200);
        setTimeOutState(result);
    
       
      };
    
    
    const handleInputChange = async () => {
      if(searchText && searchText.length > 0) {
        const res=await dispatch(userAction.getSellerByName(searchText && searchText.trim(),location?.state?.categoryId));
        if(res && res?.data && res.data.length >0){
          setSearchList(res.data)
        }
      }
    }
   
    useEffect(() => {
            loadMoreData(true);      
    }, [])

    const loadMoreData = async (firstLoad) => {
        if (loadState.offset !== loadState.prevOffset || firstLoad) {
            if (!loadState.hasMore) {
                return null;
            }
            let response
            if (location.state.from === "store") {
                response=await dispatch(getProductList("vendorProductList", loadState.offset, 10, " ", location?.state?.vendorId, location?.state?.categoryId));
            }
            else{
                response = await dispatch(
                    productAction.getVendorListbyProductCategoryId(loadState.offset, 10, location.state.categoryId)
                );
            }
            
            if (response && !response?.data.isError) {                
                if (response?.data[0]?.prodList?.length === 0 || response?.data?.data?.length===0) {
                    return setLoadState((prevState) => ({
                        ...prevState,
                        hasMore: false,
                    }));
                }
                if (firstLoad) {
                        location.state.from === "store" ? setVendorData(response?.data[0]?.prodList)
                        :setVendorData(response?.data?.data);
                } else {
                    let vendorList = [...vendorData];
                    location.state.from === "store" ? setVendorData([...vendorList, ...response?.data[0]?.prodList])
                    :setVendorData([...vendorList, ...response?.data?.data])
                    ;
                }

                setLoadState((prevState) => ({
                    ...prevState,
                    offset: prevState.offset + 10,
                    prevOffset: prevState.offset,
                }));
            }
        }
    };


    const newArrivalList=!vendorData
    ? []
    :
    
    vendorData?.map((item) => {
        
    let isInStock = !item?.isOutOfStock
    // let isInStock =
    // item.totalsubscription + item.oneTimeOrder <
    // item.order_limit;

    // if (
    //   item?.timeFrame &&
    //   item.timeFrame.includes("weekly")
    // ) {
    //   isInStock =
    //     Math.floor(
    //       (item.order_limit -
    //         (item.totalsubscription +
    //           item.oneTimeOrder)) /
    //       4
    //     ) > 0;
    // }

            return (
                <div className="col-md-3 col-6" key={item.id}>
                    <div className="home-product-item">
                        {!item.isAccessCategoryAvailable && item.is_sale !== 0 ? (
                            item.price_type === 1 ? (
                                <div className="sale-label-star">${item.sale_price} off</div>
                            ) : (
                                <div className="sale-label-star">
                                    {" "}
                                    {Math.round(100 * (1 - item.sale_price / item.price))}% off
                                </div>
                            )
                        ) : null}
                        {!item.isAccessCategoryAvailable && item.is_shipping_free ? (
                            <div to="/" className="shipping-label-star">
                                free shipping
                            </div>
                        ) : null}
                        <Link
                            to={{
                                pathname: `/product/${item.slug}`,
                                state: { spuId: item.spu_id },
                            }}
                        >
                            <div className="home-product-box">
                                <img src={item.imageName} />

                                     {(isInStock || item?.isAccessCategoryAvailable) ?
                                      <div className="quickviewbtn">
                                        <p>Quick View</p>
                                      </div>
                                      :
                                      <div className="quickviewbtn">
                                      <p>Sold Out</p>
                                    </div>

                                      }
                                      
                            </div>
                            <div className="home-productbox-detail">
                                <div className="home-productbox-heading">
                                    <div className="price-inline-remove">
                                        <h2 title={item.name ? item.name : null}>
                                            {item.name ? item.name : `-`}
                                        </h2>
                                        {!item.isAccessCategoryAvailable && item.is_sale ? (
                                            <p>
                                                <del>
                                                    {item.price
                                                        ? `$${item.price
                                                            .toFixed(2)
                                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                                        : `-`}
                                                </del>
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                                <p className="product-price-mobile">
                    {item.is_sale !== 0
                            ? item.price_type === 1
                              ? `$${(item.price - item.sale_price)
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                              : `$${(
                                  item.price -
                                  (item.price *
                                    (100 *
                                      (1 - item.sale_price / item.price))) /
                                    100
                                )
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                            : `${!item.price ? `` : `$${item.price}`}`}
                             {item.is_sale ? (
                          <p>
                            <del>
                              {item.price
                                ? `$${item.price
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                : `-`}
                            </del>
                          </p>
                        ) : null}
                        </p>
                                <div className="home-productbox-bottom">
                                    <div className="item-rating">
                                        <Rating
                                            name="hover-feedback"
                                            value={item.rating}
                                            precision={0.5}
                                            readOnly
                                        />
                                    </div>
                                    <div className="home-productbox-price">
                                        <p>
                                            {!item.isAccessCategoryAvailable && item.is_sale !== 0
                                                ? item.price_type === 1
                                                    ? `$${(item.price - item.sale_price)
                                                        .toFixed(2)
                                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                                    : `$${(
                                                        item.price -
                                                        (item.price *
                                                            (100 * (1 - item.sale_price / item.price))) /
                                                        100
                                                    )
                                                        .toFixed(2)
                                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                                : `${!item.price ? `` : `$${item.price}`}`}
                                        </p>
                                    </div>
                                </div>
                                {!item.isAccessCategoryAvailable && item.is_shipping_free ? (
                    <p className="shipping-mobile-label">FREE SHIPPING</p>
                    ) : null}
                            </div>
                        </Link>
                    </div>
                </div>
            );
        })

      




    const products =vendorData?.length > 0 ? (
            vendorData.map((item, index) => {

                return (

                    <div className="col-md-3 col-6" key={index}>
                        <div className="vendor-card-box">
                            <Link
                                to={`/store/${item.storeId}/${item.id}`}
                            >
                                <div className="home-product-box">
                                    <img src={item?.imagename ? item.imagename : profileAvtar} />

                                    {/* <a href="#" className="quickviewbtn">
                                                    <p>Quick View</p>
                                                </a> */}
                                </div>
                                <div className="home-productbox-detail">
                                    <div className="home-productbox-heading">
                                        <div className="price-inline-remove">
                                            <h5
                                                title={item.storename ? item.storename : null}
                                                className="mt-3"
                                            >
                                                {item.storename}
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="home-productbox-bottom">
                                        <div className="item-rating">
                                            <Rating
                                                name="hover-feedback"
                                                value={item.rating}
                                                precision={0.5}
                                                readOnly
                                            />
                                        </div>
                                      <p>{item?.store_overview}</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>

                );
            })
        ) : (
            <div></div>
        );
    return (

        <>
        {
            location.state.from !== "store"
            &&  
            <div className='container mt-3'>
                 <div className='search-input-vendor mb-3'>
                 <div className='search-icon-align'>
                 <input
              className="form-control"
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
              // onClick={getSearchResult}
              type="text"
              placeholder="SEARCH"
            />
           <i class="fas fa-search"></i>
      <ul>
      {searchList.length > 0 && 
            searchList.map((item,index) =>{
              return (
                <li>
              <div
              // onClick={() => onclickAction(item)}
              className="search-filter-item"
              key={index}
            >
              <Link
             to={`/store/${item.storeId}/${item.id}`}
              >
                <div className="search-filter-item-img">
                  <img src={item.imagename ? item.imagename:profileAvtar}/>
                </div>
                <div className="search-filter-item-detail">
                  <p>{item.storename}</p>
                </div>
              </Link>
            </div>
            </li>
              )
            })
            }
      </ul>
                    </div>
                 </div>
      
            </div>
        }
       

            <div className="container">
                <div>
                    {
                        location.state.from === "store"
                            ?
                            vendorData?.length > 0 && (
                                <section className="home-product-section">
                                    <div className="container">
                                        <div className="home-section-heading mb-3">


                                            <ul>
                                                <li>
                                                    <h2>{location?.state?.categoryType}</h2>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="container-fluid">


                                            <InfiniteScroll
                                                dataLength={vendorData?.length}
                                                next={() => loadMoreData(false)}
                                                hasMore={loadState.hasMore} >

                                                <div className="row">

                                {
                                    newArrivalList?.length > 0 && newArrivalList
                                }

                                                </div>
                                            </InfiniteScroll>

                                        </div>
                                    </div>
                                </section>
                )
                :
                <InfiniteScroll
                dataLength={vendorData?.length}
                next={() => loadMoreData(false)}
                hasMore={loadState.hasMore} >
                    <div className='container'>
                <div className="row padding-manage-show">

{
    products?.length > 0 && products
}

                </div>
                </div>
            </InfiniteScroll>
                
                    }
            </div>
        </div>

       
        </>




















    )
}

export default ShopByVendorList;



