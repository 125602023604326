export const ONE_TIME = "one_time";
export const RECURRING = "RECURRING";
export const WEEKLY = "weekly";
export const MONTHLY = "monthly";
export const BI_WEEKLY = "bi_weekly";
export const ANNUAL = "annual";

export const timezoneList = [
    { tz: "AKDT", title: "Alaska Daylight Time - AKDT", tzDiff: -8 },
    { tz: "CDT", title: "Central Daylight Time - CDT", tzDiff: -5 },
    { tz: "EDT", title: "Eastern Daylight Time - EDT", tzDiff: -4 },
    { tz: "HST", title: "Hawaii-Aleutian Standard Time - HST", tzDiff: -10 },
    { tz: "MDT", title: "Mountain Daylight Time - MDT", tzDiff: -6 },
    { tz: "MST", title: "Mountain Standard Time - MST", tzDiff: -7 },
    { tz: "PDT", title: "Pacific Daylight Time - PDT", tzDiff: -7 },
  ];

  export const weekDayValue = {
    Sun: "Sunday",
    Mon: "Monday",
    Tue: "Tuesday",
    Wed: "Wednesday",
    Thu: "Thursday",
    Fri: "Friday",
    Sat: "Saturday",
}

export const  weekDayObj = {
  0: "sunday",
  1: "monday",
  2: "tuesday",
  3: "wednesday",
  4: "thursday",
  5: "friday",
  6: "saturday",
};