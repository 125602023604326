import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  getProductListByCategoryWithHierarchy,
  getProductListByFilternameCategoryAndHierarchy,
  getChildCategoryList,
  // updateProductListAction
} from "../../Redux/Actions/productAction";
import "./product-list.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import InfiniteScroll from "react-infinite-scroll-component";
import Rating from "@material-ui/lab/Rating";
import { withStyles } from "@material-ui/core/styles";

const StyledRating = withStyles({
  iconFilled: {
    color: "#ff6d75",
  },
  iconHover: {
    color: "#ff3d47",
  },
})(Rating);

const options = {
  margin: 20,
  responsiveClass: true,
  nav: true,
  dots: false,
  autoplay: false,
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 2,
    },
    700: {
      items: 3,
    },
    1000: {
      items: 3,
    },
    1400: {
      items: 4,
    },
  },
};

class Product_SubCategory_List extends Component {
  limit = 10;
  start = 0;
  prevStart = 0;
  constructor(props) {
    super(props);
    this.handleClickOnLink = this.handleClickOnLink.bind(this);

    this.state = {
      items: [],
      hasMore: true,
      category: this.props.match.params.category,
      parentId: +this.props.match.params.subCategory,
      isLoading: false,
      sort_type: undefined,
      listChildCategory: [],
      categoryList: [],
      selectedCategory: { id: this.props.match.params.subCategory },
      selectedHierarchy: [],
      categoryCards: [],
      isCategoryDisabled: false,
    };
  }
  // fetchedCategoryList = []

  componentDidMount() {
    this.setState(
      {
        selectedHierarchy: [this.props.location?.state?.categoryName],
      },
      () => {
        this.fetchProducts(this.props.match.params.category, true);
      }
    );
    this.fetchChildCategory(this.props.match.params.category, 0).then(
      (value) => {
        this.fetchChildCategory(
          this.props.match.params.category,
          this.state.parentId
        );
      }
    );
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (
      nextProps.match.params.subCategory !== this.props.match.params.subCategory
    ) {
      this.setState({
        items: [],
        hasMore: true,
        category: nextProps.match.params.category,
        subCategory: nextProps.match.params.subCategory,
        isLoading: false,
        selectedHierarchy: [this.props.location?.state?.categoryName],
      });
      this.start = 0;
      this.prevStart = 0;
      this.setState(
        (prevState) => ({
          parentId: +nextProps.match.params.subCategory,
          selectedHierarchy: this.state.categoryList
            .filter((cat) => cat.id === +nextProps.match.params.subCategory)
            .map((cat) => cat.name),
          categoryList: prevState.categoryList.map((cat) => {
            if (cat.id !== +nextProps.match.params.subCategory) {
              cat.childCategoryList = [];
            }
            return cat;
          }),
        }),
        () => {
          this.fetchChildCategory(
            nextProps.match.params.category,
            this.state.parentId
          );
          this.fetchProducts(nextProps.match.params.category, true);
        }
      );
      window.scrollTo(0, 0);
    }
  }

  async addCategoriesById(category, categoryId, catList) {

    // category --> parent category
    // categoryId --> parentId
    // catList --> child categories of select category

    let isUpdated = false;
    for (let childCat of category.childCategoryList) {
      if (childCat.id === categoryId) {
        this.setState({ selectedCategory: childCat });
        childCat.childCategoryList = [...catList];
        isUpdated = true;
        category.childCategoryList.map((cat) => {
          if (cat.id !== categoryId) {
            cat.childCategoryList = [];
          }
          return cat;
        });
        break;
      } else {
        isUpdated = await this.addCategoriesById(childCat, categoryId, catList);
        if (isUpdated) {
          break;
        }
      }
    }
    return isUpdated;
  }

  async fetchChildCategory(parentSlug, parentId, categoryName) {
    const result = await this.props.getChildCategoryList(parentSlug, parentId);

    if (parentId !== 0) {
      this.setState(
        {
          categoryCards: [...result?.data],
        },
      );
    }
    // this.setState({
    //   items: [...result.productList]
    // })
    if (!result) {
      return null;
    }
    const catList = result.data.map((i) => ({ ...i, childCategoryList: [] }));
    if (parentId === 0) {
      this.setState({
        categoryList: catList,
        selectedHierarchy: catList
          .filter((cat) => cat.id === this.state.parentId)
          .map((cat) => cat.name),
      });
      return;
    } else {
      let categoryList = [...this.state.categoryList];
      for (let category of categoryList) {
        if (category.id === parentId) {
          this.setState({
            selectedCategory: category,
          });
          category.childCategoryList = [...catList];
          break;
        } else {
          const isUpdated = await this.addCategoriesById(
            category,
            parentId,
            catList
          );
          if (isUpdated) {
            break;
          }
        }
      }
      this.setState({
        categoryList: [...categoryList],
      });
    }
  }
  async fetchProducts(category, firstLoad, optionalHierarchyKey = "") {
    if (this.start !== this.prevStart || firstLoad) {
      let hierarchyArray = this.state?.selectedHierarchy?.map((i) =>
        i?.replace(/ /g, "_")
      );
      const urlParams = new URLSearchParams(window.location.search);
      const paramName = [urlParams.get('cat')]?.map((i) => 
      i?.replace(/ /g, "_"));
      await this.props.getProductListByCategoryWithHierarchy(
        category,
        this.start,
        this.limit,
        this.state.sort_type,
        hierarchyArray[0] == null ? paramName : hierarchyArray
      );

      this.prevStart = this.start;
      this.start += this.limit;
      let stateData = this.state;

      let productData = stateData.items;
      if (this.props.productList.length > 0) {
        if (stateData.items.length === 0) {
          productData = this.props.productList;
        } else {
          for (const tempProd of this.props.productList) {
            productData.push(tempProd);
          }
        }
      }

      let stateUpdate = this.state;
      stateUpdate.isLoading = false;

      stateUpdate.items = productData;
      if (this.props.productList.length < this.limit) {
        stateUpdate.hasMore = false;
      }
      this.setState({
        ...stateUpdate,
      });
    }
  }

  fetchMoreData = async () => {
    this.fetchProducts(this.props.match.params.category, false);
  };

  onCategoryClicked = async (categoryId, categoryName, hierarchyIndex) => {
    if (this.state.isCategoryDisabled) {
      return;
    }
    this.start = 0;
    // this.props.updateProductListAction([])
    this.setState({
      items: [],
      hasMore: true,
      isCategoryDisabled: true,
    });
    await this.fetchChildCategory(
      this.props.match.params.category,
      categoryId,
      categoryName
    );
    let hierarchyArray = [...this.state.selectedHierarchy];
    if (hierarchyArray.length + 1 === hierarchyIndex) {
      hierarchyArray.push(categoryName);
    } else if (hierarchyArray.length > hierarchyIndex) {
      hierarchyArray.length = hierarchyIndex;
      hierarchyArray.push(categoryName);
    } else if (hierarchyArray.length === hierarchyIndex) {
      hierarchyArray[hierarchyIndex] = categoryName;
    }
    this.setState({
      selectedHierarchy: hierarchyArray,
    });
    await this.fetchProducts(this.props.match.params.category, true);
    this.setState({
      isCategoryDisabled: false,
    });
  };

  handleProductSorting = async (event, category, isDropDown) => {
    this.limit = 10;
    this.start = 0;
    this.prevStart = 0;
    let event_value;
    if (isDropDown)
    event_value = event;
    else{
      event.preventDefault();

      event_value = event.target.value;
    }
    let hierarchyArray = this.state.selectedHierarchy.map((i) =>
      i.replace(/ /g, "_")
    );
    await this.props.getProductListByFilternameCategoryAndHierarchy(
      this.props.match.params.filterName,
      category,
      this.start,
      this.limit,
      event_value,
      hierarchyArray
    );
    await this.setState({
      sort_type: event_value,
      items: this.props.productList,
      hasMore: true,
    });
    this.prevStart = this.start;
    this.start += this.limit;
  };

  addCatListHtml(catList, category, hierarchyLevel) {
    catList.push(
      <ul key={category.id} className="child-list-access">
        <li>
          <a
            key={category.id}
            onClick={() =>
              this.onCategoryClicked(category.id, category.name, hierarchyLevel)
            }
          >
            {category.name}
          </a>
          {this.renderChild(category.childCategoryList)}
        </li>
      </ul>
    );
    if (!category.childCategoryList) {
      return;
    }

    for (let child of category.childCategoryList) {
      this.addCatListHtml(catList, child, hierarchyLevel + 1);
    }
    return null;
  }

  showChildCategories(categoryList, hierarchyLevel) {
    return (
      <ul className="child-list-access ">
        {categoryList.map((category, index) => {
          return (
            <li key={index} className="sub-changes-a">
            <div className="sub-border-list-show">
            {(() => {
              let nbsp = ""
                for (let i = 0; i <  hierarchyLevel; i++){
                
                  nbsp=nbsp+ String.fromCharCode(160)+ String.fromCharCode(160)
} return nbsp ;
      })()}
            { this.state.selectedHierarchy.includes(category.name) && 
              category.childCategoryList.length || category.name ===this.state.selectedHierarchy[this.state.selectedHierarchy?.length - 1] && 
              category.childCategoryList.length ? 
               <i className="fa fa-minus" aria-hidden="true"
                  onClick={() =>
                    { let hierarchyArray = [...this.state.selectedHierarchy];
                      hierarchyArray.pop(category.name)
                        this.setState({
                          selectedHierarchy:hierarchyArray 
                        })
                    }
                  } /> :
                  !this.state.selectedHierarchy.includes(category.name) 
                  // && category.child_count 
                  ?
              <i className="fa fa-plus" style={{cursor: "pointer"}} aria-hidden="true"
              onClick={() =>
                this.onCategoryClicked(
                  category.id,
                  category.name,
                  hierarchyLevel
                )
              } /> : <i className="fa fa-minus" aria-hidden="true"></i>
              // <span className="blankSpace"></span>
            }
              <a className={ `
                ${(hierarchyLevel%2 !==0
                // category.child_count > 0
                ) ?
                   "colorDark" : "haschild"}
                ${(this.state.selectedHierarchy.includes(category.name) && !(category.name === this.state.selectedHierarchy[this.state.selectedHierarchy?.length - 1]))  ? "parentActive" : ""}
                `}
                key={category.id}
                onClick={() =>
                  this.onCategoryClicked(
                    category.id,
                    category.name,
                    hierarchyLevel
                  )
                }
              >
                {category.name}
              </a>
              </div>
              {category.childCategoryList?.length > 0 && this.state.selectedHierarchy.includes(category.name) &&
                this.showChildCategories(
                  category.childCategoryList,
                  hierarchyLevel + 1
                )}
            </li>
          );
        })}
      </ul>
    );
  }

  async handleClickOnLink(categoryName) {
    if (this.state.isCategoryDisabled) {
      return;
    }
    categoryName.replace(/ /g, "_");
    this.setState(
      {
        selectedHierarchy: [categoryName],
        items: [],
        hasMore: true,
        isCategoryDisabled: true,
      },
      async () => {
        await this.fetchProducts(this.props.match.params.category, true);
      }
    );
    this.start = 0;
    await this.fetchChildCategory(this.props.match.params.category, 0).then(
      async (value) => {
        await this.fetchChildCategory(
          this.props.match.params.category,
          this.state.parentId
        );
      }
    );
    this.setState({
      isCategoryDisabled: false,
    });
  }

  render() {

    const categoryList = (
      <div className="col-lg-3 col-md-4">
        <div className="refine-sidebar">
          <div className="refine-sidebar-heading">
            <h2>Refine Your Search</h2>
          </div>
          <div className="refine-sidebar-box">
            <div className="refine-sidebar-top">
              <h3>Category</h3>

              <Link
                to={`/product-list/category/${this.props.match.params.category}`}
              >
                See All
              </Link>
            </div>
            <div className="refine-sidebar-mid">
              <ul>
                {this.state.categoryList.length > 0
                  ? this.state.categoryList
                      .filter(
                        (item) => item.name === this.state.selectedHierarchy[0]
                      )
                      .map((category, index) => {
                        return (
                          <li>
                            <div className="first-category-child">
                            <i className="fa fa-minus" style={{cursor:"pointer"}} aria-hidden="true"
                            onClick={()=>{
                              this.props.history.push(`/product-list/category/${this.props.match.params.category}`);
                            }}
                            />
                            <Link
                              className={`${
                                category.name ===
                                this.state.selectedHierarchy[0]
                                  ? "active"
                                  : null
                              } `}
                              to={{
                                pathname: `/product-list/category/${this.props.match.params.category}`,
                              }}
                              onClick={() =>
                                this.handleClickOnLink(category.name)
                              }
                              
                            >
                              {category.name}
                            </Link>
                            </div>
                            {category.childCategoryList.length > 0 &&
                              this.showChildCategories(
                                category.childCategoryList,
                                1
                              )}
                          </li>
                        );
                      })
                  : ""}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );

    const products =
      this.state.items.length > 0 ? (
        this.state.items.map((item, index) => {
          
          if (index >= 0 && index < 6){
            let isInStock = !item?.isOutOfStock
            // let isInStock =
            // item.totalsubscription + item.oneTimeOrder <
            // item.order_limit;
  
            // if (
            //   item?.timeFrame &&
            //   item.timeFrame.includes("weekly")
            // ) {
            //   isInStock =
            //     Math.floor(
            //       (item.order_limit -
            //         (item.totalsubscription +
            //           item.oneTimeOrder)) /
            //       4
            //     ) > 0;
            // }

            return (
              <div className="col-md-4 col-6" key={index}>
                <div className="home-product-item">
                  {item.is_sale !== 0 ? (
                    item.price_type === 1 ? (
                      <div className="sale-label-star">
                        ${item.sale_price} off
                      </div>
                    ) : (
                      <div className="sale-label-star">
                        {" "}
                        {Math.round(100 * (1 - item.sale_price / item.price))}%
                        off
                      </div>
                    )
                  ) : null}
                  {!item.isAccessCategoryAvailable && item.is_shipping_free ? (
                    <div to="/" className="shipping-label-star">
                      free shipping
                    </div>
                  ) : null}
                  <Link
                    to={{
                      pathname: `/product-list/${this.props.match.params.category}/${this.props.match.params.subCategory}/${item.slug}`,
                      product: item,
                      state: { spuId: item.spu_id },
                    }}
                  >
                    <div className="home-product-box">
                      <img src={item.imageName} />
                      <Link
                        to={{
                          pathname: `/product-list/${this.props.match.params.category}/${this.props.match.params.subCategory}/${item.slug}`,
                          product: item,
                          state: { spuId: item.spu_id },
                        }}
                        className="quickviewbtn"
                      >
                                              {(isInStock || item?.isAccessCategoryAvailable) ?
                      <div className="quickviewbtn">
                        <p>Quick View</p>
                      </div>
                      :
                      <div className="quickviewbtn">
                      <p>Sold Out</p>
                    </div>

                      }
                      </Link>
                      {/* <a href="#" className="quickviewbtn">
                                        <p>Quick View</p>
                                    </a> */}
                    </div>
                    <div className="home-productbox-detail">
                      <div className="home-productbox-heading">
                        <div className="price-inline-remove">
                          <h2 title={item.name ? item.name : null}>
                            {item.name ? item.name : `-`}
                          </h2>
                          {item.is_sale ? (
                            <p>
                              <del>
                                {item.price
                                  ? `$${item.price
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                  : `-`}
                              </del>
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <p className="product-price-mobile">
                    {item.is_sale !== 0
                            ? item.price_type === 1
                              ? `$${(item.price - item.sale_price)
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                              : `$${(
                                  item.price -
                                  (item.price *
                                    (100 *
                                      (1 - item.sale_price / item.price))) /
                                    100
                                )
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                            : `${!item.price ? `` : `$${item.price}`}`}
                             {item.is_sale ? (
                          <p>
                            <del>
                              {item.price
                                ? `$${item.price
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                : `-`}
                            </del>
                          </p>
                        ) : null}
                        </p>
                      <div className="home-productbox-bottom">
                        <div className="item-rating">
                          <Rating
                            name="hover-feedback"
                            value={item.rating}
                            precision={0.5}
                            readOnly
                          />
                        </div>
                        <div className="home-productbox-price">
                          <p>
                            {item.is_sale !== 0
                              ? item.price_type === 1
                                ? `$${(item.price - item.sale_price)
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                : `$${(
                                    item.price -
                                    (item.price *
                                      (100 *
                                        (1 - item.sale_price / item.price))) /
                                      100
                                  )
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                              : `${!item.price ? `` : `$${item.price}`}`}
                          </p>
                        </div>
                      </div>
                      {!item.isAccessCategoryAvailable && item.is_shipping_free ? (
                    <p className="shipping-mobile-label">FREE SHIPPING</p>
                    ) : null}
                    </div>
                  </Link>
                </div>
              </div>
            );
          }
        })
      ) : (
        <div></div>
      );
    return (
      <div>
        <main className="mainContainer">
          <section className="home-product-section pb-5 mt-4">
            <div className="container">
              {/* <div className="product-list-head">
                                <ul>
                                    <li><a href="javascript:;">weekly</a></li>
                                    <li><i className="fas fa-minus"></i></li>
                                    <li><a href="javascript:;">all</a></li>
                                </ul>
                            </div> */}

              <div className="mb-3">
                <div className="row">
                  <div className="col-md-3">
                    <div className="home-section-heading mobile-heading-manage">
                      <p>
                        <strong>
                          {this.props.match.params.category
                            ? this.props.match.params.category.toUpperCase()
                            : null}
                        </strong>
                      </p>

                      <span data-toggle="dropdown">
                <i className="fas fa-sliders-h" />
              </span>

              <div className="dropdown-menu dropdown-menu-right" 
              onChange={(e) => this.handleProductSorting(e, this.props.match.params.category)
                }>
                <a className="dropdown-item" onClick={(e) => this.handleProductSorting("undefined", this.props.match.params.category,true)} href="#">
                  RANDOM
                </a>
                <a className="dropdown-item" onClick={(e) => this.handleProductSorting("price_h", this.props.match.params.category,true)} href="#">
                  PRICE HIGH TO LOW
                </a>
                <a className="dropdown-item" onClick={(e) => this.handleProductSorting("price_l", this.props.match.params.category,true)} href="#">
                  PRICE LOW TO HIGH
                </a>
                <a className="dropdown-item" onClick={(e) => this.handleProductSorting("rating", this.props.match.params.category,true)} href="#">
                  TOP RATED
                </a>
              </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <OwlCarousel
                      className="owl-theme-suggestion"
                      loop={false}
                      margin={5}
                      nav
                      dots={false}
                      {...options}
                    >
                      {this.state.categoryCards.length > 0 &&
                        this.state.categoryCards.map((i) => {
                          return (
                            <div className="suggestion-tab item">
                              <p
                                onClick={() =>
                                  this.onCategoryClicked(
                                    i.id,
                                    i.name,
                                    this.state.selectedHierarchy.length
                                  )
                                }
                              >
                                {i.name}
                              </p>
                            </div>
                          );
                        })}
                    </OwlCarousel>
                  </div>

                  <div className="col-md-3 text-right">
                    <select
                      value={this.state.sort_type}
                      onChange={(e) =>
                        this.handleProductSorting(
                          e,
                          // !this.props.match.params.subCategory ? this.props.match.params.category : this.props.match.params.subCategory
                          this.props.match.params.category
                        )
                      }
                    >
                      <option value="undefined">Random</option>
                      <option value={"price_h"}>Price High to Low</option>
                      <option value={"price_l"}>Price Low to High</option>
                      <option value={"rating"}>Top Rated</option>
                    </select>
                  </div>
                </div>
              </div>

              <div>
                <InfiniteScroll
                  dataLength={this.state.items.length}
                  next={this.fetchMoreData}
                  hasMore={this.state.hasMore}
                  loader={(() => {
                    if (this.state.isLoading) {
                      return <div className="loaing-area">Loading...</div>;
                    }
                  })()}
                  scrollThreshold="500px"
                >
                  <div className="row">
                    {categoryList}
                    <div className="col-lg-9 col-md-8">
                      <div className="home-section-heading mb-3">
                        <ul>
                          <li>
                            {/* <h2>{this.state.selectedSubCategory}</h2> */}
                            <h2>
                              {this?.state?.selectedHierarchy
                                ? this.state.selectedHierarchy[
                                    this.state.selectedHierarchy.length - 1
                                  ]
                                : null}
                            </h2>
                          </li>
                        </ul>
                      </div>
                      <div className="row">{products}</div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="row">
                        {this.state.items.length > 6 &&
                          this.state.items.map((item, index) => {

                            
                            if (index > 5) {
                              let isInStock = !item?.isOutOfStock
                              return (
                                <div className="col-md-3 col-6" key={index}>
                                  <div className="home-product-item">
                                    {item.is_sale !== 0 ? (
                                      item.price_type === 1 ? (
                                        <div className="sale-label-star">
                                          ${item.sale_price} off
                                        </div>
                                      ) : (
                                        <div className="sale-label-star">
                                          {" "}
                                          {Math.round(
                                            100 *
                                              (1 - item.sale_price / item.price)
                                          )}
                                          % off
                                        </div>
                                      )
                                    ) : null}
                                    {!item.isAccessCategoryAvailable && item.is_shipping_free ? (
                                      <div
                                        to="/"
                                        className="shipping-label-star"
                                      >
                                        free shipping
                                      </div>
                                    ) : null}
                                    <Link
                                      to={{
                                        pathname: `/product-list/${this.props.match.params.category}/${this.props.match.params.subCategory}/${item.slug}`,
                                        product: item,
                                        state: { spuId: item.spu_id },
                                      }}
                                    >
                                      <div className="home-product-box">
                                        <img src={item.imageName} />
                                        <Link
                                          to={{
                                            pathname: `/product-list/${this.props.match.params.category}/${this.props.match.params.subCategory}/${item.slug}`,
                                            product: item,
                                            state: { spuId: item.spu_id },
                                          }}
                                          className="quickviewbtn"
                                        >
                                                                                {(isInStock || item?.isAccessCategoryAvailable) ?
                                      <div className="quickviewbtn">
                                        <p>Quick View</p>
                                      </div>
                                      :
                                      <div className="quickviewbtn">
                                      <p>Sold Out</p>
                                    </div>

                                      }
                                        </Link>
                                        {/* <a href="#" className="quickviewbtn">
                                        <p>Quick View</p>
                                    </a> */}
                                      </div>
                                      <div className="home-productbox-detail">
                                        <div className="home-productbox-heading">
                                          <div className="price-inline-remove">
                                            <h2
                                              title={
                                                item.name ? item.name : null
                                              }
                                            >
                                              {item.name ? item.name : `-`}
                                            </h2>
                                            {item.is_sale ? (
                                              <p>
                                                <del>
                                                  {item.price
                                                    ? `$${item.price
                                                        .toFixed(2)
                                                        .replace(
                                                          /\B(?=(\d{3})+(?!\d))/g,
                                                          ","
                                                        )}`
                                                    : `-`}
                                                </del>
                                              </p>
                                            ) : null}
                                          </div>
                                        </div>
                                        <div className="home-productbox-bottom">
                                          <div className="item-rating">
                                            <Rating
                                              name="hover-feedback"
                                              value={item.rating}
                                              precision={0.5}
                                              readOnly
                                            />
                                          </div>
                                          <div className="home-productbox-price">
                                            <p>
                                              {item.is_sale !== 0
                                                ? item.price_type === 1
                                                  ? `$${(
                                                      item.price -
                                                      item.sale_price
                                                    )
                                                      .toFixed(2)
                                                      .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                      )}`
                                                  : `$${(
                                                      item.price -
                                                      (item.price *
                                                        (100 *
                                                          (1 -
                                                            item.sale_price /
                                                              item.price))) /
                                                        100
                                                    )
                                                      .toFixed(2)
                                                      .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                      )}`
                                                : `${!item.price ? `` : `$${item.price}`}`}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              );
                            }
                          })}
                      </div>
                    </div>
                  </div>
                </InfiniteScroll>
              </div>
            </div>
          </section>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    categoryId: state.productReducer.categoryId,
    categoryName: state.productReducer.categoryName,
    productList: state.productReducer.productListByCategory,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getProductListByCategoryWithHierarchy,
      getProductListByFilternameCategoryAndHierarchy,
      getChildCategoryList,
      // updateProductListAction
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Product_SubCategory_List)
);
