import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const createMessage = (data) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/message/customer/createMessage`,
      data,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getMessagesByBuyerId = (buyer_id, offset) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/message/customer/getMessagesByBuyerId/${buyer_id}?offset=${offset}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getMessageCountByBuyerId = (buyerId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/message/customer/getMessagesCountByBuyerId/${buyerId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    dispatch({
      type: "UNREAD_MESSAGE_COUNT",
      payload: { data: res.data },
    });
    return res.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getMessagesByConversationId =
  (message_id, buyer_id, offset) => async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/message/customer/getMessagesByConversationId/${message_id}/${buyer_id}?offset=${offset}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      dispatch({ type: "LOADING_COMPLETED" });
      return res.data;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const searchMessages = (key, buyerId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/message/customer/search?buyerId=${buyerId}&key=${key}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    dispatch({
      type: "UNREAD_MESSAGE_COUNT",
      payload: { data: res.data },
    });
    return res.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getMessageCount = (buyerId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/message/customer/getMessageCount/${buyerId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    dispatch({
      type: "UNREAD_VENDOR_MESSAGE_COUNT",
      payload: { data: res.data },
    });
    return res.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};