import React from "react";

export const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <g opacity="0.6">
        <mask
          id="mask0_349_701"
          maskUnits="userSpaceOnUse"
          x="2"
          y="2"
          width="20"
          height="20"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.5 2.5H21.9768V21.977H2.5V2.5Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_349_701)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.2388 4C7.69579 4 3.99979 7.695 3.99979 12.238C3.99979 16.781 7.69579 20.477 12.2388 20.477C16.7808 20.477 20.4768 16.781 20.4768 12.238C20.4768 7.695 16.7808 4 12.2388 4ZM12.2388 21.977C6.86879 21.977 2.49979 17.608 2.49979 12.238C2.49979 6.868 6.86879 2.5 12.2388 2.5C17.6088 2.5 21.9768 6.868 21.9768 12.238C21.9768 17.608 17.6088 21.977 12.2388 21.977Z"
            fill="#222222"
          />
        </g>
        <mask
          id="mask1_349_701"
          maskUnits="userSpaceOnUse"
          x="17"
          y="18"
          width="6"
          height="6"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.74 18.207H22.764V23.2218H17.74V18.207Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_349_701)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M22.0142 23.2218C21.8232 23.2218 21.6312 23.1488 21.4842 23.0028L17.9602 19.4888C17.6672 19.1958 17.6662 18.7208 17.9592 18.4278C18.2512 18.1328 18.7262 18.1348 19.0202 18.4258L22.5442 21.9408C22.8372 22.2338 22.8382 22.7078 22.5452 23.0008C22.3992 23.1488 22.2062 23.2218 22.0142 23.2218Z"
            fill="#222222"
          />
        </g>
      </g>
    </svg>
  );
};
