import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { unsubscribeNewsLetter } from '../../Redux/Actions/userAction';

class Unsubscribe_Newsletter_Confirmation extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showLoader: true,
            msg: ""
        }
        const token = this.props.location.search.split("=");
        this.callUnsubscribeApi(token[1])
    }

    callUnsubscribeApi = async (token) => {
        const res = await this.props.unsubscribeNewsLetter(token);

        if (res && res.data && res.data.isSuccess) {
            this.setState({
                showLoader: false,
                msg: <div style={{ height: "300px", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "35px" }}>
                    You are now unsubscribed to our Newsletter
                    <span style={{ marginLeft: "10px" }}><i className="far fa-frown"></i></span>
                </div>
            })
        }
        else {
            this.setState({
                showLoader: false,
                msg: <div style={{ height: "300px", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "35px" }}>
                    Something went wrong
                </div>
            })
        }



        // this.setState({
        //     showLoader: false
        // })
    }


    render() {
        return (
            <div>
                {(() => {
                    if (this.state.showLoader) {
                        return (
                            <div style={{
                                height: "300px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <span style={{ width: "50px", height: "50px" }} className="spinner-border spinner-border-sm ml-1"></span>
                            </div>
                        )
                    }
                    else {
                        return (
                            this.state.msg
                        )
                    }
                })()}

            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ unsubscribeNewsLetter }, dispatch);
}

export default connect(null, mapDispatchToProps)(Unsubscribe_Newsletter_Confirmation)