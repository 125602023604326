import { fromPairs } from "lodash";
import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import {
  getWishlist,
  deleteWishlistItem,
} from "../../Redux/Actions/wishlistAction";
import { addToCart } from "../../Redux/Actions/cartAction";
import { getWishlistProductPrice } from "../../Redux/Actions/productAction";
import axios from "axios";

class Wishlist extends Component {
  userId = null;

  itemPriceObject = {};
  constructor(props) {
    super(props);
    this.state = {
      itemToRemove: null,
      itemToRemoveIndex: null,
      wishlistitems: [],
      selectedPair: {},
      itemVarientPrice: 0,
      discounted_Percentage: 0,
      customizationImage : '',
      disableBtn: false
    };
  }

  async componentDidMount() {
    this.userId = localStorage.getItem("userId");
    await this.getWishlistData();
  }

  getWishlistData = async () => {
    const userwishlistitem = await this.props.getWishlist(this.userId);
    let tempArr = [];
    let tempAdditionalInfo = [];
    if (userwishlistitem?.data?.data?.length > 0) {
      for (let i in userwishlistitem.data.data) {
        let response = await this.getProductPrice(
          userwishlistitem.data.data[i]
        );

        if (Object.keys(userwishlistitem.data.data[i].variation_combination).length > 0) {
          userwishlistitem.data.data[i]['variationPrice'] = await this.getVariationPrice(JSON.parse(userwishlistitem.data.data[i].variation_combination), userwishlistitem.data.data[i].product_id)
        }
        tempArr.push(response);
        tempAdditionalInfo.push(JSON.parse(userwishlistitem.data.data[i].additional_information))
      }
    }
    this.setState({
      wishlistitems: tempArr,
      additionalInfo: tempAdditionalInfo
    });
  };

  getProductPrice = async (item) => {
    const parsedVariationCombination = JSON.parse(item.variation_combination);
    item["selectedPair"] = parsedVariationCombination;
    const objectSize = Object.keys(parsedVariationCombination);
    if (objectSize.length > 0) {
      const priceItem = await this.props.getWishlistProductPrice(
        parsedVariationCombination,
        item.product_id
      );
      let discountPercent = (1 - (item.sale_price / item.price)) * 100
      this.setState({
        itemVarientPrice: priceItem.data.data.data.price,
        discounted_Percentage: !discountPercent || isNaN(discountPercent) ? 0 : discountPercent.toFixed(2)
      })
      // item.price = priceItem.data.data.data.price;
      return item;
    } else {
      let value = (item.price -
        (item.price * (100 * (1 - item.sale_price / item.price))) / 100);
      item.sale_price = Math.round(value);   
      return item;
    }
  };

  handleItemRemove = async (item, itemIndex) => {
    if (!item || !item.wishlist_id){
      return null;
    }
    await this.props.deleteWishlistItem(this.userId, item.wishlist_id);
    this.getWishlistData();
    this.closeModal();
  };

  closeModal = () => {
    this.setState({
      itemToRemove: null,
      itemToRemoveIndex: null,
    });
  };

  openModal = (item, index) => {
    this.setState({
      itemToRemove: item,
      itemToRemoveIndex: index,
    });
  };

  moveToCart = async (item, index) => {
    if (this.state.disableBtn) return;
    this.setState({
      disableBtn: true
    })
    let price = item[`price`]
    if(item.variationPrice?.data?.data?.data?.price){
      price = item.variationPrice.data.data.data.price
    }
    const data = {
      ...item,
      paymentOptionType: item.payment_option_type,
      actualPrice: this.state.itemVarientPrice > 0 && item.variationPrice?.data?.data?.data?.price  ? item.variationPrice.data.data.data.price : (item[`price`]),
      selectedQuantity: 1,

      price:
          item.is_sale == 0
              ? price
              : item["price_type"] == 1
              ? parseFloat(item[`price`] - item[`sale_price`])
              : ((this.state.itemVarientPrice > 0) && (item.is_variant === 1)) ?
                  (item.variationPrice.data.data.data.price - (item.variationPrice.data.data.data.price * (this.state.discounted_Percentage / 100))).toFixed(2)
                  : (item[`price`] - (item[`price`] *
                      (100 * (1 - item[`sale_price`] / item[`price`]))) /
                      100).toFixed(2),
    }
    if(item.additional_information && item.additional_information.toString().trim().length > 2){
      const additionalInfo = JSON.parse(item.additional_information)
      if(additionalInfo.customizationNote){
        data['customizationNote'] = additionalInfo.customizationNote;
        data['isCustomizeCategoryAvailable'] = 1
      }if(additionalInfo.customizationImage){
        const response = await axios({
          url: additionalInfo.customizationImage,
          method: 'GET',
          responseType: 'blob', // important
        })
        const base64 = await this.convertBlobToBase64(response.data)
        data['customizationImage'] = base64;
        data['isCustomizeCategoryAvailable'] = 1
      }
    }
    await this.props.addToCart(JSON.parse(item.additional_information), 1);
    // await this.props.addToCart(data, 1);
    const response = await this.props.deleteWishlistItem(
      this.userId,
      item.wishlist_id
    );

    if (response?.data?.data?.affectedRows > 0) {
      let addTocartitem = this.state.wishlistitems;
      addTocartitem.splice(index, 1);
      this.setState({
        wishlistitems: addTocartitem,
      });
    }
    this.setState({
      disableBtn: false
    })
  };

  convertBlobToBase64 = async (file) =>  new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = (event) => {
        resolve(event.currentTarget.result)
      }
      fileReader.readAsDataURL(file);
    })


  getVariationPrice = async (variationCombination, product_id) => {
    var priceItem = await this.props.getWishlistProductPrice(
      variationCombination,
      product_id
    )
    return priceItem;
  }

  render() {
    const wishlistitems =
      this.state.wishlistitems && this.state.wishlistitems.length > 0
        ? this.state.wishlistitems.map((item, index) => {
          const productTimeFrame = item?.payment_option_type !== "recurring"
            ? item.payment_option_type
            : item.timeFrame.split(",")[1];
          const date = new Date(item.created_ts);
          var options = {
            year: "numeric",
            month: "2-digit",
            day: "numeric"
          };
          const variationCombination = JSON.parse(item.variation_combination);
          const selectedPairKeys = variationCombination
            ? Object.keys(variationCombination)
            : [];
          let price = item.price ? item.price : this.state.additionalInfo[index].price
        if(item.variationPrice && item.variationPrice?.data?.data?.data?.price){
          price = item.variationPrice.data.data.data["price"]
        }
          return (
            <div className="cart-box-body" key={index}>
              <div className="cart-box-detail">
                <div className="cart-box-img">
                  <img src={item.imageName} />
                </div>
                <div className="cart-box-item-detail">
                <h2><Link to={"product/"+ item.slug} style={{color:"#000000"}}>{item.name}</Link></h2>
                  {selectedPairKeys.length > 0
                    ? selectedPairKeys.map((variant) => {
                      return variationCombination &&
                        variationCombination[variant] ? (
                        <p key={variant}>
                          <span>{variant.replace("_", " ")}: </span>
                          {variationCombination[variant]}
                        </p>
                      ) : null;
                    })
                    : null}

                  <p>
                    <span>Frequency: </span>
                    <span style={{ display: "inline" }}>{(productTimeFrame.search("_") > -1) ? productTimeFrame.replace("_", " ")  : productTimeFrame}</span>
                  </p>
                </div>
              </div>

              <div className="add-on-date"> <p style={{ marginBottom: "0px" }}>Added on :</p>
                <span>{(date.toLocaleDateString("en-IN", options))}</span>
              </div>
              <div className="cart-box-item-price">
                <p>
                  {item.is_sale !== 0
                    ? item.price_type === 1
                      ? `$${item.price -
                      item.sale_price
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }`
                      : (((this.state.itemVarientPrice > 0) && (item.is_variant === 1))
                        ? `$${(item.variationPrice.data.data.data.price - (item.variationPrice.data.data.data.price * (this.state.discounted_Percentage / 100))).toFixed(2)}`
                        : `$${(
                          item.price -
                          (item.price *
                            (100 * (1 - item.sale_price / item.price))) /
                          100
                        )
                          .toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`)
                    : `${price
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                </p>
                <a
                  onClick={() => this.moveToCart(item, index)}
                >
                  Add to Cart
                </a>
                <br />
                <a
                  onClick={() => this.openModal(item, index)}
                  data-toggle="modal"
                  data-target="#exampleModal"
                >
                  Remove from Wishlist
                </a>
              </div>
            </div>
          );
        })
        : null;

    return (
      <div>
        <main className="mainContainer">
          <section className="home-product-section mt-4">
            <div className="container">
              <div className="select-product-list">
                <div className="product-list-head product-list-border-bottom">
                  <ul>
                    <li>
                      <Link to={"/"}>home</Link>
                    </li>
                    <li>
                      <i className="fas fa-minus"></i>
                    </li>
                    <li>
                      <a>wishlist</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section className="cart-section">
            <div className="container">
              <div className="cart-section-head">
                <h2>Wishlist</h2>
                <p>
                  You have{" "}
                  {wishlistitems
                    ? wishlistitems.length > 1
                      ? `${wishlistitems.length} items`
                      : `${wishlistitems.length} item`
                    : "0 item"}{" "}
                  in your wishlist
                </p>
              </div>

              <div className="cart-section-body">
                {wishlistitems}

                <div
                  className="modal fade"
                  id="exampleModal"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Confirm
                        </h5>
                        <button
                          type="button"
                          onClick={this.closeModal}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        Are you sure to remove{" "}
                        {this.state.itemToRemove ? (
                          <b style={{wordBreak:"break-word"}}>{this.state.itemToRemove.name}</b>
                        ) : (
                          "item"
                        )}{" "}
                        from the wishlist?
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() =>
                            this.handleItemRemove(
                              this.state.itemToRemove,
                              this.state.itemToRemoveIndex
                            )
                          }
                          className="btn btn-primary"
                          data-dismiss="modal"
                        >
                          Yes
                        </button>
                        <button
                          type="button"
                          onClick={this.closeModal}
                          className="btn btn-secondary"
                          data-dismiss="modal"
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* {this.props.cartItems && cartItems.length > 0 ? (
                  <div className="cart-section-bottom">
                    <div className="checkout-btn">
                      <Link to={'/checkout'}>
                        <button type="button">checkout</button>
                      </Link>
                    </div>
                  </div>
                ) : (null)} */}
            </div>
          </section>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    product: state.productReducer.productDetail,
  };
};

const mapDispatchToProsp = (dispatch) => {
  return bindActionCreators(
    { deleteWishlistItem, getWishlist, getWishlistProductPrice, addToCart },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProsp)(Wishlist);
