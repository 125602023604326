import React from "react";
import PrivateGuard from "./privateGuard";
import { Switch, Redirect, Route } from "react-router-dom";
import Home from "../Containers/home/home";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import Cart from "../Containers/Cart/Cart";
import Product_Filter_List from "../Containers/Product-Filter-List/Product_Filter_List";
import Filter_Product_List from "../Containers/Filter-Product-List/Filter_Product_List";
import Product_Category_List from "../Containers/Product-Category-List/Product_Category_List";
import Product_SubCategory_List from "../Containers/Product-SubCategory-List/Product_SubCategory_List";
import ProductDetail from "../Containers/Product-Detail/Product_Detail";
import Login from "../Containers/login/Login";
import SignUp from "../Containers/SignUp/signup";
import Profile from "../Containers/Profile/Profile";
import Order_List from "../Containers/Order-List/Order_List";
import Order_Detail from "../Containers/Order-Detail/Order_Detail";
import Order_Track from "../Containers/Order-Track/Order_Track";

import Subscription_List from "../Containers/Subscription/Subscription_List";
import Wishlist from "../Containers/WishList/Wishlist";
import ForgotPassword from "../Containers/ForgotPassword/ForgotPassword";
import ResetPassword from "../Containers/ResetPassword/ResetPassword";
import Checkout from "../Containers/Checkout/Checkout";
import OrderConfirmation from "../Containers/Order-Confirmation/Order_Confirmation";
import Unsubscribe_Newsletter_Confirmation from "../Containers/Unsubscribe-Newsletter-Confirmation/Unsubscribe_Newsletter_Confirmation";
import NotFound404 from "../Containers/not-found-404/not-found-404";
import About_Us from "../Containers/About-Us/About_Us";
import Change_Password from "../Containers/Change-Password/Change_Password";
import checkoutSubscription from "../Containers/Checkout-Subscription/checkoutSubscription";
import Blog from "../Containers/Blog/Blog";
import BlogList from "../Containers/Blog/BlogList";
import BlogDetail from "../Containers/Blog/BlogDetail";
import BlogCategoryFilter from "../Containers/Blog/BlogCategoryFilter";
import Message from "../Containers/Message/message";
import AccessProductDetails from "../Containers/Access-Product-Details/accessProductDetails";
import JoinZoomMeeting from "../Containers/ZoomMeeting/joinZoomMeeting";
import ZoomMeetingCalendar from "../Containers/Calendar/Calendar";
import GlobalCalendar from "../Containers/GlobalCalendar/GlobalCalendar";
import SellerStore from "../Containers/SellerStore/SellerStore";
import ShopByVendor from "../Containers/Shop-By-Vendor/ShopByVendor";
import ShopByVendorList from "../Containers/Shop-by-vendor-list/ShopByVendorList";
import Dispute from "../Containers/Dispute/Dispute";
import Dispute_List from "../Containers/Dispute-List/Dispute_List";
import oneOnOneMeetings from "../Containers/OneOnOneMeetings/oneOnOneMeetings";
import OneOnOneMeetingsDetail from "../Containers/OneOnOneMeetingsDetail/OneOnOneMeetingsDetail";
import Order_Return from "../Containers/Order-Return/Order_Return";
import Order_Return_List from "../Containers/Order-Return/Order_Return_List";
import New_Vendor from "../Containers/New-vendor/New-Vendor";
import VendorHeader from "../Components/VendorHeader/VendorHeader"


const WithHeaderAndFooter = ({ path, component, exact }) => {
  return (
    <div>
      <div className="PrivateArea__content">
      <VendorHeader /> 
        <div className="main_sec">
        <Route path={path} component={component} exact={exact} />
          <Footer />
        </div>
      </div>
    </div>
  );

}


class PrivateRoutes extends React.Component {
  render() {
    return (
      <div>
            {/* admin routes */}
            <Switch>
              <WithHeaderAndFooter path="/" component={Home} exact />
              <WithHeaderAndFooter path="/" component={Home} exact />
              <WithHeaderAndFooter path="/cart" component={Cart} exact />
              <WithHeaderAndFooter path="/checkout" component={Checkout} exact />
              <WithHeaderAndFooter path="/checkout/paynow" component={Checkout} exact />
              {/* <WithHeaderAndFooter path="/blog" component={Blog} exact />
              <WithHeaderAndFooter path="/blog-details/:slug" component={BlogDetail} exact />
              <WithHeaderAndFooter path="/blog-list" component={BlogList} exact /> */}
              <WithHeaderAndFooter
                path="/blog-category-filter/:category"
                component={BlogCategoryFilter}
                exact
              />
              <WithHeaderAndFooter
                path="/product-list/:category"
                component={Product_Filter_List}
                exact
              />
              <WithHeaderAndFooter
                path="/product-list/category/:category"
                component={Filter_Product_List}
                exact
              />

              <WithHeaderAndFooter
                path="/category/:category"
                component={Product_Category_List}
                exact
              />

              {/* <Route
                path='/category/:category/:productid'
                component={ProductDetail}
                exact
              /> */}

              <WithHeaderAndFooter
                path="/category/type/:filterName/:category/:productid"
                component={ProductDetail}
                exact
              />
              <WithHeaderAndFooter
                path="/one-on-one-meetings"
                component={oneOnOneMeetings}
                exact
              />
              <WithHeaderAndFooter
                path="/one-on-one-meetings/:meetingId"
                component={OneOnOneMeetingsDetail}
                exact
              />

              <WithHeaderAndFooter
                path="/category/type/:filterName/:category"
                component={Product_Category_List}
                exact
              />
              <WithHeaderAndFooter
                path="/category/search/:searchCategory"
                component={Product_Category_List}
                exact
              />

              <WithHeaderAndFooter
                path="/product-by/category/:category/:subCategory"
                component={Product_SubCategory_List}
                exact
              />
              <WithHeaderAndFooter
                path="/category/:filterName/:category"
                component={Product_Category_List}
                exact
              />

              <WithHeaderAndFooter
                path="/product-list/:category/:productid"
                component={ProductDetail}
                exact
              />
              <WithHeaderAndFooter
                path="/product-list/:category/:subCategory/:productid"
                component={ProductDetail}
                exact
              />
              <WithHeaderAndFooter
                path="/product/:productid"
                component={ProductDetail}
                exact
              />
              <WithHeaderAndFooter path="/store/:storeId/:vendorId" component={SellerStore} exact />
              <WithHeaderAndFooter
                path="/order/:orderId/summary"
                component={OrderConfirmation}
                exact
              />

              <WithHeaderAndFooter path="/page/:pageSlug" component={About_Us} exact />

              <WithHeaderAndFooter
                path="/unsubscribe"
                component={Unsubscribe_Newsletter_Confirmation}
                exact
              />
              <WithHeaderAndFooter path="/wishlist" component={Wishlist} exact />


              <WithHeaderAndFooter
                path="/shop-by-vendor"
                component={ShopByVendor}
                exact
              />


               <WithHeaderAndFooter
                path="/shop-by-vendor-list/:categorySlug"
                component={ShopByVendorList}
                exact
              />

              <WithHeaderAndFooter
                path="/vendor"
                component={New_Vendor}
                exact
              />









              <PrivateGuard path="/login" component={Login} exact />
              <PrivateGuard path="/signup" component={SignUp} exact />
              <PrivateGuard
                path="/forgot-password"
                component={ForgotPassword}
                exact
              />
              <PrivateGuard
                path="/reset-password"
                component={ResetPassword}
                exact
              />
              <PrivateGuard path="/profile" component={Profile} exact />

              <PrivateGuard
                path="/change-password"
                component={Change_Password}
                exact
              />

              <PrivateGuard path="/messages" component={Message} exact />
              <PrivateGuard path="/orders" component={Order_List} exact />
              <PrivateGuard path="/disputes" component={Dispute_List} exact />
              <PrivateGuard
                path="/subscription"
                component={Subscription_List}
                exact
              />
              <PrivateGuard
                path="/orders/:orderId"
                component={Order_Detail}
                exact
              />
              <PrivateGuard
                path="/request-order-return/:orderId"
                component={Order_Return}
                exact
              />
              <PrivateGuard
                path="/order-return-list"
                component={Order_Return_List}
                exact
              />
              <PrivateGuard
                path="/dispute/:orderId"
                component={Dispute}
                exact
              />

              <PrivateGuard
                path="/orders/track/:trackId"
                component={Order_Track}
                exact
              />
              <WithHeaderAndFooter
                path="/checkout-subscription"
                component={checkoutSubscription}
                exact
              />
              <PrivateGuard
                path="/access-product-details"
                component={AccessProductDetails}
                exact
              />
              <PrivateGuard
                path="/zoom-meeting/calendar"
                component={ZoomMeetingCalendar}
                exact
              />
              <PrivateGuard
                path="/zoom-meeting/join"
                component={JoinZoomMeeting}
                exact
              />
              <PrivateGuard
                path="/global-calendar"
                component={GlobalCalendar}
                exact
              />

              <WithHeaderAndFooter path="*" component={NotFound404} exact />
            </Switch>
          </div>
    );
  }
}

export default PrivateRoutes;
