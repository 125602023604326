import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import moment from 'moment';
import InfiniteScroll from "react-infinite-scroll-component";
import {
  createMessage,
  getMessagesByBuyerId,
  getMessagesByConversationId, searchMessages,
} from "../../Redux/Actions/messageAction";
import "../../Styles/profile.scss";
import Profile_Sidebar from "../Profile-Side-Bar/Profile_Sidebar";
import {isEmpty} from "lodash";
import {
  convertTime,
  fileFormats,
  getFileNameFromUrl,
  getFileSizeByByteLength,
  getShortName,
  getUrlByString
} from "../../Utils/helper";
import Axios from "axios";

const Message = (props) => {
  const [message, setMessage] = useState("");
  const [error, setError] = useState([]);
  const [files, setFiles] = useState([]);
  const [fileFormat, setFileFormat] = useState([]);
  const [show, setShow] = useState(false);
  const [messageConversationList, setMessageConversationList] = useState([]);
  const [activeMsgConversationId, setActiveMsgConversationId] = useState("");
  const [messageDetails, setMessageDetails] = useState([]);
  const [errorMessage, setErrorMessage] = useState({});
  const [messageHeader, setMessageHeader] = useState({
    messageSourceType: "",
    messageSourceId: "",
    sellerId: "",
    title: "",
    sellerStoreName: "",
    sellerTimezone: "",
    sellerAvailableFrom: "",
    sellerAvailableTo: "",
    sellerChatActive: "",
  });
  const [loadData, setLoadData] = useState(false);
  const [messageSubmitButton, setMessageSubmitButton] = useState(false);
  const [messageListOffSet, setMessageListOffSet] = useState(0);
  const [prevMessageListOffSet, setPreviousMessageListOffSet] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffSet] = useState(0);
  const [loadingData, setLoadingData] = useState(true)
  let { id: buyer_id, firstname } = JSON.parse(
    localStorage.getItem("userData")
  );
  const dispatch = useDispatch();
  const keepVerifyingRef = useRef(true);
  const [timeOutState, setTimeOutState] = useState(null);
  const [allowReload, setAllowReload] = useState(false);




  let fileUploadVideo = "";
  let fileUploadImage = "";
  let fileUploadDoc = "";
  let fileUploadAddMore = "";
  const messagesEndRef = useRef(null);
  let cleartime;
  let showData = 0;
  let allowScroll = true;
  let clearLoadMore;

  useEffect(() => {
    fetchMessages(true);
    const selectedConversation = messageConversationList.find(
      (item) => item.id === activeMsgConversationId
    );
    selectMessageConversation(selectedConversation)

  }, [loadData]);

  const fetchMessages = async (firstLoad) => {
    if (!activeMsgConversationId) {
      setMessageHeader({
        ...messageHeader,
        messageSourceType: props.location.state?.message_source_type,
        messageSourceId: props.location.state?.message_source_id,
        sellerId: props.location.state?.seller_id,
        title: props.location.state?.source_detail,
        sellerStoreName: props.location.state?.storename,
        sellerTimezone: props.location.state?.timezone,
        sellerAvailableFrom: props.location.state?.availableFrom,
        sellerAvailableTo: props.location.state?.availableTo,
        sellerChatActive: props.location.state?.sellerChatActive,
      });
    }
    else {
      setHasMore(false);
    }
    // setPreviousMessageListOffSet(0)
    // setMessageListOffSet(0)
    if (loadData) {
      setPreviousMessageListOffSet(0)
      setMessageListOffSet(0)
    }
    if (messageListOffSet !== prevMessageListOffSet || firstLoad || loadData) {

      // setHasMore(false)
      const res = await dispatch(getMessagesByBuyerId(buyer_id, messageListOffSet));
  

      if (res && res.length > 0) {

        setMessageConversationList([...(!loadData ? messageConversationList : []) , ...res]);
        // prevOffset: prevState.offset,
        // offset: prevState.offset + 10,
        setPreviousMessageListOffSet(messageListOffSet)
        setMessageListOffSet(prevMessageListOffSet + 10)
        const messageSourceType = props.location.state?.message_source_type;
        const messageSourceId = props.location.state?.message_source_id;
        let selectedConversationId = "";
        if (messageSourceType && messageSourceId) {
          const selectedConversation = res.find(
            (item) =>
              item.message_source_type === messageSourceType &&
              item.message_source_id === +messageSourceId
          );          
          if (selectedConversation?.id) {
            selectedConversationId = selectedConversation.id;
          }
          if (selectedConversation?.id && !activeMsgConversationId) {
            setActiveMsgConversationId(selectedConversation.id);
          }
        } else if (activeMsgConversationId) {
          selectedConversationId = activeMsgConversationId;
        }
        if (selectedConversationId) {
          await getMessageDetails(selectedConversationId);
        }
        if (!selectedConversationId && !props.location.state?.message_source_id) {
          await selectMessageConversation(res[0]);
        }
        scrollItem()
        // }
      } else {
        setHasMore(false)
      }
      setLoadData(false);
    }
  };

  const handleSubmit = async (e) => {
    const data = {
      messageConversationId: activeMsgConversationId,
      seller_id: messageHeader.sellerId,
      buyer_id,
      sender_type: "buyer",
      message,
      files,
      message_source_type: messageHeader.messageSourceType,
      message_source_id: messageHeader.messageSourceId,
    };
    let isValidFormat = 0;
    let totalMediaSize = 0;
    data.files.length && data.files.map((i) => {
      totalMediaSize += i.size
      if (!fileFormat.includes(i?.type.split("/")[1])){
        isValidFormat+=1;
      }
    })
    if(totalMediaSize > 19000000 && !isValidFormat){
      setErrorMessage({sizeError : "You can not upload more then 20 mb size"})
      setMessageSubmitButton(false);
    }
    else {
      e.preventDefault();
      if ((message.trim().length || files.length > 0)) {
        // if ((message.trim().length || files.length > 0) && activeMsgConversationId) {
        setMessageSubmitButton(true)
        setErrorMessage("")
        let res = await dispatch(createMessage(data));
        if (res?.data && res.data?.messageConversationId) {
          setMessage("");
          setPreviousMessageListOffSet(0)
          setMessageListOffSet(0)
          setFiles([]);
          setLoadData(true);
          setActiveMsgConversationId(res.data.messageConversationId);
          scrollItem();
          setLoadingData(true);
          setMessageSubmitButton(false)
        }
      }
    }
  };

  const getMessageDetails = async (id) => {
    let res = await dispatch(getMessagesByConversationId(id, buyer_id, offset));
    if (res) {
      for (const messageArray of res) {
        if(messageArray.messageAttachmentList){
          for (const item of messageArray.messageAttachmentList) {
            if(item.attachmentName){
            let retrunedItem = await fetch(item.attachmentName, {
              method: "GET",
              headers: {}
            })
              .then(async(response) => {
                // get total length
                const contentLength = +response.headers.get('Content-Length');
      
                const contentLengthSize = await getFileSizeByByteLength(contentLength);
                item['contentLength'] = contentLengthSize;
                return contentLengthSize;
                // setMessageDetails([...tempMessageDetails]);
              })
              .catch(err => {
                console.error(err);
              });
            item["contentLength"]= retrunedItem;
            }
          }
        }
      }
      setMessageDetails(res);
      scrollItem()
    }
  };

  const selectMessageConversation = async (selectedConversation) => {
    if(!selectedConversation){
      return null
    }
    setMessageHeader({
      ...messageHeader,
      messageSourceType: selectedConversation.message_source_type,
      messageSourceId: selectedConversation.id,
      sellerId: selectedConversation.seller_id,
      title: selectedConversation.source_detail,
      sellerStoreName: selectedConversation.buyer_name,
      sellerTimezone: selectedConversation.seller_timezone,
      sellerAvailableFrom: selectedConversation.seller_available_from,
      sellerAvailableTo: selectedConversation.seller_available_to,
      sellerChatActive: selectedConversation.seller_chat_active,
    });
    setActiveMsgConversationId(selectedConversation.id);
    await getMessageDetails(selectedConversation.id);
    setShow(false);
    setMessage("");
    setFiles([]);
    setError([]);
  };

  const handleOpenChat = async (e, id) => {
    e.preventDefault();
    setLoadingData(true);
    keepVerifyingRef.current = false;
    if (messageConversationList[0][`unread_message_count`] !== 0) {
      messageConversationList[0][`unread_message_count`] = 0
    }
    const selectedConversation = messageConversationList.find(
      (item) => item.id === id
    );
    if (selectedConversation['latest_read_status'] === 0) {

      for (let message of messageConversationList) {
        if (message.id === id) {
          message[`latest_read_status`] = 1
          message['unread_message_count'] = 0
          break;
        }
      }
      setMessageConversationList([...messageConversationList])
    }
    await selectMessageConversation(selectedConversation);
  };

  // time converter function from 16:04 to 04:04 PM
  const timeConverter = (time) => {
    if (!time) {
      return "";
    }
    const timeArray = time.split(":");
    let hours = timeArray[0];
    const minutes = timeArray[1];
    //it is PM if hours from 12 onwards
    const suffix = hours >= 12 ? "PM" : "AM";
    //only -12 from hours if it is greater than 12 (if not back at mid night)
    hours = hours > 12 ? hours - 12 : hours;
    //if 00 then it is 12 am
    hours = hours === "00" ? 12 : hours;
    time = hours + ":" + minutes + " " + suffix;
    return time;
  };

  // timestamp converter function from 2021-05-05T16:30:18.000Z to 04:30 PM
  const timestampConverter = (time) => {
    if (!time) {
      return "";
    }
    Number.prototype.zeroPad = function (length) {
      length = length || 2; // defaults to 2 if no parameter is passed
      return (new Array(length).join("0") + this).slice(length * -1);
    };

    var date = new Date(time);
    time = timeConverter(
      date.getHours().zeroPad(2) + ":" + date.getMinutes().zeroPad(2)
    );
    return time;
  };




  // function to trigger our input file click
  const fileUploadVideoClick = (e) => {
    e.preventDefault();
    setFileFormat(fileFormats.video);
    fileUploadVideo.click();
    return false;
  };
  const fileUploadImageClick = (e) => {
    e.preventDefault();
    setFileFormat(fileFormats.image);
    fileUploadImage.click();
    return false;
  };
  const fileUploadDocClick = (e) => {
    e.preventDefault();
    setFileFormat(fileFormats.doc);
    fileUploadDoc.click();
    return false;
  };
  const fileUploadAddMoreClick = (e) => {
    e.preventDefault();
    fileUploadAddMore.click();
    return false;
  };

  const fileUploadHandler = (fileList, addNew) => {
    const errors = [];
    const fileArray = [];

    for (const file of fileList) {
      //read file stream and set in state
      function readFile(file) {
        const fileReader = new FileReader();
        fileReader.onload = function (event) {
          fileArray.push({
            name: file.name,
            type: file.type,
            size: file.size,
            file: file,
            stream: event.currentTarget.result,
          });
          if (addNew) {
            setFiles([...files, ...fileArray]);
          } else {
            setFiles([...fileArray]);
          }
        };
        fileReader.readAsDataURL(file);
      }
      let imgType = file?.type?.split("/");
      if (fileFormats.video.includes(imgType[1]) || fileFormats.image.includes(imgType[1]) || fileFormats.doc.includes(imgType[1]) ) {
        if (!files.find((item) => (item.name === file.name) && (item.type === file.type))) {
          readFile(file);
        }
      } else {
        errors.push("File [" + file.name + "] Format is not valid.");
      }
    }
    setError(errors);
  };

  const fileSelectHandler = (e) => {
    e.preventDefault();
    setShow(false);
    fileUploadHandler(e.target.files, false);
  };

  const addMoreFiles = (e) => {
    e.preventDefault();
    fileUploadHandler(e.target.files, true);
    return false;
  };

  const deleteSelectedFile = (e, index) => {
    e.preventDefault();
    files.splice(index, 1);
    setFiles([...files]);
  };


  const getFileSizeByUrl = async (item) => {
    if (item) {
      // start the fetch
      await Axios(item.attachmentName, {
        method: "GET",
        headers: {}
      })
        .then(async(response) => {
          // get total length
          const contentLength = +response.headers.get('Content-Length');

          const contentLengthSize = await getFileSizeByByteLength(contentLength);
          item['contentLength'] = contentLengthSize;
          return contentLengthSize;
          // setMessageDetails([...tempMessageDetails]);
        })
        .catch(err => {
          console.error(err);
        });
    }
    // const tempMessageDetails = messageDetails;
    // const messageDetailMap = tempMessageDetails.find((map) => map.id === item.messagesId);
    // const messageAttachmentMap = messageDetailMap?.messageAttachmentList.find((map) => map.id === item.id);

    // if (messageAttachmentMap) {
    //   // start the fetch
    //   fetch(item.attachmentName, {
    //     method: "GET",
    //     headers: {}
    //   })
    //     .then(response => {
    //       // get total length
    //       const contentLength = +response.headers.get('Content-Length');

    //       const contentLengthSize = getFileSizeByByteLength(contentLength);
    //       messageAttachmentMap['contentLength'] = contentLengthSize;
    //       setMessageDetails([...tempMessageDetails]);
    //     })
    //     .catch(err => {
    //       console.error(err);
    //     });
    // }
  };

  const downloadFileByUrl = (e, item) => {
    keepVerifyingRef.current = true
    const fileName = getFileNameFromUrl(item.attachmentName, item.type)
    const tempMessageDetails = messageDetails;
    const messageDetailMap = tempMessageDetails.find((map) => map.id === item.messagesId);
    const messageConversationId = messageDetailMap?.message_conversation_id
    const messageAttachmentMap = messageDetailMap?.messageAttachmentList.find((map) => map.id === item.id);

    if (messageAttachmentMap) {
      // start the fetch
      fetch(item.attachmentName, {
        method: "GET",
        headers: {}
      })
        .then(async response => {
          // obtain a reader
          const reader = response.body.getReader();

          // get total length
          const contentLength = +response.headers.get('Content-Length');
          let copyactiveMsgConversationId = activeMsgConversationId
          // read the data
          let receivedLength = 0; // received that many bytes at the moment
          let chunks = []; // array of received binary chunks (comprises the body)
          while (true) {
            if (!keepVerifyingRef.current) {
              break;
            }

            const { done, value } = await reader.read();
            if (done) {
              break;
            }
            // if (copyactiveMsgConversationId !== messageConversationId) {
            //   break;
            // }
            chunks.push(value);
            receivedLength += value.length;
            const receivedFileSize = getFileSizeByByteLength(receivedLength);
            messageAttachmentMap['receivedLength'] = receivedFileSize;
            messageAttachmentMap['receivedLengthPercent'] = Math.round((receivedLength / contentLength) * 100);
            setMessageDetails([...tempMessageDetails])

          }


          // concatenate chunks into single Uint8Array
          let chunksAll = new Uint8Array(receivedLength);
          let position = 0;
          for (let chunk of chunks) {
            chunksAll.set(chunk, position);
            position += chunk.length;
          }

          // download file with chunksAll
          const url = window.URL.createObjectURL(new Blob([chunksAll]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        })
        .catch(err => {
          console.error(err);
        });
    }
  };

  const scrollItem = () => {
    var myDiv = document.getElementById("hey1");
    if (myDiv) {
      if (cleartime) {
        clearTimeout(cleartime);
      }
      if (clearLoadMore) {
        clearTimeout(clearLoadMore);
      }
      myDiv.scrollTop = myDiv.scrollHeight + myDiv.scrollHeight * 0.8;
      allowScroll = false;
      cleartime = setTimeout(() => {
        if (clearLoadMore) {
          clearTimeout(clearLoadMore);
        }
        myDiv.scrollTop = myDiv.scrollHeight + myDiv.scrollTop;
        allowScroll = true;
      }, 1000);
    }
  };
  const handleScroll = (e) => {
    let element = e.target;
    if (clearLoadMore) {
      clearTimeout(clearLoadMore);
    }
    if (
      element.scrollTop <=
      (2 * (element.clientHeight + element.scrollHeight)) / 100 &&
      allowScroll
    ) {
      // do something at end of scroll
      clearLoadMore = setTimeout(() => {
        allowScroll = false;
        loadMore();
      }, 1000);
    }
  };
  const loadMore = async () => {
    if (loadingData) {
      let res = await dispatch(getMessagesByConversationId(activeMsgConversationId, buyer_id, offset ? offset : 10));
      if(res)
        for (const messageArray of res) {
        if(messageArray.messageAttachmentList){
          for (const item of messageArray.messageAttachmentList) {
            if(item.attachmentName){
            let retrunedItem = await fetch(item.attachmentName, {
              method: "GET",
              headers: {}
            })
              .then(async(response) => {
                // get total length
                const contentLength = +response.headers.get('Content-Length');
      
                const contentLengthSize = await getFileSizeByByteLength(contentLength);
                item['contentLength'] = contentLengthSize;
                return contentLengthSize;
                // setMessageDetails([...tempMessageDetails]);
              })
              .catch(err => {
                console.error(err);
              });
            item["contentLength"]= retrunedItem;
            }
          }
        }
      }
      if (res && res.length > 0) {
        setMessageDetails([...messageDetails, ...res]);
        setOffSet(offset + 10);
        scrollAfterLoadMore()
        if (res.length < 10) {
          setLoadingData(false)
          setOffSet(0)
        }
      }
    }
    else {
      allowScroll = false;
    }
  }
  const scrollAfterLoadMore = () => {
    var myDiv = document.getElementById("hey1");
    if (myDiv) {
      myDiv.scrollTop = myDiv.clientHeight * 0.8;
    }
    allowScroll = true;
  };


  const setValue = (timestamp) => {
    let unixTimeStamp = new Date(timestamp).getTime();
    let message = new Date(Math.ceil(unixTimeStamp));
    let today = new Date();
    let diff = moment(today, "DD.MM.YYYY")
      .startOf("day")
      .diff(moment(message, "DD.MM.YYYY").startOf("day"), "days");
    if (diff === 0) {
      if (showData === 1) {
        showData = 2;
      }
      if (showData === 0) {
        showData = 1;
      }
    }
  };

  const handleSearch = (event) => {
    const {value} = event.target;
    if (timeOutState) {
      clearInterval(timeOutState);
    }
    if(isEmpty(value)){
      setAllowReload(true)
      setMessageConversationList([])
    }
    let result = setTimeout(async () => {
      await fetchFilteredData(value);
    }, 800)
    setTimeOutState(result);
  }

  useEffect(()=>{
    if(allowReload){
      fetchMessages(true)
      setAllowReload(false)
    }
  },[messageConversationList, allowReload])

  const fetchFilteredData = async (value) => {
    if(isEmpty(value)){
      return
    }
    const response = await dispatch(searchMessages(value, buyer_id))
    if(response.isError){
      return setMessageConversationList([]);
    }
    setMessageConversationList(response.data)
    setPreviousMessageListOffSet(0)
    setMessageListOffSet(0)
  }

  return (
    <main className="mainContainer">
      <section className="profile">
        <div className="container">
          <div className="row">
            <Profile_Sidebar />
            <div className="col-lg-9">
              <div className="admin-card-box messages-box p-0">
                <div className="container-fluid">
                  <div className="row">

                    <div className="col-md-4 p-0">
                      <div className="message-search">
                        <input className="form-control"  type="text" placeholder="Search here.." onChange={(e)=>handleSearch(e)}/>
                        <span><i className="fas fa-search"></i></span>
                      </div>
                      <InfiniteScroll
                        dataLength={messageConversationList.length}
                        next={() => fetchMessages(false)}
                        hasMore={hasMore}
                        scrollableTarget="scrollableDiv"
                      >
                        <ul className="main-side-select" id="scrollableDiv" style={{overflow: "auto"}}>
                          {messageConversationList.length > 0 ? (
                            <>
                              {messageConversationList.map((message, index) => (
                                <li
                                  key={index}
                                  className={
                                    activeMsgConversationId === message.id
                                      ? "active"
                                      : ""
                                  }
                                  onClick={(e) => handleOpenChat(e, message.id)}
                                >

                                  <div className="username-short">
                                    {getShortName(message.buyer_name)}
                                  </div>
                                  <div className="username-desc">
                                    <h6 className={message.latest_read_status === 0 ? index === 0 ? '' : "active-head" : ""}>
                                      {message.source_detail}-{message.buyer_name}
                                    </h6>

                                    <p>
                                      {message.latest_message_type != null ? fileFormats.image.includes(message.latest_message_type) ? (<>
                                        <i className="far fa-image"></i> Image
                                      </>) :
                                        fileFormats.video.includes(message.latest_message_type) ? (<>
                                          <i className="far fa-file-video"></i> Video
                                        </>) : (<>
                                          <i className="far fa-file"></i> File
                                        </>) :
                                        (message.latest_message?.substring(0, 35) +
                                          (message.latest_message?.length > 35
                                            ? "..."
                                            : "")) }

                                    </p>
                                    {message.unread_message_count > 0 &&
                                      <span className="badge badge-dark">
                                        {message.unread_message_count}
                                      </span>
                                    }
                                  </div>
                                </li>
                              ))}
                            </>
                          ) :  "No Conversation found"}
                          {/* <h6>product-vendor01</h6>
                            <p>This is Photoshop's version of</p> */}
                        </ul>
                      </InfiniteScroll>
                    </div>
                    <div className="col-md-8 p-0">
                      {activeMsgConversationId ||
                        (messageHeader.messageSourceType &&
                          messageHeader.messageSourceId &&
                          messageHeader.sellerId &&
                          buyer_id) ? (
                        <div className="message-desc-chat">
                          <div className="message-head">
                            <div className="subhead-message">
                              <h6>{messageHeader.sellerStoreName}</h6>
                              <p>
                                {timeConverter(
                                  messageHeader.sellerAvailableFrom
                                ) || ""}{" "}
                                -{" "}
                                {timeConverter(
                                  messageHeader.sellerAvailableTo
                                ) || ""}{" "}
                                {messageHeader.sellerTimezone || ""}
                              </p>
                              {/*<p>10:00 AM - 16:30 PM EDT</p>*/}
                            </div>
                            <h6>
                              {messageHeader.messageSourceType === "order"
                                ? "Order ID "
                                : ""}
                              {messageHeader.title}
                            </h6>
                          </div>

                          <div
                            className="message-chat-date"
                            id="hey1"
                            ref={messagesEndRef}
                            onScroll={handleScroll}
                          >
                            {messageDetails.length > 0 ? (
                              <ul className="chat-desc-sub">
                                {messageDetails.sort((a, b) => a.id - b.id).map((message, index) => (
                                  <li key={index} className="differ-username">
                                    <div>
                                      {setValue(message.created_ts)}
                                      {showData === 1 ? (
                                        <div className="message-date">
                                          <p>Today</p>
                                        </div>
                                      ) : null}
                                    </div>
                                    {(message.sender_type == "seller") ? (

                                      <div className="flex-image-username">
                                        <div className="username-short username-short-sub">
                                          {getShortName(message.sender_name)}
                                        </div>
                                        <div className="username-desc">
                                          <div className="time-add-sub">
                                            <h6>{message.sender_name}</h6>
                                            <span className="time-msg">
                                              {convertTime(
                                                message.created_ts
                                              )}
                                            </span>
                                          </div>
                                          {(getUrlByString(message.message))}
                                          {/*{message.read_status == 1 && (
                                        <span>&#10003; Seen</span>
                                      )}*/}
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="flex-image-vendorside">
                                        <div className="username-short username-short-sub">
                                          {getShortName(message.sender_name)}
                                        </div>
                                        <div className="username-desc">
                                          <div className="time-add-sub">
                                            <h6>{message.sender_name}</h6>
                                            <span className="time-msg">
                                              {convertTime(
                                                message.created_ts
                                              )}
                                            </span>
                                          </div>
                                          {(getUrlByString(message.message))}
                                          {/*{message.read_status == 1 && (
                                      <span>&#10003; Seen</span>
                                    )}*/}
                                        </div>
                                      </div>
                                    )}

                                    {message.messageAttachmentList?.length >
                                      0 ? (
                                      <div className="username-desc-map-image">
                                        {message.messageAttachmentList.map(
                                          (item, index) => (
                                            <div key={index}>
                                              {fileFormats.image.includes(item.type) ? (message.sender_type == "seller") ? (
                                                <div
                                                  className="chat-img-desc-sub"
                                                  title={getFileNameFromUrl(item.attachmentName, item.type)}
                                                  onClick={(e) => {
                                                    if (!item.receivedLength) {
                                                      downloadFileByUrl(e, item)
                                                    }
                                                  }}
                                                >
                                                  <img className="sellerImg" src={item.attachmentName} />
                                                </div>
                                              ):(<div
                                                className="chat-img-desc-sub-vendor"
                                                title={getFileNameFromUrl(item.attachmentName, item.type)}
                                                onClick={(e) => {
                                                  if (!item.receivedLength) {
                                                    downloadFileByUrl(e, item)
                                                  }
                                                }}
                                              >
                                                <img className="vendorImg" src={item.attachmentName} />
                                              </div>) : null}


                                              { (message.sender_type == "seller") ? ([...fileFormats.doc, ...fileFormats.video].includes(item.type) ? (
                                                <div
                                                  className="download-doc-preview"
                                                  title={getFileNameFromUrl(item.attachmentName, item.type)}
                                                  onClick={(e) => {
                                                    if (!item.receivedLength) {
                                                      downloadFileByUrl(e, item)
                                                    }
                                                  }}
                                                >
                                                  <h5>{getFileNameFromUrl(item.attachmentName, item.type)}</h5>
                                                  {item.receivedLength ? (
                                                    <>
                                                      <p>{item.receivedLength} Download Complete</p>
                                                    </>
                                                  ) : (
                                                    <p>
                                                      {item.contentLength
                                                      // || getFileSizeByUrl(item)
                                                    }
                                                    </p>
                                                  )}
                                                  <span>
                                                    {fileFormats.video.includes(item.type) ? (<>
                                                      <i className="far fa-file-video"></i> Video
                                                    </>) : (<>
                                                      <i className="far fa-file"></i> File
                                                    </>)}
                                                  </span>
                                                  {item.receivedLengthPercent && item.receivedLengthPercent < 100 ? (
                                                    <progress id="file" value={item.receivedLengthPercent} max="100"> {item.receivedLengthPercent}% </progress>
                                                  ) : null}
                                                  <div className="download-option-preview">
                                                    <h6>Download</h6>
                                                  </div>
                                                </div>
                                              ) : null
                                              ) : ( [...fileFormats.doc, ...fileFormats.video].includes(item.type) ? (
                                                <div
                                                  className="download-doc-preview-vendor"
                                                  title={getFileNameFromUrl(item.attachmentName, item.type)}
                                                  onClick={(e) => {
                                                    if (!item.receivedLength) {
                                                      downloadFileByUrl(e, item)
                                                    }
                                                  }}
                                                >
                                                  <h5>{getFileNameFromUrl(item.attachmentName, item.type)}</h5>
                                                  {item.receivedLength ? (
                                                    <>
                                                      <p>{item.receivedLength} Download Complete</p>
                                                    </>
                                                  ) : (
                                                    <p>
                                                      {item.contentLength
                                                      // {/* // || getFileSizeByUrl(item) */}
                                                    }
                                                    </p>
                                                  )}
                                                  <span>
                                                    {fileFormats.video.includes(item.type) ? (<>
                                                      <i className="far fa-file-video"></i> Video
                                                    </>) : (<>
                                                      <i className="far fa-file"></i> File
                                                    </>)}
                                                  </span>
                                                  {item.receivedLengthPercent && item.receivedLengthPercent < 100 ? (
                                                    <progress id="file" value={item.receivedLengthPercent} max="100"> {item.receivedLengthPercent}% </progress>
                                                  ) : null}
                                                  <div className="download-option-preview">
                                                    <h6>Download</h6>
                                                  </div>
                                                </div>
                                              ) : null) }
                                            </div>
                                          )
                                        )}
                                      </div>
                                    ) : null}
                                  </li>
                                ))}
                                {/* <li>
                              <div className="username-short username-short-sub">
                                vn
                              </div>
                              <div className="username-desc">
                                <div className="time-add-sub">
                                  <h6>CUSTOMER NAME</h6>
                                  <span className="time-msg">12:30 PM</span>
                                </div>
                                <p>
                                  This is Photoshop's version of Lorem Ipsum.
                                  Proin gravida nibh vel velit auctor aliquet.
                                  Aenean sollicitudin, lorem quis bibendum
                                </p>
                                <span>&#10003; Seen</span>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <div className="message-chat-date">
                          <div className="message-date">
                            <p>Wednesday, 14, 2021</p>
                          </div>

                          <ul className="chat-desc-sub">
                            <li>
                              <div className="username-short username-short-sub">
                                vn
                              </div>
                              <div className="username-desc">
                                <div className="time-add-sub">
                                  <h6>product-vendor01</h6>
                                  <span className="time-msg">12:30 PM</span>
                                </div>
                                <p>
                                  This is Photoshop's version of Lorem Ipsum.
                                  Proin gravida nibh vel velit auctor aliquet.
                                  Aenean sollicitudin, lorem quis bibendum
                                </p>
                                <span>&#10003; Seen</span>
                              </div>
                            </li>

                            <li>
                              <div className="username-short username-short-sub">
                                vn
                              </div>
                              <div className="username-desc">
                                <div className="time-add-sub">
                                  <h6>CUSTOMER NAME</h6>
                                  <span className="time-msg">12:30 PM</span>
                                </div>
                                <p>
                                  This is Photoshop's version of Lorem Ipsum.
                                  Proin gravida nibh vel velit auctor aliquet.
                                  Aenean sollicitudin, lorem quis bibendum
                                </p>
                                <span>&#10003; Seen</span>
                              </div>
                            </li>

                            <li>
                              <div className="username-short username-short-sub">
                                vn
                              </div>
                              <div className="username-desc">
                                <div className="time-add-sub">
                                  <h6>product-vendor01</h6>
                                  <span className="time-msg">12:30 PM</span>
                                </div>
                                <p>
                                  This is Photoshop's version of Lorem Ipsum.
                                  Proin gravida nibh vel velit auctor aliquet.
                                  Aenean sollicitudin, lorem quis bibendum
                                </p>
                                <span>&#10003; Seen</span>
                              </div>
                            </li>

                            <li>
                              <div className="username-short username-short-sub">
                                vn
                              </div>
                              <div className="username-desc">
                                <div className="time-add-sub">
                                  <h6>product-vendor01</h6>
                                  <span className="time-msg">12:30 PM</span>
                                </div>
                                <p>
                                  This is Photoshop's version of Lorem Ipsum.
                                  Proin gravida nibh vel velit auctor aliquet.
                                  Aenean sollicitudin, lorem quis bibendum
                                </p>
                                <span>&#10003; Seen</span>
                              </div>
                            </li>
                           */}
                              </ul>
                            ) : null}
                          </div>
                          <div className="chat-box">
                            <form onSubmit={(e) => {
                              if (!messageSubmitButton) {
                                handleSubmit(e)
                              }
                            }}>
                              <div
                                className="chat-icon"
                                onClick={() => messageHeader.sellerChatActive !== 1 ? null : setShow(!show)}
                                style={(files.length > 0) ? {display:"none"} : {display:"block"}}
                              >
                                <span>
                                  <i className="fas fa-plus-circle"></i>
                                </span>
                              </div>
                              {show && files.length <= 0 ? (
                                <>
                                  <input
                                    type="file"
                                    hidden
                                    multiple
                                    ref={(input) => {
                                      fileUploadVideo = input;
                                    }}
                                    accept={fileFormats.video
                                      .map((item) => "." + item)
                                      .join(",")}
                                    onChange={(e) => fileSelectHandler(e)}
                                  />
                                  <input
                                    type="file"
                                    hidden
                                    multiple
                                    ref={(input) => {
                                      fileUploadImage = input;
                                    }}
                                    accept={fileFormats.image
                                      .map((item) => "." + item)
                                      .join(",")}
                                    onChange={(e) => fileSelectHandler(e)}
                                  />
                                  <input
                                    type="file"
                                    hidden
                                    multiple
                                    ref={(input) => {
                                      fileUploadDoc = input;
                                    }}
                                    accept={fileFormats.doc
                                      .map((item) => "." + item)
                                      .join(",")}
                                    onChange={(e) => fileSelectHandler(e)}
                                  />
                                  <ul>
                                    <li onClick={fileUploadVideoClick}>
                                      <p>
                                        <i className="fas fa-video"></i>
                                      </p>
                                    </li>
                                    <li onClick={fileUploadImageClick}>
                                      <p>
                                        <i className="fas fa-image"></i>
                                      </p>
                                    </li>
                                    <li onClick={fileUploadDocClick}>
                                      <p>
                                        <i className="fas fa-file-alt"></i>
                                      </p>
                                    </li>
                                  </ul>
                                </>
                              ) : null}
                              {/*<input
                                type="text"
                                placeholder="Your text here..."
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                              />*/}
                              <textarea
                                placeholder = {messageHeader.sellerChatActive !== 1 ? "Chat Disabled By Vendor" :"Your text here..."}
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                rows="1"
                                disabled = {messageHeader.sellerChatActive !== 1}
                              ></textarea> 
                              <div className="chat-icon"
                                onClick={(e) => {
                                  if (!messageSubmitButton) {
                                    handleSubmit(e)
                                  }
                                }}
                              >
                                {!messageSubmitButton ? <span>
                                  <i
                                    className="far fa-paper-plane"
                                disabled = {messageHeader.sellerChatActive !== 1}
                                    type="submit"
                                  ></i>
                                </span> :
                                  <span className="spinner-border spinner-border-sm ml-1"></span>
                                }
                              </div>
                            </form>

                            {files.length > 0 ? (
                              <>
                                <input
                                  type="file"
                                  hidden
                                  multiple
                                  ref={(input) => {
                                    fileUploadAddMore = input;
                                  }}
                                  accept={fileFormat
                                    .map((item) => "." + item)
                                    .join(",")}
                                  onChange={(e) => addMoreFiles(e)}
                                />
                                <ul className="preview-copy-img">
                                  {files.map((item, index) => (
                                    <li key={index}>
                                      {fileFormats.image.includes(
                                        item.type.split("/")[1]
                                      ) ? (
                                        <>
                                          <img
                                            src={item.stream}
                                            alt="preview"
                                          />
                                          <p
                                            onClick={(e) =>
                                              deleteSelectedFile(e, index)
                                            }
                                          >
                                            <i className="far fa-times-circle">
                                              {" "}
                                            </i>
                                          </p>
                                        </>
                                      ) : null}
                                      {[...fileFormats.doc, ...fileFormats.video].includes(item.type.split("/")[1]) ? (
                                        <div className="filesize-main-depth">
                                          <h6>{item.name}</h6>
                                          <label>{getFileSizeByByteLength(item.size)}</label>
                                          <p
                                            onClick={(e) =>
                                              deleteSelectedFile(e, index)
                                            }
                                          >
                                            <i className="far fa-times-circle"> </i>
                                          </p>
                                        </div>
                                      ) : null}
                                      {/*{fileFormats.video.includes(
                                        item.type.split("/")[1]
                                      ) ? (
                                        <>
                                          <video
                                            width="320"
                                            height="240"
                                            controls
                                            autoPlay={true}
                                          >
                                            <source
                                              src={item.stream}
                                              type={item.type}
                                            />
                                            Your browser does not support the
                                            video tag.
                                          </video>
                                          <p
                                            onClick={(e) =>
                                              deleteSelectedFile(e, index)
                                            }
                                          >
                                            <i className="far fa-times-circle">
                                              {" "}
                                            </i>
                                          </p>
                                        </>
                                      ) : null}*/}
                                    </li>
                                  ))}
                                  <li onClick={fileUploadAddMoreClick}>
                                    <span>
                                      <i className="fas fa-plus-square"></i>
                                    </span>
                                  </li>
                                </ul>
                              </>
                            ) : null}
                         
                            {/*<ul className="preview-copy-img">
                              <li>
                                <img src={Preview} alt="preview" />
                                <p>
                                  <i className="far fa-times-circle"></i>
                                </p>
                              </li>
                              <li>
                                <span>
                                  <i className="fas fa-plus-square"></i>
                                </span>
                              </li>
                            </ul>*/}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
             <p style={{color: "#ff0000", fontSize: '14px', marginTop: '10px'}}>{errorMessage.sizeError}</p>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Message;
