import React from "react";

export const CheckIconPrimary = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 3C10.22 3 8.47991 3.52784 6.99987 4.51677C5.51983 5.50571 4.36628 6.91131 3.68509 8.55585C3.0039 10.2004 2.82567 12.01 3.17294 13.7558C3.5202 15.5016 4.37737 17.1053 5.63604 18.364C6.89471 19.6226 8.49836 20.4798 10.2442 20.8271C11.99 21.1743 13.7996 20.9961 15.4442 20.3149C17.0887 19.6337 18.4943 18.4802 19.4832 17.0001C20.4722 15.5201 21 13.78 21 12C20.9975 9.61382 20.0485 7.3261 18.3612 5.63882C16.6739 3.95154 14.3862 3.00252 12 3ZM15.9513 10.4129L11.1052 15.259C11.0409 15.3234 10.9645 15.3745 10.8805 15.4093C10.7965 15.4441 10.7064 15.4621 10.6154 15.4621C10.5244 15.4621 10.4343 15.4441 10.3503 15.4093C10.2662 15.3745 10.1899 15.3234 10.1256 15.259L8.04866 13.1821C7.91875 13.0522 7.84577 12.876 7.84577 12.6923C7.84577 12.5086 7.91875 12.3324 8.04866 12.2025C8.17856 12.0726 8.35475 11.9996 8.53846 11.9996C8.72218 11.9996 8.89837 12.0726 9.02827 12.2025L10.6154 13.7905L14.9717 9.43327C15.0361 9.36895 15.1124 9.31792 15.1965 9.28311C15.2805 9.2483 15.3706 9.23038 15.4615 9.23038C15.5525 9.23038 15.6426 9.2483 15.7266 9.28311C15.8107 9.31792 15.887 9.36895 15.9513 9.43327C16.0157 9.49759 16.0667 9.57395 16.1015 9.65799C16.1363 9.74203 16.1542 9.83211 16.1542 9.92308C16.1542 10.014 16.1363 10.1041 16.1015 10.1882C16.0667 10.2722 16.0157 10.3486 15.9513 10.4129Z"
        fill="#C74339"
      />
    </svg>
  );
};
