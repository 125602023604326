import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  getProductListByCategory,
  getProductListByFilternameAndCategory,
  getProductListBySearchCategory,
} from "../../Redux/Actions/productAction";
import "./product-list.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import Rating from "@material-ui/lab/Rating";
import { withStyles } from "@material-ui/core/styles";
import Nothing from "../../assets/images/nothing-here.gif";

const StyledRating = withStyles({
  iconFilled: {
    color: "#ff6d75",
  },
  iconHover: {
    color: "#ff3d47",
  },
})(Rating);

class Product_Category_List extends Component {
  limit = 10;
  start = 0;
  prevStart = 0;

  constructor(props) {
    super(props);

    this.state = {
      items: [],
      hasMore: true,
      category: this.props.match.params.category,
      isLoading: false,
      sort_type: undefined,
      isSearchCategory: this.props.location.pathname.includes("search"),
      categoryTitle: "",
      isDataNotFound: false,
      splitTitleList: [],
    };
  }

  componentDidMount() {
    let title;
    let splitTitleUrl = [];
    if (this.state.isSearchCategory) {
      title = this.props.location.pathname.split("/");
      title = title[title.length - 1];
      splitTitleUrl = this.getSplitTitleUrl(title.split("-"));
      let runLoop = true;
      while (runLoop) {
        title = title.replace("-", " ");
        if (!title.includes("-")) {
          runLoop = false;
        }
      }
    } else {
      title = this.props.match.params.category;
    }
    this.setState({
      categoryTitle: title.toUpperCase(),
      splitTitleList: splitTitleUrl,
    });
    this.fetchProducts(this.props.match.params.category, true);
  }

  getSplitTitleUrl = (title) =>
    title.map((t, index) => {
      let url = [];
      for (let i = 0; i <= index; i++) {
        url.push(title[i]);
      }
      return url.join("-");
    });

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (
      !this.state.isSearchCategory &&
      nextProps.match.params.category !== this.props.match.params.category
    ) {
      //    this.setState({ startTime: nextProps.startTime });

      this.setState({
        items: [],
        hasMore: true,
        category: nextProps.match.params.category,
        isLoading: false,
        categoryTitle: nextProps.match.params.category.toUpperCase(),
      });
      this.start = 0;
      this.prevStart = 0;
      this.fetchProducts(nextProps.match.params.category, true);
    }

    if (
      this.state.isSearchCategory &&
      nextProps.location.pathname !== this.props.location.pathname
    ) {
      let title = nextProps.location.pathname.split("/");
      title = title[title.length - 1];
      let splitTitleUrl = this.getSplitTitleUrl(title.split("-"));
      let runLoop = true;
      while (runLoop) {
        title = title.replace("-", " ");
        if (!title.includes("-")) {
          runLoop = false;
        }
      }
      this.start = 0;
      this.prevStart = 0;
      this.setState(
        {
          items: [],
          hasMore: true,
          categoryTitle: title.toUpperCase(),
          isLoading: false,
          splitTitleList: splitTitleUrl,
          isDataNotFound: false,
        },
        () => this.fetchProducts("", true)
      );
      window.scrollTo(0, 0);
    }
  }

  async fetchProducts(category, firstLoad) {
    let isDataNotFound = this.state.isDataNotFound;
    if (this.start !== this.prevStart || firstLoad) {
      if (this.state.isSearchCategory) {
        const search = this.props.location.pathname.split("/");
        const response = await this.props.getProductListBySearchCategory(
          search[search.length - 1],
          this.start,
          this.limit,
          this.state.sort_type
        );
        if (response && !response.isFound) {
          isDataNotFound = true;
        } else {
          isDataNotFound = false;
        }
      } else if (this.props.match.params.filterName) {
        await this.props.getProductListByFilternameAndCategory(
          this.props.match.params.filterName,
          category,
          this.start,
          this.limit,
          this.state.sort_type
        );
      } else {
        await this.props.getProductListByCategory(
          category,
          this.start,
          this.limit,
          this.state.sort_type
        );
      }

      this.prevStart = this.start;
      this.start += this.limit;
      let stateData = this.state;

      let productData = stateData.items;
      if (this.props.productList.length > 0) {
        if (stateData.items.length === 0) {
          productData = this.props.productList;
        } else {
          for (const tempProd of this.props.productList) {
            productData.push(tempProd);
          }
        }
      }

      let stateUpdate = this.state;
      stateUpdate.isLoading = false;

      stateUpdate.items = productData;
      stateUpdate.isDataNotFound = isDataNotFound;
      if (this.props.productList.length < this.limit) {
        stateUpdate.hasMore = false;
      }
      this.setState({
        ...stateUpdate,
      });
    }
  }

  fetchMoreData = async () => {
    this.fetchProducts(this.props.match.params.category, false);
  };

  handleProductSorting = async (event, category, isDropDown) => {
    this.limit = 10;
    this.start = 0;
    this.prevStart = 0;
    // event.preventDefault();
    let isDataNotFound = this.state.isDataNotFound;
    // let event_value = event.target.value;
    let event_value;
    if (isDropDown)
    event_value = event;
    else{
      event.preventDefault();

      event_value = event.target.value;
    }
    await this.setState(
      {
        sort_type: event_value,
        items: [],
        hasMore: true,
      },
      async () => {
        if (this.state.isSearchCategory) {
          const search = this.props.location.pathname.split("/");
          const response = await this.props.getProductListBySearchCategory(
            search[search.length - 1],
            this.start,
            this.limit,
            this.state.sort_type,
            event_value
          );
          if (response && !response.isFound) {
            isDataNotFound = true;
          }
        } else {
          await this.props.getProductListByFilternameAndCategory(
            this.props.match.params.filterName,
            category,
            this.start,
            this.limit,
            event_value
          );
        }
        this.setState({
          items: this.props.productList,
          isDataNotFound,
        });
      }
    );

    this.prevStart = this.start;
    this.start += this.limit;
  };

  replaceUnderScores = (string) => {
    let isUnderScoreLeft = true;
    while (isUnderScoreLeft) {
      string = string.replace("_", " ");
      if (!string.includes("_")) {
        isUnderScoreLeft = false;
      }
    }
    return string;
  };

  render() {
    const products =
      this.state.items.length > 0 ? (
        this.state.items.map((item, index) => {
          
          let isInStock = !item?.isOutOfStock
          // let isInStock =
          // item.totalsubscription + item.oneTimeOrder <
          // item.order_limit;

          // if (
          //   item?.timeFrame &&
          //   item.timeFrame.includes("weekly")
          // ) {
          //   isInStock =
          //     Math.floor(
          //       (item.order_limit -
          //         (item.totalsubscription +
          //           item.oneTimeOrder)) /
          //       4
          //     ) > 0;
          // }

          return (
            <div className="col-md-3 col-6" key={index}>
              <div className="home-product-item">
                {item.is_sale !== 0 && this.props.match.params.category!=="access" ? (
                  item.price_type === 1 ? (
                    <div className="sale-label-star">
                      ${item.sale_price} off
                    </div>
                  ) : (
                    <div className="sale-label-star">
                      {" "}
                      {Math.round(100 * (1 - item.sale_price / item.price))}%
                      off
                    </div>
                  )
                ) : null}
                {item.is_shipping_free && this.props.match.params.category!=="access" ? (
                  <div to="/" className="shipping-label-star">
                    free shipping
                  </div>
                ) : null}
                <Link
                  to={{
                    pathname: this.state.isSearchCategory
                      ? `/product/${item.slug}`
                      : `${this.props.match.url}/${item.slug}`,
                    product: item,
                    state: { spuId: item.spu_id },
                  }}
                >
                  <div className="home-product-box">
                    <img src={item.imageName} />

                                         {(isInStock || item?.isAccessCategoryAvailable) ?
                      <div className="quickviewbtn">
                        <p>Quick View</p>
                      </div>
                      :
                      <div className="quickviewbtn">
                      <p>Sold Out</p>
                    </div>

                      }

                    {/* <a href="#" className="quickviewbtn">
                                                        <p>Quick View</p>
                                                    </a> */}
                  </div>
                  <div className="home-productbox-detail">
                    <div className="home-productbox-heading">
                      <div className="price-inline-remove">
                        <h2 title={item.name ? item.name : null}>
                          {item.name ? item.name : `-`}
                        </h2>
                        {item.is_sale ? (
                          <p>
                            <del>
                              {item.price
                                ? `$${item.price
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                : `-`}
                            </del>
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <p className="product-price-mobile">
                    {item.is_sale !== 0
                            ? item.price_type === 1
                              ? `$${(item.price - item.sale_price)
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                              : `$${(
                                  item.price -
                                  (item.price *
                                    (100 *
                                      (1 - item.sale_price / item.price))) /
                                    100
                                )
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                            : `${!item.price ? `` : `$${item.price}`}`}
                             {item.is_sale ? (
                          <p>
                            <del>
                              {item.price
                                ? `$${item.price
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                : `-`}
                            </del>
                          </p>
                        ) : null}
                        </p>
                    <div className="home-productbox-bottom">
                      <div className="item-rating">
                      <Rating
                          name="hover-feedback"
                          value={item.rating}
                          precision={0.5}
                          readOnly
                        />
                      </div>
                      <div className="home-productbox-price">
                        <p>
                          {item.is_sale !== 0
                            ? item.price_type === 1
                              ? `$${(item.price - item.sale_price)
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                              : `$${(
                                  item.price -
                                  (item.price *
                                    (100 *
                                      (1 - item.sale_price / item.price))) /
                                    100
                                )
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                            : `${!item.price ? `` : `$${item.price}`}`}
                        </p>
                      </div>
                    </div>
                    {item.is_shipping_free ? (
                    <p className="shipping-mobile-label">FREE SHIPPING</p>
                    ) : null}
                  </div>
                </Link>
              </div>
            </div>
          );
        })
      ) : (
        <div></div>
      );
    return (
      <div>
        <main className="mainContainer">
          <section className="home-product-section pb-5 mt-4">
            <div className="container">
              <div className="container-fluid">
                <div className="select-product-list">
                  <div className="home-section-heading">
                    <div className="product-list-head">
                      <ul>
                        {this.state.isSearchCategory ? (
                          this.state.categoryTitle
                            .split(" ")
                            .map((title, index) => (
                              <>
                                {index !== 0 && (
                                  <li>
                                    <i className="fas fa-minus"></i>
                                
                                  </li>
                                )}
                                <li>
                                  <Link
                                    to={`/category/search/${this.state.splitTitleList[index]}`}
                                  >
                                    {this.replaceUnderScores(title)}
                                  </Link>
                                </li>
                              </>
                            ))
                        ) : (
                          <h3>{this.state.categoryTitle}</h3>
                        )}
                      </ul>
                    </div>
                  </div>
                  <span data-toggle="dropdown">
                <i className="fas fa-sliders-h" />
              </span>

              <div className="dropdown-menu dropdown-menu-right" 
              onChange={(e) => this.handleProductSorting(e, this.props.match.params.category)
                }>
                <a className="dropdown-item" onClick={(e) => this.handleProductSorting("undefined", this.props.match.params.category,true)} href="#">
                  RANDOM
                </a>
                <a className="dropdown-item" onClick={(e) => this.handleProductSorting("price_h", this.props.match.params.category,true)} href="#">
                  PRICE HIGH TO LOW
                </a>
                <a className="dropdown-item" onClick={(e) => this.handleProductSorting("price_l", this.props.match.params.category,true)} href="#">
                  PRICE LOW TO HIGH
                </a>
                <a className="dropdown-item" onClick={(e) => this.handleProductSorting("rating", this.props.match.params.category,true)} href="#">
                  TOP RATED
                </a>
              </div>
                  <select
                    value={this.state.sort_type}
                    onChange={(e) =>
                      this.handleProductSorting(
                        e,
                        this.props.match.params.category
                      )
                    }
                  >
                    <option value="undefined">Random</option>
                    <option value={"price_h"}>Price High to Low</option>
                    <option value={"price_l"}>Price Low to High</option>
                    <option value={"rating"}>Top Rated</option>
                  </select>
                </div>
                {this.state.isDataNotFound && (
                  <div className="page-search-not">
                    <img src={Nothing} alt="Gif" />
                    <p>
                      No Product found related to{" "}
                      <strong>
                        {this.state.categoryTitle
                          .toLowerCase()
                          .replace(/_/g, " ")}
                        !
                      </strong>
                    </p>
                  </div>
                )}

                <div className="my-4">
                  {this.state.isDataNotFound &&
                    this.state.items.length !== 0 && (
                      <h5 className="mb-4">Here are some related products</h5>
                    )}

                  <InfiniteScroll
                    dataLength={this.state.items.length}
                    next={this.fetchMoreData}
                    hasMore={this.state.hasMore}
                    loader={(() => {
                      if (this.state.isLoading) {
                        return <div className="loaing-area">Loading...</div>;
                      }
                    })()}
                    scrollThreshold="500px"
                  >
                    <div className="row">{products}</div>
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    categoryId: state.productReducer.categoryId,
    categoryName: state.productReducer.categoryName,
    productList: state.productReducer.productListByCategory,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getProductListByCategory,
      getProductListByFilternameAndCategory,
      getProductListBySearchCategory,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Product_Category_List)
);
