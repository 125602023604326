import React, { Component } from "react";

import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  getProductList,
  getChildCategoryList,
  getProductListbyFeature,
} from "../../Redux/Actions/productAction";
// import "./product-list.scss";
import "./../Product-Filter-List/product-list.scss";
import "../Filter-Product-List/Filter_Product_List.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import Rating from "@material-ui/lab/Rating";
import { withStyles } from "@material-ui/core/styles";

import BannerCategoryImgOne from "../../assets/images-2/banner-category-img-one.jpg";
import BannerCategoryImgThree from "../../assets/images-2/banner-category-img-three.jpg";
import BannerCategoryImgTwo from "../../assets/images-2/banner-category-img-two.jpg";
import axios from "axios";
let source;

const StyledRating = withStyles({
  iconFilled: {
    color: "#ff6d75",
  },
  iconHover: {
    color: "#ff3d47",
  },
})(Rating);

class Filter_Product_List extends Component {
  catUpdated = false;
  limit = 3;
  start = 0;
  prevStart = 0;
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      hasMore: true,
      categorySlug: this.props.match.params.category,
      isLoading: false,
      sort_type: undefined,
      listChildCategory: [],
      categoryList: [],
      isMobileView: false,
    };
    source = axios.CancelToken.source();
  }

  componentDidMount() {
    this.fetchChildCategory(this.state.categorySlug, 0);
    this.fetchProducts(this.props.match.params.category, true);
  }

  async componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.match.params.category !== this.props.match.params.category) {
      if (source) {
        source.cancel("Landing Component got props changed");
      }
      source = axios.CancelToken.source();
      //    this.setState({ startTime: nextProps.startTime });

      this.setState({
        items: [],
        hasMore: true,
        category: nextProps.match.params.category,
        isLoading: false,
        categoryList: [],
      });
      this.start = 0;
      this.prevStart = 0;
      this.setState(
        {
          categorySlug: nextProps.match.params.category,
        },
        () => {
          this.fetchChildCategory(nextProps.match.params.category, 0);
          this.fetchProducts(nextProps.match.params.category, true);
        }
      );
    }
    window.scrollTo(0, 0);
  }

  async addCategoriesById(category, categoryId, catList) {
    let isUpdated = false;
    for (let childCat of category.childCategoryList) {
      if (childCat.id === categoryId) {
        childCat.childCategoryList = [...catList];
        isUpdated = true;
        break;
      } else {
        isUpdated = await this.addCategoriesById(childCat, categoryId, catList);
        if (isUpdated) {
          break;
        }
      }
    }
    return isUpdated;
  }
  async fetchChildCategory(parentSlug, parentId) {
    const result = await this.props.getChildCategoryList(
      parentSlug,
      parentId,
      source.token
    );
    if (!result || result.data.length === 0) {
      return null;
    }
    const catList = result.data.map((i) => ({ ...i, childCategoryList: [] }));
    if (parentId === 0) {
      this.setState({
        categoryList: catList,
      });
      return;
    } else {
      let categoryList = [...this.state.categoryList];
      for (let category of categoryList) {
        if (category.id === parentId) {
          category.childCategoryList = [...catList];
          break;
        } else {
          const isUpdated = await this.addCategoriesById(
            category,
            parentId,
            catList
          );
          if (isUpdated) {
            break;
          }
        }
      }
      this.setState({
        categoryList: [...categoryList],
      });
    }
  }
  async fetchProducts(category, firstLoad) {
    category = category.split("?")[0];

    if (this.start !== this.prevStart || firstLoad) {
      await this.props.getProductListbyFeature(
        category,
        this.start,
        this.limit,
        this.state.sort_type,
        source.token
      );
      this.prevStart = this.start;
      this.start += this.limit;

      if (this.props.productList.length > 0) {
        if (firstLoad) {
          this.setState((prevState) => {
            return {
              ...prevState,
              items: [...this.props.productList],
            };
          });
        } else {
          this.setState((prevState) => {
            return {
              ...prevState,
              items: prevState.items.concat(this.props.productList),
            };
          });
        }
      } else {
        this.setState({ hasMore: false });
      }
    }
  }

  fetchMoreData = async () => {
    this.fetchProducts(this.props.match.params.category, false);
  };

  handleProductSorting = async (event, category, isDropDown) => {
    this.limit = 3;
    this.start = 0;
    this.prevStart = 0;
    let event_value;
    if (isDropDown) event_value = event;
    else {
      event.preventDefault();

      event_value = event.target.value;
    }
    await this.props.getProductListbyFeature(
      category,
      0,
      this.limit,
      event_value,
      source.token
    );

    await this.setState({
      sort_type: event_value,
      items: this.props.productList,
      hasMore: true,
    });
    this.prevStart = this.start;
    this.start += this.limit;
  };

  switchSubCategory = (slug) => {
    alert(slug);
  };

  addCatListHtml(catList, category) {
    catList.push(
      <>
        <a
          key={category.id}
          onClick={() => this.fetchChildCategory("", category.id)}
        >
          {category.name}
        </a>
        <br />
      </>
    );
    if (!category.childCategoryList) {
      return;
    }

    for (let child of category.childCategoryList) {
      this.addCatListHtml(catList, child);
    }
    // return null;
  }

  showChildCategories(category) {
    if (!category.childCategoryList) {
      return null;
    }
    let catList = [];
    let categoryList = category.childCategoryList;
    for (let cat of categoryList) {
      this.addCatListHtml(catList, cat);
    }
    return catList;
  }
  showCategory = () => {
    this.setState({
      isMobileView: !this.state.isMobileView,
    });
  };
  render() {
    const categoryList = (
      <>
        <div className="col-lg-3 col-md-4">
          <div className="refine-sidebar">
            <div className="refine-sidebar-heading">
              <h2>Refine Your Search</h2>
            </div>
            <div className="refine-sidebar-box">
              <div className="refine-sidebar-top">
                <h3 className="desktop-heading-refine">ALL CATEGORIES</h3>
                <h3
                  className="mobile-heading-refine"
                  onClick={this.showCategory}
                >
                  Refine Your Search
                </h3>
                <i
                  className={`fa fa-angle-down ${
                    this.state.isMobileView ? `reverse-icon` : ``
                  }`}
                  aria-hidden="true"
                  onClick={this.showCategory}
                ></i>
              </div>
              <div
                className={`refine-sidebar-mid ${
                  !this.state.isMobileView ? "desktop-heading-refine" : ""
                }`}
              >
                <ul>
                  {this.state.categoryList.length > 0
                    ? this.state.categoryList.map((category, index) => {
                        return (
                          <li key={index}>
                            <div className="first-category-child">
                              <i
                                className="fa fa-plus"
                                style={{ cursor: "pointer" }}
                                aria-hidden="true"
                                onClick={() => {
                                  this.props.history.push(
                                    `/product-by/category/${this.props.match.params.category}/${category.id}`,
                                    { categoryName: category.name }
                                  );
                                }}
                              />
                              {/* <a
                            onClick={()=>this.fetchChildCategory(this.state.categorySlug, category.id)}
                          >
                            {category.name}
                          </a> */}
                              <Link
                                className={
                                  // this.props.match.params.subCategory ==
                                  // category.slug
                                  //   ?
                                  "highlight"
                                  // : ""
                                }
                                onClick={() =>
                                  this.fetchChildCategory(
                                    this.state.categorySlug,
                                    category.id
                                  )
                                }
                                to={{
                                  pathname: `/product-by/category/${this.props.match.params.category}/${category.id}`,
                                  state: { categoryName: category.name },
                                }}
                              >
                                {category.name}
                              </Link>
                              {category.childCategoryList.length > 0 &&
                                this.showChildCategories(category)}
                            </div>
                          </li>
                        );
                      })
                    : ""}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-8 col-lg-9">
          {this.props.match.params.category == "access" ? (
            <div className="banner-category-part">
              <img src={BannerCategoryImgOne} alt="banner" />
              <div>
                <h1>ACCESS</h1>
                <p>
                  Gain Access To Industry Professionals Through Online One On
                  One or Group Sessions
                </p>
              </div>
            </div>
          ) : this.props.match.params.category == "replenish" ? (
            <div className="banner-category-part">
              <img src={BannerCategoryImgTwo} alt="banner" />
              <div>
                <h1>Replenish</h1>
                <p>Explore Life Essential Products For Your Everyday Needs</p>
              </div>
            </div>
          ) : this.props.match.params.category == "customize" ? (
            <div className="banner-category-part">
              <img src={BannerCategoryImgThree} alt="banner" />
              <div>
                <h1>customize</h1>
                <p>Explore Unique Personalized Goods, Plans, and Content</p>
              </div>
            </div>
          ) : null}
        </div>
      </>
    );
    const productList =
      this.state.items.length > 0 ? (
        this.state.items.map((category, index) => {
          return (
            <div key={index}>
              {category.prodList.length > 0 ? (
                <>
                  <div className="row order-change-mobile">
                    {index < 1 ? categoryList : ""}

                    <div className="col-lg-12 col-md-12">
                      <div className="home-section-heading mb-3">
                        <ul>
                          <li>
                            <h2>{category.catName}</h2>
                          </li>
                          <li>|</li>
                          <li>
                            <Link
                              to={`/category/type/${category.slug}/${this.props.match.params.category}`}
                            >
                              See All
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="row">
                        {category.prodList.map((item, itemIndex) => {
                          let isInStock = !item?.isOutOfStock;

                          return (
                            <div
                              className={"col-sm-6 col-md-6 col-lg-3 col-6"}
                              key={itemIndex}
                            >
                              <div className="home-product-item">
                                {item.is_sale !== 0 &&
                                this.props.match.params.category !==
                                  "access" ? (
                                  item.price_type === 1 ? (
                                    <div className="sale-label-star">
                                      ${item.sale_price} off
                                    </div>
                                  ) : (
                                    <div className="sale-label-star">
                                      {" "}
                                      {Math.round(
                                        100 * (1 - item.sale_price / item.price)
                                      )}
                                      % off
                                    </div>
                                  )
                                ) : null}
                                {item.is_shipping_free &&
                                this.props.match.params.category !==
                                  "access" ? (
                                  <div to="/" className="shipping-label-star">
                                    free shipping
                                  </div>
                                ) : null}
                                <Link
                                  to={{
                                    pathname: `/product-list/${this.props.match.params.category}/${item.slug}`,
                                    product: item,
                                    state: { spuId: item.spu_id },
                                  }}
                                >
                                  <div className="home-product-box">
                                    <img src={item.imageName} />
                                    {isInStock ||
                                    item?.isAccessCategoryAvailable ? (
                                      <div className="quickviewbtn">
                                        <p>Quick View</p>
                                      </div>
                                    ) : (
                                      <div className="quickviewbtn">
                                        <p>Sold Out</p>
                                      </div>
                                    )}

                                    {/* <a href="#" className="quickviewbtn">
                                                            <p>Quick View</p>
                                                        </a> */}
                                  </div>
                                  <div className="home-productbox-detail">
                                    <div className="home-productbox-heading">
                                      <div className="price-inline-remove">
                                        <h2
                                          title={item.name ? item.name : null}
                                        >
                                          {item.name ? item.name : `-`}
                                        </h2>
                                        {item.is_sale ? (
                                          <p>
                                            <del>
                                              {item.price
                                                ? `$${item.price
                                                    .toFixed(2)
                                                    .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                    )}`
                                                : ``}
                                            </del>
                                          </p>
                                        ) : null}
                                      </div>
                                    </div>
                                    <p className="product-price-mobile">
                                      {item.is_sale !== 0
                                        ? item.price_type === 1
                                          ? `$${(item.price - item.sale_price)
                                              .toFixed(2)
                                              .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )}`
                                          : `$${(
                                              item.price -
                                              (item.price *
                                                (100 *
                                                  (1 -
                                                    item.sale_price /
                                                      item.price))) /
                                                100
                                            )
                                              .toFixed(2)
                                              .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )}`
                                        : `${
                                            !item.price ? `` : `$${item.price}`
                                          }`}
                                      {item.is_sale ? (
                                        <p>
                                          <del>
                                            {item.price
                                              ? `$${item.price
                                                  .toFixed(2)
                                                  .replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  )}`
                                              : `-`}
                                          </del>
                                        </p>
                                      ) : null}
                                    </p>
                                    <div className="home-productbox-bottom">
                                      <div className="item-rating">
                                        <Rating
                                          name="hover-feedback"
                                          value={item.rating}
                                          precision={0.5}
                                          readOnly
                                        />
                                      </div>
                                      <div className="home-productbox-price">
                                        <p>
                                          {item.is_sale !== 0
                                            ? item.price_type === 1
                                              ? `$${(
                                                  item.price - item.sale_price
                                                )
                                                  .toFixed(2)
                                                  .replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  )}`
                                              : !item.price
                                              ? ``
                                              : `$${(
                                                  item.price -
                                                  (item.price *
                                                    (100 *
                                                      (1 -
                                                        item.sale_price /
                                                          item.price))) /
                                                    100
                                                )
                                                  .toFixed(2)
                                                  .replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  )}`
                                            : `${
                                                !item.price
                                                  ? ``
                                                  : `$${item.price}`
                                              }`}
                                        </p>
                                      </div>
                                    </div>
                                    {item.is_shipping_free ? (
                                      <p className="shipping-mobile-label">
                                        FREE SHIPPING
                                      </p>
                                    ) : null}
                                  </div>
                                </Link>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          );
        })
      ) : (
        <div className="row">{categoryList}</div>
      );

    return (
      <main className="mainContainer">
        <section className="home-product-section pb-5 mt-4">
          <div className="container">
            <div className="select-product-list">
              <div className="product-list-head">
                <ul>
                  <li>
                    <a>
                      {this.props.location.search === ""
                        ? this.props.match.params.category.split("?")[0]
                        : this.props.match.params.category.replace("-", " ")}
                      {/* {this.props.match.params.category.replace("-", " ")} */}
                    </a>
                  </li>
                </ul>
              </div>

              <span data-toggle="dropdown">
                <i className="fas fa-sliders-h" />
              </span>

              <div
                className="dropdown-menu dropdown-menu-right"
                onChange={(e) =>
                  this.handleProductSorting(e, this.props.match.params.category)
                }
              >
                <a
                  className="dropdown-item"
                  onClick={(e) =>
                    this.handleProductSorting(
                      "undefined",
                      this.props.match.params.category,
                      true
                    )
                  }
                  href="#"
                >
                  RANDOM
                </a>
                <a
                  className="dropdown-item"
                  onClick={(e) =>
                    this.handleProductSorting(
                      "price_h",
                      this.props.match.params.category,
                      true
                    )
                  }
                  href="#"
                >
                  PRICE HIGH TO LOW
                </a>
                <a
                  className="dropdown-item"
                  onClick={(e) =>
                    this.handleProductSorting(
                      "price_l",
                      this.props.match.params.category,
                      true
                    )
                  }
                  href="#"
                >
                  PRICE LOW TO HIGH
                </a>
                <a
                  className="dropdown-item"
                  onClick={(e) =>
                    this.handleProductSorting(
                      "rating",
                      this.props.match.params.category,
                      true
                    )
                  }
                  href="#"
                >
                  TOP RATED
                </a>
              </div>

              {/* {productList} */}
              <select
                onChange={(e) =>
                  this.handleProductSorting(e, this.props.match.params.category)
                }
              >
                <option value="undefined">Random</option>
                <option value={"price_h"}>Price High To Low</option>
                <option value={"price_l"}>Price Low To High</option>
                <option value={"rating"}>Top Rated</option>
              </select>
            </div>

            {productList}
          </div>
        </section>
        <section className="home-product-section mb-5">
          <div className="container">
            <div className="home-section-heading mb-3">
              <ul>
                <li>
                  <h2>
                    Shop by category <span>-</span> all
                  </h2>
                </li>
              </ul>
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4">
                  <Link
                    to={{
                      pathname: "/product-list/category/access",
                      parentId: "3",
                      categoryById: "4",
                    }}
                  >
                    <div className="button-category-shop">access</div>
                  </Link>
                </div>

                <div className="col-md-4">
                  <Link
                    to={{
                      pathname: "/product-list/category/replenish",
                      state: this.state,
                      parentId: "101",
                      categoryById: "5",
                    }}
                  >
                    <div className="button-category-shop ">replenish</div>
                  </Link>
                </div>

                <div className="col-md-4">
                  <Link
                    to={{
                      pathname: "/product-list/category/customize",
                      parentId: "119",
                      categoryById: "6",
                    }}
                  >
                    <div className="button-category-shop ">customize</div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    categoryId: state.productReducer.categoryId,
    categoryName: state.productReducer.categoryName,
    productList: state.productReducer.productListByFilter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getProductList, getChildCategoryList, getProductListbyFeature },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Filter_Product_List)
);
