import React, { Component } from "react";
import "../../Styles/profile-side-bar.scss";
import profileAvtar from "../../assets/images/profile-img.png";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { getMessageCountByBuyerId } from "../../Redux/Actions/oneOnOneMeetings";
import { getMessageCount } from "../../Redux/Actions/messageAction";

class Profile_Sidebar extends Component {
  userId = localStorage.getItem("userId");
  monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  timestamp = "?time=" + new Date().getTime();

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (this.props.userInfo) {
      this.setUserState(this.props.userInfo);
      this.props.getMessageCountByBuyerId(this.userId);
      this.props.getMessageCount(this.userId);
    }
  }

  setUserState(userInfo) {
    var dateObject = new Date(userInfo?.createts);
    var year = dateObject.getFullYear();
    var month = dateObject.getMonth();
    var date = dateObject.getDate();
    if (this.monthNames[month] !== undefined) {
      let memberSince = `${this.monthNames[month]} ${date}, ${year}`;
      this.setState({
        ...userInfo,
        memberSince,
      });
    }
  }

  componentWillReceiveProps(nextProps, prevState) {
    if (Object.keys(this.state).length > 0) {
      if (nextProps.userInfo?.timestamp !== this.state.timestamp) {
        this.setUserState(nextProps.userInfo);
        // this.setState({
        //     ...nextProps.userInfo
        // })
      }
    } else if (nextProps.userInfo !== null) {
      // this.props.userInfo = {...nextProps.userInfo}
      this.setUserState(nextProps.userInfo);
      // this.setState({
      //     ...nextProps.userInfo
      // })
    }
  }

  render() {
    const countMessageCount = () => {
      this.props.getMessageCountByBuyerId(this.userId);
      this.props.getMessageCount(this.userId);
    };
    return (
      <div className="col-lg-3">
        <div className="profile-tab">
          <div className="user-tab-detail">
            <div className="user-tab-detail-img">
              <img
                src={
                  this.state?.imagename && this.state?.imagename !== "undefined"
                    ? this.state.imagename
                    : profileAvtar
                }
              />
            </div>
            <h2>
              {this.state?.firstname} {this.state?.lastname}
            </h2>
            <h3>Member Since</h3>
            <p>{this.state?.memberSince}</p>
          </div>

          <div className="profile-tab-nav">
            <ul>
            <li>
                <Link
                  onClick={countMessageCount}
                  to={"/global-calendar"}
                  className={
                    this.props.location.pathname === "/global-calendar"
                      ? "active"
                      : "null"
                  }
                >
                  <h4>Calendar</h4>
                  <p>View Meetings</p>
                </Link>
              </li>
              <li>
                <Link
                  onClick={countMessageCount}
                  to={"/subscription"}
                  className={
                    this.props.match.path == "/subscription" ? "active" : "null"
                  }
                >
                  <h4>Subscriptions</h4>
                  <p>View Or Update Subscription Plans</p>
                </Link>
              </li>
              <li>
                <Link
                  onClick={countMessageCount}
                  to={"/orders"}
                  className={
                    this.props.match.path == "/orders" ||
                    this.props.match.path == "/orders/:orderId" ||
                    this.props.match.path == "/orders/track/:trackId"
                      ? "active"
                      : "null"
                  }
                >
                  <h4>Orders / Track</h4>
                  <p>View Or Track Your Subscription Orders</p>
                </Link>
              </li>
              {/* <li>
                <Link
                  onClick={countMessageCount}
                  to={"/disputes"}
                  className={
                    this.props.match.path == "/disputes" ||
                    this.props.match.path.includes("dispute/")
                      ? "active"
                      : "null"
                  }
                >
                  <h4>Order Support</h4>
                  <p>Resolve Disputes</p>
                </Link>
              </li> */}
              {/* {TODO uncomment ooo meeting} */}
              <li>
                <Link
                  onClick={countMessageCount}
                  to={"/one-on-one-meetings"}
                  className={
                    this.props.match.path == "/one-on-one-meetings" ||
                    this.props.match.path == "/one-on-one-meetings/:meetingId"
                      ? "active"
                      : "null"
                  }
                >
                  <h4>
                    One On One Sessions
                    {this.props.unreadMessageCount > 0 && (
                      <span
                        className="badge badge-dark"
                        style={{ marginLeft: "10px" }}
                      >
                        {this.props.unreadMessageCount}
                      </span>
                    )}
                  </h4>
                  <p>One On One Sessions</p>
                </Link>
              </li>
              <li>
                <Link
                  onClick={countMessageCount}
                  to={"/order-return-list"}
                  className={
                    this.props.match.path == "/order-return-list" 
                    // ||
                    // this.props.match.path == "/order-return-request/:meetingId"
                      ? "active"
                      : "null"
                  }
                >
                  <h4>
                    Order Return Request
                   
                  </h4>
                  <p>Order Return Request</p>
                </Link>
              </li>
              <li>
                <Link
                  onClick={countMessageCount}
                  to={"/messages"}
                  className={
                    this.props.match.path == "/messages" ? "active" : "null"
                  }
                >
                  <h4>Contact Vendor</h4>
                  <p>Messaging</p>
                  <p>Send Message</p>
                  {this.props.unreadVendorMessageCount > 0 && (
                      <span
                        className="badge badge-dark"
                        style={{ marginLeft: "10px" }}
                      >
                        {this.props.unreadVendorMessageCount}
                      </span>
                    )}
                </Link>
              </li>

              {/* <li>
                <Link
                  onClick={countMessageCount}
                  to={"/global-calendar"}
                  className={
                    this.props.location.pathname === "/global-calendar"
                      ? "active"
                      : "null"
                  }
                >
                  <h4>Calendar</h4>
                  <p>View Meetings</p>
                </Link>
              </li> */}

              <li>
                <Link
                  onClick={countMessageCount}
                  to={"/disputes"}
                  className={
                    this.props.match.path == "/disputes" ||
                    this.props.match.path.includes("dispute/")
                      ? "active"
                      : "null"
                  }
                >
                  <h4>Order Support</h4>
                  <p>Resolve Disputes</p>
                </Link>
              </li>

              <li>
                <Link
                  onClick={countMessageCount}
                  to={"/profile"}
                  className={
                    this.props.location.pathname === "/profile"
                      ? "active"
                      : "null"
                  }
                >
                  <h4>Login/Security</h4>
                  <p>Edit Or Update Information</p>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userReducer.userInfo,
    unreadMessageCount: state.userReducer.unreadMessageCount,
    unreadVendorMessageCount: state.userReducer.unreadVendorMessageCount
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getMessageCountByBuyerId,
      getMessageCount,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Profile_Sidebar)
);
