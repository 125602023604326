import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const signup = ({
  firstname,
  lastname,
  email,
  password,
  confirmPassword,
  isacceptedtnc,
  newsletter,
}) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/users/signup`,
      data: {
        firstname,
        lastname,
        email,
        password,
        confirmPassword,
        isacceptedtnc,
        newsletter
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    dispatch({
      type: "SIGNUP",
      payload: { data: res.data },
    });
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const vemail = ({ code }) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "patch",
      url: `${URL}/api/v1/users/verifyCode`,
      data: {
        code,
        userId: localStorage.getItem("userId"),
        companyId: localStorage.getItem("companyId"),
      },
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    dispatch({
      type: "EMAILVERIFICATION",
      payload: { data: res.data },
    });
    localStorage.clear();
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const login = ({ email, password }) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/users/login`,
      data: {
        email,
        password,
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    dispatch({
      type: "LOGIN",
      payload: { data: res.data },
    });

    dispatch({
      type: "SETUSERINFO",
      payload: res.data.user,
    });

  } catch (error) {
    dispatch(handleError(error));
  }
};


export const socialLogin = (body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/users/sociallogin`,
      data: body
    });
    dispatch({ type: "LOADING_COMPLETED" });
    
    dispatch({
      type: "SOCIALLOGIN",
      payload: { data: res.data },
    });

    dispatch({
      type: "SETUSERINFO",
      payload: res.data.user,
    });

  } catch (error) {
    dispatch(handleError(error));
  }
};


export const socialSignup = (body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/users/socialSignUp`,
      data: body
    });
    dispatch({ type: "LOADING_COMPLETED" });
    
    dispatch({
      type: "SOCIALLOGIN",
      payload: { data: res.data },
    });

    dispatch({
      type: "SETUSERINFO",
      payload: res.data.user,
    });

  } catch (error) {
    dispatch(handleError(error));
  }
};








export const logout = () => async (dispatch) => {
  try {
    const rememberme = localStorage.getItem("rememberme");
    const cartItems = localStorage.getItem("cartItems");
    
    localStorage.clear();

    if(rememberme){
      localStorage.setItem("rememberme", rememberme);
    }
    if(cartItems){
      localStorage.setItem("cartItems", cartItems);
    }

    

    let auth2 = window.gapi.auth2.getAuthInstance();
    auth2.signOut().then(function () {
      console.log('User signed out.');
    });

    dispatch({
      type: "LOGOUT",
    });
  } catch (error) {
    dispatch(handleError(error));
  }
};



export const matchtoken = () => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/users/matchtoken?companyId=${localStorage.getItem(
        "companyId"
      )}&userId=${localStorage.getItem("userId")}&token=${localStorage.getItem(
        "token"
      )}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    dispatch({
      type: "LOGIN",
      payload: { data: res.data },
    });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.clear();
        window.location.reload();
      } else {
        dispatch({
          type: "ERROR",
          payload: { error: error.response.data },
        });
      }

    } else { }
  }
};


export const updateProfile = (data) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    let formData = new FormData()
    for (var key in data) {
      formData.append(key, data[key]);
    }
    // formData.append("selectedImg", data.selectedImg);
    // formData.append("lastname", data.lastname);

    const res = await axios({
      method: 'post',
      url: `${URL}/api/v1/users/${data.id}/updateProfile`,
      data: formData,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    });
    dispatch({
      type: "SETUSERINFO",
      payload: res.data.data,
    });
    return res;
  } catch (error) {
    return error
  }
}

export const getUserDetail = (data) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: 'get',
      url: `${URL}/api/v1/users/${data.id}`,
      data: data,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    dispatch({
      type: "SETUSERINFO",
      payload: res?.data?.data
    });
    return res;

  } catch (error) {
    dispatch(handleError(error));
  }
}


export const logoutUser = () => async (dispatch) => {
  try {
    localStorage.removeItem('userId');
    localStorage.removeItem('userData');
    localStorage.removeItem('token');
    dispatch({ type: "LOGOUT" });



  } catch (error) {


  }
}

export const updateLoginType = (data) => async (dispatch) => {
  dispatch({type : "UPDATE_LOGIN_TYPE", payload : data})
}