import React, { Component } from "react";
import "../../Styles/product-detail.scss";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import OwlCarousel from "react-owl-carousel";
// import moment from "moment";
import moment from "moment-timezone";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import TimePicker from "react-time-picker";
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { timezoneList, weekDayObj } from "../../Constants/constant";
// import DatePicker from 'react-date-picker';
import AlertModal from "../../Components/AlertModal/AlertModal";
import {
  getProductDetail,
  getProductPrice,
  submitRatingReview,
  getRatingForUser,
  clearStateProductState,
  getUpdatedDobaProduct,
  uploadImage,
  getOtherComment,
  getSuggestedProductList,
  bookFreeSeminarSlot,
  getSessionFrequency,
  getOneTimeSlots,
  getSessionSlot,
} from "../../Redux/Actions/productAction";
import { addToCart, getCartItems } from "../../Redux/Actions/cartAction";
import Rating from "@material-ui/lab/Rating";
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import ctaImg from "../../assets/images/cta-img.png";
import { Modal } from "react-bootstrap";

import ZoomMeetingCalendar from "../Calendar/Calendar";
import imageCompression from "browser-image-compression";
import { trim } from "lodash";
const vendorUrl = process.env.REACT_APP_VENDOR_URL;
const weekDays = {
  Sun: "Sunday",
  Mon: "Monday",
  Tue: "Tuesday",
  Wed: "Wednesday",
  Thu: "Thursday",
  Fri: "Friday",
  Sat: "Saturday",
};
const StyledRating = withStyles({
  iconFilled: {
    color: "#ff6d75",
  },
  iconHover: {
    color: "#ff3d47",
  },
})(Rating);

const labels = {
  0.5: 0.5,
  1: 1,
  1.5: 1.5,
  2: 2,
  2.5: 2.5,
  3: 3,
  3.5: 3.5,
  4: 4,
  4.5: 4.5,
  5: 5,
};

const options = {
  margin: 20,
  responsiveClass: true,
  nav: true,
  dots: false,
  autoplay: false,
  responsive: {
    0: {
      items: 2,
    },
    768: {
      items: 1,
    },
    992: {
      items: 1,
    },
    1200: {
      items: 2,
    },
  },
};

let  removeMonthly = false;

class ProductDetail extends Component {
  allAttributes = [];

  constructor(props) {
    super(props);
    this.state = {
      customizedProductTime: "",
      vendorSelectedWeekDay: null,
      flagForVariant: false,
      Showloader: false,
      quantity: 1,
      selectedPair: {},
      isAddedToCart: false,
      isPreview: false,
      errorData: {},
      ratingValue: 0,
      hover: -1,
      userData: JSON.parse(localStorage.getItem("userData")),
      ratingError: "",
      orderLimitError: false,
      showCalendar: false,
      paymentOptionType: "recurring",
      discountPercentage: 0,
      showMoreDiscription: false,
      spuId: this.props.location.state && this.props.location.state.spuId,
      product: {},
      showModal: false,
      disableButton: false,
      modalImage: "",
      otherComments: [],
      order_id: null,
      loadCommentsAtaTime: 5,
      suggestedProducts: [],
      limit: 15,
      isMeetingOneOnOne: false,
      isDobaUnavailable: false,
      showHideFreeSeminarModal: false,
      session: "",
      sessionFrequency: "",
      sessionSlot: [],
      sessionGroupMeetingNameOption: [],
      sessionGroupMeetingName: null,
      formData: {
        start: "",
        end: "",
        date: "",
        price: "",
        name: "",
      },
      alertState: {
        open: false,
        message: "",
        singleButton: true,
      },
      isFreeSeminarCreated: false,
      reqMessage: "",
      selectedWeekDay: null,
      availableTimeSlots: {},
      selectedOneTimeDate: "",
      selectedSlots: [],
      sessionFrequencyOption: [],
      selectedStructureId: null,
      availableWeek: ["First", "Second", "Third"],
      customizeQuestions: [],
      showErrorMessage:"",
      showAccordion: false,
    };
    this.handleDateTimeChange = this.handleDateTimeChange.bind(this);
    this.fileUpload = React.createRef();
    this.DatePicker = React.createRef();
    this.storeId = null;
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  checkForProductPreview = () => {
    this.setState({
      isPreview: false,
    });
    let queryParams = this.props.location.search;
    if (queryParams.length > 0) {
      queryParams = queryParams.slice(1);
      if (queryParams === "preview") {
        this.setState({
          isPreview: true,
        });
      }
    }
  };

  async getProductList() {
    const result = await this.props.getSuggestedProductList(
      this.props.product?.id,
      this.state.limit
    );
    this.setState({
      suggestedProducts: result?.data?.data,
    });
  }

  async componentDidMount() {
    await this.checkForProductPreview();

    // if (!this.state.spuId){
    await this.getProductDetail(this.props.match.params.productid);

    // }
    if (this.state.spuId) {
      const product = await this.props.getUpdatedDobaProduct(
        this.props.match.params.productid,
        this.state.spuId
      );

      if (product) {
        this.setState({
          product,
          flagForVariant: true,
        });
      } else {
        this.setState({
          isDobaUnavailable: true,
          flagForVariant: false,
        });
      }
    }
    this.props.getCartItems();
    /*setTimeout(
            () => this.getProductDetail(this.props.match.params.productid),
            1000
        );*/
    // check user login or not
    if (this.state.userData?.id) {
      await this.getProductRatingDetails(
        this.state.userData.id,
        this.props.product?.id
      );
    }
    if (
      this.props.product.description &&
      this.props.product.description.length > 205
    ) {
      this.setState({ showMoreDiscription: true });
    }
  }

  async componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (
      nextProps.match.params.productid !== this.props.match.params.productid
    ) {
      this.setState({
        suggestedProducts: [],
        sessionFrequency: "",
        availableTimeSlots: {},
        session: "",
        selectedWeek: null,
      });
      this.checkForProductPreview();
      await this.getProductDetail(nextProps.match.params.productid);
      await this.props.getCartItems();
      if (this.state.userData?.id) {
        await this.getProductRatingDetails(
          this.state.userData.id,
          this.props.product.id
        );
      }
      window.scrollTo(0, 0);
    }

    if (nextProps.product.name && nextProps.product.name.length !== 0) {
      if (
        (nextProps.product.isReplenishCategoryAvailable === 1 ||
          nextProps.product.isCustomizeCategoryAvailable === 1) &&
        nextProps.product.isAccessCategoryAvailable != 1
      ) {
        this.setState({
          paymentOptionType: "one_time",
        });
      }
    }

    if (
      nextProps.location.state &&
      nextProps.location.state.spuId &&
      (nextProps.location.state.spuId !== this.props.location?.state?.spuId ||
        !this.state.spuId)
    ) {
      this.setState(
        {
          spuId: nextProps.location.state && nextProps.location.state.spuId,
        },
        async () => {
          const product = await this.props.getUpdatedDobaProduct(
            this.props.match.params.productid,
            this.state.spuId
          );
          if (product) {
            this.setState({
              product,
            });
          } else {
            this.setState({
              isDobaUnavailable: true,
            });
          }
        }
      );
    } else if (!nextProps?.location?.state?.spuId) {
      this.setState({
        spuId: undefined,
      });
    }
  }

  async getProductDetail(productID) {
    this.setState({
      Showloader: true,
    });
    let product = await this.props.getProductDetail(
      productID,
      this.state.isPreview,
      localStorage.getItem("userId"),
      this.timezone
    );

    if (!this.state.spuId && product?.spu_id && product?.spu_id.length !== 0) {
      const updateProduct = await this.props.getUpdatedDobaProduct(
        this.props.match.params.productid,
        product.spu_id
      );
      if (updateProduct) {
        product = updateProduct;
        this.setState({
          product: updateProduct,
          spuId: product.spu_id,
        });
      } else {
        this.setState({
          isDobaUnavailable: true,
        });
      }
    }
    this.getProductList();

    this.setState({
      customizedProductTime: product.customize_delivery_time,
      customizeQuestions:
        product.customize_questions !== "undefined" &&
        product.customize_questions
          ? JSON.parse(product.customize_questions)
          : null,
    });

    const seminarDetails =
      product.free_seminar_details && product.free_seminar_details.length
        ? JSON.parse(product.free_seminar_details)
        : null;

    // const seminarDetails = product.free_seminar_details && product.free_seminar_details.length ?
    //   JSON.parse(product.free_seminar_details) : null

    this.storeId = product.storeId;

    this.setState({
      otherComments: await this.getOtherComments(
        this.props.product?.id,
        this.state.loadCommentsAtaTime
      ),
    });
    this.setState(
      {
        sessionsOption: product?.available_options,
        quantity: 1,
        selectedPair: {},
        isAddedToCart: false,
        errorData: {},
        discountPercentage:
          this.props.product.sale_price == 0
            ? 0
            : Math.round(
                100 *
                  (1 - this.props.product.sale_price / this.props.product.price)
              ),
        paymentOptionType:
          (this.state.product.isReplenishCategoryAvailable === 1 ||
            this.state.product.isCustomizeCategoryAvailable === 1) &&
          this.state.product.isAccessCategoryAvailable != 1
            ? "one_time"
            : "recurring",
        customizationNote: "",
        customizationImage: "",
        product,
        formData: {
          start: seminarDetails && seminarDetails.start,
          end: seminarDetails && seminarDetails.end,
          date: seminarDetails && seminarDetails.date,
          note: seminarDetails && seminarDetails.note,
          price: seminarDetails && seminarDetails.price,
          name: seminarDetails && seminarDetails.name,
        },
        isFreeSeminarCreated: seminarDetails ? true : false,
        userRegisteredForFreeSeminar: product.freeSeminarId,
        disableBookOneTimeSlot: product.freeSeminarId ? true : false,
      },
      async () => {
        // if (
        //   !this.state.spuId &&
        //   product.spu_id &&
        //   product.spu_id.length !== 0
        // ) {
        //   const updateProduct = await this.props.getUpdatedDobaProduct(
        //     this.props.match.params.productid,
        //     product.spu_id
        //   );
        //   if (updateProduct) {
        //     this.setState({
        //       product: updateProduct,
        //       spuId: product.spu_id,
        //     });
        //   } else {
        //     this.setState({
        //       isDobaUnavailable: true,
        //     });
        //   }
        // }
        // this.getProductList();
      }
    );
    this.setState({
      Showloader: false,
    });
    this.setState({
      Showloader: false,
    });
  }

  componentWillUnmount() {
    this.props.clearStateProductState();
  }

  getProductRatingDetails = async (userId, productId) => {
    this.props.getRatingForUser({ userId, productId });
  };

  getDatesArrayOccuringOnSameDayOfMonth = (day) => {
    // day is long day string
    let dateArray = [];
    const date = moment(new Date()).format("YYYY-MM-DD");
    let weekDay = moment(date).startOf("month").day(day);

    if (weekDay.date() > 7) weekDay.add(7, "d");
    let month = weekDay.month();
    while (month === weekDay.month()) {
      dateArray.push(
        moment(
          weekDay.year().toString() +
            "-" +
            (weekDay.month() + 1).toString() +
            "-" +
            weekDay.date().toString(),
          "YYYY-MM-DD"
        ).format("YYYY-MM-DD")
      );
      weekDay.toString();
      weekDay.add(7, "d");
    }
    return dateArray;
  };

  getTimeDifference = (sellerTimezone, userTimezone) => {
    const timezoneDetail = timezoneList.find(
      (item) => item.tz === sellerTimezone
    );
    const now = moment.utc();
    let customer_tz_offset = moment.tz.zone(userTimezone).utcOffset(now) / 60;
    if (customer_tz_offset > 0) {
      customer_tz_offset = customer_tz_offset - customer_tz_offset * 2;
    } else if (customer_tz_offset < 0) {
      customer_tz_offset = -customer_tz_offset;
    }
    let cv_timezone_diff = -customer_tz_offset + timezoneDetail.tzDiff; // this converts customer tz to vendor tz for vice versa change sign of both variables
    const cv_timezone_diff_split = cv_timezone_diff.toString().split(".");
    const hours_interval = cv_timezone_diff_split[0];
    const minutes_interval = hours_interval.includes("-")
      ? "-" + (cv_timezone_diff_split[1] === "5" ? "30" : "0")
      : cv_timezone_diff_split[1] === "5"
      ? "30"
      : "0";
    return { hours_interval, minutes_interval };
  };

  parseDateByTimezoneInterval = (
    date,
    time,
    hoursInterval,
    minutesInterval
  ) => {
    const day = moment(moment(date).hours(moment(date + " " + time).hours() + hoursInterval)).minutes(moment(date + " " + time).minutes() + minutesInterval);
    let options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    };
    let today = moment(day).format("YYYY-MM-DD HH:mm");
    return today;
  };

  getOtherComments = async (productId, limitPerClick) => {
    const otherUserCommentsData = await this.props.getOtherComment({
      productId,
      limitPerClick,
    });
    return otherUserCommentsData?.data?.data;
  };

  handleQuantityChange = (incmType, localQuantity) => {
    if (incmType == "plus") {
      let weeklyCheck = this.state.product.timeFrame.includes("weekly")
        ? this.state.quantity *  (((this.state.product.isReplenishCategoryAvailable || this.state.product.isCustomizeCategoryAvailable) && this.state.paymentOptionType==="one_time")?1:4)
        : this.state.quantity;
      if (
        weeklyCheck +
          this.state.product.totalsubscription +
          this.state.product.oneTimeOrder +
          localQuantity -
          1 <
        this.state.product.order_limit
      ) {
        this.setState((prevState) => {
          if (prevState.quantity < this.state.product.quantity) {
            return {
              ...prevState,
              quantity: prevState.quantity + 1,
            };
          }
        });
      } else {
        this.setState({
          orderLimitError: true,
        });
      }
    } else {
      this.setState((prevState) => {
        if (prevState.quantity > 1) {
          return {
            ...prevState,
            quantity: prevState.quantity - 1,
            orderLimitError: false,
          };
        }
      });
    }
  };

  validateItemOptions = () => {
    let isFormValid = true;
    let selectedPair = this.state.selectedPair;
    let errorData = {};

    this.allAttributes.forEach((attribute) => {
      if (!selectedPair[attribute]) {
        isFormValid = false;
        errorData[attribute] = attribute
          ? `${attribute.replace("_", " ")} is required`
          : "Option is required";
      }
    });

    this.setState({
      errorData,
    });

    return isFormValid;
  };

  alertModal = (data) => {
    this.setState({
      alertState: { open: true, message: data.message, singleButton: true },
    });
  };

  closeAlertModal = () => {
    this.setState({
      alertState: {
        open: false,
        message: "",
        singleButton: true,
      },
    });
  };

  addToCart = async (item, quantity, seminarPrice, seminarName,alertState,seminarExist) => {
    let cartItems = localStorage.getItem("cartItems")
      ? JSON.parse(localStorage.getItem("cartItems"))
      : null;
    let isSeminarProductAlreadyInCart =
      cartItems &&
      cartItems.filter(
        (i) => item.id == i.id && !!seminarPrice && !!i.isSeminarEvent
      );

    // let productId = i.id
    // let seminarEvent = i.isSeminarEvent
    // if( productId = i.id && seminarEvent){
    //   isItemInCart = true
    //   return
    // }else {
    //   isItemInCart = false
    // }
    // })

    if (isSeminarProductAlreadyInCart?.length) {
      this.alertModal({
        message: `Item Already Exists In The Cart!`,
        singleButton: true,
      });
      return;
    }
    if (item.productVariation.length > 0 && !this.validateItemOptions()) {
      return;
    }

    if (
      this.state?.sessionsOption &&
      this.state.product.isAccessCategoryAvailable &&
      (this.state?.session !== "group" || (this.state?.session == "group" && !this.state.selectedGroupMeetingName)) &&
      !!!this.state.selectedSlots?.length &&
      !!!seminarPrice
    ) {
      this.setState({
        showErrorMessage: "Please Select Slot To Add Item In Cart",
      });
      return;
    }
    // else{
    //   let productDetails = {...item}
    //   productDetails["subscriptionPrice"] = this.state.subscriptionPrice
    //   productDetails["oneTimePrice"] =      this.state.oneTimePrice
    //   productDetails["sessionFrequency"] =  this.state.sessionFrequency
    // }

    item["selectedPair"] = this.state.selectedPair;
    this.props.addToCart(
      {
        ...item,
        isSeminarEvent: !!seminarPrice,
        sale_price: this.state.discountPercentage,
        // actualPrice: item[`price`],
        actualPrice: seminarPrice ? seminarPrice : item[`price`],
        name: seminarName ? seminarName : item[`name`],
        price:
          !!!this.state.product.isAccessCategoryAvailable ||
          (this.state.product.isAccessCategoryAvailable &&
            !!!this.state?.sessionsOption) ||
          !!seminarPrice
            ? // actualPrice: item[`price`],
              // price:
              item.is_sale == 0
              ? // ? item[`price`]
                seminarPrice
                ? seminarPrice
                : item[`price`]
              : item["price_type"] == 1
              ? parseFloat(item[`price`] - item[`sale_price`])
              : this.state.product.price -
                (this.state.product.price * this.state.discountPercentage) / 100
            : this.state.sessionFrequency == "One Time"
            ? this.state.oneTimePrice
            : this.state.subscriptionPrice,
        // (this.state.product.price * this.state.discountPercentage) / 100,
        paymentOptionType:
          this.state.session == "one_on_one" &&
          this.state?.sessionFrequency?.toLowerCase() == "one time"
            ? "one_time"
            : this.state.paymentOptionType,
        customizationNote: this.state.customizationNote,
        customizationImage: this.state.customizationImage,
        subscriptionPrice: this.state.subscriptionPrice,
        oneTimePrice: this.state.oneTimePrice,
        sessionFrequency: seminarExist ? "" : this.state.sessionFrequency,
        selectedStructureId: seminarExist ? null : this.state.selectedStructureId,
        selectedWeekDay: seminarExist ? null :this.state.selectedWeekDay,
        vendorSelectedWeekDay: seminarExist ? null :this.state.vendorSelectedWeekDay,
        sessionType: seminarExist ? "" :this.state.session,
        timeSlots: seminarExist ? [] : item.timeSlots,
        vendorTimeSlots: seminarExist ? [] : item.vendorTimeSlots,
      },
      !this.state.product.isAccessCategoryAvailable ||
        (this.state.product.isAccessCategoryAvailable &&
          !this.state?.sessionsOption) ||
        !seminarPrice
        ? quantity
        : this.state.selectedSlots?.length
        ? this.state.selectedSlots?.length
        : 1
      // !seminarPrice ? quantity : 1 ################# shubham's changes
    );
    this.setState({
      isAddedToCart: true,
      // showHideFreeSeminarModal: false
    });

    setTimeout(() => {
      this.setState({
        isAddedToCart: false,
        showHideFreeSeminarModal: false,
      });
    }, 2000);
    // if (this.validateItemOptions()) {
    //     // let { option1, option2, option3 } = this.state;
    //     // item = Object.assign(item, { option1, option2, option3 })

    //     item["selectedPair"] = this.state.selectedPair;

    //     this.props.addToCart(item, quantity);
    //     this.setState({
    //         isAddedToCart: true
    //     });
    //     setTimeout(() => {
    //         this.setState({
    //             isAddedToCart: false
    //         });
    //     }, 2000);
    // }
  };

  handleChange = (event, field) => {
    event.persist();
    this.setState((prevState) => ({
      ...prevState,
      [field]: event.target.value,
    }));
  };

  handleOptionChange = async (event, field, isEnableOption, index) => {
    event.persist();
    this.setState({ disableButton: true });

    let productVariations = this.state.product.productVariation;
    if (this.state.errorData && this.state.errorData[field]) {
      delete this.state.errorData[field];
    }

    let selectedPair = this.state.selectedPair;

    if (isEnableOption == false) {
      let temp = { ...selectedPair };
      delete temp[field];
      let selectedVariantString = [];
      for (let key in temp) {
        selectedVariantString.push(`"${key}":"${temp[key]}"`);
      }
      let newVariantString = JSON.stringify({
        [field]: event.target.getAttribute("value"),
      })
        .replace("}", "")
        .replace("{", "");
      let possibleCombination = [];
      for (let variation of productVariations) {
        let isPossibleCombination = true;
        for (let string of selectedVariantString) {
          if (
            !variation.variant_value.includes(string) ||
            !variation.variant_value.includes(newVariantString)
          ) {
            isPossibleCombination = false;
            break;
          }
        }
        if (isPossibleCombination) {
          possibleCombination.push(variation);
        }
      }
      if (
        !(
          selectedPair[field] &&
          selectedPair[field] !== event.target.getAttribute("value") &&
          possibleCombination.length !== 0
        )
      ) {
        selectedPair = {};
      }
    }

    selectedPair[field] = event.target.getAttribute("value");

    const selectedAttributes = Object.keys(selectedPair);

    let selectedVariant = this.props.product.productVariation.filter((p) =>
      p.variant_value.includes(JSON.stringify(selectedPair))
    );
    if (selectedVariant.length === 0) {
      selectedVariant = this.props.product.productVariation[index];
    } else {
      selectedVariant = selectedVariant[0];
    }

    if (selectedAttributes.length === this.allAttributes.length) {
      selectedVariant.galleryImages =
        typeof selectedVariant.galleryImages == "string"
          ? JSON.parse(selectedVariant.galleryImages)
          : selectedVariant.galleryImages;
      if (!!this.state.spuId) {
        let productObj = { ...this.state.product };
        productObj["price"] = +selectedVariant.price;
        productObj["oneTimeOrder"] = 0;
        productObj["imageName"] =
          selectedVariant.galleryImages &&
          selectedVariant.galleryImages.length !== 0
            ? selectedVariant.galleryImages[0]
            : "";
        productObj["order_limit"] = selectedVariant.quantity;
        productObj["item_no"] = selectedVariant.item_no;
        productObj["galleryImages"] = JSON.stringify(
          selectedVariant.galleryImages
        );
        this.setState({
          product: { ...productObj },
        });
        // productObj.imageName =
      } else {
        // call api to get this pair price
        let productObj = { ...this.state.product };

        if (selectedVariant.image && selectedVariant.image.length !== 0) {
          productObj["imageName"] = selectedVariant.image;
        } else {
          productObj["imageName"] = this.props.product["imageName"];
        }
        let variantData = await this.props.getProductPrice(
          selectedPair,
          this.state.product.id
        );
        variantData = variantData?.data?.data;

        if (variantData) {
          productObj["imageName"] = variantData.image;
          productObj["price"] = variantData.price;
        }

        this.setState({
          product: { ...productObj },
        });
      }
    } else {
      let productObj = { ...this.state.product };
      productObj["imageName"] = this.props.product["imageName"];
      this.setState({
        product: { ...productObj },
      });
    }

    this.setState((prevState) => ({
      ...prevState,
      selectedPair,
    }));
    this.setState({ disableButton: false });
  };

  getSelectedAttributeVariants = (productAttributeVariantDetail = []) => {
    let attributeVariantObj = {};
    let variants = [];
    if (productAttributeVariantDetail?.length > 0) {
      const allKeys = Object.keys(
        JSON.parse(productAttributeVariantDetail[0].variant_value)
      );
      this.allAttributes = allKeys;
      const selectedPair = this.state.selectedPair;

      productAttributeVariantDetail.forEach((variant) => {
        variant = JSON.parse(variant.variant_value);
        let isEnable = true;

        const selectedPairKey = Object.keys(selectedPair);

        selectedPairKey.forEach((key) => {
          if (selectedPair[key] != variant[key]) {
            isEnable = false;
          }
        });

        allKeys.forEach((key) => {
          if (!attributeVariantObj[key]) {
            // attributeVariantObj[key] = [variant[key]]
            attributeVariantObj[key] = {
              value: [variant[key]],
              isEnable: [isEnable],
            };
          } else {
            const index = attributeVariantObj[key].value.indexOf(variant[key]);
            if (index == -1) {
              attributeVariantObj[key].value.push(variant[key]);
              attributeVariantObj[key].isEnable.push(isEnable);
            } else {
              if (isEnable == true) {
                attributeVariantObj[key].isEnable[index] = isEnable;
              }
            }
            // if (!attributeVariantObj[key].value.includes(variant[key])) {
            //     // attributeVariantObj[key].push(variant[key])
            //     attributeVariantObj[key].value.push(variant[key])
            //     attributeVariantObj[key].isEnable.push(isEnable)
            // }
          }
        });
      });

      variants = allKeys.map((attribute, index) => {
        return (
          <div key={index} className="col-md-12">
            <div className="plant-type-option">
              <div className="dropdown">
                <button
                  className="btn dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                >
                  {this.state.selectedPair[attribute]
                    ? this.state.selectedPair[attribute]
                    : attribute.replace("_", " ")}
                  <span className="add-icon">
                    <i className="fas fa-plus"></i>
                  </span>
                </button>
                <ul className="dropdown-menu">
                  {attributeVariantObj[attribute].value.map((variant, i) => {
                    return (
                      <li
                        key={i}
                        value={variant}
                        onClick={(e) =>
                          this.handleOptionChange(
                            e,
                            attribute,
                            attributeVariantObj[attribute].isEnable[i],
                            i
                          )
                        }
                        className={
                          this.state.selectedPair[attribute] === variant
                            ? "active"
                            : attributeVariantObj[attribute].isEnable[i] != true
                            ? "disabled"
                            : ""
                        }
                      >
                        {variant}
                      </li>
                    );
                  })}
                </ul>
                <span className="form-field-error">
                  {this.state.errorData[attribute] &&
                    this.state.errorData[attribute]}
                </span>
              </div>
            </div>
          </div>
        );
      });
    }

    return variants;
  };

  handleSubmitReviewForProduct = async (e) => {
    e.preventDefault();
    this.setState({
      disableReviewButton: true,
    });
    if (this.state.ratingValue) {
      this.setState({
        ratingError: "",
      });
      await this.props.submitRatingReview({
        user_id: this.state.userData.id,
        product_id: this.state.product.id,
        rating: this.state.ratingValue,
        comment: this.state.comment ? this.state.comment : "",
        order_id: this.props.ratingDetails?.data.order_id,
      });
      await this.getProductRatingDetails(
        this.state.userData.id,
        this.state.product.id
      );
      this.setState({
        otherComments: await this.getOtherComments(
          this.props.product?.id,
          this.state.loadCommentsAtaTime
        ),
      });
    } else {
      this.setState({
        ratingError: "You have to give atleast one rating",
      });
    }
    this.setState({
      disableReviewButton: false,
    });
  };

  handleRedirectLoginPage = (e) => {
    e.preventDefault();
    this.props.history.push("/login", { state: "productDetails" });
  };

  handleSelectPaymentType = (event) => {
    const { value } = event.target;
    let customizationNote =
      (this.state.product.isReplenishCategoryAvailable === 1 ||
        this.state.product.isCustomizeCategoryAvailable === 1) &&
      this.state.product.isAccessCategoryAvailable != 1
        ? this.state.customizationNote
        : null;
    let customizationImage =
      (this.state.product.isReplenishCategoryAvailable === 1 ||
        this.state.product.isCustomizeCategoryAvailable === 1) &&
      this.state.product.isAccessCategoryAvailable != 1
        ? this.state.customizationImage
        : null;
    this.setState({
      paymentOptionType: value,
      customizationNote,
      customizationImage,
    });
  };

  handleMeetingSlotChange = async (event, fieldName, field, selectedMeetingdata) => {
    const { value } = event.target;
    let tempState = this.state;

   if(fieldName == "one_on_one" && value == "One Time"){
    removeMonthly = true
   } else {
    removeMonthly = false
   }

    tempState[field] = value.trim();
    this.setState({
      ...tempState,
      selectedOneTimeDate: "",
      selectedSlots: [],
      showErrorMessage:"",
      selectedGroupMeetingName: ""
      // sessionFrequency: "",
      // sessionFrequencyOption: []
    });
    if (fieldName == "group"){
      if (field == "session"){
     
        this.setState({
          selectedWeekDay: null,
          availableTimeSlots: {},
          selectedWeek: null,
          sessionFrequency: "",
          sessionFrequencyOption: [],
          showSessionLoader: true,
        });

        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const result = await this.props.getSessionFrequency(
          this.props.product?.id,
          this.state.session,
          timezone
        );

        if (result?.length) {
           
          let tempSessionFrequencyOption = []
          result.map(item => {
            if (!tempSessionFrequencyOption.includes(item.frequency))
            tempSessionFrequencyOption.push(item.frequency)
          })          

          this.setState({
            sessionFrequencyOption: tempSessionFrequencyOption,
            groupData: result
          })
        }
        this.setState({
          showSessionLoader: false
        })
      }

      else if(field === "sessionFrequency"){
        let tempData = this.state.groupData.filter(item => item.frequency.toLowerCase().replace(/ /g,"_") == value.toLowerCase().replace(/ /g,"_") && item.availability !== '{}')
        
        this.setState({
          groupAvailability: [...tempData],
          product: {
            ...this.state.product,
            price: 0,
          },
        })
      }
      
      else if (field == "groupMeetingName"){
        if(selectedMeetingdata){
        
          let tempAvailability = selectedMeetingdata.availability.length ? JSON.parse(selectedMeetingdata.availability):null;
          let tempVendorAvailability = selectedMeetingdata.vendorAvailability?.length ? JSON.parse(selectedMeetingdata.vendorAvailability):null;

          if (selectedMeetingdata.frequency == "Monthly"){

            let tempCustomerSlots={};
            let tempVendorSlots={};

            tempCustomerSlots[tempAvailability["weekDay"].slice(0, 3)] = [
              [
                tempAvailability["startTime"],
                tempAvailability["endTime"],
                tempAvailability["weekOfMonth"],
              ],
            ];

            tempVendorSlots[tempVendorAvailability["weekDay"]?.slice(0, 3)] = [
              [
                tempVendorAvailability["startTime"],
                tempVendorAvailability["endTime"],
                tempVendorAvailability["weekOfMonth"],
              ],
            ]
            tempAvailability = tempCustomerSlots
            tempVendorAvailability = tempVendorSlots
          }
          let productDetail = { ...this.state.product };
          productDetail["timeSlots"] = tempAvailability;
          productDetail["vendorTimeSlots"] = tempVendorAvailability;
          productDetail["sessionFrequency"] = selectedMeetingdata?.frequency;
          await this.setState({
            groupMeetingAvailability: tempAvailability,
            product: productDetail
          })
        }
        this.setState({
          selectedGroupMeetingName: value,
          selectedMeetingdata,
          subscriptionPrice: 
            selectedMeetingdata?.subscription_cost
              ? selectedMeetingdata?.subscription_cost
              : 0,
          product: {
            ...this.state.product,
            price: selectedMeetingdata?.subscription_cost,
          },
          selectedStructureId: selectedMeetingdata?.id
          
        })
      }

    }else{
      if (field === "sessionGroupMeetingName") {
        const matchedMeeting = this.state.sessionFrequencyOption?.filter(
          (item) => item?.group_meeting_name?.trim() == value?.trim()
        );
        await this.setState({
          ...this.state,
          sessionFrequency: matchedMeeting[0]?.frequency,
          product: {
            ...this.state.product,
            price: matchedMeeting[0]?.subscription_cost,
          },
          selectedStructureId: matchedMeeting && matchedMeeting[0]?.id,
          subscriptionPrice:
            matchedMeeting && matchedMeeting[0]?.subscription_cost
              ? matchedMeeting[0]?.subscription_cost
              : 0,
          oneTimePrice:
            matchedMeeting && matchedMeeting[0]?.one_time_cost
              ? matchedMeeting[0]?.one_time_cost
              : 0,
          selectedSlots: [],
          selectedWeekDay: null,
         
        });
      }
      // if (event.target.value == "One Time"){
      //   this.openDatepicker()
      // }
  
      if (field == "sessionFrequency" || field === "sessionGroupMeetingName") {
        this.setState({
          selectedWeekDay: null,
          selectedWeek: null,
          // sessionGroupMeetingName: ""
        });
        if (!!this.state.sessionGroupMeetingName) {
          let availability = null;
          let vendorTimeSlots = null;
          let availableWeekdaysArray = [];
          let groupMonthlyMeetingAvailabiility = {};
          let groupMonthlyMeetingVendorAvailabiility = {};
          let slots = this.state.sessionFrequencyOption
            ?.filter(
              (item) =>
                item.group_meeting_name == this.state.sessionGroupMeetingName &&
                item.frequency == this.state.sessionFrequency
            )
            .map((ele) => {
              availability = ele?.availability
                ? JSON.parse(ele?.availability)
                : null;
              vendorTimeSlots = ele?.vendorAvailability
                ? JSON.parse(ele?.vendorAvailability)
                : null;
  
              if (this.state.sessionFrequency == "Monthly") {
                groupMonthlyMeetingAvailabiility[
                  availability["weekDay"].slice(0, 3)
                ] =
                  // moment("02:00 PM", ["h:mm A"]).format("HH:mm")
                  // moment("18:15", ["HH:mm"]).format("h:mm A")
                  [
                    [
                      availability["startTime"],
                      availability["endTime"],
                      availability["weekOfMonth"],
                    ],
                  ];
                groupMonthlyMeetingVendorAvailabiility[
                  vendorTimeSlots["weekDay"].slice(0, 3)
                ] = [
                  [
                    vendorTimeSlots["startTime"],
                    vendorTimeSlots["endTime"],
                    vendorTimeSlots["weekOfMonth"],
                  ],
                ];
                this.setState({
                  selectedWeekDay: availability["weekDay"],
                });
                availability = { ...groupMonthlyMeetingAvailabiility };
                vendorTimeSlots = { ...groupMonthlyMeetingVendorAvailabiility };
              }
            });
          availability &&
            Object.entries(availability).map(([key, val]) => {
              availableWeekdaysArray.push(weekDays[key]);
            });
          this.setState({
            availableWeekdaysArray,
            availableTimeSlots: { ...availability },
            vendorTimeSlots: { ...vendorTimeSlots },
            product: { ...this.state.product, price: slots.subscription_cost },
          });
        }
        let matchedRecord = this.state.sessionFrequencyOption?.filter(
          (item) => item.group_meeting_name == this.state.sessionGroupMeetingName
        );
        this.setState({
          selectedStructureId: matchedRecord && matchedRecord[0]?.id,
          subscriptionPrice:
            matchedRecord && matchedRecord[0]?.subscription_cost
              ? matchedRecord[0]?.subscription_cost
              : 0,
          oneTimePrice:
            matchedRecord && matchedRecord[0]?.one_time_cost
              ? matchedRecord[0]?.one_time_cost
              : 0,
          product: {
            ...this.state.product,
            price: matchedRecord[0]?.subscription_cost,
          },
        });
      }
      if (field === "session") {
        this.setState({
          selectedWeekDay: null,
          availableTimeSlots: {},
          selectedWeek: null,
          sessionFrequency: "",
          sessionFrequencyOption: [],
          showSessionLoader: true,
        });
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const result = await this.props.getSessionFrequency(
          this.props.product?.id,
          this.state.session,
          timezone
        );
  
        if (result?.length) {
          let groupMeetingName = [];
          let availability = null;
          let vendorTimeSlots = null;
          let availableWeekdaysArray = [];
          let availableDatesArray = [];
          let isOneTimeEvent = false;
          const result1 = result?.map((val, index) => {
            const event = new Date();
            availableDatesArray = val.dates
              ?.split(",")
              ?.map((item) => item?.split(" ")[0]);
            groupMeetingName.push(val?.group_meeting_name);
  
            availability = val?.availability
              ? JSON.parse(val?.availability)
              : null;
            vendorTimeSlots = val?.vendorAvailability
              ? JSON.parse(val?.vendorAvailability)
              : null;
  
            // ONLY HIGHLIGHT DATES WHICH HAVE OPEN SLOTS
            // {
            let filteredDate =
              availableDatesArray &&
              availableDatesArray.filter((e) => {
                const dayFromDate = moment(e)
                  ?.toLocaleString("en-us", { weekday: "long" })
                  ?.toLowerCase()
                  ?.slice(0, 3);
                return Object.keys(availability)?.includes(
                  dayFromDate?.charAt(0)?.toUpperCase() + dayFromDate?.slice(1)
                );
              });
            if (filteredDate) availableDatesArray = [...filteredDate];
  
            // }
  
            if (val?.frequency.includes("One Time")) {
              isOneTimeEvent = true;
            }
          });
          availability &&
            Object.entries(availability).map(([key, val]) => {
              availableWeekdaysArray.push(weekDays[key]);
            });
  
          this.setState({
            ...this.state,
            sessionFrequencyOption: result,
            sessionFrequency: "",
            sessionGroupMeetingNameOption: groupMeetingName,
            sessionGroupMeetingName: null,
            availableWeekdaysArray,
            availableTimeSlots: { ...availability },
            vendorTimeSlots: { ...vendorTimeSlots },
            availableDatesArray
          });
        }
          this.setState({
            showSessionLoader: false
          })
      }
    }
  };

  handleOneOnOneMeetingBookSlot = async () => {
    if (!this.validateItemOptions()) {
      return;
    }
    this.setState({
      isMeetingOneOnOne: true,
      showCalendar: true,
    });
  };

  handleUpload = async (event) => {
    let img = event.target.files[0];
    if (!img) {
      return null;
    }
    const options = {
      maxSizeMB: 3,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      img = await imageCompression(img, options);
    } catch (e) {
      console.error("Error while compressing image : ", e);
    }
    this.readFile(img);
  };

  readFile = (file) => {
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      if (this.state.customizationImage.length === 0) {
        this.setState({
          customizationImage: event.currentTarget.result,
          modalImage: event.currentTarget.result,
        });
      } else {
        this.setState({
          modalImage: event.currentTarget.result,
        });
      }
    };
    fileReader.readAsDataURL(file);
  };

  handleReadMore = async () => {
    const isShowingMore = !this.state.showMoreDiscription;
    if (isShowingMore) {
      window.scrollTo(0, 0);
    }
    this.setState({ showMoreDiscription: isShowingMore });
  };

  handleLoadMore = async () => {
    this.setState({
      loadCommentsAtaTime: (await this.state.loadCommentsAtaTime) + 5,
    });
    this.setState({
      otherComments: await this.getOtherComments(
        this.props.product?.id,
        this.state.loadCommentsAtaTime
      ),
    });
  };

  imageModal = () => (
    <Modal show={this.state.showModal} centered>
      <Modal.Header
        closeButton
        onClick={() => {
          this.setState({
            showModal: false,
          });
        }}
      >
        <b>Customization Image</b>
      </Modal.Header>
      <Modal.Body>
        <div className="customization-image-edit-inner">
          <div className="customization-image-edit">
            <img src={this.state.modalImage} alt="" />
          </div>
          <div className="action-btn">
            <button
              className="orange-btn mr-2"
              onClick={() => this.fileUpload.current?.click()}
            >
              <i className="far fa-edit"></i>
            </button>
            <button
              className="orange-btn"
              onClick={() =>
                this.setState({
                  customizationImage: "",
                  showModal: false,
                })
              }
            >
              <i className="far fa-trash-alt"></i>
            </button>
          </div>
        </div>

        <div className="d-flex align-content-center justify-content-center">
          <button
            className="orange-btn"
            onClick={() =>
              this.setState((prevState) => ({
                showModal: false,
                customizationImage: prevState.modalImage,
              }))
            }
          >
            Done
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );

  handleFreeSeminarBooking = async () => {
    if (this.state.userRegisteredForFreeSeminar) {
      return;
    } else {
      this.setState({
        showHideFreeSeminarModal: true,
      });
    }
  };
  handleAlertResponse = (type, id, data) => {
    this.closeAlertModal();
  };

  handleFreeSeminarModalShowHide = () => {
    this.setState({
      showHideFreeSeminarModal: false,
      returnOrderMessage: "",
    });
  };

  handleReturnOrderAlert = () => {
    this.setState({
      showHideFreeSeminarModal: true,
    });
  };

  handleSubmitFreeSeminar = async () => {
    this.setState({
      showBookNowLoader: true,
      disableBookOneTimeSlot: true,
    });
    let body = {
      start: this.state.formData["start"],
      end: this.state.formData["end"],
      date: this.state.formData.date,
      timezone: this.timezone,
    };
    const res = await this.props.bookFreeSeminarSlot(
      this.state.product.id,
      localStorage.getItem("userId"),
      body
    );

    if (res.status == 201) {
      this.setState({
        reqMessage: "Request sent successfully",
      });
      setTimeout(() => {
        this.handleFreeSeminarModalShowHide();
        this.setState({
          reqMessage: "",
        });
      }, 1500);
    }

    this.setState({
      showBookNowLoader: false,
    });
  };

  handleDateTimeChange = (e) => {
    this.setState({ selectedSlots: [] });
    let selectedDate = null;
    this.setState({ selectedOneTimeDate: e }, async () => {
      selectedDate = this.state.selectedOneTimeDate;
      selectedDate = moment(selectedDate).format("YYYY-MM-DD");
      let tempAvailabilityObj = {};

      let slots = Object.keys(this.state.availableTimeSlots)
        ? this.state.availableTimeSlots
        : null;
      let day = new Date(e)?.toLocaleString("en-us", { weekday: "long" });
      let dayFromDate = day.charAt(0).toUpperCase() + day.slice(1);

      tempAvailabilityObj[dayFromDate.slice(0, 3)] = [];

      tempAvailabilityObj = {
        [dayFromDate.slice(0, 3)]: slots[dayFromDate.slice(0, 3)],
      };

      this.setState({
        availableTimeSlots: {
          ...this.state.availableTimeSlots,
          ...tempAvailabilityObj,
        },
        selectedWeekDay: dayFromDate,
      });
    });
  };

  handleMonthChange = (e) => {
    // let dateArray = [];
    // this.state.availableWeekdaysArray && this.state.availableWeekdaysArray.map((day) => {
    //   const date = moment(new Date(e)).format('YYYY-MM-DD')
    //   let weekDay = moment(date).startOf("month").day(day);
    //   if (weekDay.date() > 7) weekDay.add(7, "d");
    //   let month = weekDay.month();
    //   while (month === weekDay.month()) {
    //     dateArray.push(
    //       moment(weekDay.year().toString() +
    //         "-" +
    //         (weekDay.month() + 1).toString() +
    //         "-" +
    //         weekDay.date().toString()).format("YYYY-MM-DD"));
    //     weekDay.toString();
    //     weekDay.add(7, "d");
    //   }
    // })
    // this.setState({
    //   availableDatesArray: dateArray
    // })
  };

  openDatepicker = () => this._calendar.setOpen(true);

  freeSeminarModal = () => (
    <Modal
      show={this.state.showHideFreeSeminarModal}
      onHide={this.handleFreeSeminarModalShowHide}
      className="calendar-modal"
      centered
    >
      <Modal.Header className="calendar-header">
        <span onClick={this.handleFreeSeminarModalShowHide}>
          <i className="fas fa-times"></i>
        </span>
        <h5 className="text-center w-100 m-0">One Time Session</h5>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              {this.state.reqMessage && (
                <div className="success-msg">
                  <p>{this.state.reqMessage}</p>
                </div>
              )}
              {/* <p className="text-center">
                Request Link for this Free Seminar
              </p> */}
            </div>
          </div>
          <div className="col-md-12">
            <div className="seminar-info">
              <ul>
                <li>
                  <h3>Meeting Name :</h3>
                  <p>{this.state.formData[`name`]}</p>
                </li>
                <li>
                  <h3>Meeting Date :</h3>
                  <p>
                    {this.state.formData.date &&
                    this.state.formData.date.length > 0
                      ? this.state.formData.date
                      : ""}
                  </p>
                </li>
                <li>
                  <h3>Meeting Time :</h3>
                  <p>
                    {moment(this.state.formData["start"], "HH:mm").format(
                      "hh:mm A"
                    )}{" "}
                    to{" "}
                    {moment(this.state.formData["end"], "HH:mm").format(
                      "hh:mm A"
                    )}
                  </p>
                </li>
                {this.state.formData.note !== "" && (
                  <li>
                    <h3>Meeting Description :</h3>
                    <p>{this.state.formData["note"]}</p>
                  </li>
                )}
                {this.state.formData.price !== "" && (
                  <li>
                    <h3>Meeting Price :</h3>
                    <p>${this.state.formData[`price`]}</p>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        {!this.state.formData.price ? (
          <div className="button-center">
            <button
              onClick={() => this.handleSubmitFreeSeminar()}
              className="orange-btn"
            >
              Book slot
              {(() => {
                if (this.state?.showBookNowLoader) {
                  return (
                    <span class="spinner-border spinner-border-sm ml-1"></span>
                  );
                }
              })()}
            </button>
          </div>
        ) : (
          <div className="text-center">
            <button
              // disabled={this.state.isSeminarEvent}
              onClick={() =>
                this.addToCart(
                  this.state.product,
                  this.state.quantity,
                  this.state.formData.price,
                  this.state.formData.name,
                  this.state.alertState,
                  true
                )
              }
              type="button"
              className="orange-btn"
            >
              {this.state.isAddedToCart ? (
                <i className="far fa-check-circle"></i>
              ) : null}{" "}
              <span>Add To Cart</span>
            </button>
          </div>
        )}
        <AlertModal
          data={this.state.alertState}
          closeAlertModal={this.closeAlertModal}
          handleAlertResponse={(type, id, data) =>
            this.handleAlertResponse(type, id, data)
          }
        />
      </Modal.Body>
    </Modal>
  );

  compareTwoArrays = (arr1, arr2) =>
    JSON.stringify(arr1) === JSON.stringify(arr2);

  handleTimeslotSelection = (slot, index) => {
    this.setState({
      showErrorMessage: "",
    });
    let selectedDay = this.state.selectedWeekDay;
    let dateArray = this.getDatesArrayOccuringOnSameDayOfMonth(selectedDay); //this function returns array of dates occuring on same day of month
    let sellerTimezone = this.state.product.timezone;
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // customer timezone

    // customer time is converted into vendor time to match slots according to their parsed weekdays
    // customer weekday is parsed to vendor weekday
    let timeDifference = this.getTimeDifference(sellerTimezone, timezone);
    const hours_interval = timeDifference.hours_interval;
    const minutes_interval = timeDifference.minutes_interval;

    const parsedStartTime = this.parseDateByTimezoneInterval(
      dateArray[0],
      slot[0],
      +hours_interval,
      +minutes_interval
    );
    const parsedEndTime = this.parseDateByTimezoneInterval(
      dateArray[0],
      slot[1],
      +hours_interval,
      +minutes_interval
    );

    let parsedWeekDay = weekDayObj[moment(parsedStartTime.split(" ")[0]).day()].charAt(0).toUpperCase()+weekDayObj[moment(parsedStartTime.split(" ")[0]).day()].slice(1) // parsed weekday in vendor timezone

    let startTime = parsedStartTime?.split(" ")[1]; // in vendor time zone
    let endTime = parsedEndTime.split(" ")[1]; // in vendor time zone
    let parsedTimeArray = [
      `${+startTime.split(":")[0]}:${+startTime.split(":")[1]}`,
      `${+endTime.split(":")[0]}:${+endTime.split(":")[1]}`,
    ]; //selected timeslot array parsed in vendor's timezone
    let result = false;

    // get timeslots by using parsed weekdays in vendorAvailability object

    let vendorTimeSlots =
      this.state.vendorTimeSlots[
        Object.keys(this.state.vendorTimeSlots)?.find(
          (key) => weekDays[key] === parsedWeekDay
        )
      ];
    if (
      this.state?.vendorTimeSlots &&
      Object.keys(this.state?.vendorTimeSlots)?.length > 0
    ) {
      this.setState({
        vendorSelectedWeekDay: parsedWeekDay,
      });
    }
    let indexInVendorTimeSlots = index; //identifying index of selected time slot in vendorAvailability object
    vendorTimeSlots &&
      vendorTimeSlots.filter((obj, position) => {
        result = this.compareTwoArrays(obj, parsedTimeArray); //comparing timeslots of vendorTimeSlots array and selected timeslot parsed in vendor timezone
        if (result == true) {
          indexInVendorTimeSlots = position;
        }
      });

    let vendorTime =
      this.state.vendorTimeSlots[
        Object.keys(this.state.vendorTimeSlots)?.find(
          (key) => weekDays[key] === parsedWeekDay
        )
      ] &&
      this.state.vendorTimeSlots[
        Object.keys(this.state.vendorTimeSlots)?.find(
          (key) => weekDays[key] === parsedWeekDay
        )
      ][indexInVendorTimeSlots]; // got selected time slot in vendor's time zone in HH:mm format

    let vendorSelectedTimeSlot = [];
    let tempTimeSlotsArray = [...this.state.selectedSlots];
    let indexOfSelectedSlot = tempTimeSlotsArray.indexOf(slot);
    if (this.state.selectedSlots.includes(slot)) {
      tempTimeSlotsArray.splice(indexOfSelectedSlot, 1);
      vendorSelectedTimeSlot = [];
    } else {
      tempTimeSlotsArray = []; // Comment this line to enable multiple time slots selection
      this.state.selectedWeek && slot.push(this.state.selectedWeek);
      tempTimeSlotsArray.push(slot);
      vendorSelectedTimeSlot.push(vendorTime);
    }

    let productDetail = { ...this.state.product };
    productDetail["timeSlots"] = [...tempTimeSlotsArray];
    productDetail["vendorTimeSlots"] = vendorSelectedTimeSlot;
    this.setState({
      selectedSlots: [...tempTimeSlotsArray],
      vendorSelectedTimeSlot,
      product: { ...productDetail },
    });
  };

  render() {
    const category = this.props.match.params.category
      ? this.props.match.params.category
      : "";
    const subCategory = this.props.match.params.subCategory
      ? this.props.match.params.subCategory
      : "";
    const productTimeFrame = this.state.product?.timeFrame
      ? this.state.product.timeFrame.split(",")[1].charAt(0).toUpperCase() +
        this.state.product.timeFrame.split(",")[1].slice(1)
      : null;

    let quanityPerweek = 0;

    let demoQuanityPerweek =
      this.props.cartItems?.length > 0
        ? this.props?.cartItems.filter((products) => {
            if (products.slug === this.props.match.params.productid) {
              quanityPerweek += products.selectedQuantity;
            }
          })
        : "";

    let cartItemsQuantityFromLocalStorage =
      this.state.product?.timeFrame?.includes("weekly")
        ? quanityPerweek * (((this.state.product.isReplenishCategoryAvailable || this.state.product.isCustomizeCategoryAvailable) && this.state.paymentOptionType==="one_time")?1:4)
        : quanityPerweek;
    let galleryImages = this.state?.product?.galleryImages
      ? typeof this.state?.product?.galleryImages == "string"
        ? JSON.parse(this.state.product.galleryImages)
        : this.state?.product?.galleryImages
      : null;

    const tabImg = galleryImages
      ? galleryImages.map((imgPath, index) => {
          if (imgPath === this.state.product.imageName) {
            return null;
          }
          return (
            <li className="nav-item" key={index}>
              <a
                className="nav-link"
                data-toggle="tab"
                href={`#tab${index + 2}`}
              >
                <div className="tab-img">
                  <img src={imgPath} />
                </div>
              </a>
            </li>
          );
        })
      : null;

    const productImg = galleryImages
      ? galleryImages.map((imgPath, index) => {
          return (
            <div className="tab-pane fade" id={`tab${index + 2}`} key={index}>
              <div className="product-img">
                <img src={imgPath} />
              </div>
            </div>
          );
        })
      : null;

    // const productPrice = this.props.product.is_variant === 1 ? null : (this.props.product.price ? `$${(this.props.product.price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : null)

    const productOptions = this.getSelectedAttributeVariants(
      this.state.product.productVariation
    );

    // const routeBackParam = category === "home" ? this.props.history.push("/") : this.props.history.goBack;

    const setShowCalendar = (value) => {
      if (this.state.paymentOptionType === "one-on-one" && value) {
        this.setState({
          isMeetingOneOnOne: true,
        });
      } else {
        this.setState({
          isMeetingOneOnOne: false,
        });
      }
      value
        ? this.setState((prevState) => {
            return {
              ...prevState,
              showCalendar: value,
            };
          })
        : this.setState((prevState) => {
            return {
              ...prevState,
              showCalendar: value,
              paymentOptionType: "recurring",
            };
          });
    };

    const isHtmlContent = (content) =>
      content && content.split()[0].includes("<");
    let isInStock =
      this.state.product.totalsubscription + this.state.product.oneTimeOrder <
      this.state.product.order_limit;
    if (
      this.state?.product?.timeFrame &&
      this.state.product.timeFrame.includes("weekly")
    ) {
      isInStock =
        Math.floor(
          (this.state.product.order_limit -
            (this.state.product.totalsubscription +
              this.state.product.oneTimeOrder)) /
              (((this.state.product.isReplenishCategoryAvailable || this.state.product.isCustomizeCategoryAvailable) && this.state.paymentOptionType==="one_time")?1:4)
        ) > 0;
    }

    const showBookSlotForFreeSeminar =
      new Date(
        this.state.formData.date && this.state.formData.date.split("-")[0],
        this.state.formData.date && this.state.formData.date.split("-")[1] - 1,
        this.state.formData.date && this.state.formData.date.split("-")[2],
        this.state.formData.start && this.state.formData.start.split(":")[0],
        this.state.formData.start && this.state.formData.start.split(":")[1]
      ).getTime() > Date.now();

    return (
      <main className="mainContainer mt-0">
        <section className="home-product-section mt-4">
          <div className="container">
            <div className="select-product-list">
              <div className="product-list-head product-list-border-bottom">
                <ul>
                  {/* <li><a href="javascript:;">New Arrivals</a></li>
                                <li><i className="fas fa-minus"></i></li> */}
                  <li>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => this.props.history.push("/")}
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <i className="fas fa-minus"></i>
                  </li>
                  <li>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        category === "home"
                          ? this.props.history.push("/")
                          : this.props.history.push(
                              `/product-list/category/${category}`
                            )
                      }
                    >
                      {category.replace("-", " ")}{" "}
                    </a>
                  </li>
                  {category == "" ? (
                    ""
                  ) : (
                    <li>
                      <i className="fas fa-minus"></i>
                    </li>
                  )}
                  {isNaN(subCategory) && subCategory.length > 0 && (
                    <>
                      <li>
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => this.props.history.goBack()}
                        >
                          {subCategory.replace("-", " ")}{" "}
                        </a>
                      </li>
                      <li>
                        <i className="fas fa-minus"></i>
                      </li>
                    </>
                  )}

                  <li className="product-list-slug-name">
                    <a
                    // href={"/product/" + this.state.product.slug}
                    >
                      {this.state.product.name?.length > 30 ? this.state.product.name.slice(0,30)?.trim() + "..." : this.state.product.name}
                    </a>
                  </li>
                </ul>
              </div>

              <div className="mobile-handburger">
              <ul>
                  {/* <li><a href="javascript:;">New Arrivals</a></li>
                                <li><i className="fas fa-minus"></i></li> */}
                  <li>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => this.props.history.push("/")}
                    >
                      Home
                    </a>
                  </li>
                  <li className="mx-1"> {`>`} </li>
                  <li>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        category === "home"
                          ? this.props.history.push("/")
                          : this.props.history.push(
                              `/product-list/category/${category}`
                            )
                      }
                    >
                      {category.replace("-", " ")}{" "}
                    </a>
                  </li>
                  {category == "" ? (
                    ""
                  ) : (
                    <li className="mx-1"> {`>`} </li>
                  )}
                  {isNaN(subCategory) && subCategory.length > 0 && (
                    <>
                      <li>
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => this.props.history.goBack()}
                        >
                          {subCategory.replace("-", " ")}{" "}
                        </a>
                      </li>
                      <li className="mx-1"> {`>`} </li>
                    </>
                  )}

                  <li className="product-list-slug-name">
                    <a
                    // href={"/product/" + this.state.product.slug}
                    >
                      {this.state.product.name?.length > 30 ? this.state.product.name.slice(0,30)?.trim() + "..." : this.state.product.name}
                    </a>
                  </li>
                </ul>
                {/* <ul>
                  <li>Home</li>
                  <li className="mx-1"> {`>`} </li>
                  <li>New Arrivals</li>
                  <li className="mx-1">{`>`}</li>
                  <li>Pink Lady’s Handbag…</li>
                </ul> */}
              </div>
            </div>
          </div>
        </section>
        <section className="product-detail">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="product-detail-slide">
                  <div className="row product-detail-order">
                    <div className="col-lg-2 col-md-2">
                      <ul className="nav">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            data-toggle="tab"
                            href="#tab1"
                          >
                            <div className="tab-img">
                              <img src={this.state.product.imageName} alt="" />
                            </div>
                          </a>
                        </li>

                        {tabImg}
                      </ul>
                    </div>

                    <div className="col-lg-9 col-md-10">
                      <div className="tab-content">
                        <div className="tab-pane active" id="tab1">
                          <div className="product-img">
                            {!this.state.product.isAccessCategoryAvailable && this.state.product.is_shipping_free ? (
                              <div className="shipping-label-star">
                                {" "}
                                free shipping
                              </div>
                            ) : null}
                            {this.state.product.is_sale !== 0 &&
                              (this.state.product.price_type === 1
                                ? this.state.product.sale_price && (
                                    <div className="sale-label-star">
                                      ${this.state.product.sale_price} off
                                    </div>
                                  )
                                : !!this.state.discountPercentage && (
                                    <div className="sale-label-star">
                                      {this.state.discountPercentage}% off
                                    </div>
                                  ))}
                            <img src={this.state.product.imageName} alt="" />
                          </div>
                        </div>

                        {productImg}
                      </div>
                    </div>
                  </div>
                </div>

                              {/* product videos */}
              {this.state.product.gallery_videos &&
              this.state.product.gallery_videos.length > 0 ? (
                <>
                  <h6 className="mt-5">watch videos</h6>
                  {this.state.product.gallery_videos && (
                    <div className="video-product-group-detail">
                      {this.state.product.gallery_videos
                        .split(",")
                        .map((video, index) => (
                          <div className="video-size">
                            <video width="200" height="150" controls>
                              <source src={video} />
                              Your browser does not support the video tag.
                            </video>
                          </div>
                        ))}
                    </div>
                  )}
                </>
              ) : null}
              </div>

              <div className="col-lg-5">
                <div className="product-detail-right">
                  <div className="product-detail-content">
                    <div className="product-detail-head">
                      <h2>{this.state.product.name}</h2>
                      {this.state.Showloader ? (
                        <strong>Loading...</strong>
                      ) : this.state.product
                          .isAccessCategoryAvailable ? null : (
                        <div>
                          <span>
                            {this.state.product.is_sale == 0 ? (
                              this.state.product.price ? (
                                `$${parseFloat(this.state.product.price)
                                  ?.toFixed(2)
                                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                              ) : null
                            ) : (
                              <del>
                                {this.state.product.price
                                  ? `$${parseFloat(this.state.product.price)
                                      ?.toFixed(2)
                                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                  : null}
                              </del>
                            )}
                          </span>
                          <span>
                            {this.state.product.is_sale !== 0
                              ? this.state.product.price_type === 1
                                ? `$${(
                                    this.state.product.price -
                                    this.state.product.sale_price
                                  )
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                : `$${(
                                    this.state.product.price -
                                    (this.state.product.price *
                                      this.state.discountPercentage) /
                                      100
                                  )
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                              : ` `}
                          </span>
                        </div>
                      )}
                    </div>

                    {this.state.product?.rating ? (
                      <div className="home-productbox-bottom">
                        <div className="item-rating">
                        <Rating
                              name="hover-feedback"
                              value={this.state.product.rating}
                              precision={0.5}
                              readOnly
                            />
                        </div>
                      </div>
                    ) : null}

                    <div className="product-detail-mid">
                      <div className="product-detail-radio flex-change-radio">
                        {(this.state.product.isReplenishCategoryAvailable ===
                          1 ||
                          this.state.product.isCustomizeCategoryAvailable ===
                            1) &&
                          this.state.product.isAccessCategoryAvailable != 1 && (
                            <div className="form-check">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  value="one_time"
                                  onChange={(event) =>
                                    this.handleSelectPaymentType(event)
                                  }
                                  checked={
                                    this.state.paymentOptionType === "one_time"
                                  }
                                  name="one_time"
                                />
                                <strong>One time</strong>
                              </label>
                            </div>
                          )}

                        {productTimeFrame &&
                        !this.state.product.isAccessCategoryAvailable ? (
                          <div className="form-check">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                className="form-check-input"
                                value="recurring"
                                onChange={(event) =>
                                  this.handleSelectPaymentType(event)
                                }
                                checked={
                                  this.state.paymentOptionType === "recurring"
                                }
                                name="recurring"
                              />
                              <strong>Frequency:</strong>{" "}
                              <span
                                style={{
                                  // textTransform: "uppercase",
                                  fontSize: "14px",
                                }}
                              >
                                {productTimeFrame}
                              </span>
                            </label>
                          </div>
                        ) : null}

                        <span className="form-field-error">
                          {this.state.showErrorMessage}
                        </span>
                        {/* For choosing the Session */}
                        {this.state.product.isAccessCategoryAvailable ? (
                          <>
                            <div className="d-flex align-items-center my-2 choose-option-custom">
                              {this.state?.sessionsOption?.length && (
                                <strong className="mr-2">
                                  Choose Session Type:
                                </strong>
                              )}
                              {this.state.showSessionLoader && (
                                <span className="spinner-border spinner-border-sm mr-3"></span>
                              )}
                              {this.state?.sessionsOption?.length &&
                                (this.state?.sessionsOption?.split(",")
                                  ?.length ? (
                                  this.state?.sessionsOption
                                    ?.split(",")
                                    ?.map((val, index) => (
                                      <div className="form-check">
                                        <label className="form-check-label">
                                          <input
                                            type="radio"
                                            className="form-check-input"
                                            value={val}
                                            onChange={(event) =>
                                              this.handleMeetingSlotChange(
                                                event,
                                                val,
                                                "session"
                                              )
                                            }
                                            checked={
                                              this.state.session.trim() ===
                                              val.trim()
                                            }
                                            name="sessionSelect"
                                          />

                                          <span
                                            style={{
                                              textTransform: "uppercase",
                                              fontSize: "14px",
                                            }}
                                          >
                                            {val?.length
                                              ? val.replace(/_/g, " ")
                                              : ""}
                                          </span>
                                        </label>
                                      </div>
                                    ))
                                ) : (
                                  <div className="form-check">
                                    <label className="form-check-label">
                                      <input
                                        type="radio"
                                        className="form-check-input"
                                        value={this.state?.sessionsOption}
                                        onChange={(event) =>
                                          this.handleMeetingSlotChange(
                                            event,
                                            this.state?.sessionsOption,
                                            "session"
                                          )
                                        }
                                        checked={this.state.session.length}
                                        name="sessionSelect"
                                      />

                                      <span
                                        style={{
                                          textTransform: "uppercase",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {this.state?.sessionsOption?.length
                                          ? this.state?.sessionsOption
                                          : ""}
                                      </span>
                                    </label>
                                  </div>
                                ))}
                            </div>
                            {this.state.product.isAccessCategoryAvailable &&
                            this.state.product.price ? (
                              <p className="m-0 monthly-fees mb-3">
                                {!removeMonthly ? <strong>{productTimeFrame + ` Fees`}:</strong> :<strong>{`One Time Fee`}:</strong> }{" "}
                                <span>
                                  {this.state.product.is_sale == 0 ? (
                                    this.state.product.price ? (
                                      `$${parseFloat(this.state.product.price)
                                        ?.toFixed(2)
                                        ?.replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ","
                                        )}`
                                    ) : null
                                  ) : (
                                    <del>
                                      {this.state.product.price
                                        ? `$${parseFloat(
                                            this.state.product.price
                                          )
                                            ?.toFixed(2)
                                            ?.replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ","
                                            )}`
                                        : null}
                                    </del>
                                  )}
                                </span>
                                <span>
                                  {this.state.product.is_sale !== 0
                                    ? this.state.product.price_type === 1
                                      ? `$${(
                                          this.state.product.price -
                                          this.state.product.sale_price
                                        )
                                          .toFixed(2)
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}`
                                      : `$${(
                                          this.state.product.price -
                                          (this.state.product.price *
                                            this.state.discountPercentage) /
                                            100
                                        )
                                          .toFixed(2)
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}`
                                    : ` `}
                                </span>
                              </p>
                            ) : null}

                            {this.state?.sessionsOption?.length &&
                              this.state.session && (
                                <div className="form-check pl-0 w-100">
                                  <div className="form-group">
                                    <select
                                      name="country"
                                      className="form-control"
                                      onChange={(event) =>
                                        this.handleMeetingSlotChange(
                                          event,
                                          this.state.session,
                                          "sessionFrequency"
                                        )
                                      }
                                      value={
                                        this.state.sessionFrequency?.length
                                          ? this.state.sessionFrequency
                                          : "select"
                                      }
                                    >
                                      <option value="select" selected disabled>
                                        Choose Frequency
                                      </option>
                                      {this.state?.sessionFrequencyOption
                                        ?.length
                                        ? this.state?.sessionFrequencyOption?.map(
                                            (val, index) => {
                                              return this.state.session ===
                                                  "group" ? (
                                                <option value={val ? val : null}>
                                                {val}
                                                </option>
                                              ) : (
                                                this.state.session !==
                                                  "group" &&
                                                  (val?.frequency?.split(",")
                                                    ?.length ? (
                                                    val?.frequency
                                                      ?.split(",")
                                                      ?.filter(
                                                        (val) => val !== "All"
                                                      )
                                                      ?.map((values, index) => {
                                                        return (
                                                          <option
                                                            value={values}
                                                          >
                                                            {values}
                                                          </option>
                                                        );
                                                      })
                                                  ) : (
                                                    <option
                                                      value={val?.frequency}
                                                    >
                                                      {val?.frequency}
                                                    </option>
                                                  ))
                                              );
                                            }
                                          )
                                        : ""}
                                    </select>
                                  </div>
                                </div>
                              )}

{/* {this.state?.sessionsOption?.length &&
                              this.state?.sessionGroupMeetingNameOption[0] !==
                                null && (
                                <div className="form-check pl-0 w-100">
                                  {this.state.session ? (
                                    <div className="form-group">
                                      <select
                                        name="country"
                                        className="form-control"
                                        onChange={(event) =>
                                          this.handleMeetingSlotChange(
                                            event,
                                            "sessionGroupMeetingName",
                                            "sessionGroupMeetingName"
                                          )
                                        }
                                        value={
                                          this.state.sessionGroupMeetingName
                                            ?.length
                                            ? this.state.sessionGroupMeetingName
                                            : "select"
                                        }
                                      >
                                        <option
                                          value="select"
                                          selected
                                          disabled
                                        >
                                          Choose meeting name
                                        </option>
                                        {this.state
                                          ?.sessionGroupMeetingNameOption
                                          ?.length
                                          ? this.state?.sessionGroupMeetingNameOption?.map(
                                              (val, index) => {
                                                return (
                                                  <option value={val}>
                                                    {val}
                                                  </option>
                                                );
                                              }
                                            )
                                          : ""}
                                      </select>
                                    </div>
                                  ) : null}
                                </div>
                              )} */}

                            {this.state.sessionFrequency &&
                              this.state.session == "one_on_one" &&
                              this.state.sessionFrequency == "Monthly" && (
                                <div className="form-group w-100">
                                  <select
                                    name="availableWeekDays"
                                    className="form-control"
                                    onChange={(event) =>
                                      this.handleMeetingSlotChange(
                                        event,
                                        "selectedWeek",
                                        "selectedWeek"
                                      )
                                    }
                                    value={
                                      this.state.selectedWeek?.length
                                        ? this.state.selectedWeek
                                        : "select"
                                    }
                                  >
                                    <option value="select" selected disabled>
                                      Choose week
                                    </option>
                                    {this.state.availableWeek &&
                                      this.state.availableWeek?.map(
                                        (weekday, index) => {
                                          return (
                                            <option value={weekday}>
                                              {weekday}
                                            </option>
                                          );
                                        }
                                      )}
                                  </select>
                                </div>
                              )}
                            {this.state.sessionFrequency &&
                              (this.state.selectedWeek ||
                                this.state.sessionFrequency !== "Monthly") &&
                              ((this.state.session == "one_on_one" &&
                                this.state.sessionFrequency !== "One Time")) && (this.state.session !== "group")  && (
                                <div className="form-group w-100">
                                  <select
                                    name="availableWeekDays"
                                    className="form-control"
                                    onChange={(event) =>
                                      this.handleMeetingSlotChange(
                                        event,
                                        "selectedWeekDay",
                                        "selectedWeekDay"
                                      )
                                    }
                                    value={
                                      this.state.selectedWeekDay?.length
                                        ? this.state.selectedWeekDay
                                        : "select"
                                    }
                                  >
                                    <option value="select" selected disabled>
                                      Choose day(s)
                                    </option>
                                    {this.state.availableWeekdaysArray &&
                                      this.state.availableWeekdaysArray?.map(
                                        (weekday, index) => {
                                          return (
                                            <option value={weekday}>
                                              {weekday}
                                            </option>
                                          );
                                        }
                                      )}
                                  </select>
                                  {!this.state.availableWeekdaysArray
                                    ?.length ? (
                                    <p className="form-field-error">
                                      No slots available at the moment
                                    </p>
                                  ) : null}
                                </div>
                              )}

                            {/* {this.state.sessionFrequency == "One Time" &&
                        this.openDatepicker()} */}
                            <div className="datepicker-center">
                              {this.state.sessionFrequency == "One Time" && (
                                <DatePicker
                                  dateFormat={"yyyy-MM-dd"}
                                  selected={this.state.selectedOneTimeDate}
                                  ref={(c) => (this._calendar = c)}
                                  onChange={this.handleDateTimeChange}
                                  filterDate={(d) =>
                                    this.state.availableDatesArray?.includes(
                                      moment(d).format("YYYY-MM-DD")
                                    )
                                  }
                                  shouldCloseOnSelect={false}
                                  popperProps={{ strategy: "fixed" }}
                                  minDate={new Date()}
                                  onMonthChange={this.handleMonthChange}
                                  inline
                                />
                              )}
                            </div>
                            {(this.state.selectedWeekDay?.length &&
                              this.state.session !== "group") && (
                              <div className="available-slot">
                                <label>
                                  <strong>Available Slots:</strong>
                                </label>
                                {this.state.availableTimeSlots[
                                  Object.keys(
                                    this.state.availableTimeSlots
                                  )?.find(
                                    (key) =>
                                      weekDays[key] ===
                                      this.state.selectedWeekDay
                                  )
                                ] ? (
                                  <OwlCarousel
                                    // className="owl-theme"
                                    loop={false}
                                    margin={10}
                                    key={[
                                      this.state.selectedWeekDay,
                                      this.state.selectedSlots,
                                    ]}
                                    startPosition={this.state.currentSlideIndex}
                                    nav
                                    dots={false}
                                    onTranslated={(e) => {
                                      this.setState({
                                        currentSlideIndex: e.item.index,
                                      });
                                    }}
                                    {...options}
                                  >
                                    {this.state.availableTimeSlots[
                                      Object.keys(
                                        this.state.availableTimeSlots
                                      )?.find(
                                        (key) =>
                                          weekDays[key] ===
                                          this.state.selectedWeekDay
                                      )
                                    ]?.map((slot, index) => {
                                      return (
                                        <div
                                          className={`item ${
                                            this.state.selectedSlots.includes(
                                              slot
                                            )
                                              ? `slots-active`
                                              : ``
                                          }`}
                                          onClick={() =>
                                            this.handleTimeslotSelection(
                                              slot,
                                              index
                                            )
                                          }
                                        >
                                          <p>
                                            {moment(slot[0], ["HH:mm"]).format(
                                              "hh:mm A"
                                            )}{" "}
                                            to{" "}
                                            {moment(slot[1], ["HH:mm"]).format(
                                              "hh:mm A"
                                            )}{" "}
                                            {`${
                                              this.state.session == "group" &&
                                              this.state.sessionFrequency ==
                                                "Monthly"
                                                ? ` | ` +
                                                  `Every ` +
                                                  slot[2] +
                                                  ` ` +
                                                  this.state.selectedWeekDay +
                                                  ` Of The Month `
                                                : ``
                                            }`}
                                            {`(${
                                              Intl.DateTimeFormat().resolvedOptions()
                                                .timeZone
                                            })`}
                                          </p>
                                        </div>
                                      );
                                    })}
                                  </OwlCarousel>
                                ) : (
                                  <p className="form-field-error">
                                    No slots available at the moment
                                  </p>
                                )}
                              </div>
                            )}

                            {/* Available slots for group meetings */}

                            {(this.state.sessionFrequency?.length &&
                              (this.state.session == "group")) ? (
                              <div className="available-slot">
                                <label>
                                  <strong>Available Slots:</strong>
                                </label>

                            <div id="accordion">
                            {
                              this.state.groupAvailability?.length ? 
                              this.state.groupAvailability.map((meeting) => {

                                  return <div className="card"
                                  
                                  >
                                    <div className="card-header slot-header"
                                  
                                    >
                                      <label className="container-slot">
                                        {" "}
                                        {meeting.group_meeting_name}
                                        <input
                                          type="radio"
                                          value={meeting.group_meeting_name}
                                          onChange={(event) =>
                                            this.handleMeetingSlotChange(
                                              event,
                                              this.state?.session,
                                              "groupMeetingName",
                                              meeting
                                              )
                                            }
                                            checked={
                                              this.state.selectedGroupMeetingName == meeting.group_meeting_name
                                            }
                                        />
                                        <span className="checkmark-slot"></span>
                                      </label>
                                      <i 
                                      className={`fa fa-angle-down ${(this.state.selectedGroupMeetingName == meeting.group_meeting_name) ? `reverse-icon` : ``}`} aria-hidden="true" />
                                    </div>
                                    {this.state.selectedGroupMeetingName &&
                                    this.state.selectedGroupMeetingName == meeting.group_meeting_name ? (
                                      this.state.groupMeetingAvailability &&
                                      Object.keys(this.state.groupMeetingAvailability)?.length ? (
                                        <div className="slot-main-body">
                                          <div className="card-body slot-body">
                                            <div className="slot-desc">
                                            {
                                              Object.entries(this.state.groupMeetingAvailability).map(([weekDay, slotsArray]) => (
                                              <>
                                              <p className="my-2">
                                                <strong>{weekDays[weekDay]}- {`(${Intl.DateTimeFormat().resolvedOptions().timeZone})`}</strong>
                                              </p>
                                              <div className="container-fluid">
                                                <div className="row">
                                                { slotsArray?.length ? 
                                                    slotsArray?.map((slot) => {
                                                        return <div className="col-md-4 px-1">
                                                            <p className="slot-chip">
                                                            {moment(slot[0], ["HH:mm"]).format("hh:mm A")}{" "}-{" "}
                                                            {moment(slot[1], ["HH:mm"]).format("hh:mm A")}{" "}
                                                                {`${
                                                                  this.state.session == "group" &&
                                                                  this.state.sessionFrequency ==
                                                                    "Monthly"
                                                                    ? ` | ` +
                                                                      `Every ` +
                                                                      slot[2] +
                                                                      ` ` +
                                                                      weekDays[weekDay] +
                                                                      ` Of The Month `
                                                                    : ``
                                                                }`}</p>
                                                                  </div>
                                                                    })
                                                                   : null}
                                                </div>
                                              </div>
                                              </>
                                              ))
                                            }
                                            </div>
                                          </div>
                                        </div>
                                      ) : null
                                    ) : null}
                                  </div>

                              })
                            
                              : <p className="form-field-error">
                                          No slots available at the moment
                                        </p>
                              }

                            </div>
                            </div>
                            ) : null}
                          </>
                        ) : null}

                        {/* TODO uncomment ooo meeting */}
                        {isInStock &&
                          this.state.userData &&
                          !!this.state.product.isAccessCategoryAvailable &&
                          !!this.state.product.one_on_one_meeting_price &&
                          !this.state.product.isOneOnOneMeetingScheduled && (
                            <div className="w-100 mt-2">
                              <button
                                className="orange-btn w-100"
                                onClick={this.handleOneOnOneMeetingBookSlot}
                              >
                                Book one-on-one session
                              </button>
                            </div>
                          )}
                        {this.state.userData &&
                        this.state.isFreeSeminarCreated &&
                        showBookSlotForFreeSeminar &&
                        this.state.product.isAccessCategoryAvailable ? (
                          <div className="w-100 mt-2">
                            <button
                              className={`orange-btn w-100 ${
                                !!this.state.disableBookOneTimeSlot
                                  ? `disable-btn`
                                  : ``
                              }`}
                              onClick={this.handleFreeSeminarBooking}
                              disabled={this.state.disableBookOneTimeSlot}
                            >
                              One-time Session : {this.state.formData[`name`]}
                            </button>
                          </div>
                        ) : null}
                      </div>

                      <label>
                        <b>Description:</b>
                      </label>
                      {isHtmlContent(this.state.product.description) ? (
                        <div
                          className={`${
                            this.state.showMoreDiscription ? "prewrap-para" : ""
                          } image-desc-product-doba`}
                          dangerouslySetInnerHTML={{
                            __html: this.state.product.description,
                          }}
                        />
                      ) : (
                        <p
                          className={
                            this.state.showMoreDiscription ? "prewrap-para" : ""
                          }
                          style={{ whiteSpace: "pre-wrap" }}
                        >
                          {this.state.product.description}{" "}
                        </p>
                      )}

                      {this.props.product.description &&
                        this.props.product.description.length > 205 &&
                        this.state.showMoreDiscription && (
                          <div className="text-left mb-3">
                            <p
                              className="read-link-clamp"
                              onClick={() => this.handleReadMore()}
                            >
                              Read More...
                            </p>
                          </div>
                        )}
                      {this.props.product.description &&
                        this.props.product.description.length > 205 &&
                        !this.state.showMoreDiscription && (
                          <div className="text-left mb-3">
                            <p
                              className="read-link-clamp"
                              onClick={() => this.handleReadMore()}
                            >
                              Read Less...
                            </p>
                          </div>
                        )}
                      {/* TODO: Uncomment customize questions */}
                      {this.state.customizeQuestions?.length ? (
                        <>
                          <label>
                            <b>Please provide following information:</b>
                          </label>
                          <ol>
                            {this.state.customizeQuestions.map(
                              (item, index) => (
                                <li>{item}</li>
                              )
                            )}
                          </ol>
                        </>
                      ) : null}

                      <div className="form-group form-group-custom-space">
                        {this.state.product.isCustomizeCategoryAvailable ==
                          1 && (
                          <>
                            {/* <label style={{ marginRight: "6px" }}>
                              <b>Customization Note:</b>
                            </label> */}
                            <textarea
                              className="form-control"
                              rows="3"
                              value={
                                this.state.customizationNote
                                  ? this.state.customizationNote
                                  : ""
                              }
                              onChange={(e) =>
                                this.handleChange(e, "customizationNote")
                              }
                            />
                            <div className="form-group">
                              <label style={{ marginRight: "6px" }}>
                                <b>Customization Image:</b>
                              </label>
                              {(!this.state.customizationImage ||
                                this.state.customizationImage?.length ===
                                  0) && (
                                <div className="customization-file-upload">
                                  Upload Image
                                  <input
                                    type="file"
                                    accept="image/png, image/gif, image/jpeg"
                                    onChange={(e) => this.handleUpload(e)}
                                  />
                                </div>
                              )}
                            </div>
                            {!this.state.customizedProductTime == 0 && (
                              <label style={{ marginRight: "6px" ,marginBottom: "0px" }}>
                                <b>Estimated Product Creation Time: </b>
                                {this.state.customizedProductTime == 1
                                  ? `${this.state.customizedProductTime} Day`
                                  : `${this.state.customizedProductTime} Days`}
                              </label>
                            )}
                            {this.state.customizationImage &&
                              this.state.customizationImage.length > 1 && (
                                <div className="d-flex align-items-center">
                                  <div className="custom-image-upload">
                                    <img
                                      onClick={() =>
                                        this.setState({ showModal: true })
                                      }
                                      src={this.state.customizationImage}
                                      alt=""
                                    />
                                  </div>
                                  <button
                                    className="orange-btn ml-3"
                                    onClick={() =>
                                      this.setState({ showModal: true })
                                    }
                                  >
                                    Edit
                                  </button>
                                </div>
                              )}
                          </>
                        )}
                      </div>
                      <input
                        style={{ display: "none" }}
                        type="file"
                        ref={this.fileUpload}
                        accept="image/png, image/gif, image/jpeg"
                        onChange={(e) => this.handleUpload(e)}
                      />

                      {this.state.product.is_variant == 1 &&
                        ((this.state.product.spu_id &&
                          this.state.flagForVariant) ||
                          !this.state.product.spu_id) && (
                          <div className="plant-group">
                            <div className="row">{productOptions}</div>
                          </div>
                        )}
                      <div className="mt30">
                      {this.state.product.storename &&
                        !this.state.product.spu_id && (
                          <label style={{ marginRight: "6px" }}>
                            <b>Seller:</b>
                          </label>
                        )}
                      {this.state.product.storename &&
                        !this.state.product.spu_id && (
                          <Link
                            style={{
                              whiteSpace: "pre-wrap",
                              fontSize: "14px",
                              textDecoration: "underline",
                              color: "#e25e52",
                            }}
                            to={`/store/${this.state.product.storeId}/${this.state.product.userId}`}
                          >
                            {this.state.product.storename}
                            <span>
                              <i
                                className="fas fa-external-link-alt"
                                style={{ marginLeft: "4px" }}
                              ></i>
                            </span>
                          </Link>
                        )}
                        </div>
                      {Object.keys(this.state.product).length > 0 ? (
                        (isInStock ||
                          this.state.product?.isAccessCategoryAvailable) &&
                        !this.state.isDobaUnavailable ? (
                          <>
                            {" "}
                            {!!!this.state.product
                              ?.isAccessCategoryAvailable && (
                              <div className="quanity">
                                <h4>
                                  Quantity
                                  {!this.state.product
                                    ?.isAccessCategoryAvailable && (
                                    <span>
                                      {!this.state.product.timeFrame.includes(
                                        "weekly"
                                      )
                                        ? (this.state.product.order_limit -
                                          (this.state.product
                                            .totalsubscription +
                                            this.state.product
                                              .oneTimeOrder) <=
                                          5 &&(this.state.product.isReplenishCategoryAvailable || this.state.product.isCustomizeCategoryAvailable) && this.state.paymentOptionType==="one_time")? 
                                        `(Limited quantity - only ${
                                          this.state.product.order_limit -
                                          (this.state.product
                                            .totalsubscription +
                                            this.state.product.oneTimeOrder)
                                        } left)`: this.state.product.order_limit -
                                            (this.state.product
                                              .totalsubscription +
                                              this.state.product
                                                .oneTimeOrder) <=
                                            5 &&
                                          `(Limited quantity - only ${
                                            this.state.product.order_limit -
                                            (this.state.product
                                              .totalsubscription +
                                              this.state.product.oneTimeOrder)
                                          } left)`
                                        :(this.state.product.order_limit -
                                          (this.state.product
                                            .totalsubscription +
                                            this.state.product
                                              .oneTimeOrder) <=
                                          5 &&
                                          (this.state.product.isReplenishCategoryAvailable || this.state.product.isCustomizeCategoryAvailable) 
                                        && this.state.paymentOptionType==="one_time") ?
                                        `(Limited quantity - only ${
                                          this.state.product.order_limit -
                                          (this.state.product
                                            .totalsubscription +
                                            this.state.product.oneTimeOrder)
                                        } left)`: (this.state.product.order_limit -
                                            (this.state.product
                                              .totalsubscription +
                                              this.state.product
                                                .oneTimeOrder)) /
                                            4 <=
                                            5 &&
                                          `(Limited quantity - only ${Math.floor(
                                            (this.state.product.order_limit -
                                              (this.state.product
                                                .totalsubscription +
                                                this.state.product
                                                  .oneTimeOrder)) /
                                              4
                                          )} left for subscription)`}
                                    </span>
                                  )}
                                </h4>
                                <div className="quanity-counter">
                                  <button
                                    type="button"
                                    onClick={() =>
                                      this.handleQuantityChange("minus")
                                    }
                                    className="btnLeft"
                                  >
                                    <i className="fas fa-minus"></i>
                                  </button>
                                  <p>{this.state.quantity}</p>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      this.handleQuantityChange(
                                        "plus",
                                        cartItemsQuantityFromLocalStorage
                                      )
                                    }
                                    className="btnRight"
                                  >
                                    <i className="fas fa-plus"></i>
                                  </button>
                                </div>
                              </div>
                            )}
                            {!this.state.isPreview ? (
                              <div className="addtocart">
                                <div className="d-flex align-items-center mobile-wrap">
                                  <button
                                    className={
                                      this.state.orderLimitError ||
                                      (!(
                                        (this.state.product.timeFrame.includes(
                                          "weekly"
                                        )
                                          ? this.state.quantity *  (((this.state.product.isReplenishCategoryAvailable || this.state.product.isCustomizeCategoryAvailable) && this.state.paymentOptionType==="one_time")?1:4)
                                          : this.state.quantity) +
                                          cartItemsQuantityFromLocalStorage +
                                          (this.state.product
                                            .totalsubscription +
                                            this.state.product.oneTimeOrder) <=
                                        this.state.product.order_limit
                                      ) &&
                                        !this.state.product
                                          ?.isAccessCategoryAvailable)
                                        ? "disable-btn"
                                        : "mr-2 orange-btn"
                                    }
                                    disabled={
                                      (!(
                                        (this.state.product.timeFrame.includes(
                                          "weekly"
                                        )
                                          ? this.state.quantity *  (((this.state.product.isReplenishCategoryAvailable || this.state.product.isCustomizeCategoryAvailable) && this.state.paymentOptionType==="one_time")?1:4)
                                          : this.state.quantity) +
                                          cartItemsQuantityFromLocalStorage +
                                          this.state.product.totalsubscription +
                                          this.state.product.oneTimeOrder <=
                                        this.state.product.order_limit
                                      ) ||
                                        this.state.orderLimitError ||
                                        this.state.disableButton) &&
                                      !this.state.product
                                        ?.isAccessCategoryAvailable
                                    }
                                    onClick={() =>
                                      this.addToCart(
                                        this.state.product,
                                        this.state.quantity
                                      )
                                    }
                                    type="button"
                                  >
                                    {this.state.isAddedToCart ? (
                                      <i className="far fa-check-circle"></i>
                                    ) : null}{" "}
                                    <span>Add To Cart</span>
                                  </button>
                                  {/*Show Contact Vendor button based on isChatActive field of product*/}
                                  {this.state.product.isChatActive === 1 ? (
                                    <button
                                      onClick={() => {
                                        this.props.history.push("/messages", {
                                          message_source_type: "product",
                                          sender_type: "buyer",
                                          message_source_id:
                                            this.state.product.id,
                                          seller_id: this.state.product.userId,
                                          source_detail:
                                            this.state.product.name,
                                          storename:
                                            this.state.product.storename,
                                          timezone: this.state.product.timezone,
                                          availableFrom:
                                            this.state.product.available_from,
                                          availableTo:
                                            this.state.product.available_to,
                                            sellerChatActive:
                                            this.state.product.isChatActive,
                                        });
                                      }}
                                      className="orange-outline-btn"
                                    >
                                      <span>Contact Vendor</span>
                                    </button>
                                  ) : null}
                                </div>
                                {/* {this.state.isAddedToCart ? <p>Added to Cart</p> : null} */}

                                <div className="mt-3">
                                  {this.state.product?.doba_processing_time
                                    ? `Processing Time: ${this.state.product.doba_processing_time} Business Days (Estimated)`
                                    : null}
                                </div>
                              </div>
                            ) : null}
                          </>
                        ) : (
                          <div className="addtocart">
                            <button className="disable-btn" disabled={true}>
                              <span>Out of Stock</span>
                            </button>
                          </div>
                        )
                      ) : null}

                      {localStorage.getItem("token") &&
                      this.state.product &&
                      this.state.product.id &&
                      this.state.product.isScheduleAvailable &&
                      this.state.product.isAccessCategoryAvailable ? (
                        <>
                          <div className="addtocart">
                            <div className="vertical-button-add">
                              <button
                                className="orange-btn"
                                onClick={() => setShowCalendar(true)}
                              >
                                {" "}
                                View Schedule{" "}
                              </button>
                            </div>
                          </div>
                        </>
                      ) : null}
                      <ZoomMeetingCalendar
                        {...this.props}
                        show={this.state.showCalendar}
                        data={{
                          productId: this.state.product.id,
                          sellerId: this.state.product?.userId,
                          productTimeFrame: productTimeFrame,
                          isMeetingOneOnOne: this.state.isMeetingOneOnOne,
                          storeId: this.storeId,
                          productVariation: JSON.stringify(
                            this.state.selectedPair
                          ),
                          selectedPair: JSON.stringify(this.state.selectedPair),
                        }}
                        setShowCalendar={setShowCalendar}
                        isPurchased={false}
                        handleSlotBook={() =>
                          this.setState((prevState) => ({
                            ...prevState,
                            product: {
                              ...prevState.product,
                              isOneOnOneMeetingScheduled: 1,
                            },
                          }))
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.state.suggestedProducts.length > 0 && (
              <>
                <h6>suggested products</h6>
                <OwlCarousel
                  className="owl-theme"
                  loop={false}
                  margin={10}
                  nav
                  dots={false}
                  {...options}
                >
                  {this.state.suggestedProducts.map((item) => {
                    let isInStock = !item?.isOutOfStock;
                    {
                      /* let isInStock =
                      item.totalsubscription + item.oneTimeOrder <
                      item.order_limit;

                    if (item?.timeFrame && item.timeFrame.includes("weekly")) {
                      isInStock =
                        Math.floor(
                          (item.order_limit -
                            (item.totalsubscription + item.oneTimeOrder)) /
                            4
                        ) > 0;
                    } */
                    }

                    return (
                      item.status === "approved" && (
                        <div className="item" key={item.id}>
                          <div className="home-product-item">
                            {item.is_sale !== 0 ? (
                              item.price_type === 1 ? (
                                <div className="sale-label-star">
                                  ${item.sale_price} off
                                </div>
                              ) : (
                                <div className="sale-label-star">
                                  {" "}
                                  {Math.round(
                                    100 * (1 - item.sale_price / item.price)
                                  )}
                                  % off
                                </div>
                              )
                            ) : null}
                            {!this.state.product.isAccessCategoryAvailable && item.is_shipping_free ? (
                              <div to="/" className="shipping-label-star">
                                free shipping
                              </div>
                            ) : null}
                            <Link
                              to={{
                                pathname: `/product/${item.slug}`,
                                state: { spuId: item.spu_id },
                              }}
                            >
                              <div className="home-product-box">
                                <img src={item.imageName} />

                                {isInStock ||
                                item?.isAccessCategoryAvailable ? (
                                  <div className="quickviewbtn">
                                    <p>Quick View</p>
                                  </div>
                                ) : (
                                  <div className="quickviewbtn">
                                    <p>Sold Out</p>
                                  </div>
                                )}
                              </div>
                              <div className="home-productbox-detail">
                                <div className="home-productbox-heading">
                                  <div className="price-inline-remove">
                                    <h2 title={item.name ? item.name : null}>
                                      {item.name ? item.name : `-`}
                                    </h2>
                                    {item.is_sale ? (
                                      <p>
                                        <del>
                                          {item.price
                                            ? `$${item.price
                                                .toFixed(2)
                                                .replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ","
                                                )}`
                                            : `-`}
                                        </del>
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="home-productbox-bottom">
                                  <div className="item-rating">
                                    <Rating
                                      name="hover-feedback"
                                      value={item.rating}
                                      precision={0.5}
                                      readOnly
                                    />
                                  </div>
                                  <div className="home-productbox-price">
                                    <p>
                                      {item.is_sale !== 0
                                        ? item.price_type === 1
                                          ? `$${(item.price - item.sale_price)
                                              .toFixed(2)
                                              .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )}`
                                          : `$${(
                                              item.price -
                                              (item.price *
                                                (100 *
                                                  (1 -
                                                    item.sale_price /
                                                      item.price))) /
                                                100
                                            )
                                              .toFixed(2)
                                              .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )}`
                                        : `${
                                            !item.price ? `` : `$${item.price}`
                                          }`}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      )
                    );
                  })}
                </OwlCarousel>
              </>
            )}
          </div>
        </section>
        {/* rating */}
        {this.state.userData ? (
          this.props.ratingDetails?.isPurchased ? (
            <>
              <div className="rating-section">
                <div className="container">
                  <div className="rating-section-inner">
                    {!this.props.ratingDetails?.isRated ? (
                      <form onSubmit={this.handleSubmitReviewForProduct}>
                        <h5>SUBMIT YOUR REVIEW</h5>
                        <Rating
                          name="hover-feedback"
                          value={this.state.ratingValue}
                          precision={0.5}
                          onChange={(event, newValue) => {
                            this.setState({ ratingValue: newValue });
                          }}
                          onChangeActive={(event, newHover) => {
                            this.setState({ hover: newHover });
                          }}
                        />
                        {this.state.ratingValue !== null && (
                          <Box ml={2}>
                            {
                              labels[
                                this.state.hover !== -1
                                  ? this.state.hover
                                  : this.state.value
                              ]
                            }
                          </Box>
                        )}
                        <div style={{ color: "red" }}>
                          {this.state.ratingError}
                        </div>
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            placeholder="add a review message..."
                            value={this.state.comment}
                            onChange={(event) =>
                              this.handleChange(event, "comment")
                            }
                          ></textarea>
                        </div>

                        <div className="rating-btn">
                          <button
                            className="orange-outline-btn"
                            disabled={this.state.disableReviewButton}
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    ) : (
                      <p>Product already rated!</p>
                    )}
                    {/* {this.state.otherComments?.map((i, index) => {
                                            return (<div>
                                                <p key={index}>{i.name}</p>
                                                <Rating
                                                    name="hover-feedback"
                                                    // value={this.state?.userRatingDetails?.rating ? this.state.userRatingDetails[0].rating : 0}
                                                    value={i.rating}
                                                    precision={0.5}
                                                />
                                                <p key={index}>{i.comment}</p>
                                            </div>
                                            )
                                        })} */}
                  </div>
                </div>
              </div>
            </>
          ) : null
        ) : (
          <div className="text-center">
            {" "}
            <button
              style={{ marginBottom: "10px" }}
              onClick={this.handleRedirectLoginPage}
            >
              Login now
            </button>
          </div>
        )}
        <div className="rating-section">
          <div className="container">
            <div className="rating-section-inner">
              <h3 className="text-left">Customer reviews</h3>
              <div className="row">
                <div className="col-md-6">
                  <ul className="rating-desc-customer">
                    {this.state.otherComments?.length > 0 ? (
                      this.state.otherComments?.map((i, index) => {
                        return (
                          <li>
                            <div className="name-date">
                              <p key={index}>
                                <i class="fas fa-user"></i>
                                <strong>{i.name ? i.name : i.user_name }</strong>
                              </p>
                              <span>{i.created_ts.slice(0, 10)}</span>
                            </div>
                            <Rating
                              name="hover-feedback"
                              // value={this.state?.userRatingDetails?.rating ? this.state.userRatingDetails[0].rating : 0}
                              value={i.rating}
                              precision={0.5}
                              readOnly
                            />
                            <p>{i.comment}</p>

                            <p>
                              <b>
                                {i.vendor_comment
                                  ? `Response : ` + i.vendor_comment
                                  : null}
                              </b>
                            </p>
                          </li>
                        );
                      })
                    ) : (
                      <h4>No reviews added</h4>
                    )}
                  </ul>
                </div>
              </div>
              <div className="text-left mb-3">
                {this.state.otherComments?.length >=
                this.state.loadCommentsAtaTime ? (
                  <button
                    className="read-link-clamp orange-btn"
                    onClick={() => this.handleLoadMore()}
                  >
                    Load More...
                  </button>
                ) : null}
                {/* <p
                  className="read-link-clamp orange-btn"
                  onClick={() => this.handleLoadMore()}
                >
                  {this.state.otherComments.length >=
                  this.state.loadCommentsAtaTime
                    ? "Load More..."
                    : null}
                </p> */}
              </div>
            </div>
          </div>
        </div>
        <div className="cta-video-main">
          <img src={ctaImg} alt="CTA" />
          <a className="orange-btn" href={vendorUrl} target="_blank">
            apply now
          </a>
        </div>
        {this.imageModal()}
        {this.freeSeminarModal()}
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    product: state.productReducer.productDetail,
    ratingDetails: state.productReducer.ratingDetails,
    cartItems: state.cartReducer.cartItems,
    productOriginalPrice: state.productReducer.productOriginalPrice,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getProductDetail,
      getProductPrice,
      addToCart,
      submitRatingReview,
      getRatingForUser,
      getCartItems,
      clearStateProductState,
      getUpdatedDobaProduct,
      uploadImage,
      getOtherComment,
      getSuggestedProductList,
      bookFreeSeminarSlot,
      getSessionFrequency,
      getOneTimeSlots,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductDetail)
);
