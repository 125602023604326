import React, { Component } from "react";
import "../../Styles/cart.scss";
import productImg from "../../assets/images-2/footer-product-item.png";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { matchTwoObjects } from "../../Utils/helper";
import moment from "moment";
import { Modal } from "react-bootstrap";

import {
  getCartItems,
  changeItemQuantity,
  removeItemFromCart,
} from "../../Redux/Actions/cartAction";
import {
  addWishlist,
  getWishlist,
  delWishlist,
} from "../../Redux/Actions/wishlistAction";
import Wishlist from "../WishList/Wishlist";
import { weekDayValue } from "../../Constants/constant";
// import moment from "moment";

class Cart extends Component {
  userId = null;

  constructor(props) {
    super(props);
    this.state = {
      itemToRemove: null,
      itemToRemoveIndex: null,
      orderLimitError: false,
      disableBtn: false,
      showModal: false,
      selectedStId: null
    };
  }

  componentDidMount() {
    this.props.getCartItems();
    this.userId = localStorage.getItem("userId");
  }

  handleItemRemove = (item, itemIndex) => {
    this.props.removeItemFromCart(item, itemIndex);
    this.closeModal();
  };

  handleQuantityChange = (incmType, item, itemIndex) => {
    if (incmType === "plus") {
      let weeklyCheck = item.timeFrame.includes("weekly")
        ? (item.selectedQuantity) * (((item.isReplenishCategoryAvailable || item.isCustomizeCategoryAvailable) && item.paymentOptionType==="one_time")?1:4)
        : item.selectedQuantity;
      if (item.selectedQuantity < item.quantity) {
        if (weeklyCheck + item.totalsubscription + item.oneTimeOrder < item.order_limit) {
          this.props.changeItemQuantity(
            item,
            item.selectedQuantity + 1,
            itemIndex
          );
        } else {
          this.setState({
            [`${itemIndex}`]: true,
          });
        }
      }
    } else {
      if (item.selectedQuantity > 1) {
        this.props.changeItemQuantity(
          item,
          item.selectedQuantity - 1,
          itemIndex
        );
        this.setState({
          [`${itemIndex}`]: false,
        });
      }
    }
  };

  openModal = (item, index) => {
    this.setState({
      itemToRemove: item,
      itemToRemoveIndex: index,
    });
  };

  async moveToWishlist(item, index) {
    if (this.userId) {
      if (this.state.disableBtn) return;
      this.setState({
        disableBtn: true,
      });
      var count = 0;
      const paymentOptionMethod = item.paymentOptionType
        ? item.paymentOptionType
        : item.payment_option_type;
      const userwishlistitem = await this.props.getWishlist(this.userId);
      var cartItemSelectedPair = Object.fromEntries(
        Object.entries(item.selectedPair).sort()
      );
      var wishlistItemSelectedPair = "";

      userwishlistitem.data.data.map((productId) => {
        wishlistItemSelectedPair = Object.fromEntries(
          Object.entries(
            JSON.parse(`${productId.variation_combination}`)
          ).sort()
        );

        if (!item.isAccessCategoryAvailable &&
          item.id === productId.product_id &&
          paymentOptionMethod === productId.payment_option_type &&
          matchTwoObjects(cartItemSelectedPair, wishlistItemSelectedPair)
        ) {
          count = 1;
        }
      });
      let additionalInfo = {};
      const customizationNote = item.isCustomizeCategoryAvailable
        ? item.customizationNote
        : undefined;
      const customizationImage = item.isCustomizeCategoryAvailable
        ? item.customizationImage
        : undefined;
      if (customizationNote) {
        additionalInfo["customizationNote"] = customizationNote;
      }
      if (customizationImage) {
        additionalInfo["customizationImage"] = customizationImage;
      }
      if (count !== 1) {
        let wishlist = {
          payment_option_type: item.paymentOptionType,
          userid: this.userId,
          product_id: item.id,
          variant_combination: item.selectedPair,
          additional_information: {
            ...additionalInfo,
            ...item
          },
        };
        await this.props.addWishlist(wishlist);
        this.handleItemRemove(item, index);
      } else {
        this.handleItemRemove(item, index);
      }
      this.setState({
        disableBtn: false,
      });
    } else {
      this.props.history.push("/login");
    }
  }

  groupTimeSlotsModal = () => (
    <Modal show={this.state.showModal} centered>
      <Modal.Header
        closeButton
        onClick={() => {
          this.setState({
            showModal: false,
          });
        }}
      >
        <b>Time slots</b>
      </Modal.Header>
      <Modal.Body className="time-slot-modal">
      <div>
      {
        this.props.cartItems.filter(i => i.selectedStructureId == this.state.selectedStId).map((item, index) => {
          return <>

          {!item.isSeminarEvent && item.paymentOptionType === "recurring" && item.sessionType == "group" ? (


              Object.entries(item.timeSlots).map(([weekDay, slotsArray]) => {

                return <>
                <p className="mb-2"><strong>{weekDayValue[weekDay]}</strong></p>
                <ul>{slotsArray?.length ? 
                slotsArray.map(i => {
                  return <li>{moment(i[0], "HH:mm").format("hh:mm A")} - {moment(i[1], "HH:mm").format("hh:mm A")} {i[2] ? `Every ${i[2]} ${weekDay} Of The Month` : null}</li>
                })
                : null}</ul>

                </>

              })
            
          ) : null}
          </>
        })
      }
      </div>
      </Modal.Body>
    </Modal>
  );

  closeModal = () => {
    this.setState({
      itemToRemove: null,
      itemToRemoveIndex: null,
    });
  };

  render() {
    const cartItems =
      this.props.cartItems && this.props.cartItems.length > 0
        ? this.props.cartItems
            .map((item, index) => {
              const productFrequency = localStorage.getItem("cartItems")
                ? JSON.parse(localStorage.getItem("cartItems"))[index].timeFrame
                : null;
              const productTimeFrame = item?.timeFrame
                ? item.timeFrame.split(",")[1]
                : null;
              const selectedPairKeys = item.selectedPair
                ? Object.keys(item.selectedPair)
                : [];
              const freeSeminarDetails = item.free_seminar_details
                ? JSON.parse(item.free_seminar_details)
                : null;

              return (
                <div className="cart-box-body" key={index}>
                  <div className="cart-box-detail">
                    <div className="cart-box-img">
                      <img src={item.imageName} />
                    </div>
                    <div className="cart-box-item-detail">
                      <h2>
                        <Link
                          to={"product/" + item.slug}
                          style={{ color: "#000000" }}
                        >
                          {item.name}
                        </Link>
                      </h2>

                      {selectedPairKeys.length > 0
                        ? selectedPairKeys.map((variant) => {
                            return item.selectedPair &&
                              item.selectedPair[variant] ? (
                              <p key={variant}>
                                <span>{variant.replace("_", " ")}: </span>
                                {item.selectedPair[variant]}
                              </p>
                            ) : null;
                          })
                        : null}
                    <div className="month-freq">
                      <span>Payment Frequency:&nbsp;</span> 
                      {item.paymentOptionType === "recurring" && !item.isSeminarEvent ? (
                        <p>{productTimeFrame}</p>
                      ) : (
                        <p>
                           <span> One Time </span>
                        </p>
                      )}
                    </div>
                    {!item.isSeminarEvent &&
                      item.timeSlots && item.sessionType == "group" ? 

                    <div className="month-freq">
                      <div onClick={() => this.setState({ showModal: true, selectedStId: item.selectedStructureId})}>
                      <button className="orange-outline-btn mt-2">view details</button>
                                  </div>
                    </div>
                    :
                    null
                    }
                    {!item.isSeminarEvent && item?.timeSlots && item.sessionType !== "group" && (
                        <div>
                          <span>Time Slots:</span>
                          {
                            <div className="d-flex align-items-center flex-wrap add-on-date mb-0">
                              {item?.timeSlots && item?.timeSlots.length>0 &&
                                item.timeSlots?.map((slot) => {
                                  return (
                                    <p className="mr-1">
                                      {moment(slot[0], ["HH:mm"]).format(
                                        "h:mm A"
                                      )}{" "}
                                      to{" "}
                                      {moment(slot[1], ["HH:mm"]).format(
                                        "h:mm A"
                                      )}
                                    </p>
                                  );
                                })}
                            </div>
                          }
                        </div>
                      )}
                      {item.isSeminarEvent ? (
                        <>
                          <div className="d-flex align-items-center flex-wrap font14 set-mobile-center">
                            <span>Date:&nbsp;</span>
                            {freeSeminarDetails.date}
                          </div>
                          <div className="d-flex align-items-center flex-wrap font14 set-mobile-center">
                            <span>Time Slots:&nbsp;</span>
                            {moment(freeSeminarDetails.start, "hh:mm").format(
                              "hh:mm A"
                            )}{" "}
                            {"to"}{" "}
                            {moment(freeSeminarDetails.end, "hh:mm").format(
                              "hh:mm A"
                            )}
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>

                  <div className="cart-box-item-mobile">
                    {!!!item?.isAccessCategoryAvailable && (
                      <div className="cart-box-item-counter">
                        {!item.isSeminarEvent ? (
                          <div className="cart-box-item-counter">
                            <button
                              type="button"
                              onClick={() =>
                                this.handleQuantityChange("minus", item, index)
                              }
                              className="btnLeft"
                            >
                              <i className="fas fa-minus"></i>
                            </button>
                            <p>{item.selectedQuantity}</p>
                            <button
                              type="button"
                              onClick={() =>
                                this.handleQuantityChange("plus", item, index)
                              }
                              className={
                                this.state[`${index}`]
                                  ? `btnRight disablebtn-right`
                                  : "btnRight"
                              }
                            >
                              <i className="fas fa-plus"></i>
                            </button>
                          </div>
                        ) : null}
                      </div>
                    )}

                    {this.state[`${index}`] && (
                      <div
                        className="stock-out text-center"
                        style={{ color: "#e25e53" }}
                      >
                        <p>Maximum Order Limit Reached</p>
                      </div>
                    )}
                  </div>

                  <div className="cart-box-item-price">
                    {item.is_sale != 0 && (
                      <p>
                        <del>
                          $
                          {(item.actualPrice * item.selectedQuantity)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </del>
                      </p>
                    )}

                    <p>
                      $
                      {(item.selectedQuantity * item.price)
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </p>
                    <a
                      onClick={() => this.openModal(item, index)}
                      data-toggle="modal"
                      data-target="#exampleModal"
                    >
                      Remove
                    </a>
                    <br />
                    <a onClick={() => this.moveToWishlist(item, index)}>
                      Add to Wishlist
                    </a>
                  </div>
                </div>
              );
            })
            .reverse()
        : null;

    return (
      <div>
        <main className="mainContainer">
          <section className="home-product-section mt-4">
            <div className="container">
              <div className="select-product-list">
                <div className="product-list-head product-list-border-bottom">
                  <ul>
                    <li>
                      <Link to={"/"}>home</Link>
                    </li>
                    <li>
                      <i className="fas fa-minus"></i>
                    </li>
                    <li>
                      <a>cart</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section className="cart-section">
            <div className="container">
              <div className="cart-section-head">
                <h2>cart</h2>
                <p>
                  You have{" "}
                  {this.props.cartItems
                    ? this.props.cartItems.length > 1
                      ? `${this.props.cartItems.length} items`
                      : `${this.props.cartItems.length} item`
                    : "0 item"}{" "}
                  in your cart
                </p>
              </div>

              <div className="cart-section-body">
                {cartItems}

                <div
                  className="modal fade"
                  id="exampleModal"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Confirm
                        </h5>
                        <button
                          type="button"
                          onClick={this.closeModal}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <p>
                          Are you sure to remove{" "}
                          {this.state.itemToRemove ? (
                            <b style={{ wordBreak: "break-word" }}>
                              {this.state.itemToRemove.name}
                            </b>
                          ) : (
                            "item"
                          )}{" "}
                          from the cart?
                        </p>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() =>
                            this.handleItemRemove(
                              this.state.itemToRemove,
                              this.state.itemToRemoveIndex
                            )
                          }
                          className="btn btn-primary"
                          data-dismiss="modal"
                        >
                          Yes
                        </button>
                        <button
                          type="button"
                          onClick={this.closeModal}
                          className="btn btn-secondary"
                          data-dismiss="modal"
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {this.props.cartItems && this.props.cartItems.length > 0 ? (
                <div className="cart-section-bottom text-center">
                  {!localStorage.getItem("userData") && (
                    <p className="mb-3">
                      <Link to={"/login"}>
                        Please Sign in to track your order
                      </Link>
                    </p>
                  )}
                  <div className="checkout-btn">
                    <Link to={"/checkout"}>
                      <button type="button">checkout</button>
                    </Link>
                  </div>
                </div>
              ) : null}
            </div>
          </section>
        </main>
        {this.groupTimeSlotsModal()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartReducer.cartItems,
  };
};

const mapDispatchToProsp = (dispatch) => {
  return bindActionCreators(
    {
      getCartItems,
      getWishlist,
      changeItemQuantity,
      removeItemFromCart,
      addWishlist,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProsp)(Cart);
