import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import "./zoom.scss";

import { generateToken } from "../../Redux/Actions/zoomAction";
import Profile_Sidebar from "../Profile-Side-Bar/Profile_Sidebar";

const JoinZoomMeeting = (props) => {
    const dispatch = useDispatch();

    const leaveUrl = window.location.origin + "/subscription";

    useEffect(() => {
        fetchInitialData()
    }, []);

    const fetchInitialData = async () => {
        const res = await dispatch(generateToken({
            subscriptionId: props.location.state?.subscriptionId
        }));
        if (res && res.data){
            const { ZoomMtg } = await import(`@zoomus/websdk`);
            ZoomMtg.setZoomJSLib('https://source.zoom.us/1.9.5/lib', '/av');
            ZoomMtg.preLoadWasm();
            ZoomMtg.prepareJssdk();

            setTimeout(()=>{
                ZoomMtg.init({
                    leaveUrl: leaveUrl,
                    isSupportAV: true,
                    success: (success) => {
                        ZoomMtg.join({
                            signature: res.data.signature,
                            meetingNumber: res.data.meetingId,
                            userName: res.data.userName,
                            apiKey: res.data.apiKey,
                            userEmail: res.data.userEmail,
                            passWord: res.data.passWord,
                            success: (success) => {
                            },
                            error: (error) => {
                                console.error(error)
                            }
                        })
                    },
                    error: (error) => {
                        console.error(error)
                    }
                })
            }, 1000)
        } else{
            props.history.push(leaveUrl)
        }
    };

    return (
        <main className="mainContainer">
            <section className="home-product-section mt-4">
                <div className="container">
                    <div className="select-product-list">
                        <div className="product-list-head product-list-border-bottom">
                            <ul>
                                <li><a href="javascript:;" style={{textTransform:"capitalize"}}>Join Zoom Meeting</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="access-pro">
                <div className="container">
                    <div className="row">
                        <Profile_Sidebar />
                        <div className="col-lg-9">
                            <div id="zmmtg-root" className="zoom-wrapper"/>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default JoinZoomMeeting;