import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import * as storeAction from "../../Redux/Actions/storeAction";
// import { getRatingForUser } from "../../Redux/Actions/storeAction";
import { getProductList } from "../../Redux/Actions/productAction";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import profileAvtar from "../../assets/images/profile-img.png";
import { useHistory } from "react-router";

const StyledRating = withStyles({
  iconFilled: {
    color: "#ff6d75",
  },
  iconHover: {
    color: "#ff3d47",
  },
})(Rating);

const SellerStore = (props) => {
  // const { storeId } = props.match.params;
  const { storeId, vendorId } = props.match.params;
  const history = useHistory();
  
  const userData = JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const [storeState, setStoreState] = useState({});
  const [ratingState, setRatingState] = useState({});
  const [newRatingState, setNewRatingState] = useState({
    ratingValue: 1,
    hover: 1,
    ratingError: "",
    comment: "",
    disableReviewButton: false,
  });
  const [showSubmitRatingButton, setShowSubmitRatingButton] = useState(true);
  const labels = {
    // 0.5: 0.5,
    1: 1,
    1.5: 1.5,
    2: 2,
    2.5: 2.5,
    3: 3,
    3.5: 3.5,
    4: 4,
    4.5: 4.5,
    5: 5,
  };
  const [newArrivals, setNewArrivals] = useState([]);
  const [bestSellers, setBestSellers] = useState([]);
  const [featured, setFeatured] = useState([]);
  const [shareText, setShareText] = useState(false);

  useEffect(() => {
    fetchStoreData();
    getProductData();
    if (userData && userData.id) {
      fetchRatingsData();
    }
  }, []);

  const fetchStoreData = async () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await dispatch(
      storeAction.getSellerStoreDetailById(storeId, timezone)
    );
    if (response && response.success) {
      setStoreState(response.data);
    }
  };

  const fetchRatingsData = async () => {
    const response = await dispatch(
      storeAction.getRatingForUser(userData.id, storeId)
    );
    if (response) {
      setRatingState(response);
    }
  };

  const handleSubmitReviewForStore = async (e) => {
    e.preventDefault();
    setNewRatingState((prevState) => ({
      ...prevState,
      disableReviewButton: true,
    }));
    if (newRatingState.ratingValue) {
      setNewRatingState((prevState) => ({ ...prevState, ratingError: "" }));
      await dispatch(
        storeAction.submitRatingReview({
          user_id: userData.id,
          store_id: storeId,
          rating: newRatingState.ratingValue,
          comment: newRatingState.comment,
          order_id: ratingState?.data.order_id,
        })
      );
      await fetchStoreData();
      await fetchRatingsData();
    } else {
      setNewRatingState((prevState) => ({
        ...prevState,
        ratingError: "you must give at least one rating",
      }));
    }
    setNewRatingState((prevState) => ({
      ...prevState,
      disableReviewButton: false,
    }));
  };

  const handleChange = (event, key) => {
    event.persist();
    setNewRatingState((prevState) => ({
      ...prevState,
      [key]: event.target.value,
    }));
  };

  const handleShareButton = () => {
    let currentLink = window.location.href;
    navigator.clipboard.writeText(currentLink);
    setShareText(true);
    setTimeout(() => {
      setShareText(false);
    }, 1000);
    // document.body.removeChild(dummy);
  };
  // -----------------------------------------------------------------------------------------------------------------------
  // let toAssignState = 0;
  // const productList = {
  //   newArrivals: [],
  //   bestSellers: [],
  //   featured: [],
  //   weekly: [],
  //   monthly: [],
  //   annual: [],
  // };
  // const filterList = [
  //   "newArrivals",
  //   "bestSellers",
  //   "featured",
  //   "weekly",
  //   "monthly",
  //   "annual",
  // ];

  const getProductData = async () => {
    const response = await dispatch(
      getProductList("vendorList", 0, 10, " ", vendorId)
    );
    if (response && response.data?.length > 0) {
      response.data.forEach((item) => {
   
        if (item.catName === "Access") {
          setNewArrivals(item.prodList);
        }
        if (item.catName === "Replenish") {
          setBestSellers(item.prodList);
        }
        if (item.catName === "Customize") {
          setFeatured(item.prodList);
        }
      });
    }
  };

  const newArrivalList = !newArrivals
    ? []
    : newArrivals.map((item) => {
        let isInStock = !item?.isOutOfStock;
        // let isInStock =
        // item.totalsubscription + item.oneTimeOrder <
        // item.order_limit;

        // if (
        //   item?.timeFrame &&
        //   item.timeFrame.includes("weekly")
        // ) {
        //   isInStock =
        //     Math.floor(
        //       (item.order_limit -
        //         (item.totalsubscription +
        //           item.oneTimeOrder)) /
        //       4
        //     ) > 0;
        // }

        return (
          <div className="col-md-3 col-6" key={item.id}>
            <div className="home-product-item">
              {item.is_sale !== 0 ? (
                item.price_type === 1 ? (
                  <div className="sale-label-star">${item.sale_price} off</div>
                ) : (
                  <div className="sale-label-star">
                    {" "}
                    {Math.round(100 * (1 - item.sale_price / item.price))}% off
                  </div>
                )
              ) : null}
              {!item?.isAccessCategoryAvailable && item.is_shipping_free ? (
                <div to="/" className="shipping-label-star">
                  free shipping
                </div>
              ) : null}
              <Link
                to={{
                  pathname: `/product/${item.slug}`,
                  state: { spuId: item.spu_id },
                }}
              >
                <div className="home-product-box">
                  <img src={item.imageName} alt="" />

                  {isInStock || item?.isAccessCategoryAvailable ? (
                    <div className="quickviewbtn">
                      <p>Quick View</p>
                    </div>
                  ) : (
                    <div className="quickviewbtn">
                      <p>Sold Out</p>
                    </div>
                  )}
                </div>
                <div className="home-productbox-detail">
                  <div className="home-productbox-heading">
                    <div className="price-inline-remove">
                      <h2 title={item.name ? item.name : null}>
                        {item.name ? item.name : `-`}
                      </h2>
                      {item.is_sale ? (
                        <p>
                          <del>
                            {item.price
                              ? `$${item.price
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                              : `-`}
                          </del>
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <p className="product-price-mobile">
                    {item.is_sale !== 0
                            ? item.price_type === 1
                              ? `$${(item.price - item.sale_price)
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                              : `$${(
                                  item.price -
                                  (item.price *
                                    (100 *
                                      (1 - item.sale_price / item.price))) /
                                    100
                                )
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                            : `${!item.price ? `` : `$${item.price}`}`}
                             {item.is_sale ? (
                          <p>
                            <del>
                              {item.price
                                ? `$${item.price
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                : `-`}
                            </del>
                          </p>
                        ) : null}
                        </p>
                  <div className="home-productbox-bottom">
                    <div className="item-rating">
                      <Rating
                        name="hover-feedback"
                        value={item.rating}
                        precision={0.5}
                        readOnly
                      />
                    </div>
                    <div className="home-productbox-price">
                      <p>
                        {item.is_sale !== 0
                          ? item.price_type === 1
                            ? `$${(item.price - item.sale_price)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                            : `$${(
                                item.price -
                                (item.price *
                                  (100 * (1 - item.sale_price / item.price))) /
                                  100
                              )
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                          : `${!item.price ? `` : `$${item.price}`}`}
                      </p>
                    </div>
                  </div>
                  {!item?.isAccessCategoryAvailable && item.is_shipping_free ? (
                    <p className="shipping-mobile-label">FREE SHIPPING</p>
                    ) : null}
                </div>
              </Link>
            </div>
          </div>
        );
      });

  const bestSellersList = bestSellers?.map((item) => {
    let isInStock = !item?.isOutOfStock;
    // let isInStock =
    // item.totalsubscription + item.oneTimeOrder <
    // item.order_limit;

    // if (
    //   item?.timeFrame &&
    //   item.timeFrame.includes("weekly")
    // ) {
    //   isInStock =
    //     Math.floor(
    //       (item.order_limit -
    //         (item.totalsubscription +
    //           item.oneTimeOrder)) /
    //       4
    //     ) > 0;
    // }
    return (
      <div className="col-md-3 col-6" key={item.id}>
        <div className="home-product-item">
          {item.is_sale !== 0 ? (
            item.price_type === 1 ? (
              <div className="sale-label-star">
                ${item.sale_price} off
              </div>
            ) : (
              <div className="sale-label-star">
                {" "}
                {Math.round(100 * (1 - item.sale_price / item.price))}% off
              </div>
            )
          ) : null}
          {!item?.isAccessCategoryAvailable && item.is_shipping_free ? (
            <div to="/" className="shipping-label-star">
              free shipping
            </div>
          ) : null}
          <Link
            to={{
              pathname: `/product/${item.slug}`,
              state: { spuId: item.spu_id },
            }}
          >
            <div className="home-product-box">
              <img src={item.imageName} alt="" />
              {isInStock || item?.isAccessCategoryAvailable ? (
                <div className="quickviewbtn">
                  <p>Quick View</p>
                </div>
              ) : (
                <div className="quickviewbtn">
                  <p>Sold Out</p>
                </div>
              )}
              {/* <a href="#" className="quickviewbtn">
                    <p>Quick View</p>
                  </a> */}
            </div>
            <div className="home-productbox-detail">
              <div className="home-productbox-heading">
                <div className="price-inline-remove">
                  <h2 title={item.name ? item.name : null}>
                    {item.name ? item.name : `-`}
                  </h2>
                  {item.is_sale ? (
                    <p>
                      <del>
                        {item.price
                          ? `$${item.price
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                          : `-`}
                      </del>
                    </p>
                  ) : null}
                </div>
              </div>
              <p className="product-price-mobile">
                    {item.is_sale !== 0
                            ? item.price_type === 1
                              ? `$${(item.price - item.sale_price)
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                              : `$${(
                                  item.price -
                                  (item.price *
                                    (100 *
                                      (1 - item.sale_price / item.price))) /
                                    100
                                )
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                            : `${!item.price ? `` : `$${item.price}`}`}
                             {item.is_sale ? (
                          <p>
                            <del>
                              {item.price
                                ? `$${item.price
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                : `-`}
                            </del>
                          </p>
                        ) : null}
                        </p>
              <div className="home-productbox-bottom">
                <div className="item-rating">
                  <Rating
                    name="hover-feedback"
                    value={item.rating}
                    precision={0.5}
                    readOnly
                  />
                </div>
                <div className="home-productbox-price">
                  <p>
                    {item.is_sale !== 0
                      ? item.price_type === 1
                        ? `$${(item.price - item.sale_price)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                        : `$${(
                            item.price -
                            (item.price *
                              (100 * (1 - item.sale_price / item.price))) /
                              100
                          )
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                      : `${!item.price ? `` : `$${item.price}`}`}
                  </p>
                </div>
              </div>
              {!item?.isAccessCategoryAvailable && item.is_shipping_free ? (
                    <p className="shipping-mobile-label">FREE SHIPPING</p>
                    ) : null}
            </div>
          </Link>
        </div>
      </div>
    );
  });

  const featuredList = featured?.map((item) => {
    let isInStock = !item?.isOutOfStock;
    // let isInStock =
    // item.totalsubscription + item.oneTimeOrder <
    // item.order_limit;

    // if (
    //   item?.timeFrame &&
    //   item.timeFrame.includes("weekly")
    // ) {
    //   isInStock =
    //     Math.floor(
    //       (item.order_limit -
    //         (item.totalsubscription +
    //           item.oneTimeOrder)) /
    //       4
    //     ) > 0;
    // }

    return (
      <div className="col-md-3 col-6" key={item.id}>
        <div className="home-product-item">
          {item.is_sale !== 0 ? (
            item.price_type === 1 ? (
              <div className="sale-label-star">${item.sale_price} off</div>
            ) : (
              <div className="sale-label-star">
                {" "}
                {Math.round(100 * (1 - item.sale_price / item.price))}% off
              </div>
            )
          ) : null}
          {!item?.isAccessCategoryAvailable && item.is_shipping_free ? (
            <div to="/" className="shipping-label-star">
              free shipping
            </div>
          ) : null}
          <Link
            to={{
              pathname: `/product/${item.slug}`,
              state: { spuId: item?.spu_id },
            }}
          >
            <div className="home-product-box">
              <img src={item.imageName} alt="" />
              {isInStock || item?.isAccessCategoryAvailable ? (
                <div className="quickviewbtn">
                  <p>Quick View</p>
                </div>
              ) : (
                <div className="quickviewbtn">
                  <p>Sold Out</p>
                </div>
              )}
            </div>
            <div className="home-productbox-detail">
              <div className="home-productbox-heading">
                <div className="price-inline-remove">
                  <h2 title={item.name ? item.name : null}>
                    {item.name ? item.name : `-`}
                  </h2>
                  {item.is_sale ? (
                    <p>
                      <del>
                        {item.price
                          ? `$${item.price
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                          : `-`}
                      </del>
                    </p>
                  ) : null}
                </div>
              </div>
              <p className="product-price-mobile">
                    {item.is_sale !== 0
                            ? item.price_type === 1
                              ? `$${(item.price - item.sale_price)
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                              : `$${(
                                  item.price -
                                  (item.price *
                                    (100 *
                                      (1 - item.sale_price / item.price))) /
                                    100
                                )
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                            : `${!item.price ? `` : `$${item.price}`}`}
                             {item.is_sale ? (
                          <p>
                            <del>
                              {item.price
                                ? `$${item.price
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                : `-`}
                            </del>
                          </p>
                        ) : null}
                        </p>
              <div className="home-productbox-bottom">
                <div className="item-rating">
                  <Rating
                    name="hover-feedback"
                    value={item.rating}
                    precision={0.5}
                    readOnly
                  />
                </div>
                <div className="home-productbox-price">
                  <p>
                    {item.is_sale !== 0
                      ? item.price_type === 1
                        ? `$${(item.price - item.sale_price)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                        : `$${(
                            item.price -
                            (item.price *
                              (100 * (1 - item.sale_price / item.price))) /
                              100
                          )
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                      : `${!item.price ? `` : `$${item.price}`}`}
                  </p>
                </div>
              </div>
              {!item?.isAccessCategoryAvailable && item.is_shipping_free ? (
                    <p className="shipping-mobile-label">FREE SHIPPING</p>
                    ) : null}
            </div>
          </Link>
        </div>
      </div>
    );
  });

  return (
    <>
    <div className="profile-bg">
    <div className="container">
    <div className="profile-box-new">
      <img src={storeState?.imagename ? storeState.imagename : profileAvtar} alt="profile" className="profile-img-set" />
      <div className="profile-box-desc">
        <div className="subdesc-profile">
        <h4>{storeState.storename}</h4>
        <div className="rating-align">
          <div className="subalign-rating">
        <div className="item-rating">
                      <Rating
                        name="hover-feedback"
                        value={storeState.rating ? storeState.rating : 0}
                        precision={0.5}
                        readOnly
                      />
        </div>
        <div className="button-align-mobile">
        <button onClick={() => setShowSubmitRatingButton(false)} className="orange-outline-btn">RATE THIS STORE</button>
        <button  onClick={() => {
                      history.push("/messages", {
                        message_source_type: "profile",
                        message_source_id: vendorId,
                        sender_type: "buyer",

                        source_detail: storeState.storename,
                        seller_id: vendorId,
                        storename: storeState.storename,
                        timezone: storeState.timezone,
                        availableFrom: storeState.available_from,
                        availableTo: storeState.available_to,
                        sellerChatActive: storeState.isChatActive,
                      });
                    }} className="orange-outline-btn">CONTACT VENDOR</button>
        <button className="orange-outline-btn tooltip-msg" onClick={handleShareButton}>
        <i className="fa fa-share-alt" aria-hidden="true" />
        {shareText ? <span>Copied to clipboard!</span> : null}
        </button>
        </div>
        </div>
        <div className="timezone-custom">
            <p><i className="far fa-clock" /> Timezone: {storeState?.timezone}</p>
            <p><i className="fas fa-map-marker-alt" /> {storeState?.city}, United States</p>
          </div>
        </div>
          </div>
          <p className="profile-para">{storeState.store_overview ? storeState.store_overview : null}</p>
      </div>
        </div>
    </div>
    </div>
    <div className="container text-center">
        {showSubmitRatingButton ? (
          <>
            <div className="rating-section">
              <div className="container">
                <div className="rating-section-inner border-0">
                  {/* <button
                    onClick={() => setShowSubmitRatingButton(false)}
                    className="mr-2"
                  >
                    Rate this store
                  </button> */}

                  {/* <button
                    onClick={() => {
                      history.push("/messages", {
                        message_source_type: "profile",
                        message_source_id: storeId,
                        sender_type: "buyer",

                        source_detail: storeState.storename,
                        seller_id: vendorId,
                        storename: storeState.storename,
                        timezone: storeState.timezone,
                        availableFrom: storeState.available_from,
                        availableTo: storeState.available_to,
                      });
                    }}
                    className="mr-2"
                  >
                    <span>Contact Vendor</span>
                  </button> */}
                  {/* <button onClick={handleShareButton} className="mr-2">
                  Share
                </button> */}
                </div>
                {/* {shareText ? <span>Copied to clipboard!</span> : null} */}
              </div>
            </div>
          </>
        ) : !(userData && userData.id) ? (
          <>
            <div className="rating-section">
              <div className="container">
                <div className="rating-section-inner">
                  <Link style={{ color: "#e25e52" }} to={"/login"}>
                    Please login to submit your rating!
                    <span>
                      <i
                        className="fas fa-external-link-alt"
                        style={{ marginLeft: "4px" }}
                      ></i>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </>
        ) : ratingState.isPurchased ? (
          <>
            <div className="rating-section">
              <div className="container">
                <div className="rating-section-inner">
                  {!ratingState?.isRated ? (
                    <form onSubmit={handleSubmitReviewForStore}>
                      <h5>SUBMIT YOUR REVIEW</h5>
                      <Rating
                        name="hover-feedback"
                        value={newRatingState.ratingValue}
                        precision={0.5}
                        onChange={(event, newValue) => {
                          setNewRatingState((prevState) => ({
                            ...prevState,
                            ratingValue: newValue >= 1 ? newValue : 1,
                          }));
                        }}
                        onChangeActive={(event, newHover) => {
                          setNewRatingState((prevState) => ({
                            ...prevState,
                            hover: newHover >= 1 ? newHover : 1,
                          }));
                        }}
                      />
                      {newRatingState.ratingValue !== null && (
                        <Box ml={2}>
                          {
                            labels[
                              newRatingState.hover == 1
                                ? newRatingState.ratingValue
                                : newRatingState.hover
                            ]
                          }
                        </Box>
                      )}
                      <div style={{ color: "red" }}>
                        {newRatingState.ratingError}
                      </div>
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          placeholder="add a review message..."
                          value={newRatingState.comment}
                          onChange={(event) => handleChange(event, "comment")}
                        />
                      </div>

                      <div className="rating-btn">
                        <button
                          className="orange-outline-btn"
                          disabled={newRatingState.disableReviewButton}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  ) : (
                    <p>Rating already given!</p>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="rating-section">
              <div className="container">
                <div className="rating-section-inner">
                  <p>Place order to give rating!</p>
                </div>
              </div>
            </div>
          </>
        )}
    
      <h4>Products from this vendor</h4>
      <main className="mainContainer">
        {/* NEW ARRIVALS */}
        {newArrivalList?.length > 0 && (
          <section className="home-product-section">
            <div className="container">
              <div className="home-section-heading mb-3">
                <ul>
                  <li>
                    <h2>Access</h2>
                  </li>
                  <li>|</li>
                  <li>
                    <Link
                      to={{
                        pathname: "/shop-by-vendor-list/access",
                        state: {
                          categoryType: "access",
                          from: "store",
                          categoryId: 3,
                          vendorId: vendorId,
                        },
                      }}
                    >
                      See All
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="container-fluid">
                {/* <select> */}
                {/* </select> */}
                <div className="row">{newArrivalList}</div>
              </div>
            </div>
          </section>
        )}

        {/* BEST SELLER */}
        {bestSellersList?.length > 0 && (
          <section className="home-product-section">
            <div className="container">
              <div className="home-section-heading mb-3">
                <ul>
                  <li>
                    <h2>Replenish</h2>
                  </li>
                  <li>|</li>
                  <li>
                    <Link
                      to={{
                        pathname: "/shop-by-vendor-list/replenish",
                        state: {
                          categoryType: "replenish",
                          from: "store",
                          categoryId: 101,
                          vendorId: vendorId,
                        },
                      }}
                    >
                      See All
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="container-fluid">
                <div className="row">{bestSellersList}</div>
              </div>
            </div>
          </section>
        )}

        {/* FEATURED */}
        {featuredList?.length > 0 && (
          <section className="home-product-section">
            <div className="container">
              <div className="home-section-heading mb-3">
                <ul>
                  <li>
                    <h2>Customize</h2>
                  </li>
                  <li>|</li>
                  <li>
                    <Link
                      to={{
                        pathname: "/shop-by-vendor-list/customize",
                        state: {
                          categoryType: "customize",
                          from: "store",
                          categoryId: 119,
                          vendorId: vendorId,
                        },
                      }}
                    >
                      See All
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="container-fluid">
                <div className="row">{featuredList}</div>
              </div>
            </div>
          </section>
        )}
      </main>
    </div>
    </>
  );
};
export default SellerStore;
