import axios from 'axios';
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;


export const getRecentBlogs = ( limit, offset=0) => async (dispatch) => {
    try {
        dispatch({ type: "LOADING_STARTED" });
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/blogs/most-recent?limit=${limit}&offset=${offset}`
        });
        dispatch({ type: "LOADING_COMPLETED" });
        return res.data

    } catch (error) {
        dispatch(handleError(error));
    }
}

export const categoriesList = () => async (dispatch) => {
    try {
        dispatch({ type: "LOADING_STARTED" });
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/blogs/category-list`
        });
        dispatch({ type: "LOADING_COMPLETED" });
        return res.data

    } catch (error) {
        dispatch(handleError(error));
    }
}

export const getTopBlogs = (offset) => async (dispatch) => {
    try {
        dispatch({ type: "LOADING_STARTED" });
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/blogs/top-blogs?offset=${offset}`
        });
        dispatch({ type: "LOADING_COMPLETED" });
        return res.data

    } catch (error) {
        dispatch(handleError(error));
    }
}
export const getBlogsByCategory = (slug, offset) => async (dispatch) => {
    try {
        dispatch({ type: "LOADING_STARTED" });
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/blogs/category/${slug}?offset=${offset}`
        });
        dispatch({ type: "LOADING_COMPLETED" });
        return res.data

    } catch (error) {
        dispatch(handleError(error));
    }
}

export const getBlogBySlug = (slug) => async (dispatch) => {
    try {
        dispatch({ type: "LOADING_STARTED" });
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/blogs/${slug}`
        });
        dispatch({ type: "LOADING_COMPLETED" });
        return res.data

    } catch (error) {
        dispatch(handleError(error));
    }
}
export const getRelevantBlogs = (categoryId, limit) => async (dispatch) => {
    try {
        dispatch({ type: "LOADING_STARTED" });
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/blogs/relevant/${categoryId}?limit=${limit}`
        });
        dispatch({ type: "LOADING_COMPLETED" });
        return res.data

    } catch (error) {
        dispatch(handleError(error));
    }
}

