import React, { Component } from "react";
import "../../Styles/cart.scss";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import queryString from "query-string";
import {
  getCartItems,
  paypalSubscriptionCreate,
  paypalSubscriptionUpdate,
  paypalSubscriptionBillCreate,
  paypalSubscriptionExecute,
  createOrder,
} from "../../Redux/Actions/cartAction";

import { getMultipleOrderDetail } from "../../Redux/Actions/orderAction";

import CryptoJS from "react-native-crypto-js";

class CheckoutSubscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartData: [],
      oneTimeItems: [],
      discountInfo: {},
      orderDetail: [],
      itemDetail: [],
      filterSubscriptionItems: [],
      isDisableSubscribeButton: false,
      paymentResponse: null,
      isPaymentButtonEnable: false,
      transication_id: null,
      token: null,
    };
    this.paypalRef = React.createRef();
    this.orderDetailModalRef = React.createRef();
  }

  clearItemInterval;

  async componentDidMount() {
    let billingUpdateData;
    let approval_url;
    await this.getDiscountInfoFromLocalStorage();

    let cartData = localStorage.getItem("cartItems")
      ? JSON.parse(localStorage.getItem("cartItems"))
      : [];
    let userData = localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData"))
      : [];
    let userInfo = localStorage.getItem("userAddress")
      ? JSON.parse(localStorage.getItem("userAddress"))
      : [];
    let billingInfo = localStorage.getItem("billingData")
      ? JSON.parse(localStorage.getItem("billingData"))
      : [];
    if (this.props.location.state?.userData) {
      // if(!userData && this.props.location.state?.userData){
      userData = JSON.parse(this.props.location.state?.userData);
    } else {
      if (localStorage.getItem("tempAddress")?.length) {
        if (userData) {
          userData["address"] = localStorage.getItem("tempAddress");
          localStorage.setItem("userData", JSON.stringify(userData));
        }
      }
    }
    let filterSubscriptionItems = cartData.filter(
      (item, index) => item.paymentOptionType !== "one_time"
    );
    let oneTimeItems = cartData.filter(
      (item, index) => item.paymentOptionType === "one_time"
    );
    let totalAmountOfOneTimeItems = 0;
    for (let item of oneTimeItems) {
      let discount =
        (item.price *
          item.selectedQuantity *
          this.state.discountInfo.discountPercent) /
        100;
      totalAmountOfOneTimeItems +=
        +item.price * item.selectedQuantity +
        (item.shippingCharge ? +item.shippingCharge : 0) +
        (item.tax ? +item.tax : 0) -
        discount;
    }
    totalAmountOfOneTimeItems = +parseFloat(totalAmountOfOneTimeItems).toFixed(
      2
    );
    await this.clearItemOnSubscribed(cartData);

    await this.setState({
      cartData,
      oneTimeItems,
      filterSubscriptionItems,
      totalAmountOfOneTimeItems,
    });
    const token =
      queryString.parse(this.props.location.search)?.token !==
      localStorage.getItem("agreementToken")
        ? queryString.parse(this.props.location.search)?.token
        : null;
    this.setState((prevState) => ({
      ...prevState,
      token: queryString.parse(this.props.location.search)?.token,
    }));
    localStorage.setItem(
      "agreementToken",
      queryString.parse(this.props.location.search)?.token
    );
    await this.isComingFromCheckoutPage(token);

    cartData = this.state.cartData;
    if (token) {
      const foundItemIndex = localStorage.getItem("itemIndex");
      let executionAttempt = 3;
      while (executionAttempt--) {
        let executedData = await this.props.paypalSubscriptionExecute({
          token,
        });

        if (executedData.message?.id) {
          // let foundItemIndex = cartData.findIndex((x) => x.id == clicked);

          if (foundItemIndex >= 0 && cartData && cartData[foundItemIndex]) {
            cartData[foundItemIndex]["subscriptionId"] =
              executedData.message.id;
            localStorage.setItem("cartItems", JSON.stringify(cartData));
            await this.setState({
              cartData,
              isDisableSubscribeButton: true,
            });
            const createOrderRes = await this.props.createOrder({
              id: userData.id ? userData.id : 0,
              firstname: userInfo.firstname,
              lastname: userInfo.lastname,
              email: userInfo.email,
              address: userInfo.address,
              address_2: userInfo.address_2,
              city: userInfo.city,
              state: userInfo.state,
              country: userInfo.country,
              zipCode: userInfo.zipCode,
              phonenumber: userInfo.phonenumber,
              // billingFirstName : billingInfo.billingFirstName,
              // billingLastName : billingInfo.billingLastName,
              // billingEmail: billingInfo.billingEmail,
              // billingAddress: billingInfo.billingAddress,
              // billingAddress_2: billingInfo.billingAddress_2,
              // billingCity: billingInfo.billingCity,
              // billingState: billingInfo.billingState,
              // billingCountry: billingInfo.billingCountry,
              // billingZipCode: billingInfo.billingZipCode,
              // billingPhonenumber: billingInfo.billingPhonenumber,
              paymentType: "paypal",
              cartItems: [cartData[foundItemIndex]],
              ...this.state.discountInfo,
            });
            if (createOrderRes && createOrderRes.isError === false) {
              await this.fetchOrderDetail(createOrderRes.orderId);
              this.enableSubscriptionButton();
            }

            cartData[foundItemIndex]["subscribed"] = true;
            localStorage.setItem("cartItems", JSON.stringify(cartData));
            await this.setState({ cartData });
            // window.open(window.location.pathname, "_self");
            clearInterval(this.clearItemInterval);
            await this.clearItemOnSubscribed(cartData);
            break;
          }
        }
      }
    }
    for (let i = 0; i < cartData?.length; i++) {
      let tcreate = 3;
      let tupdate = 3;
      let tbillcreate = 3;
      while (
        (tcreate || tupdate || tbillcreate) &&
        !cartData[i]?.approval_url
      ) {
        // update the amount by considering shipping , tax, discount
        if (cartData[i].oneTimeItems === "one_time") {
          continue;
        }
        const data = await this.props.paypalSubscriptionCreate({
          productAmount: cartData[i].price * cartData[i].selectedQuantity,
          shippingAmount: cartData[i].shippingCharge
            ? cartData[i].shippingCharge
            : 0,
          taxAmount: cartData[i].tax ? cartData[i].tax : 0,
          timeFrame: cartData[i].timeFrame,
          discountInfo: this.state.discountInfo,
          productTitle: cartData[i].name,
          productDesc: cartData[i].description,
        });
        if (data) {
          billingUpdateData = await this.props.paypalSubscriptionUpdate({
            billingPlan: data.data,
            userData,
            productTitle: cartData[i]?.name,
            productDesc: cartData[i]?.description,
          });

          if (billingUpdateData) {
            approval_url = await this.props.paypalSubscriptionBillCreate({
              billingAgreementAttributes: billingUpdateData.data,
            });

            if (approval_url) {
              cartData[i]["approval_url"] = approval_url.data;
              cartData[i]["subscribed"] = false;
              await this.setState({ cartData });
              localStorage.setItem(
                "cartItems",
                JSON.stringify(this.state.cartData)
              );
              break;
            } else {
              tbillcreate--;
              continue;
            }
          } else {
            tupdate--;
            continue;
          }
        } else {
          tcreate--;
          continue;
        }
      }
      if (cartData[i].approval_url) {
        cartData[i]["disabled"] = false;
        await this.setState({ cartData });
      } else {
        cartData[i]["disabled"] = true;
        await this.setState({ cartData });
      }
    }
  }

  fetchOrderDetail = async (encryptOrderId) => {
    let decryptOrderId = "";
    if (encryptOrderId) {
      encryptOrderId = encryptOrderId?.replace(/@@@/g, "/");
      decryptOrderId = CryptoJS.AES.decrypt(
        encryptOrderId,
        "itemsOrderId!@#$%"
      );
      decryptOrderId = decryptOrderId.toString(CryptoJS.enc.Utf8);
      decryptOrderId = JSON.parse(decryptOrderId);
    }
    const res = await this.props.getMultipleOrderDetail(decryptOrderId);

    if (res && res.data && res.data && !res.data.isError) {
      await this.setState({
        orderDetail: res.data.data.orderDetail,
        itemDetail: res.data.data.itemDetail,
      });

      // open modal and display order detail in modal
      this.orderDetailModalRef.current.click();
    } else {
      // something went wrong
    }
    return;
  };

  enableSubscriptionButton() {
    setTimeout(() => {
      this.setState({
        isDisableSubscribeButton: false,
      });
    }, 5000);
  }

  getDiscountInfoFromLocalStorage = async () => {
    let discount = localStorage.getItem("discount");
    if (discount) {
      discount = CryptoJS.AES.decrypt(discount, "#$discountsecret$#");
      discount = discount.toString(CryptoJS.enc.Utf8);
      discount = JSON.parse(discount);
      await this.setState({
        discountInfo: discount,
      });
      return;
    }
    const discountInfo = await this.setDiscountInfoInLocalStorage();
    this.setState({ discountInfo });
    // await this.setState({
    //   discountInfo: {
    //     discountCode: "",
    //     discountId: null,
    //     discountPercent: 0,
    //   },
    // });
    return;
  };

  setDiscountInfoInLocalStorage = async () => {
    const routeStateInfo = this.props.history.location.state;
    const encryptDiscountInfo = await CryptoJS.AES.encrypt(
      JSON.stringify({
        discountCode: routeStateInfo.discountCode,
        discountId: routeStateInfo.discountId,
        discountPercent: routeStateInfo.discountPercent,
        totalDiscount: routeStateInfo.totalDiscount,
      }),
      "#$discountsecret$#"
    ).toString();
    localStorage.setItem("discount", encryptDiscountInfo);
    return {
      discountCode: routeStateInfo.discountCode,
      discountId: routeStateInfo.discountId,
      discountPercent: routeStateInfo.discountPercent,
      totalDiscount: routeStateInfo.totalDiscount,
    };
  };

  getDiscountInfoFromRoute = () => {
    const routeStateInfo = this.props.history.location.state;

    if (routeStateInfo) {
      return {
        discountCode: routeStateInfo.discountCode,
        discountId: routeStateInfo.discountId,
        discountPercent: routeStateInfo.discountPercent,
      };
    }

    return {
      discountCode: "",
      discountId: null,
      discountPercent: 0,
    };
  };

  async isComingFromCheckoutPage(token) {
    if (!token && this.props.history.location?.state?.history !== "/checkout") {
      localStorage.removeItem("discount");
      this.props.history.push("/checkout");
    } else if (this.props.history.location?.state?.history == "/checkout") {
      let cartData = this.state.cartData.map((item) => {
        if (item.approval_url) {
          delete item.approval_url;
        }
        return item;
      });
      localStorage.setItem("cartItems", JSON.stringify(cartData));

      const discountInfo = await this.setDiscountInfoInLocalStorage();

      await this.setState({
        cartData,
        discountInfo,
      });
    }
    return;
  }

  clearItemOnSubscribed(cartData) {
    let items = cartData?.length ? cartData : [];
    let isItemToRemove = false;
    this.clearItemInterval = setInterval(() => {
      for (let i = 0; i < items.length; i++) {
        if (items[i].subscribed === true) {
          isItemToRemove = true;
          items.splice(i, 1);
        }
      }
      if (isItemToRemove) {
        localStorage.setItem("cartItems", JSON.stringify(items));
        this.setState({
          items,
        });
        this.props.getCartItems();
      }
    }, 1000 * 5);
  }

  clearOneTimeItems() {
    clearInterval(this.clearItemInterval);
    // let items = this.state.cartData ? this.state.cartData : [];
    let items = localStorage.getItem("cartItems")
      ? JSON.parse(localStorage.getItem("cartItems"))
      : [];
    let subscriptionItems = [];
    for (let item of items) {
      if (item.paymentOptionType !== "one_time") {
        subscriptionItems.push(item);
      }
    }
    localStorage.setItem("cartItems", JSON.stringify(subscriptionItems));
    this.setState({
      items,
      oneTimeItems: [],
    });
    this.clearItemOnSubscribed(JSON.stringify(subscriptionItems));
    this.props.getCartItems();
  }

  handleClick = async (itemIndex) => {
    localStorage.setItem("itemIndex", itemIndex);
    await window.open(this.state.cartData[itemIndex].approval_url, "_self");
  };

  componentWillUnmount() {
    clearInterval(this.clearItemInterval);
  }

  loadPaypal = () => {
    if (this.paypalRef.current) {
      window.paypal
        .Buttons({
          style: {
            layout: "horizontal",
            shape: "pill",
          },
          onInit: (data, actions) => {
            if (this.state.paymentResponse) {
              actions.disable();
            } else {
              actions.enable();
            }
          },
          onClick: (data, actions) => {
            // if (localStorage.getItem("userId")) {
            if (!this.state.isPaymentButtonEnable) {
              // if (this.validateUserInfo()) {
              this.setState({
                isPaymentButtonEnable: true,
              });
              return actions.resolve();
              // }
              // else {
              //   return actions.reject();
              // }
            } else {
              return actions.reject();
            }
            /*}
            else {
              // user is not logged in
              this.props.history.push('/signup');
              return actions.reject();
            }*/
          },
          createOrder: (data, actions) => {
            this.setState({
              isPaymentInitialized: true,
              incompletePaymentMessage: "",
              paymentDescription: "",
            });
            return actions.order.create({
              purchase_units: [
                {
                  // description: this.paypalDescriptionString,
                  description: "This is a dummy text",
                  amount: {
                    currency_code: "USD",
                    value: this.state.totalAmountOfOneTimeItems,
                  },
                },
              ],
            });
          },
          onApprove: async (data, actions) => {
            await this.setState({
              showLoader: true,
            });

            const order = await actions.order.capture();
            let transication_id =
              order.purchase_units[0].payments.captures[0].id;
            await this.setState({
              paymentResponse: order,
              transication_id,
              // isPaymentInitialized: false
            });
            await this.submitOrder();
            let cartData = localStorage.getItem("cartItems")
              ? JSON.parse(localStorage.getItem("cartItems"))
              : null;
            this.setState({
              cartData,
            });
          },
          onCancel: async (data, actions) => {
            await this.setState({
              showLoader: false,
              isPaymentInitialized: false,
              isPaymentButtonEnable: false,
              incompletePaymentMessage: "Please pay to proceed.",
            });
          },
          onError: (err) => {
            this.setState({
              showLoader: false,
              incompletePaymentMessage: "Please pay to proceed.",
            });
          },
        })
        .render(this.paypalRef.current);
    }
    this.paypalRef.current = null;
  };

  submitOrder = async () => {
    // const userData = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) :
    //   JSON.parse(this.props.location.state.userData);
    let userData = !!this.props.location?.state?.userData
      ? JSON.parse(this.props.location.state.userData)
      : JSON.parse(localStorage.getItem("userData"));
    let userInfo = localStorage.getItem("userAddress")
      ? JSON.parse(localStorage.getItem("userAddress"))
      : [];
    let billingInfo = localStorage.getItem("billingData")
      ? JSON.parse(localStorage.getItem("billingData"))
      : [];
    const createOrderRes = await this.props.createOrder({
      id: userData.id ? userData.id : 0,
      firstname: userInfo.firstname,
      lastname: userInfo.lastname,
      email: userInfo.email,
      address: userInfo.address,
      address_2: userInfo.address_2,
      city: userInfo.city,
      state: userInfo.state,
      country: userInfo.country,
      zipCode: userInfo.zipCode,
      phonenumber: userInfo.phonenumber,
      // billingFirstName : billingInfo.billingFirstName,
      // billingLastName : billingInfo.billingLastName,
      // billingEmail: billingInfo.billingEmail,
      // billingAddress: billingInfo.billingAddress,
      // billingAddress_2: billingInfo.billingAddress_2,
      // billingCity: billingInfo.billingCity,
      // billingState: billingInfo.billingState,
      // billingCountry: billingInfo.billingCountry,
      // billingZipCode: billingInfo.billingZipCode,
      // billingPhonenumber: billingInfo.billingPhonenumber,
      paymentType: "paypal",
      cartItems: this.state.oneTimeItems,
      transication_id: this.state.transication_id,
      ...this.state.discountInfo,
    });
    if (!createOrderRes.isError) {
      this.fetchOrderDetail(createOrderRes.orderId);
      this.clearOneTimeItems(this.state.oneTimeItems);
      // this.setState({
      //   cartData: []
      // })
      // window.location.reload();
    } else {
      // something went wrong with the checkout
      await this.setState({
        isPaymentButtonEnable: false,
        errorData: this.props.errorData,
        showLoader: false,
      });
      // this.getCartItems();
    }
  };

  handleCloseCheckoutSummary() {
    const cartItem = localStorage.getItem("cartItems")
      ? JSON.parse(localStorage.getItem("cartItems"))
      : null;
    if (cartItem?.length) {
      if (cartItem[0].paymentOptionType !== "one_time") {
        this.setState({
          cartData: cartItem,
        });
      } else {
        this.props.history.push("/cart");
      }
    } else {
      this.props.history.push("/");
    }
  }

  render() {
    const cartItems =
      this.state.cartData &&
      this.state.cartData.length > 0 &&
      this.state.filterSubscriptionItems.length > 0
        ? this.state.cartData.map((item, index) => {
            if (item.paymentOptionType === "one_time") {
              return null;
            }
            const productTimeFrame = item?.timeFrame
              ? item.timeFrame.split(",")[1]
              : null;
            let discount =
              (item.price *
                item.selectedQuantity *
                this.state.discountInfo.discountPercent) /
              100;

            const selectedPairKeys = item.selectedPair
              ? Object.keys(item.selectedPair)
              : [];
            return (
              <div
                className={
                  "cart-box-body " +
                  (item.subscribed ? "disable-subscribed" : "null")
                }
                key={index}
              >
                <div className="cart-box-detail">
                  <div className="cart-box-img">
                    <img src={item.imageName} />
                  </div>
                  <div className="cart-box-item-detail">
                    <h2>{item.name}</h2>
                    {selectedPairKeys.length > 0
                      ? selectedPairKeys.map((variant, variantIndex) => {
                          return item.selectedPair &&
                            item.selectedPair[variant] ? (
                            <p key={variantIndex}>
                              <span>{variant.replace("_", " ")}: </span>
                              {item.selectedPair[variant]}
                            </p>
                          ) : null;
                        })
                      : null}
                    {/* <p><span>Plant Type: </span>Decorative House Plant</p> */}
                    <p>
                      <span>Frequency: </span>
                      {item.paymentOptionType === "recurring" ? (
                        <>{productTimeFrame}</>
                      ) : (
                        <p>
                          <span>One Time</span>
                        </p>
                      )}
                    </p>
                    {item.subscribed ? (
                      <button className="orange-btn mt-2" disabled>
                        Subscribed
                      </button>
                    ) : (
                      <button
                        disabled={
                          item.disabled || this.state.isDisableSubscribeButton
                        }
                        value={index}
                        onClick={() => this.handleClick(index)}
                        className="mt-2"
                      >
                        Subscribe
                        {(() => {
                          if (!item.approval_url) {
                            return (
                              <span className="spinner-border spinner-border-sm ml-1"></span>
                            );
                          } else if (item.disabled) {
                            return;
                          }
                        })()}
                      </button>
                    )}
                  </div>
                </div>
                <label className="m-0">
                  <strong>Quantity</strong>
                </label>
                <div className="cart-box-item-counter">
                  <p>{item.selectedQuantity}</p>
                </div>
                <div className="cart-box-item-price">
                  <p>
                    $
                    {(
                      item.price * item.selectedQuantity +
                      (item.shippingCharge ? item.shippingCharge : 0) -
                      discount +
                      (item.tax ? item.tax : 0)
                    )
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </p>
                </div>
              </div>
            );
          })
        : null;

    // const oneTimeLowers=

    const itemDetail = this.state.itemDetail;

    const orderDetail =
      this.state.orderDetail && this.state.orderDetail.length > 0
        ? this.state.orderDetail.map((order, index) => {
            let shippingAddress = order.address
              ? JSON.parse(order.address)
              : null;
            // let billingAddress = order.billing_Info
            // ? JSON.parse(order.billing_Info)
            // : null;
            let date = order.createTS
              ? new Date(order.createTS).toISOString().substring(0, 10)
              : null;
            let parsedQuestions = order.customize_questions !== "undefined" && order.customize_questions
              ? JSON.parse(order.customize_questions)
              : null;
            let customizedNote = itemDetail[0].customizationNote
              ? itemDetail[0].customizationNote
              : null;
            return (
              <div key={index} className="purchase-cartGroup">
                <div className="cart-box m-3">
                  <div className="cart-box-detail">
                    <div className="cart-box-img">
                      <img src={itemDetail[index].imageName} alt="" />
                    </div>
                    <div className="cart-box-item-detail">
                      <h2>{itemDetail[index].name}</h2>
                      <p>
                        OrderID:
                        <span>
                          {" "}
                          {order.orderNumber ? `#${order.orderNumber}` : "-"}
                        </span>
                      </p>

                      {customizedNote ? (
                        <div className="cart-box-item-detail">
                          <p>
                            <strong>Customize Note:</strong>
                          </p>
                          <span>{customizedNote}</span>
                        </div>
                      ) : null}

                      {parsedQuestions?.length ? (
                        <div className="cart-box-item-detail">
                          <p>
                            <strong>Customize Questions:</strong>
                          </p>
                          {parsedQuestions.map((item, index) =>
                            index < parsedQuestions?.length - 1 ? (
                              <span>
                                {item}
                                {", "}
                              </span>
                            ) : (
                              <span>{item}</span>
                            )
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="cart-box-item-price">
                    <p>
                      $
                      {order.total
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </p>
                  </div>
                </div>

                <div className="order-detail text-left p-3">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="order-detail-left">
                        <div className="table-responsive">
                          <table className="table">
                            <tr>
                              <td>Recipient:</td>
                              <td>
                                {shippingAddress.firstname
                                  ? `${shippingAddress.firstname} ${shippingAddress.lastname}`
                                  : `-`}
                              </td>
                            </tr>
                            <tr>
                              <td>Shipping Address:</td>
                              <td>
                                {shippingAddress.address
                                  ? `${
                                      shippingAddress.address_2
                                        ? shippingAddress.address_2 + ", "
                                        : ""
                                    } ${shippingAddress.address}, ${
                                      shippingAddress.city
                                    }, ${shippingAddress.state}, ${
                                      shippingAddress.country
                                    } - ${shippingAddress.zipCode}`
                                  : `-`}
                              </td>
                            </tr>
                            {/* <tr>
                            <td>Billing Recipient:</td>
                            <td>
                              {billingAddress.name
                                ? `${billingAddress.name}`
                                : `${shippingAddress.firstname} ${shippingAddress.lastname}`}
                            </td>
                          </tr>
                          <tr>
                            <td>Billing Address:</td>
                            <td>
                              {billingAddress.address
                                ? `${billingAddress.billingAddress_2
                                  ? billingAddress.billingAddress_2 + ", "
                                  : ""
                                } ${billingAddress.address}, ${billingAddress.city
                                }, ${billingAddress.state},
                                 ${billingAddress.zipCode}`
                                :  `${shippingAddress.address_2
                                  ? shippingAddress.address_2 + ", "
                                  : ""
                                } ${shippingAddress.address}, ${shippingAddress.city
                                }, ${shippingAddress.state}, ${shippingAddress.country
                                } - ${shippingAddress.zipCode}`}
                            </td>
                          </tr> */}

                            <tr>
                              <td>Process Date:</td>
                              <td>{date ? date : `-`}</td>
                            </tr>
                            <tr>
                              <td>Order Status:</td>
                              <td>{order.status ? order.status : `-`}</td>
                            </tr>
                            <tr>
                              <td>Payment Status:</td>
                              <td>
                                {order.paymentStatus
                                  ? order.paymentStatus
                                  : `-`}
                              </td>
                            </tr>
                            <tr>
                              <td>Recepits Voucher:</td>
                              <td>-</td>
                            </tr>
                            {order.trackingCompany && (
                              <>
                                {" "}
                                <tr>
                                  <td>Tracking Company:</td>
                                  <td>
                                    {order.trackingCompany
                                      ? order.trackingCompany
                                      : `-`}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Delivery Plan:</td>
                                  <td>
                                    {order.delivery_plan
                                      ? order.delivery_plan.split("_").join(" ")
                                      : `-`}
                                  </td>
                                </tr>
                                {!order.customize_delivery_time == 0 && (
                                  <tr>
                                    <td>Product Creation Time:</td>
                                    <td>
                                      {order.customize_delivery_time
                                        ? `${order.customize_delivery_time} Day`
                                        : `${order.customize_delivery_time} Days`}
                                    </td>
                                  </tr>
                                )}
                              </>
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="order-detail-right">
                        <div className="table-responsive">
                          <table className="table">
                            <tr>
                              <td>Item Price</td>
                              <td>
                                {itemDetail[index]
                                  ? `$${itemDetail[index].pricePerUnit
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td>Quantity</td>
                              <td>
                                {itemDetail[index]
                                  ? `${itemDetail[index].orderedQuantity}`
                                  : "-"}
                              </td>
                            </tr>
                            {order.trackingCompany && (
                              <tr>
                                <td>Shipping</td>
                                <td>
                                  {order.shipping_amount
                                    ? `$${order.shipping_amount
                                        .toFixed(2)
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                    : "Free"}
                                </td>
                              </tr>
                            )}
                            {order.discount && +order.discount > 0 ? (
                              <tr>
                                <td>Discount</td>
                                <td>{`-$${(+order.discount)
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</td>
                              </tr>
                            ) : null}
                            <tr>
                              <td>Subtotal</td>
                              <td>
                                {order.subTotal
                                  ? `$${order.subTotal
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td>Tax</td>
                              <td>
                                {order.tax
                                  ? `$${order.tax
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                  : `$0.00`}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Total</b>
                              </td>
                              <td>
                                <b>
                                  {order.total
                                    ? `$${order.total
                                        .toFixed(2)
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                    : "-"}
                                </b>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        : "...";

    return (
      <div>
        <main className="mainContainer">
          <section className="home-product-section mt-4">
            <div className="container">
              <div className="select-product-list">
                <div className="product-list-head product-list-border-bottom">
                  <ul>
                    <li>
                      <Link to={"/"}>home</Link>
                    </li>
                    <li>
                      <i className="fas fa-minus"></i>
                    </li>
                    <li>
                      <Link to="/cart">cart</Link>
                    </li>
                    <li>
                      <i className="fas fa-minus"></i>
                    </li>
                    <li>
                      <Link to="/checkout">checkout</Link>
                    </li>
                    <li>
                      <i className="fas fa-minus"></i>
                    </li>
                    <li>
                      <a>subscription</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section className="cart-section">
            <div className="container">
              <div className="cart-section-head">
                <h2>Order List</h2>
                <p>
                  You have{" "}
                  {this.state.cartData?.length
                    ? this.state.cartData.length > 1
                      ? `${this.state.cartData.length} items`
                      : `${this.state.cartData.length} item`
                    : "0 item"}{" "}
                  in your list
                </p>
              </div>

              <div className="cart-section-body">{cartItems}</div>
              <div className="cart-section-body">
                {this.state.oneTimeItems.length > 0 && (
                  <>
                    <div className="cart-section-head">
                      <h5> One Time Purchase Items</h5>
                    </div>
                    {this.state.oneTimeItems.map((item, index) => {
                      let discount =
                        (item.price *
                          item.selectedQuantity *
                          this.state.discountInfo.discountPercent) /
                        100;
                      return (
                        <div
                          className={
                            "cart-box-body " +
                            (item.subscribed ? "disable-subscribed" : "null")
                          }
                          key={index}
                        >
                          <div className="cart-box-detail">
                            <div className="cart-box-img">
                              <img src={item.imageName} alt="" />
                            </div>
                            <div className="cart-box-item-detail">
                              <h2>{item.name}</h2>
                              {/* {selectedPairKeys.length > 0
                              ? selectedPairKeys.map((variant, variantIndex) => {
                                return item.selectedPair &&
                                  item.selectedPair[variant] ? (
                                  <p key={variantIndex}>
                                    <span>{variant.replace("_", " ")}: </span>
                                    {item.selectedPair[variant]}
                                  </p>
                                ) : null;
                              })
                              : null} */}
                              {/* <p><span>Plant Type: </span>Decorative House Plant</p> */}
                              <p>
                                <span>Frequency: </span>One Time
                              </p>
                            </div>
                          </div>
                          <label className="m-0">
                            <strong>Quantity</strong>
                          </label>
                          <div className="cart-box-item-counter">
                            <p>{item.selectedQuantity}</p>
                          </div>
                          <div className="cart-box-item-price">
                            <p>
                              $
                              {(
                                item.price * item.selectedQuantity +
                                (item.shippingCharge
                                  ? item.shippingCharge
                                  : 0) +
                                (item.tax ? item.tax : 0) -
                                discount
                              )
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                    {/* {} */}
                    {/* <label className="container-sub">Paypal
                      <input type="radio" value="paypal" onClick={() => setTimeout(() => { this.loadPaypal() }, 700)} checked={this.state.paymentType === 'paypal'} name="radio" />
                      <span className="checkmark"></span>
                    </label> */}
                    {this.loadPaypal()}
                    <div className="text-center paypal-custom-btn">
                      <div ref={this.paypalRef} />
                    </div>
                  </>
                )}
              </div>

              {/* Button trigger modal */}
              <button
                type="button"
                ref={this.orderDetailModalRef}
                style={{ display: "none" }}
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#exampleModalCenter"
              >
                Launch demo modal
              </button>

              <div
                className="modal fade"
                id="exampleModalCenter"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered modal-lg"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLongTitle">
                        Order Summary
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        // onClick={()=> localStorage.removeItem("discount")}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <section className="cart-section">
                        <div className="container">
                          <div className="cart-section-head">
                            <h2>ORDER PLACED</h2>
                          </div>

                          <div className="checkout-body">
                            <div className="row">
                              <div className="col-lg-12 col-md-12">
                                <div className="thankyou-box">
                                  <div className="thankyou-box-detail">
                                    <i className="far fa-check-circle"></i>
                                    <h3>Thank you for your purchase!</h3>
                                    <section className="track-section">
                                      <div className="container">
                                        {orderDetail}
                                      </div>
                                    </section>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        // onClick={()=> localStorage.removeItem("discount")}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartReducer.cartItems,
  };
};

const mapDispatchToProsp = (dispatch) => {
  return bindActionCreators(
    {
      getCartItems,
      paypalSubscriptionCreate,
      paypalSubscriptionUpdate,
      paypalSubscriptionBillCreate,
      paypalSubscriptionExecute,
      createOrder,
      getMultipleOrderDetail,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProsp)(CheckoutSubscription)
);
