import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

function generatePassword() {
  var length = 8,
    charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*",
    retVal = "";
  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

export const getUserDetail = (data) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: 'get',
      url: `${URL}/api/v1/users/${data.id}`,
      data: data,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    dispatch({ type: "LOADING_COMPLETED" });
    return res;

  } catch (error) {
    dispatch(handleError(error));
  }
}

export const setUserInfo = (userInfo) => async (dispatch) => {

  dispatch({
    type: "SETUSERINFO",
    payload: userInfo
  });
}

export const changePassword = (email, oldPassword, newPassword, confirmNewPassword, socialVerificationData) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: 'post',
      url: `${URL}/api/v1/users/change-password`,
      data: { email, oldPassword, newPassword, confirmNewPassword, socialVerificationData},
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    dispatch({ type: "LOADING_COMPLETED" });
    return res;

  } catch (error) {
    dispatch(handleError(error));
  }
}

export const checkOldPassword = (userId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/users/old-password/${userId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    console.log("res from get countries list", res);
    return res.data;
  } catch (error) {
    console.log("error to get countries list", error);
    dispatch(handleError(error));
  }
};


export const forgotPassword = (email) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/users/forgotPassword`,
      data: {
        email
      }
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res;
    // dispatch({ type: "FORGOT_PASSWORD", payload: { data: res.data } });
  } catch (error) {
    dispatch(handleError(error));
  }
}

export const resetPassword = (newPassword, confirmNewPassword, resetKey) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/users/resetPassword`,
      data: {
        newPassword,
        confirmNewPassword,
        resetKey
      }
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res;

  } catch (error) {
    dispatch(handleError(error));
  }
}

export const subscribeNewsLetter = (email) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/users/newsLetterSubscription`,
      data: { email }
    });
    dispatch({ type: "LOADING_COMPLETED" });

    return res;
  }
  catch (error) {
    dispatch(handleError(error));
  }
}

export const unsubscribeNewsLetter = (token) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/users/newsLetterUnsubscription`,
      data: { token }
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res;
  }
  catch (error) {
    dispatch(handleError(error));
  }
}


export const getUserDataFromLocalStorage = () => async (dispatch) => {
  const userData = localStorage.getItem('userData');
}
