import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as blogActions from "../../Redux/Actions/blogAction";
import InfiniteScroll from "react-infinite-scroll-component";

const BlogCategoryFilter = (props) => {
  const dispatch = useDispatch();
  const [blogState, setBlogState] = useState([]);
  const [categoriesState, setCategoriesState] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [loadState, setLoadState] = useState({
    offset: 0,
    prevOffset: 0,
    hasMore: true,
  });

  useEffect(() => {
    loadCategories();
    if (props.match?.params?.category) {
      loadBlogsByCategory(true);
    }
  }, []);

  useEffect(() => {
    if (props.match?.params?.category) {
      setLoadState({
        offset: 0,
        prevOffset: 0,
        hasMore: true,
      });
    }
  }, [props.match?.params?.category]);

  useEffect(() => {
    if (loadState.offset === 0) {
      loadBlogsByCategory(true);
    }
  }, [loadState]);

  const loadCategories = async () => {
    const response = await dispatch(blogActions.categoriesList());
    if (response && !response.isError) {
      setCategoriesState(response.data);
    }
  };

  const loadBlogsByCategory = async (firstLoad) => {
    if (loadState.offset !== loadState.prevOffset || firstLoad) {
      if (!loadState.hasMore) {
        return null;
      }
      const response = await dispatch(
        blogActions.getBlogsByCategory(
          props.match?.params?.category,
          loadState.offset
        )
      );

      if (response && !response.isError) {
        if (response.data.length === 0) {
          return setLoadState((prevState) => ({
            ...prevState,
            hasMore: false,
          }));
        }
        if (firstLoad) {
          setSelectedCategory(response.data.category);
          setBlogState(response.data.blogs);
        } else {
          let blogList = [...blogState];
          setBlogState([...blogList, ...response.data.blogs]);
        }

        setLoadState((prevState) => ({
          ...prevState,
          offset: prevState.offset + 6,
          prevOffset: prevState.offset,
        }));
      }
    }
  };

  const convertTimestampToDate = (timeStamp) => {
    if (!timeStamp) {
      return null;
    }
    const jsDate = new Date(timeStamp);
    const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(jsDate);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(jsDate);
    return da + " " + mo;
  };

  const BlogItems = ({showFirstTwoRowsOnly}) => {
    return blogState.map((blog, index) => {
      if((showFirstTwoRowsOnly && index < 6) || (!showFirstTwoRowsOnly && index > 5)) {
        return (
            <div className={showFirstTwoRowsOnly ? "col-md-6 col-lg-4" : "col-md-6 col-lg-3"} key={index}>
              <div className="blog-item">
                <div className="blog-item-top">
                  <img src={blog.image} />
                </div>
                <div className="blog-item-mid">
                  <h3>
                    <a
                        onClick={() => {
                          props.history.push(`/blog-details/${blog.slug}`);
                        }}
                    >
                      {blog.title}
                    </a>
                  </h3>
                  <p className="line-clamp-para" dangerouslySetInnerHTML={{ __html: blog.content }}>
                  </p>
                </div>
                <div className="blog-item-bottom">
                  <div className="row justify-content-between">
                    <div className="col-6">
                      <div className="blog-read">
                        <a
                            onClick={() => {
                              props.history.push(
                                  `/blog-details/${blog.slug}`
                              );
                            }}
                        >
                          read more
                        </a>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="blog-date text-right">
                        <p>
                          {convertTimestampToDate(blog.blogCreatedDate)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        )
      } else {
        return null
      }

    })
  }

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-lg-3 col-md-4">
          <div className="refine-sidebar">
            <div className="refine-sidebar-heading">
              <h2 className="mb-4">Refine Your Search</h2>
            </div>
            <div className="refine-sidebar-box">
              <div className="refine-sidebar-top">
                <h3>Category</h3>
              </div>
              <div className="refine-sidebar-mid">
                <ul>
                  {categoriesState.map((category, index) => (
                    <li key={index}>
                      <a
                        onClick={() => props.history.push(`/blog-category-filter/${category.slug}`)}
                        className={selectedCategory.id === category.id ? "active" : ''}
                      >
                        {category.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-9 col-md-8">
          <div className="home-section-heading mb-3">
            <ul>
              <li>
                {selectedCategory.name && <h2>{selectedCategory.name}</h2>}
              </li>
            </ul>
          </div>
          <div className="row">
            <BlogItems showFirstTwoRowsOnly={true}/>
          </div>
        </div>
      </div>
      <InfiniteScroll
        dataLength={blogState.length}
        next={() => loadBlogsByCategory(false)}
        hasMore={loadState.hasMore}
      >
        <div className="row">
          <BlogItems showFirstTwoRowsOnly={false}/>
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default BlogCategoryFilter;
