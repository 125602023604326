import React, { useEffect, useState } from 'react'
import * as productAction from "../../Redux/Actions/productAction";
import* as userAction from "../../Redux/Actions/storeAction";

import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import profileAvtar from "../../assets/images/profile-img.png";
import { debounce } from "lodash";

import BannerCategoryImgFour from '../../assets/images-2/banner-category-img-four.jpg';



const ShopByVendor = (props) => {



  const StyledRating = withStyles({
    iconFilled: {
      color: "#ff6d75",
    },
    iconHover: {
      color: "#ff3d47",
    },
  })(Rating);


  const dispatch = useDispatch()
  const [vendorData, setVendorData] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [searchText, setSearchText] = useState();
  const [timeOutState, setTimeOutState] = useState(null);

  useEffect(() => {
    fetchVendorList();
  }, [])

 
  const handleSearch = async (searchText) => {
    if(searchText.length===0){
      setSearchList([]);
      setSearchText("")
      return null
    }
    setSearchText(searchText);

    if (timeOutState) {
      clearInterval(timeOutState);
    }
    let result = setTimeout(() => {
      handleInputChange(searchText)
    }, 200);
    setTimeOutState(result);

    // if(searchText && searchText.length===0){
    //   setSearchList([]);
    //   return null
    // }
  };


const handleInputChange = async (searchText) => {
  if(searchText && searchText.length > 0) {
    const res=await dispatch(userAction.getSellerByName(searchText.trim()));
    if(res && res?.data && res.data.length >0){
      setSearchList(res.data)
    }
  }
  
}
  

  const fetchVendorList = async (category, firstLoad) => {
    const response = await dispatch(
      productAction.getVendorListbyProductCategoryId(0, 4, "")
    );
    if (response && response?.data?.data?.length > 0) {
      setVendorData(response?.data?.data)
    }
  }
 
  const vendorList =
    vendorData.length > 0 ? (
      vendorData.map((vendor, index) => {
        return (
          <div key={index} className="container">
            {vendor.vendorList.length > 0 ? (
              <>
                <div className="row">

                  <div
                    className="col-lg-12 col-md-12"
                  >
                    <div className="home-section-heading mb-3">
                      <ul>
                        <li>
                          <h2>{vendor.category === 3 ? "Access" : vendor.category === 101 ? "Replenish" : "Customize"}</h2>
                        </li>
                        <li>|</li>
                        <li>
                          <Link
                            to={{
                              pathname: "/shop-by-vendor-list/list",
                              state: { categoryId: vendor.category },
                            }}

                          >
                            See All
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="row padding-manage-show">
                      {vendor.vendorList.map((item, itemIndex) => {
                        {/* if ((index == 0 && itemIndex < 6) || index > 0) */}
                          return (
                            <div
                              className="col-sm-6 col-md-6 col-lg-3 col-6"
                              key={itemIndex}
                            >
                              <div className="vendor-card-box">


                                <Link
                                  to={`/store/${item.storeId}/${item.id}`}

                                >
                                  <div className="home-product-box">
                                    <img src={item.imagename ? item.imagename : profileAvtar} />
                                  </div>
                                  <div className="home-productbox-detail">
                                    <div className="home-productbox-heading">
                                      <div className="price-inline-remove">
                                        <h5
                                          title={item.storename ? item.storename : null}
                                        >
                                          {item?.storename}
                                        </h5>

                                      </div>
                                    </div>
                                    <div className="home-productbox-bottom">
                                      <div className="item-rating">
                                        <Rating
                                          name="hover-feedback"
                                          value={item.rating}
                                          precision={0.5}
                                          readOnly
                                        />
                                      </div>
                                      <p className='m-0'>{item?.store_overview}</p>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          );
                      })}
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        );
      })
    ) : (
      null
    );
  return (
    <div className='container mt-3'>
      <div className="banner-category-part mb-0">
  <img src={BannerCategoryImgFour} alt="banner" />
  <div>
    <h1>Shop by vendor</h1>
    <p>Discover and Explore Full Vendor Store Offerings</p>
  </div>
</div>

      <div className='search-input-vendor mb-3'>
        <div className='search-icon-align'>
      <input
        className="form-control"
        value={searchText}
        onChange={(e) => handleSearch(e.target.value)}
        // onClick={getSearchResult}
        type="text"
        placeholder="SEARCH BY VENDOR"
      />

      <i class="fas fa-search"></i>

      {searchList.length > 0 && <ul>
      {
      searchList.map((item,index) =>{
        return (
            <li>
            <div
        // onClick={() => onclickAction(item)}
        className="search-filter-item"
        key={index}
      >
        <Link
       to={`/store/${item.id}/${item.userId}`}
        >
          <div className="search-filter-item-img">
            <img src={item.imagename ? item.imagename:profileAvtar}/>
          </div>
          <div className="search-filter-item-detail">
            <p>{item?.storename}</p>
          </div>
        </Link>
      </div>
      </li>

        )
      })
      }
                </ul>}
      </div>

      </div>
     
      <div>
        {vendorList}
      </div>
    </div>

  )
}

export default ShopByVendor;



