import React, { Component } from "react";
import "../../Styles/checkout.scss";
import Logo from "../../assets/images-2/logo.png";
import card from "../../assets/images/atm.png";
import paypal from "../../assets/images/paypal.png";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import StripeCheckOutForm from "./StripeCheckoutForm";
import { debounce } from "lodash";
import moment from "moment";

import { setUserInfo } from "../../Redux/Actions/userAction";
import Select from "react-select";

import {
  validateAppliedCoupon,
  getProductsCurrentStatus,
  checkoutItem,
  removeItemFromCart,
  validateUserCheckoutInfo,
  paypalSubscriptionUrl,
  getCountriesList,
  getStatesList,
  getCitiesList,
  getSavedCardsList,
  deleteSavedCard,
  getSavedCardsFromStripe,
  getDobaDeliveryOption,
} from "../../Redux/Actions/cartAction";
import { getUserDetail } from "../../Redux/Actions/authAction";
import {
  getPlans,
  getUSPSPlanPrice,
  getUpsPlanPrice,
} from "../../Redux/Actions/checkoutAction";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { getTax } from "../../Redux/Actions/taxAction";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import noImage from "../../assets/images/image_not_available.jpg";

import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Modal } from "react-bootstrap";
import imageCompression from "browser-image-compression";
import { weekDayValue } from "../../Constants/constant";
// import moment from "moment";

const URL = process.env.REACT_APP_API_URL;

// Make sure to call `loadStripe` outside of a component’s render to avoid
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
if (!process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) {
}

let classInstance = null;

window.acceptUIResponseHandler = (response) => {
  classInstance.authorizeCardResponse(response);
  if (response.messages.resultCode === "Error") {
    var i = 0;
    while (i < response.messages.message.length) {
      i = i + 1;
    }
  }
};

class Checkout extends Component {
  userData = localStorage.getItem("userData");

  shippingApiRelatedData = [
    "firstname",
    "lastname",
    "phonenumber",
    "email",
    "address",
    "address_2",
    "city",
    "state",
    "country",
    "zipCode",
  ];
  apiCallInterval;

  fieldsList = {
    isPaymentButtonEnable: false,
    paymentType: "",
    isCardDropdownEnable: false,
    savedCardList: [],
    selectedCardId: null,
    authorizeErrorMsg: "",
    paymentTypeSelectErrorMsg: "",
    paymentResponse: null,
    incompletePaymentMessage: "",
    isPaymentInitialized: false,
    paymentDescription: "",
    cartSubtotal: 0,
    paypalDescriptionString: "",
    processedProductList: [],
    cartItems: [],
    isErrorItem: false,
    billingAddress: "",
    billingAddress_2: "",
    address: "",
    address_2: "",
    addressId: null,
    city: "",
    state: "",
    country: "",
    billingCountry: "",
    citiesList: [],
    billingCitiesList: [],
    defaultSelectedCity: [],
    billingDefaultSelectedCity: [],
    statesList: [],
    billingStatesList: [],
    defaultSelectedState: [],
    billingDefaultSelectedState: [],
    countriesList: [],
    billingCountriesList: [],
    createts: "",
    email: "",
    billingEmail: "",
    firstname: "",
    billingFirstName: "",
    id: 0,
    imagename: "",
    isacceptedtnc: 0,
    isactive: 0,
    lastname: "",
    billingLastName: "",
    phonenumber: "",
    billingPhonenumber: "",
    role: "",
    type: "",
    updatedTS: "",
    updatedts: "",
    userId: 0,
    zipCode: "",
    billingZipCode: "",
    newBillingInformation: "",
    selectedImg: null,
    authData: {
      name: "",
      image: "",
    },
    errorData: {},
    showLoader: false,
    showCouponBtnLoader: false,
    discountCode: "",
    discountId: null,
    couponErrorResMessage: "",
    couponSuccessResMessage: "",
    discountPercent: 0,
    totalDiscount: 0,
    servicesList: ["Fedex", "USPS", "UPS"],
    selectedService: "",
    servicePlans: [],
    selectedPlan: "",
    showCourierLoaderByItemIndex: null,
    stripeResponse: {},
    handleClickKey: false,
    handleRestForm: false,
    chkbox: false,
    editCustomizationNote: [],
    tempCustomizationNote: [],
    showModal: false,
    itemIndex: 0,
    isChecked: "",
    taxAmount: null,
    productUnavailableErrorMessage:"",
    groupTimeSlotsModal: false,
    toggleShowMobile:false,
    newCardDetails: {}
  };

  shipping = 0;

  constructor(props) {
    super(props);
    this.state = this.fieldsList;

    classInstance = this;

    this.cartType = props.match.path.includes("paynow")
      ? "buyNowItems"
      : "cartItems";

    this.isGuestUser = !localStorage.getItem("userId");

    this.paypalRef = React.createRef();

    this.authorizePopref = React.createRef();
    this.authorizeInputRadioRef = React.createRef();
    this.paypalInputRadioRef = React.createRef();
    this.confirmPopupRef = React.createRef();
    this.fileUpload = React.createRef();
  }

  async componentDidMount() {
    localStorage.removeItem("discount");
    localStorage.removeItem("tempAddress");
    await this.fetchCountriesList();
    this.getUserProfileData();
    this.clearShippingInfo();
    this.loadScript("https://jstest.authorize.net/v3/AcceptUI.js");
    this.setCustomizedNoteState();
    await this.getCartItems();
    this.getItemsCurrentStatus();
    this.fetchSavedCardsList();
    this.isGuestUser = !localStorage.getItem("userData");
    if (this.isGuestUser) {
      this.handleAddNewCard();
    }
    // this.loadStripe();
    // this.loadPaypal();
  }

  setCustomizedNoteState() {
    let cartItems = JSON.parse(localStorage.getItem(this.cartType))
      ? [...JSON.parse(localStorage.getItem(this.cartType))]
      : [];
    let editCustomizationNote = cartItems.map((i) => false);
    let tempCustomizationNote = cartItems.map((i) => "");
    this.setState((prevState) => ({
      ...prevState,
      editCustomizationNote,
      tempCustomizationNote,
    }));
  }

  async getUserProfileData() {
    if (this.userData) {
      const profileData = await this.props.getUserDetail(
        JSON.parse(this.userData)
      );
      this.isGuestUser = !localStorage.getItem("userData");
      const countryData = this.state.countriesList[0]?.props.value?JSON.parse(this.state.countriesList[0].props.value) : null;
       let statesList = await this.props.getStatesList(countryData.countryId);
       if (statesList?.data?.data?.length > 0) {
         statesList = statesList.data.data.map((state) => ({
           value: `${JSON.stringify({
             stateId: state.id,
             stateName: state.state_name,
            })}`,
            label: `${state.state_name}`,
          })
          );
          let countrySelectedState = statesList.filter((item)=>
          item.label?.toLowerCase() == profileData?.data?.data.state?.toLowerCase()
          )
          // if (key == "country")
          {
            this.clearShippingInfo();
            this.setState((prevState) => ({
              ...prevState,
              // country: countryData.countryName,
              statesList: statesList,
              defaultSelectedState: countrySelectedState,
              citiesList: [],
              defaultSelectedCity: [],
            }));
          }  
          this.setState({
            countrySelectedState: countrySelectedState
          })
        }

        let stateValue = this.state?.countrySelectedState && this.state?.countrySelectedState[0]?.value ? JSON.parse(this.state.countrySelectedState[0].value) : null
        let citiesList = stateValue && stateValue?.stateId ? await this.props.getCitiesList(stateValue?.stateId): null;
        if (citiesList?.data?.data?.length > 0){
          citiesList = citiesList.data.data.map((city) => ({
            value: `${JSON.stringify({ cityId: city.id, cityName: city.city })}`,
            label: `${city.city}`,
          }));
        } 
        let countrySelectedCity = citiesList ? citiesList?.filter((item)=>
          item.label?.toLowerCase() == profileData?.data?.data.city?.toLowerCase()
          ): []
        // if (key == "state")
         {
          this.setState((prevState) => ({
            ...prevState,
            citiesList: citiesList,
            defaultSelectedCity: countrySelectedCity,
          }),
          () => {
            setTimeout(() => {
              if (this.validateUserInfo(false)) {
                this.state.cartItems.map((item, index) =>
                  this.handleServiceChange(index)
                );
              }
            }, 200);
          }
          );
        }
      if (profileData?.data?.data) {
        this.setState({
          ...profileData.data.data,
          zipCode: profileData?.data?.data?.zipCode?.length
            ? profileData?.data?.data?.zipCode
            : "",
        });
      }
    }
  }

  getCartItems() {
    let cartItems = JSON.parse(localStorage.getItem(this.cartType))
      ? [...JSON.parse(localStorage.getItem(this.cartType))]
      : [];
    this.createProductList(cartItems);
    if (
      !localStorage.getItem(this.cartType) ||
      JSON.parse(localStorage.getItem(this.cartType)).length === 0
    ) {
      this.props.history.push("/");
    }
  }

  async getItemsCurrentStatus() {
  const a =  await this.props.getProductsCurrentStatus(
      this.state.cartItems,
      this.cartType,
      // customerDetails,
    );

    // await this.setState({
    //   newCustomerId : a.data?.stripeCustomer
    // })
    await this.getCartItems();

    // api call after every specified time
    /*  this.apiCallInterval = setInterval(async () => {
            await this.props.getProductsCurrentStatus(this.state.cartItems);
            await this.getCartItems();
        }, 1000 * 5 * 60);*/
  }

  async fetchCountriesList() {
    let countries = await this.props.getCountriesList();
    let billingCountries = await this.props.getCountriesList();
    if (countries?.data?.data?.length > 0) {
      countries = countries.data.data.map((country) => (
        <option
          key={country.id}
          value={JSON.stringify({
            countryId: country.id,
            countryName: country.name,
          })}
        >
          {country.name}
        </option>
      ));
      this.setState({
        countriesList: countries,
        statesList: [],
        citiesList: [],
      });
    }
    if (billingCountries?.data?.data?.length > 0) {
      billingCountries = billingCountries.data.data.map(
        (country) => (
          (
            <option
              key={country.id}
              value={JSON.stringify({
                countryId: country.id,
                countryName: country.name,
              })}
            >
              {country.name}
            </option>
          )
        )
      );
      this.setState({
        billingCountriesList: billingCountries,
        billingStatesList: [],
        billingCitiesList: [],
      });
    }
  }

  async fetchSavedCardsList() {
    if (this.userData) {
      const stripeCustomerId = JSON.parse(this.userData).id;
      const res = await this.props.getSavedCardsList(stripeCustomerId);
      if (res && res.data && !res.data.isError) {
        this.setState({
          savedCardList: res.data.data,
        });
      }
    }
  }

  deleteCard = async (cardId, index) => {
    const userId = JSON.parse(this.userData).id;
    const deleteRes = await this.props.deleteSavedCard(userId, cardId);
    if (deleteRes && deleteRes.data && deleteRes.data.isSuccess) {
      let savedCardList = this.state.savedCardList;
      savedCardList.splice(index, 1);
      this.setState({
        savedCardList,
        selectedCardId: null,
        isCardDropdownEnable: true,
      });
    }
  };


 changeNumberFormate = (data) => {
    if (data && data?.length) {
      return `(${data?.substring(0, 3)}) ${data?.substring(
        3,
        6
      )}-${data?.substring(6, data.length)}`;
    }
    return null;
  };

  updateSubscription(cardId, index) {
    confirmAlert({
      title: "",
      message: "Are you sure to remove this card?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.deleteCard(cardId, index),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  loadScript = (src) => {
    var tag = document.createElement("script");
    tag.src = src;
    tag.id = "custom-response-script";
    tag.type = "text/javascript";
    document.body.appendChild(tag);
  };

  authorizeCardResponse = (response) => {
    let responseMessage = response.messages;
    if (responseMessage?.resultCode === "Error") {
      this.setState({
        incompletePaymentMessage: "",
        authorizeErrorMsg: responseMessage.message[0].text,
      });
    } else {
      this.setState({
        authorizeErrorMsg: "",
        paymentResponse: response,
        incompletePaymentMessage: "",
        selectedCardId: null,
      });
    }
  };

  createProductList = (items) => {
    let cartItems = items;
    let cartSubtotal = 0;
    let paypalDescriptionString = "This order consist of ";
    let isErrorItem = false;

    const productList =
      cartItems &&
      cartItems.length > 0 &&
      cartItems.map((item) => {
        if (item.isError) {
          isErrorItem = true;
        } else {
          cartSubtotal = cartSubtotal + item.price * item.selectedQuantity;
          paypalDescriptionString = paypalDescriptionString + item.name + ",";
        }
      });

    cartItems = cartItems.map((i) => {
      if (
        this.isGuestUser &&
        (i.isAccessCategoryAvailable || i.paymentOptionType === "recurring")
      ) {
        i["errorMsg"] = "Login required for this product!";
      }
      if (i.errorMsg) {
        this.setState({
          productUnavailableErrorMessage: i.errorMsg,
        });
      }
      return i;
    });

    // set state here
    this.setState((prevState) => ({
      ...prevState,
      isErrorItem: isErrorItem,
      cartItems,
      cartSubtotal,
      paypalDescriptionString,
      processedProductList: productList,
    }));
  };

  showBillingForm = (e) => {
    this.setState({
      isChecked: e.target.checked,
    });
  };
  handleChange = (e, key, index) => {
    this.setState({
      taxAmount: null,
    });
    if (key == "address") {
      localStorage.setItem("tempAddress", e.target.value);
    }
    if (
      this.state.errorData &&
      this.state.errorData[key] &&
      this.state.errorData[key][0]
    ) {
      this.state.errorData[key][0] = null;
    }
    if (key === "paymentType") {
      this.state.incompletePaymentMessage = "";
      this.state.authorizeErrorMsg = "";
      this.state.isPaymentButtonEnable = false;
      if (e.target.value === "authorize")
        this.loadScript("https://jstest.authorize.net/v3/AcceptUI.js");
    }
    let value = (key === "phonenumber") ? e : e?.target?.value;

    if (key === "discountCode") {
      value = value.toUpperCase();
    }

    if (
      key === "phonenumber" ||
      key === "zipCode" ||
      key === "billingPhonenumber" ||
      key === "billingZipCode"
    ) {
      // value = value.replace(/[^a-zA-Z ]/g, "");
      value = value?.replace(/\D+/g, "");
      if (key === "zipCode") {
        this.clearShippingInfo();
      }
    }
    if (index !== undefined) {
      let tempCustomizationNote = [...this.state.tempCustomizationNote];
      tempCustomizationNote[index] = value;
      value = tempCustomizationNote;
    }

    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));

    setTimeout(() => {
      if (
        this.shippingApiRelatedData.includes(key) &&
        this.validateUserInfo(false)
      ) {
        this.state.cartItems.map((item, index) =>
          this.handleServiceChange(index)
        );
      }
    }, 200);
  };

  validateUserInfo = (setErrorMessage = true) => {
    let isFormValid = true;
    let errorData = {
      firstname: [],
      lastname: [],
      email: [],
      phonenumber: [],
      address: [],
      zipCode: [],
      city: [],
      state: [],
      country: [],
    };

    if (this.state.newCardDetails && Object.keys(this.state.newCardDetails).length && 
      (!this.state.newCardDetails?.name 
        || !this.state.newCardDetails?.email || this.state.newCardDetails?.email.search("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")
        || !this.state.newCardDetails?.phone || !this.state.newCardDetails?.address?.postal_code)){
      isFormValid = false;
    }

    if (this.state.firstname.trim().length === 0) {
      isFormValid = false;
      errorData.firstname = ["Firstname field cannot be empty."];
    } else if (this.state.firstname.search("^[a-zA-Z]+$")) {
      isFormValid = false;
      errorData.firstname = ["First Name only contain Characters"];
    }

    if (this.state.lastname.trim().length === 0) {
      isFormValid = false;
      errorData.lastname = ["Lastname field cannot be empty."];
    } else if (this.state.lastname.search("^[a-zA-Z]+$")) {
      isFormValid = false;
      errorData.lastname = ["Last Name only contain Characters"];
    }

    if (this.state.email.trim().length === 0) {
      isFormValid = false;
      errorData.email = ["Email field cannot be empty."];
    } else if (this.state.email.search("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")) {
      isFormValid = false;
      errorData.email = ["Enter correct Email Address"];
    }

    // if (!this.state.phonenumber || this.state.phonenumber.trim().length === 0) {
    //   isFormValid = false;
    //   errorData.phonenumber = ["Phone number field cannot be empty."];
    // }
    if (
      this.state.phonenumber?.length &&
      (this.state.phonenumber.length < 10 || this.state.phonenumber.length > 13)
    ) {
      isFormValid = false;
      errorData.phonenumber = ["Phone number should be 10 to 15 digits."];
    }

    if (!this.state.address || this.state.address.trim().length === 0) {
      isFormValid = false;
      errorData.address = ["Address field cannot be empty."];
    }

    if (!this.state.zipCode || this.state.zipCode.trim().length === 0) {
      isFormValid = false;
      errorData.zipCode = ["Zip code field cannot be empty."];
    } else if (
      !(this.state.zipCode.length > 4 && this.state.zipCode.length < 7)
    ) {
      isFormValid = false;
      errorData.zipCode = ["please Enter Correct 5 or 6 digit code."];
    }

    if (!this.state.city || this.state.city.trim().length === 0) {
      isFormValid = false;
      errorData.city = ["City field cannot be empty."];
    }

    if (!this.state.state || this.state.state.trim().length === 0) {
      isFormValid = false;
      errorData.state = ["State field cannot be empty."];
    }

    if (!this.state.country || this.state.country.trim().length === 0) {
      isFormValid = false;
      errorData.country = ["Country field cannot be empty."];
    }

    if (setErrorMessage) {
      this.setState({
        errorData,
      });
    }

    return isFormValid;
  };

  updateUserInfoLocalStorage = () => {
    let userInfo = JSON.parse(this.userData);
    userInfo = {
      ...userInfo,
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      email: this.state.email,
      phonenumber: this.state.phonenumber,
      address: this.state.address,
      address_2: this.state.address_2,
      zipCode: this.state.zipCode,
      city: this.state.city,
      state: this.state.state,
      country: this.state.country,
    };

    localStorage.setItem("userData", JSON.stringify(userInfo));
  };

  handleSubmit = async (e) => {
    // this.updateUserInfoLocalStorage();
    let userDetails = {
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      email: this.state.email,
      phonenumber: this.state.phonenumber,
      address: this.state.address,
      address_2: this.state.address_2,
      zipCode: this.state.zipCode,
      city: this.state.city,
      state: this.state.state,
      country: this.state.country,
    };
    const selectedCardDetails = this.state.savedCardList.filter(
      (item) => item.id == this.state.selectedCardId
    );

    const parsedBillingInfo =selectedCardDetails[0]?.billing_details ? JSON.parse(selectedCardDetails[0]?.billing_details) : null

   const tempBillingObj ={
    name : parsedBillingInfo?.name,
    phone: parsedBillingInfo?.phone,
    email: parsedBillingInfo?.email,
    address:{
      line1:parsedBillingInfo?.address,
      city : parsedBillingInfo?.city,
      state: parsedBillingInfo?.state,
      zipCode:parsedBillingInfo?.zipCode
    },   
   } 

    if (selectedCardDetails[0] && selectedCardDetails[0]?.billing_details) {
      this.setState({
        newBillingInformation: {
          billing_details: {
            ...tempBillingObj,
          },
        },
      });
    }
    let isErrorFound = false;
    this.state.cartItems.map((i) => {
      if (i.errorMsg && i.errorMsg.length > 0) {
        isErrorFound = true;
      }
    });
    if (isErrorFound) {
      return null;
    }
    if (this.state.paymentType == "stripe" && !this.state.selectedCardId) {
      await this.setState({ handleClickKey: true, handleRestForm: false });
    }
    // if (localStorage.getItem("userId")) {
    if (this.state.paymentType == "paypal") {
      localStorage.setItem("userAddress", JSON.stringify(userDetails));
      // localStorage.setItem("billingData", JSON.stringify(billingInfo));
      this.orderPaymentProcess();
    } else if (
      this.state.paymentType == "stripe" &&
      (this.state.paymentResponse || this.state.selectedCardId)
    ) {
      this.orderPaymentProcess();
    } else if (this.state.paymentType == "") {
      this.setState({
        showLoader: false,
        incompletePaymentMessage: "Please select a payment method",
      });
    }
    if (!this.validateUserInfo()) {
      return;
    }
    // } else {
    //   // user is not logged in
    //   this.props.history.push("/login");
    //
  };

  orderPaymentProcess = async () => {
    if (this.validateUserInfo()) {
      if (!this.checkCartShippingValidations()) {
        if (!this.state.isErrorItem) {
          if (this.state.paymentType === "stripe") {
            if (this.state.paymentResponse || this.state.selectedCardId) {
              this.setState({
                showLoader: false,
              });

              // await this.submitOrder();
              this.confirmPopupRef.current.click();
            } else {
              this.setState({
                showLoader: false,
                incompletePaymentMessage: "Please pay to proceed.",
              });
            }
          } else if (this.state.paymentType === "paypal") {
            const afterValidationRes =
              await this.props.validateUserCheckoutInfo({ ...this.state });

            if (afterValidationRes?.data === "ok") {
              this.confirmPopupRef.current.click();
            } else {
              await this.setState({
                isPaymentButtonEnable: false,
                errorData: this.props.errorData,
                showLoader: false,
              });
            }
          } else {
            // show message to select a payment type
            this.setState({
              showLoader: false,
              incompletePaymentMessage: "Please select a payment method",
            });
          }
        } else {
          this.setState({
            showLoader: false,
            incompletePaymentMessage:
              this.state.productUnavailableErrorMessage !== ""
                ? this.state.productUnavailableErrorMessage
                : "Please remove the error item to proceed.",
          });
        }
      } else {
        // cart items shipping validations failed
        await this.setState({
          showLoader: false,
        });
      }
    } else {
      // input validations failed
      await this.setState({
        showLoader: false,
      });
    }
  };

  submitOrder = async () => {
    clearInterval(this.apiCallInterval);
    this.setState({
      showLoader: true,
    });
    if (this.state.paymentType === "stripe") {
      const newCardDetails = {
        name: this.state?.newBillingInformation?.billing_details?.name,
        address:
          this.state?.newBillingInformation?.billing_details?.address?.line1,
        city: this.state?.newBillingInformation?.billing_details?.address?.city,
        state:
          this.state?.newBillingInformation?.billing_details?.address?.state,
        zipCode:
          this.state?.newBillingInformation?.billing_details?.address
            ?.postal_code,
      };
      localStorage.setItem(
        "new_billing_address",
        JSON.stringify(newCardDetails)
      );
       await this.props.checkoutItem({ ...this.state });
      if (this.props.checkoutRes && this.props.checkoutRes.isError === false) {
        await this.props.getUserDetail(
          JSON.parse(this.userData)
        );
        this.props.history.push({
          pathname: `/order/${this.props.checkoutRes.orderId}/summary`,
          state: { selectedCardId: this.state.selectedCardId },
        });
      } else {
        this.setState({
          incompletePaymentMessage: this.props.checkoutRes?.paymentMessage?.raw?.message
        })
        // this.cardDeclined()
        await this.getItemsCurrentStatus();
        // something went wrong with the checkout
        await this.setState({
          isPaymentButtonEnable: false,
          errorData: this.props.errorData,
          showLoader: false,
        });
        this.getCartItems();
      }
    } else if (this.state.paymentType === "paypal") {
      localStorage.removeItem("new_billing_address");
      this.props.history.push({
        pathname: "/checkout-subscription",
        state: {
          detail: this.state.cartItems,
          discountCode: this.state.discountCode,
          discountId: this.state.discountId,
          totalDiscount: this.state.totalDiscount,
          discountPercent: this.state.discountPercent,
          history: "/checkout",
          userData: JSON.stringify({
            id: this.isGuestUser ? 0 : JSON.parse(this.userData).id,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            email: this.state.email,
            address: this.state.address,
            address_2: this.state.address_2,
            city: this.state.city,
            state: this.state.state,
            country: this.state.country,
            zipCode: this.state.zipCode,
            phonenumber: this.state.phonenumber,
          }),
        },
      });
    }
  };

  onNotConfirmChanges = () => {
    this.setState({
      showLoader: false,
    });
  };

  handleItemRemove = async (item, itemIndex) => {
    await this.props.removeItemFromCart(item, itemIndex, this.cartType);
    this.getCartItems();
    // this.validateCoupon();
  };

  handleCalculateTax = debounce((tax) => {}, 100);

  validateCoupon = async () => {
    let couponCode = this.state.discountCode?.trim();
    if (couponCode == "") {
      this.setState({
        couponErrorResMessage: "Please Enter Coupon.",
        showCouponBtnLoader: false,
      });
      return;
    }
    // if (localStorage.getItem("userId")) {
    this.setState({
      showCouponBtnLoader: true,
      couponErrorResMessage: "",
      couponSuccessResMessage: "",
      discountId: null,
      discountPercent: 0,
      totalDiscount: 0,
    });

    if (couponCode.length > 0) {
      if (this.state.isErrorItem) {
        this.setState({
          couponErrorResMessage: "Please remove the error item to apply.",
          showCouponBtnLoader: false,
        });
        return;
      }

      const res = await this.props.validateAppliedCoupon(
        this.state.cartSubtotal,
        couponCode.toUpperCase()
      );
      if (res && res.data) {
        if (res.data.isValid && res.data.isApplied) {
          // coupon applied

          // let discount = (this.state.cartSubtotal * res.data.discountPercent) / 100;

          this.setState({
            showCouponBtnLoader: false,
            discountId: res.data.discountCodeId,
            couponSuccessResMessage: res.data.message,
            discountPercent: +res.data.discountPercent,
            totalDiscount: +res.data.discountAmount,
          });
        } else {
          // coupon not applied or coupon does not exist
          this.setState({
            showCouponBtnLoader: false,
            couponErrorResMessage: res.data.message,
          });
        }
      } else {
        // something went wrong
        this.setState({
          showCouponBtnLoader: false,
          couponErrorResMessage: "something went wrong",
        });
      }
    } else {
      this.setState({
        showCouponBtnLoader: false,
        couponErrorResMessage: "",
        couponSuccessResMessage: "",
      });
    }
    // } else {
    //   // user is not logged in
    //   this.props.history.push("/signup");
    // }
  };

  openAuthorizePaymentPop = (paymentType) => {
    if (paymentType === "paypal") {
      this.setState({
        paymentType: paymentType,
        paymentResponse: null,
        selectedCardId: null,
        isCardDropdownEnable: false,
        incompletePaymentMessage: "",
      });
      return;
    } else {
      this.setState((prevState) => ({
        isCardDropdownEnable: !prevState.isCardDropdownEnable,
        paymentType: "stripe",
        incompletePaymentMessage: "",
      }));
      return;
    }
  };

  handleServiceChange = async (selectedItemIndex) => {
    let cartData = this.state.cartItems;
    if (
      cartData[selectedItemIndex].spu_id &&
      cartData[selectedItemIndex].spu_id.length !== 0
    ) {
      let plan = await this.props.getDobaDeliveryOption({
        address: {
          firstName: this.state.firstname,
          telephone: this.state.phonenumber,
          addressLine1: this.state.address,
          addressLine2: this.state.address_2,
          city: this.state.city,
          postCode: this.state.zipCode,
          state: this.state.state,
          countryId: this.state?.countriesList[0]?.props.value
            ? JSON.parse(this.state?.countriesList[0]?.props.value).countryId
            : null,
        },
        item: cartData[selectedItemIndex],
      });
      plan = plan.data;
      cartData[selectedItemIndex]["planList"] = plan.map((p) => p.shipName);
      cartData[selectedItemIndex]["planPriceList"] = plan.map((p) => p.shipFee);
      cartData[selectedItemIndex]["shippingMethodIdList"] = plan.map(
        (p) => p.shippingMethodId
      );
      await this.setState({
        cartItems: cartData,
        showCourierLoaderByItemIndex: null,
      });
      return null;
    }
    /*let dobaItems = cartData.filter((item)=>(item.spu_id && item.spu_id.length !==0 && item.spu_id !== "null"))
        cartData = cartData.filter((item)=> !dobaItems.map((i)=>i.id.toString()).includes(item.id.toString()))*/

    const courierService = JSON.parse(
      cartData[selectedItemIndex]["couriers_option"]
    )[0];
    if (!courierService) {
      return;
    }
    this.setState({
      showCourierLoaderByItemIndex: selectedItemIndex,
    });

    let plans;

    if (courierService === "Fedex" || courierService === "UPS") {
      plans = await this.props.getPlans({
        trackingCompany: courierService,
        address: {
          firstName: this.state.firstname,
          telephone: this.state.phonenumber,
          addressLine1: this.state.address,
          addressLine2: this.state.address_2,
          city: this.state.city,
          postCode: this.state.zipCode,
          state: this.state.state,
          countryId: this.state?.countriesList[0]?.props.value
            ? JSON.parse(this.state?.countriesList[0]?.props.value).countryId
            : null,
        },
        productInfo: {
          items: {
            quantityPerSet: cartData[selectedItemIndex].selectedQuantity,
          },
          productId: cartData[selectedItemIndex].id,
        },
      });

      if (courierService === "Fedex") {
        if (plans && plans.data) {
          if (!plans.data.isFound || plans.data.isError) {
            cartData[selectedItemIndex]["deliveryPlanErrorMsg"] =
              "Address is not deliverable.";
            cartData[selectedItemIndex]["planList"] = [];
            cartData[selectedItemIndex]["planPriceList"] = [];
          } else {
            delete cartData[selectedItemIndex]["deliveryPlanErrorMsg"];
            cartData[selectedItemIndex]["planList"] = plans.data.data.type;
            cartData[selectedItemIndex]["planPriceList"] =
              plans.data.data.prices;
            cartData[selectedItemIndex]["selectedService"] = courierService;
          }
        } else {
          cartData[selectedItemIndex]["deliveryPlanErrorMsg"] =
            "Fedex is out of service.";
          cartData[selectedItemIndex]["planList"] = [];
          cartData[selectedItemIndex]["planPriceList"] = [];
        }

      } else {
        let planList = [];
        let planPriceList = [];
        if (
          plans?.data?.data?.TimeInTransitResponse?.TransitResponse
            ?.ServiceSummary?.length > 0
        ) {
          const serviceSummary =
            plans.data.data.TimeInTransitResponse.TransitResponse
              .ServiceSummary;

          for (let service of serviceSummary) {
            planList.push(service.Service.Description);
            planPriceList.push(service.Service.Code);
          }
          delete cartData[selectedItemIndex]["deliveryPlanErrorMsg"];
          cartData[selectedItemIndex]["planList"] = planList;
          cartData[selectedItemIndex]["planPriceList"] = planPriceList;
          cartData[selectedItemIndex]["selectedService"] = courierService;
        } else {
          cartData[selectedItemIndex]["deliveryPlanErrorMsg"] =
            "Address is not deliverable.";
        }
      }
    } else if (courierService === "USPS") {
      delete cartData[selectedItemIndex]["deliveryPlanErrorMsg"];
      cartData[selectedItemIndex]["planList"] = [
        "Priority Mail Express",
        "Priority Mail",
      ];
      cartData[selectedItemIndex]["selectedService"] = courierService;
      cartData[selectedItemIndex]["planPriceList"] = [];
    }

    delete cartData[selectedItemIndex]["selectedPlan"];
    if (this.props.match.path.includes("paynow")) {
      localStorage.setItem("buyNowItems", JSON.stringify(cartData));
    } else {
      localStorage.setItem(this.cartType, JSON.stringify(cartData));
    }
    await this.setState({
      cartItems: cartData,
      showCourierLoaderByItemIndex: null,
    });
  };

  handleServicePlanChange = async (
    event,
    id,
    selectedItemIndex,
    forAccessCategoryAndDigital
  ) => {
    if (forAccessCategoryAndDigital) {
      let cartData = this.state.cartItems;

      let getShippingRateParams = {
        address: {
          firstName: this.state.firstname,
          telephone: this.state.phonenumber,
          addressLine1: this.state.address,
          addressLine2: this.state.address_2,
          city: this.state.city,
          postCode: this.state.zipCode,
          state: this.state.state,
          countryId: this.state?.countriesList[0]?.props.value
            ? JSON.parse(this.state?.countriesList[0]?.props.value).countryId
            : null,
        },
        productInfo: {
          productId: cartData[selectedItemIndex].id,
          product: cartData[selectedItemIndex],
        },
      };

      getShippingRateParams["amount"] =
        cartData[selectedItemIndex].price *
        cartData[selectedItemIndex].selectedQuantity;
      getShippingRateParams["shippingAmount"] = 0;

      if (
        getShippingRateParams["address"]["firstName"] &&
        getShippingRateParams["address"]["telephone"] &&
        getShippingRateParams["address"]["addressLine1"] &&
        getShippingRateParams["address"]["city"] &&
        getShippingRateParams["address"]["postCode"] &&
        getShippingRateParams["address"]["state"] &&
        getShippingRateParams["address"]["countryId"] &&
        !this.state.taxAmount
      ) {
        const taxAmount = await this.calculateTax({ ...getShippingRateParams });

        cartData[selectedItemIndex]["tax"] = taxAmount.tax_amount;

        this.setState({
          cartItems: cartData,
          taxAmount,
        });
        localStorage.setItem("cartItems",JSON.stringify(this.state.cartItems))
      }
    } else {
      this.setState({
        showCourierLoaderByItemIndex: selectedItemIndex,
      });
      let cartData = this.state.cartItems;
      // let selectedItemIndex = cartData ?.findIndex((item) => item.id == id);
      const value = event.target.value;

      const courierService = JSON.parse(
        cartData[selectedItemIndex]["couriers_option"]
      )[0];
      let selectedPlanIndex = cartData[selectedItemIndex].planList.findIndex(
        (plan) => plan === value
      );

      let getShippingRateParams = {
        address: {
          firstName: this.state.firstname,
          telephone: this.state.phonenumber,
          addressLine1: this.state.address,
          addressLine2: this.state.address_2,
          city: this.state.city,
          postCode: this.state.zipCode,
          state: this.state.state,
          countryId: this.state?.countriesList[0]?.props.value
            ? JSON.parse(this.state?.countriesList[0]?.props.value).countryId
            : null,
        },
        trackingCompany: courierService,
        productInfo: {
          productId: cartData[selectedItemIndex].id,
          product: cartData[selectedItemIndex],
        },
      };

      let isCalculateTax = true;
      cartData[selectedItemIndex]["selectedPlan"] =
        cartData[selectedItemIndex].planList[selectedPlanIndex];
      let courierOptions = JSON.parse(
        cartData[selectedItemIndex].couriers_option
      );
      cartData[selectedItemIndex]["selectedService"] = courierOptions[0];
      if (
        cartData[selectedItemIndex].spu_id &&
        cartData[selectedItemIndex].spu_id.length !== 0
      ) {
        isCalculateTax = false;

        cartData[selectedItemIndex]["selectedShippingMethodId"] =
          cartData[selectedItemIndex].shippingMethodIdList[selectedPlanIndex];
        cartData[selectedItemIndex]["shippingCharge"] = cartData[
          selectedItemIndex
        ]["is_shipping_free"]
          ? 0
          : cartData[selectedItemIndex].planPriceList[selectedPlanIndex];

        delete cartData[selectedItemIndex].deliveryPlanErrorMsg;
      } else if (courierService === "Fedex") {
        // cartData[selectedItemIndex]["selectedPlan"] = value;
        cartData[selectedItemIndex]["shippingCharge"] = cartData[
          selectedItemIndex
        ]["is_shipping_free"]
          ? 0
          : cartData[selectedItemIndex].planPriceList[selectedPlanIndex];

        delete cartData[selectedItemIndex].deliveryPlanErrorMsg;
      } else if (courierService === "USPS") {
        getShippingRateParams["deliveryPlanName"] = value;

        const planPrice = await this.props.getUSPSPlanPrice({
          ...getShippingRateParams,
        });

        if (planPrice?.data?.data?.prices.length > 0) {
          // cartData[selectedItemIndex]["selectedPlan"] = value;
          cartData[selectedItemIndex]["shippingCharge"] = cartData[
            selectedItemIndex
          ]["is_shipping_free"]
            ? 0
            : +planPrice.data.data.prices[1];
          delete cartData[selectedItemIndex].deliveryPlanErrorMsg;
        } else {
          cartData[selectedItemIndex]["deliveryPlanErrorMsg"] =
            "Address is not deliverable.";
        }
      } else if (courierService === "UPS") {
        const selectedPlanIndex = cartData[
          selectedItemIndex
        ].planList.findIndex((item) => item == value);
        getShippingRateParams["planCode"] =
          cartData[selectedItemIndex].planPriceList[selectedPlanIndex];

        const planPrice = await this.props.getUpsPlanPrice({
          ...getShippingRateParams,
        });

        if (
          planPrice?.data?.data?.RateResponse?.RatedShipment?.TotalCharges
            ?.MonetaryValue
        ) {
          // cartData[selectedItemIndex]["selectedPlan"] = value;
          cartData[selectedItemIndex]["shippingCharge"] = cartData[
            selectedItemIndex
          ]["is_shipping_free"]
            ? 0
            : +planPrice.data.data.RateResponse.RatedShipment.TotalCharges
                .MonetaryValue;
          delete cartData[selectedItemIndex].deliveryPlanErrorMsg;
        } else {
          cartData[selectedItemIndex]["deliveryPlanErrorMsg"] =
            "Address is not deliverable.";
        }
      }

      // delete cartData[selectedItemIndex].deliveryPlanErrorMsg;

      if (isCalculateTax) {
        getShippingRateParams["amount"] =
          cartData[selectedItemIndex].price *
          cartData[selectedItemIndex].selectedQuantity;
        getShippingRateParams["shippingAmount"] =
          cartData[selectedItemIndex]["shippingCharge"];

        const taxAmount = await this.calculateTax({ ...getShippingRateParams });

        cartData[selectedItemIndex]["tax"] = taxAmount.tax_amount;
      }
      if (this.props.match.path.includes("paynow")) {
        localStorage.setItem("buyNowItems", JSON.stringify(cartData));
      } else {
        localStorage.setItem(this.cartType, JSON.stringify(cartData));
      }
      await this.setState({
        cartItems: cartData,
        showCourierLoaderByItemIndex: null,
      });
    }
  };

  calculateTax = async (taxParams) => {
    const taxRes = await this.props.getTax(taxParams);
    if (taxRes?.data) {
      return { tax_amount: taxRes.data.data };
    } else {
      return { tax_amount: 0 };
    }
  };

  handleCountryChange = async (event, key) => {
    event.preventDefault();
    const countryValue = JSON.parse(event.target.value);
    let statesList = await this.props.getStatesList(countryValue.countryId);
    if (statesList?.data?.data?.length > 0) {
      statesList = statesList.data.data.map((state) => ({
        value: `${JSON.stringify({
          stateId: state.id,
          stateName: state.state_name,
        })}`,
        label: `${state.state_name}`,
      }));

      if (
        this.state.errorData &&
        this.state.errorData["country"] &&
        this.state.errorData["country"][0]
      ) {
        this.state.errorData["country"][0] = null;
      }
      if (
        this.state.errorData &&
        this.state.errorData["billingCountry"] &&
        this.state.errorData["billingCountry"][0]
      ) {
        this.state.errorData["billingCountry"][0] = null;
      }
      if (key == "country") {
        this.clearShippingInfo();
        this.setState((prevState) => ({
          ...prevState,
          country: countryValue.countryName,
          statesList: statesList,
          defaultSelectedState: [],
          citiesList: [],
          defaultSelectedCity: [],
        }));
      } else if (key == "billingCountry") {
        this.setState((prevState) => ({
          ...prevState,
          billingCountry: countryValue.countryName,
          billingStatesList: statesList,
          billingDefaultSelectedState: [],
          billingCitiesList: [],
          billingDefaultSelectedCity: [],
        }));
      }
    }
  };

  handleStateChange = async (event, key) => {
    const stateValue = JSON.parse(event.value);
    this.setState({
      defaultSelectedState: event,
      state: stateValue.stateName,
    });
    if (key == "state") {
      this.clearShippingInfo();
      this.setState({
        defaultSelectedState: event,
        state: stateValue.stateName,
      });
    }
    if (key == "billingState") {
      this.setState({
        billingDefaultSelectedState: event,
        billingState: stateValue.stateName,
      });
    }
    let citiesList = await this.props.getCitiesList(stateValue.stateId);
    if (citiesList?.data?.data?.length > 0) {

      citiesList = citiesList.data.data.map((city) => ({
        value: `${JSON.stringify({ cityId: city.id, cityName: city.city })}`,
        label: `${city.city}`,
      }));

      if (
        this.state.errorData &&
        this.state.errorData["state"] &&
        this.state.errorData["state"][0]
      ) {
        this.state.errorData["state"][0] = null;
      }
      if (
        this.state.errorData &&
        this.state.errorData["billingState"] &&
        this.state.errorData["billingState"][0]
      ) {
        this.state.errorData["billingState"][0] = null;
      }
      if (key == "state") {
        this.setState((prevState) => ({
          ...prevState,
          citiesList: citiesList,
          defaultSelectedCity: [],
        }));
      }
      if (key == "billingState") {
        this.setState((prevState) => ({
          ...prevState,
          billingCitiesList: citiesList,
          billingDefaultSelectedCity: [],
        }));
      }
    }
  };

  handleCityChange = (event, key) => {
    const cityValue = JSON.parse(event.value);
    if (
      this.state.errorData &&
      this.state.errorData["city"] &&
      this.state.errorData["city"][0]
    ) {
      this.state.errorData["city"][0] = null;
    }
    if (
      this.state.errorData &&
      this.state.errorData["billingCity"] &&
      this.state.errorData["billingCity"][0]
    ) {
      this.state.errorData["billingCity"][0] = null;
    }
    if (key == "city") {
      this.clearShippingInfo();
      this.setState(
        (prevState) => ({
          ...prevState,
          defaultSelectedCity: event,
          city: cityValue.cityName,
        }),
        () => {
          setTimeout(() => {
            if (this.validateUserInfo(false)) {
              this.state.cartItems.map((item, index) =>
                this.handleServiceChange(index)
              );
            }
          }, 200);
        }
      );
    }
    if (key == "billingCity") {
      this.setState((prevState) => ({
        ...prevState,
        billingDefaultSelectedCity: event,
        billingCity: cityValue.cityName,
      }));
    }
  };

  onSelectCard = (event, card) => {
    // event.preventDefault();
    setTimeout(() => {
      this.setState({
        handleRestForm: true,
        newCard: false,
      });
    }, 100);

    const value = event.target.value;
    if (value === "new_card") {
      this.setState({
        paymentType: "stripe",
        paymentResponse: null,
        selectedCardId: null,
      });
      this.authorizePopref.current.click();
      return;
    } else {
      this.setState({
        paymentType: "stripe",
        paymentResponse: null,
        selectedCardId: event.target.value,
        newCard: false,
      });
      return;
    }
  };

  checkCartShippingValidations = () => {
    const cartItems = this.state.cartItems;
    let isError = false;
    cartItems.forEach((item) => {
      if (!item.selectedPlan && JSON.parse(item.couriers_option).length !== 0) {
        isError = true;
        item["deliveryPlanErrorMsg"] = "Please select a delivery plan.";
      }
    });
    if (isError) {
      localStorage.setItem(this.cartType, JSON.stringify(cartItems));
      this.setState({
        cartItems,
      });
    }
    return isError;
  };

  clearShippingInfo = () => {
    let cartData = localStorage.getItem(this.cartType);
    if (cartData) {
      cartData = JSON.parse(cartData);
      cartData.forEach((item) => {
        delete item.planList;
        delete item.planPriceList;
        delete item.selectedPlan;
        // delete item.selectedService;
        delete item.shippingCharge;
        delete item.tax;
        delete item.deliveryPlanErrorMsg;
        if (
          this.isGuestUser &&
          (item.isAccessCategoryAvailable ||
            item.paymentOptionType === "recurring")
        ) {
          item["errorMsg"] = "Login required for this product!";
        } else {
          delete item.errorMsg;
        }

        delete item.isErrorItem;
        delete item.isError;
      });
      if (this.props.match.path.includes("paynow")) {
        localStorage.setItem("buyNowItems", JSON.stringify(cartData));
      } else {
        localStorage.setItem(this.cartType, JSON.stringify(cartData));
      }
      this.setState({
        cartItems: cartData,
      });
    }
  };

  handleNewCardDetails = async (billingDetails) => {
    await this.setState({
      newCardDetails: {...billingDetails},
      newBillingInformation: {"billing_details":{...billingDetails}}
    })
  }

  handleStripeResponse = async (res, paylaod) => {
    if (res?.billing_details) {
      this.setState({
        newBillingInformation: res,
      });
    }
    // if (res) {
    await this.setState({
      paymentResponse: res,
      paymentType: "stripe",
      cardToken: paylaod,
      incompletePaymentMessage: "",
    });
    if (this.state.paymentType == "stripe") {
      if (res == null) {
        await this.setState({
          handleClickKey: false,
          handleRestForm: false,
        });
      }

      if (res == false) {
        await this.setState({
          selectedCardId: null,
        });
      } else {
        this.orderPaymentProcess();
      }
    }
    // }
  };

  handlePaymentResponseFromStripe = () => {
    return this.state.paymentResponse;
  };

  handleChangeChk = () => {
    this.setState({
      chkbox: !this.state.chkbox,
    });
  };

  handleAddNewCard = async () => {
    if (this.state.newCard) {
      await this.setState({
        handleRestForm: true,
        selectedCardId: null,
      });
    }
    setTimeout(() => {
      this.setState(
        {
          [`newCard`]: !this.state.newCard,
        },
        () => {
          if (this.state.newCard) {
            this.setState({
              selectedCardId: null,
            });
          }
        }
      );
    }, 100);
  };

  handleUpload = async (event) => {
    let img = event.target.files[0];
    if (!img) {
      return null;
    }
    const options = {
      maxSizeMB: 3,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      img = await imageCompression(img, options);
    } catch (e) {
      console.error("Error while compressing image : ", e);
    }
    this.readFile(img);
  };

  readFile = (file) => {
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      let base64 = event.currentTarget.result;
      let cartItems = [...this.state.cartItems];
      cartItems[this.state.itemIndex]["customizationImage"] = base64;
      this.setState({
        cartItems,
      });
      if (this.props.match.path.includes("paynow")) {
        localStorage.setItem("buyNowItems", JSON.stringify(cartItems));
      } else {
        localStorage.setItem(this.cartType, JSON.stringify(cartItems));
      }
    };
    fileReader.readAsDataURL(file);
  };

  groupTimeSlotsModal = () => (
    <Modal show={this.state.showGroupTimeModal} centered>
      <Modal.Header
        closeButton
        onClick={() => {
          this.setState({
            showGroupTimeModal: false,
          });
        }}
      >
        <b>Time slots</b>
      </Modal.Header>
      <Modal.Body className="time-slot-modal">
      <div>
      {
        this.state.cartItems.filter(i => i.selectedStructureId == this.state.selectedStId).map((item, index) => {
          return <>

          {!item.isSeminarEvent && item.paymentOptionType === "recurring" && item.sessionType == "group" ? (


              Object.entries(item.timeSlots).map(([weekDay, slotsArray]) => {

                return <>
                <p className="mb-2"><strong>{weekDayValue[weekDay]}</strong></p>
                <ul>{slotsArray?.length ? 
                slotsArray.map(i => {
                  return <li>{moment(i[0], "HH:mm").format("hh:mm A")} - {moment(i[1], "HH:mm").format("hh:mm A")} {i[2] ? `Every ${i[2]} ${weekDay} Of The Month` : null}</li>
                })
                : null}</ul>

                </>

              })
            
          ) : null}
          </>
        })
      }
      </div>
      </Modal.Body>
    </Modal>
  );

  imageModal = () => (
    <Modal show={this.state.showModal} centered>
      <Modal.Header
        closeButton
        onClick={() => {
          this.setState({
            showModal: false,
          });
        }}
      >
        <b>Customization Image</b>
      </Modal.Header>

      <Modal.Body>
        <div className="customization-image-edit-inner">
          <div className="customization-image-edit">
            {this.state.cartItems[this.state.itemIndex]?.customizationImage ? (
              <img
                src={
                  this.state.cartItems[this.state.itemIndex]?.customizationImage
                }
              />
            ) : (
              <div className="image-not-upload">
                <i className="fas fa-file-upload"></i>
                <p>Image Upload</p>
              </div>
            )}
          </div>
          <div className="action-btn">
            <button
              className="orange-btn mr-2"
              onClick={() => this.fileUpload.current?.click()}
            >
              <i className="far fa-edit"></i>
            </button>
            <button
              className="orange-btn"
              onClick={() => {
                let cartItems = [...this.state.cartItems];
                cartItems[this.state.itemIndex]["customizationImage"] = "";
                this.setState({
                  cartItems,
                  showModal: false,
                });
                if (this.props.match.path.includes("paynow")) {
                  localStorage.setItem(
                    "buyNowItems",
                    JSON.stringify(cartItems)
                  );
                } else {
                  localStorage.setItem(
                    this.cartType,
                    JSON.stringify(cartItems)
                  );
                }
              }}
            >
              <i className="far fa-trash-alt"></i>
            </button>
          </div>
        </div>
        <div className="d-flex align-content-center justify-content-center">
          <button
            className="orange-btn mr-2"
            onClick={() => {
              this.setState({
                showModal: false,
              });
            }}
          >
            Done
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );

  render() {
    const cartItems = localStorage.getItem("cartItems")
      ? JSON.parse(localStorage.getItem("cartItems"))
      : null;
    let totalShippingCharges = 0;

    let totalTaxCharge = 0;

    const savedCardList = this.state.savedCardList.map((card, index) => {
      return (
        <li key={card.id}>
          <div className="payment-left">
            <div className="checkout-check">
              <label className="custom-check">
                {" "}
                <p>
                  {card.brand_type && card.brand_type}{" "}
                  <span>
                    {card.card_number}({card.exp_date})
                  </span>
                </p>
                <input
                  onClick={(event) => this.onSelectCard(event, card)}
                  type="radio"
                  value={card.id}
                  name="paymentTypeRadio"
                  checked={this.state.selectedCardId == card.id}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>
          <div className="payment-right">
            <button
              onClick={(event) => this.updateSubscription(card.id, index)}
            >
              Remove
            </button>
          </div>
        </li>
      );
    });

    let countryId = this.state?.countriesList[0]?.props.value
      ? JSON.parse(this.state?.countriesList[0]?.props.value).countryId
      : null;

    return (
      <main className="mainContainer">
        <section className="home-product-section mt-4">
          <div className="container">
            <div className="select-product-list">
              <div className="product-list-head product-list-border-bottom">
                <ul>
                  <li>
                    <Link to={"/"}>home</Link>
                  </li>
                  <li>
                    <i className="fas fa-minus"></i>
                  </li>
                  <li>
                    <Link to={"/cart"}>cart</Link>
                  </li>
                  <li>
                    <i className="fas fa-minus"></i>
                  </li>
                  <li>
                    <a>checkout</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="cart-section">
          <div className="container">
            <div className="cart-section-head">
              <h2>Checkout</h2>
              <p>Enter Your Details Below</p>
            </div>

            <div className="checkout-body">
              <div className="row">
                <div className="col-lg-7">
                  <h4 onClick={() => this.setState({toggleShowMobile: !this.state.toggleShowMobile})}>
                    Shipping Information
                    {/* <span>
                      <i className={`fas fa-chevron-${!this.state.toggleShowMobile ? `down` : `up`}`} />
                    </span> */}
                  </h4>
                  {/* {this.isGuestUser && <span><Link to={"/login"}>Please Sign in to track your order</Link></span>} */}
                  {/* <div className={`checkout-form ${!this.state.toggleShowMobile ? `show-mobile` : ``}`}> */}
                  <div className={`checkout-form`}>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            type="text"
                            maxLength="15"
                            value={this.state?.firstname}
                            onChange={(event) =>
                              this.handleChange(event, "firstname")
                            }
                            autocomplete="nope"
                            className="form-control"
                            placeholder="First Name"
                          />
                          <span className="form-field-error">
                            {this.state.errorData?.firstname &&
                              this.state.errorData.firstname[0]}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            type="text"
                            maxLength="15"
                            value={this.state?.lastname}
                            onChange={(event) =>
                              this.handleChange(event, "lastname")
                            }
                            autocomplete="nope"
                            className="form-control"
                            placeholder="Last Name"
                          />
                          <span className="form-field-error">
                            {this.state.errorData?.lastname &&
                              this.state.errorData.lastname[0]}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            type="text"
                            value={this.state?.email}
                            onChange={(event) =>
                              this.handleChange(event, "email")
                            }
                            autocomplete="nope"
                            className="form-control"
                            placeholder="Email Address"
                          />
                          <span className="form-field-error">
                            {this.state.errorData?.email &&
                              this.state.errorData.email[0]}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <PhoneInput
                           className="phone-input"
                            country={'us'}
                            value={!this.state.phonenumber ? "": this.state.phonenumber}
                            onChange={(event) => this.handleChange(event, "phonenumber")}
                            placeholder="Phone Number"
                            onlyCountries={["us"]}
                          />
                          <span className="form-field-error">
                            {this.state.errorData?.phonenumber &&
                              this.state.errorData.phonenumber[0]}
                          </span>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group">
                          <input
                            type="text"
                            maxLength="50"
                            value={this.state?.address}
                            onChange={(event) =>
                              this.handleChange(event, "address")
                            }
                            autocomplete="nope"
                            className="form-control"
                            placeholder="Shipping Address"
                          />
                          <span className="form-field-error">
                            {this.state.errorData?.address &&
                              this.state.errorData.address[0]}
                          </span>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group">
                          <input
                            type="text"
                            maxLength="50"
                            value={this.state?.address_2}
                            onChange={(event) =>
                              this.handleChange(event, "address_2")
                            }
                            autocomplete="nope"
                            className="form-control"
                            placeholder="Address Line 2 (optional)"
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group">
                          <select
                          value = {this.state.country}
                            name="country"
                            className="form-control"
                            onChange={(event) =>
                              this.handleCountryChange(event, "country")
                            }
                          >
                            <option value="" selected disabled>
                              Select Country
                            </option>
                            {this.state.countriesList}
                          </select>
                          <span className="form-field-error">
                            {this.state.errorData?.country &&
                              this.state.errorData.country[0]}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <Select
                            value={this.state.defaultSelectedState}
                            placeholder={<div>Select State</div>}
                            name="value"
                            options={this.state.statesList}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(event) =>
                              this.handleStateChange(event, "state")
                            }
                          />
                          <span className="form-field-error">
                            {this.state.errorData?.state &&
                              this.state.errorData.state[0]}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <Select
                            value={this.state.defaultSelectedCity}
                            placeholder={<div>Select City</div>}
                            name="value"
                            options={this.state.citiesList}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(event) =>
                              this.handleCityChange(event, "city")
                            }
                          />
                          <span className="form-field-error">
                            {this.state.errorData?.city &&
                              this.state.errorData.city[0]}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            type="text"
                            maxLength="6"
                            className="form-control"
                            placeholder="Zip Code"
                            autocomplete="nope"
                            value={this.state?.zipCode}
                            onChange={(event) =>
                              this.handleChange(event, "zipCode")
                            }
                          />
                          <span className="form-field-error">
                            {this.state.errorData?.zipCode &&
                              this.state.errorData.zipCode[0]}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <h5 className="mb-3">Billing Information</h5>
                        <div className="selectpayement-card">
                          <div className="selectpayement-heading">
                            <h3>Select Payement Method</h3>
                          </div>
                          <div className="selectpayement-body">
                            <div className="selectpayement-item">
                              <div
                                onClick={() => this.openAuthorizePaymentPop("")}
                              >
                                <a className="selectpayement-item-inner">
                                  <div className="card-name">
                                    <img src={card} />
                                    <h4>Card</h4>
                                  </div>
                                  <div className="payment-errow">
                                    <i
                                      className={`fas fa-chevron-${
                                        !this.state.isCardDropdownEnable
                                          ? "down"
                                          : "up"
                                      }`}
                                    ></i>
                                  </div>
                                </a>
                              </div>
                              <div
                                className={
                                  this.state.isCardDropdownEnable === true
                                    ? "selectpayement-drop"
                                    : "selectpayement-drop d-none"
                                }
                              >
                                <div className="payment-option-list">
                                  <ul>
            
            {savedCardList}
                                  </ul>
                                </div>

                                {!this.isGuestUser && (
                                  <div className="saved-text">
                                    <input
                                      className="checkbox-round "
                                      type="checkbox"
                                      checked={this.state.newCard}
                                      onChange={this.handleAddNewCard}
                                    />
                                    <span>Add New Card</span>
                                  </div>
                                )}
                                {(this.state.newCard || this.isGuestUser) && (
                                  <>
                                    {" "}
                                    <StripeCheckOutForm
                                      cartItems={this.state.cartItems}
                                      amount={this.state.cartSubtotal}
                                      handleStripeResponse={
                                        this.handleStripeResponse
                                      }
                                      handleNewCardDetails={this.handleNewCardDetails}
                                      handleClickKey={this.state.handleClickKey}
                                      handleRestForm={this.state.handleRestForm}
                                      email={this.state?.email}
                                      address={this.state?.address}
                                      state={this.state?.state}
                                      city={this.state?.city}
                                    />
                                    {!this.isGuestUser && (
                                      <div className="saved-text">
                                        <input
                                          className="checkbox-round"
                                          type="checkbox"
                                          defaultChecked={this.state.chkbox}
                                          onChange={this.handleChangeChk}
                                        />
                                        <span>
                                          Save card detail for faster payment
                                        </span>
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>

                            <div
                              className="selectpayement-item"
                              onClick={() =>
                                this.openAuthorizePaymentPop("paypal")
                              }
                            >
                              <a className="selectpayement-item-inner">
                                <div className="card-name">
                                  <img src={paypal} />
                                  <h4>Paypal</h4>
                                </div>
                                <div className="payment-errow">
                                  <div className="checkout-check">
                                    <label className="custom-check">
                                      <input
                                        ref={this.paypalInputRadioRef}
                                        type="radio"
                                        value="paypal"
                                        checked={
                                          this.state.paymentType === "paypal"
                                        }
                                        name="paymentTypeRadio"
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {this.state.cartItems && this.state.cartItems.length > 0 ? (
                      <div className="checkout-btn">
                        <button
                          disabled={
                            this.state.showLoader ||
                            this.state.isPaymentInitialized
                          }
                          onClick={(e) => this.handleSubmit(e)}
                          type="button"
                        >
                          {this.state.isPaymentInitialized
                            ? "Payment processing, please wait..."
                            : "continue"}
                          {(() => {
                            if (this.state.showLoader) {
                              return (
                                <span className="spinner-border spinner-border-sm ml-1"></span>
                              );
                            }
                          })()}
                        </button>
                      </div>
                    ) : null}

                    <button
                      type="button"
                      style={{ display: "none" }}
                      ref={this.confirmPopupRef}
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#confirmationPopup"
                    ></button>

                    <div
                      className="modal fade"
                      id="confirmationPopup"
                      tabIndex="-1"
                      role="dialog"
                      data-keyboard="false"
                      aria-labelledby="confirmationPopupTitle"
                      aria-hidden="true"
                    >
                      <div
                        className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg"
                        role="document"
                      >
                        <div className="modal-content">
                          <div className="modal-header">
                            {/* <h5 className="modal-title" id="exampleModalLongTitle">Proceed confirmation</h5> */}
                            <h5
                              className="modal-title"
                              id="exampleModalLongTitle"
                            >
                              <img src={Logo} />
                            </h5>
                            <button
                              type="button"
                              className="close"
                              onClick={this.onNotConfirmChanges}
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>

                          <div className="modal-body">
                            <h4 className="mb-4">Shipping Information</h4>
                            <div className="row">
                              <div className="col-md-6">
                                <p>
                                  <b>Firstname:</b> {this.state.firstname}
                                </p>
                              </div>

                              <div className="col-md-6">
                                <p>
                                  <b>Lastname:</b> {this.state.lastname}
                                </p>
                              </div>

                              <div className="col-md-6">
                                <p>
                                  <b>Email:</b> {this.state.email}
                                </p>
                              </div>
                              {this.state.phonenumber?.length > 0 && (
                                <div className="col-md-6">
                                  <p>
                                    <b>Phone Number:</b>{" "}
                                    {this.changeNumberFormate(this.state.phonenumber)}
                                  </p>
                                </div>
                              )}

                              <div className="col-md-6">
                                <p>
                                  <b>Address:</b> {this.state.address}
                                </p>
                              </div>

                              {this.state.address_2 &&
                              this.state.address_2.trim().length > 0 ? (
                                <div className="col-md-6">
                                  <p>
                                    <b>Address Line 2:</b>{" "}
                                    {this.state.address_2}
                                  </p>
                                </div>
                              ) : null}

                              <div className="col-md-6">
                                <p>
                                  <b>City:</b> {this.state.city}
                                </p>
                              </div>

                              <div className="col-md-6">
                                <p>
                                  <b>State:</b> {this.state.state}
                                </p>
                              </div>

                              <div className="col-md-6">
                                <p>
                                  <b>Country:</b> {this.state.country}
                                </p>
                              </div>

                              <div className="col-md-6">
                                <p>
                                  <b>ZipCode:</b> {this.state.zipCode}
                                </p>
                              </div>
                            </div>
                            {this.state.newBillingInformation && (
                              <>
                                <h4
                                  className="mb-4"
                                  style={{
                                    borderTop: "1px solid #ddd",
                                    paddingTop: "10px",
                                  }}
                                >
                                  Billing Information
                                </h4>
                                <div className="row">
                                  <div className="col-md-6">
                                    <p>
                                      <b>Name:</b>{" "}
                                      {
                                        this.state?.newBillingInformation
                                          ?.billing_details?.name
                                      }
                                    </p>
                                  </div>

                                  {/* <div className="col-md-6">
                                <p>
                                  <b>Lastname:</b> {this.state.billingLastName}
                                </p>
                              </div> */}

                                  <div className="col-md-6">
                                    <p>
                                      <b>Email:</b>{" "}
                                      {
                                        this.state?.newBillingInformation
                                          ?.billing_details?.email
                                      }
                                    </p>
                                  </div>
                                  <div className="col-md-6">
                                    <p>
                                      <b>Phone Number:</b>{" "}
                                      {
                                        this.changeNumberFormate(this.state?.newBillingInformation
                                          ?.billing_details?.phone ?this.state?.newBillingInformation
                                          ?.billing_details?.phone : this.state.phonenumber )
                                      }
                                    </p>
                                  </div>
                                  {this.state?.newBillingInformation?.billing_details?.address?.line1 === "" ? "" :
                                  <div className="col-md-6">
                                    <p>
                                      <b>Address:</b>{" "}
                                      {this.state?.newBillingInformation
                                        ?.billing_details?.address?.line1
                                        ? this.state?.newBillingInformation
                                            ?.billing_details?.address?.line1
                                        : this.state?.newBillingInformation
                                            ?.billing_details?.address &&
                                          typeof this.state
                                            ?.newBillingInformation
                                            ?.billing_details?.address ==
                                            "object"
                                        ? ``
                                        : this.state?.newBillingInformation
                                            ?.billing_details?.address}
                                    </p>
                                  </div>}
                              {this.state?.newBillingInformation?.billing_details?.address.city === "" ? "" :
                                  <div className="col-md-6">
                                    <p>
                                      <b>City:</b>{" "}
                                      {this.state?.newBillingInformation
                                        ?.billing_details?.address?.city
                                        ? this.state?.newBillingInformation
                                            ?.billing_details?.address?.city
                                        : this.state?.newBillingInformation
                                            ?.billing_details?.city}
                                    </p>
                                  </div>}
                                  {this.state?.newBillingInformation?.billing_details?.address.state === "" ? "" :
                                  <div className="col-md-6">
                                    <p>
                                      <b>State:</b>{" "}
                                      {this.state?.newBillingInformation
                                        ?.billing_details?.address?.state
                                        ? this.state?.newBillingInformation
                                            ?.billing_details?.address?.state
                                        : this.state?.newBillingInformation
                                            ?.billing_details?.state}
                                    </p>
                                  </div>}

                                  <div className="col-md-6">
                                    <p>
                                      <b>ZipCode:</b>{" "}
                                      {this.state?.newBillingInformation
                                        ?.billing_details?.address?.postal_code
                                        ? this.state?.newBillingInformation
                                            ?.billing_details?.address
                                            ?.postal_code
                                        : this.state?.newBillingInformation
                                            ?.billing_details?.address.zipCode}
                                    </p>
                                  </div>
                                </div>
                              </>
                            )}
                            {this.state.cartItems
                              .map((item, index) => {
                                const itemTimeFrame = item.timeFrame
                                  ? item.timeFrame.split(",")[1]
                                  : null;
                                let discount =
                                  (item.price *
                                    item.selectedQuantity *
                                    this.state.discountPercent) /
                                  100;
                                const selectedPairKeys = item.selectedPair
                                  ? Object.keys(item.selectedPair)
                                  : [];
                                const freeSeminarDetails =
                                  item.free_seminar_details
                                    ? JSON.parse(item.free_seminar_details)
                                    : null;
                                const parsedQuestions =
                                  item.customize_questions !== "undefined" &&
                                  item.customize_questions
                                    ? JSON.parse(item.customize_questions)
                                    : null;

                                return (
                                  <div
                                    key={index}
                                    className="cart-box customization-box"
                                  >
                                    <div className="custom-flex-box mb-2">
                                      <div className="cart-box-detail">
                                        <div className="cart-box-img">
                                          <img src={item.imageName} alt="" />
                                        </div>
                                        <div className="cart-box-item-detail">
                                          <h2
                                            style={{
                                              maxWidth: "500px",
                                              wordBreak: "break-all",
                                            }}
                                          >
                                            {item.name}
                                          </h2>

                                          {selectedPairKeys.length > 0
                                            ? selectedPairKeys.map(
                                                (variant, variantIndex) => {
                                                  return item.selectedPair &&
                                                    item.selectedPair[
                                                      variant
                                                    ] ? (
                                                    <p key={variantIndex}>
                                                      <span>
                                                        {variant.replace(
                                                          "_",
                                                          " "
                                                        )}
                                                        :{" "}
                                                      </span>
                                                      {
                                                        item.selectedPair[
                                                          variant
                                                        ]
                                                      }
                                                    </p>
                                                  ) : null;
                                                }
                                              )
                                            : null}
                                          <p>
                                            <strong>Frequency: </strong>
                                            {itemTimeFrame && !item.isSeminarEvent &&
                                            item.paymentOptionType ===
                                              "recurring" ? (
                                              <span>{itemTimeFrame}</span>
                                            ) : (
                                              <span>One Time</span>
                                            )}
                                          </p>
                                          {!item.isSeminarEvent &&
                                              item.timeSlots && item.sessionType == "group" ? 
                                                                  
                                            <div className="month-freq">
                                              <div onClick={() => this.setState({ showGroupTimeModal: true, selectedStId: item.selectedStructureId})}>
                                              <button className="orange-outline-btn mt-2">view details</button>
                                                          </div>
                                            </div>
                                            :
                                            null
                                          }
                                          {item.timeSlots && item.sessionType !== "group" && (
                                            <div className="d-flex align-items-center flex-wrap">
                                              <strong>Time slots:</strong>
                                              {item.timeSlots && item.sessionType !== "group" && item.timeSlots?.map((slot) => {
                                                return (
                                                  <p className="mr-1">
                                                    {moment(slot[0], [
                                                      "HH:mm",
                                                    ]).format("h:mm A")}{" "}
                                                    to{" "}
                                                    {moment(slot[1], [
                                                      "HH:mm",
                                                    ]).format("h:mm A")}
                                                  </p>
                                                );
                                              })}
                                            </div>
                                          )}

                                          {item.isSeminarEvent ? (
                                            <>
                                              <div className="d-flex align-items-center flex-wrap font14">
                                                <span>Date: </span>
                                                {freeSeminarDetails?.date}
                                              </div>
                                              <div className="d-flex align-items-center flex-wrap font14">
                                                <span>Time Slots: </span>
                                                {moment(
                                                  freeSeminarDetails?.start,
                                                  "hh:mm"
                                                ).format("hh:mm A")}{" "}
                                                {"to"}{" "}
                                                {moment(
                                                  freeSeminarDetails?.end,
                                                  "hh:mm"
                                                ).format("hh:mm A")}
                                              </div>
                                            </>
                                          ) : null}
                                          {item.isCustomizeCategoryAvailable ==
                                            1 &&
                                          parsedQuestions &&
                                          parsedQuestions.length ? (
                                            <>
                                              <p>
                                                <strong>
                                                  Customize Questions:
                                                </strong>
                                              </p>
                                              {parsedQuestions.map(
                                                (item, index) =>
                                                  index <
                                                  parsedQuestions?.length -
                                                    1 ? (
                                                    <span>
                                                      {item}
                                                      {", "}
                                                    </span>
                                                  ) : (
                                                    <span>{item}</span>
                                                  )
                                              )}
                                            </>
                                          ) : null}
                                          {item.isCustomizeCategoryAvailable ==
                                            1 && (
                                            <>
                                              <p>
                                                <strong>
                                                  Customization Note:
                                                </strong>
                                                <span>
                                                  {item.customizationNote}
                                                </span>
                                              </p>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                      <div className="cart-box-item-price">
                                        <p>
                                          <span>
                                            $
                                            {(
                                              item.price *
                                                item.selectedQuantity +
                                              (item.shippingCharge
                                                ? item.shippingCharge
                                                : 0) -
                                              discount +
                                              (item.tax ? item.tax : 0)
                                            )
                                              .toFixed(2)
                                              .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )}
                                          </span>
                                        </p>
                                        {/* <p>${(item.price * item.selectedQuantity).toFixed(2)}</p> */}
                                      </div>
                                    </div>

                                    {item.isCustomizeCategoryAvailable == 1 && (
                                      <div className="customize-box-item-detail form-group">
                                        {item.customizationImage &&
                                          item.customizationImage.length >
                                            1 && (
                                            <>
                                              <img
                                                src={item.customizationImage}
                                                alt=""
                                              />
                                              <p>
                                                <strong>
                                                  Customization Image
                                                </strong>
                                              </p>
                                            </>
                                          )}
                                      </div>
                                    )}
                                  </div>
                                );
                              })
                              .reverse()}

                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="grey-btn"
                              onClick={this.onNotConfirmChanges}
                              data-dismiss="modal"
                            >
                              Edit
                            </button>
                            <button
                              type="button"
                              onClick={this.submitOrder}
                              className="orange-btn"
                              data-dismiss="modal"
                            >
                              Confirm
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="paypal-alrt">
                      <h5>{this.state.incompletePaymentMessage}</h5>
                    </div>
                  </div>
                </div>

                <div className="col-lg-5">
                  <div className="checkout-right">
                    <div className="checkout-item">
                      <div className="cart-section-body">
                        {this.state.cartItems &&
                        this.state.cartItems?.length > 0 ? (
                          this.state.cartItems
                            .map((item, index) => {
                              let discount =
                                (item.price *
                                  item.selectedQuantity *
                                  this.state.discountPercent) /
                                100;

                              (JSON.parse(
                                localStorage.getItem(this.cartType)
                              )[0]?.isAccessCategoryAvailable ||
                                JSON.parse(
                                  localStorage.getItem(this.cartType)
                                )[0]?.is_digital) &&
                                this.handleServicePlanChange(
                                  null,
                                  item.id,
                                  index,
                                  true
                                );

                              let courierOptions = JSON.parse(
                                item.couriers_option
                              );
                              totalShippingCharges =
                                totalShippingCharges +
                                (item.shippingCharge ? item.shippingCharge : 0);
                              totalTaxCharge += item.tax ? item.tax : 0;
                              const itemTimeFrame = item.timeFrame
                                ? item.timeFrame.split(",")[1]
                                : null;
                              const selectedPairKeys = item.selectedPair
                                ? Object.keys(item.selectedPair)
                                : [];
                              const freeSeminarDetails =
                                item.free_seminar_details
                                  ? JSON.parse(item.free_seminar_details)
                                  : null;

                              return (
                                <div key={index} className="checkout-main">
                                  <div className="checkout-box customization-box">
                                    <div className="custom-flex-box mb-2">
                                      {item.isError ? (
                                        <span
                                          className="cart-cross"
                                          onClick={() =>
                                            this.handleItemRemove(item, index)
                                          }
                                        >
                                          <i className="far fa-times-circle"></i>
                                        </span>
                                      ) : null}
                                      <div className="cart-box-detail">
                                        <div className="cart-box-img">
                                          <img src={item.imageName} alt="" />
                                        </div>
                                        <div className="cart-box-item-detail">
                                          <h2 title={item.name}>{item.name}</h2>
                                          {selectedPairKeys.length > 0
                                            ? selectedPairKeys.map(
                                                (variant, variantIndex) => {
                                                  return item.selectedPair &&
                                                    item.selectedPair[
                                                      variant
                                                    ] ? (
                                                    <p key={variantIndex}>
                                                      <strong>
                                                        {variant.replace(
                                                          "_",
                                                          " "
                                                        )}
                                                        :{" "}
                                                      </strong>
                                                      <span>
                                                        {
                                                          item.selectedPair[
                                                            variant
                                                          ]
                                                        }
                                                      </span>
                                                    </p>
                                                  ) : null;
                                                }
                                              )
                                            : null}
                                          <p>
                                            <strong>Frequency: </strong>
                                            {itemTimeFrame && !item.isSeminarEvent &&
                                            item.paymentOptionType ===
                                              "recurring" ? (
                                              <span>{itemTimeFrame}</span>
                                            ) : (
                                              <span>One Time</span>
                                            )}
                                          </p>
                                          {
                                              !item.isSeminarEvent && item.timeSlots && item.sessionType == "group" ? 
                                                                  
                                            <div className="month-freq">
                                              <div onClick={() => this.setState({ showGroupTimeModal: true, selectedStId: item.selectedStructureId})}>
                                              <button className="orange-outline-btn mt-2">view details</button>
                                                          </div>
                                            </div>
                                            :
                                            null
                                          }
                                          {item.timeSlots && item.sessionType !== "group" && (
                                            <div className="d-flex align-items-center flex-wrap">
                                              <strong>Time slots: </strong>
                                              {item.timeSlots && item.sessionType !== "group" && item.timeSlots?.map((slot) => {
                                                return (
                                                  <p className="mr-1">
                                                    {moment(slot[0], [
                                                      "HH:mm",
                                                    ]).format("h:mm A")}{" "}
                                                    to{" "}
                                                    {moment(slot[1], [
                                                      "HH:mm",
                                                    ]).format("h:mm A")}
                                                  </p>
                                                );
                                              })}
                                            </div>
                                          )}
                                          {item.isSeminarEvent ? (
                                            <>
                                              <div className="d-flex align-items-center flex-wrap font14">
                                                <span>Date: </span>
                                                {freeSeminarDetails?.date}
                                              </div>
                                              <div className="d-flex align-items-center flex-wrap font14">
                                                <span>Time Slots: </span>
                                                {moment(
                                                  freeSeminarDetails?.start,
                                                  "hh:mm"
                                                ).format("hh:mm A")}{" "}
                                                {"to"}{" "}
                                                {moment(
                                                  freeSeminarDetails?.end,
                                                  "hh:mm"
                                                ).format("hh:mm A")}
                                              </div>
                                            </>
                                          ) : null}

                                          {item.isCustomizeCategoryAvailable ==
                                            1 && (
                                            <>
                                              <p>
                                                <strong>
                                                  Customization Note :
                                                </strong>
                                              </p>
                                              {!this.state
                                                .editCustomizationNote[
                                                index
                                              ] && (
                                                <p>
                                                  <span className="item-custom-desc">
                                                    {item.customizationNote}
                                                  </span>
                                                  <i
                                                    className="fas fa-edit"
                                                    style={{
                                                      color: "#e25e53",
                                                      marginLeft: "2px",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                      let editCustomizationNote =
                                                        [
                                                          ...this.state
                                                            .editCustomizationNote,
                                                        ];
                                                      let tempCustomizationNote =
                                                        [
                                                          ...this.state
                                                            .tempCustomizationNote,
                                                        ];
                                                      editCustomizationNote[
                                                        index
                                                      ] = true;
                                                      tempCustomizationNote[
                                                        index
                                                      ] = JSON.parse(
                                                        JSON.stringify(
                                                          item.customizationNote
                                                        )
                                                      );
                                                      this.setState(
                                                        (prevState) => ({
                                                          ...prevState,
                                                          editCustomizationNote,
                                                          tempCustomizationNote,
                                                        })
                                                      );
                                                    }}
                                                  />
                                                </p>
                                              )}
                                              {this.state.editCustomizationNote[
                                                index
                                              ] && (
                                                <div className="checkout-textarea-edit">
                                                  <span>
                                                    <i
                                                      className="fas fa-check"
                                                      onClick={() => {
                                                        let cartItems = [
                                                          ...this.state
                                                            .cartItems,
                                                        ];
                                                        let editCustomizationNote =
                                                          [
                                                            ...this.state
                                                              .editCustomizationNote,
                                                          ];
                                                        cartItems[index][
                                                          `customizationNote`
                                                        ] =
                                                          this.state.tempCustomizationNote[
                                                            index
                                                          ];
                                                        editCustomizationNote[
                                                          index
                                                        ] = false;
                                                        if (
                                                          this.props.match.path.includes(
                                                            "paynow"
                                                          )
                                                        ) {
                                                          localStorage.setItem(
                                                            "buyNowItems",
                                                            JSON.stringify(
                                                              cartItems
                                                            )
                                                          );
                                                        } else {
                                                          localStorage.setItem(
                                                            this.cartType,
                                                            JSON.stringify(
                                                              cartItems
                                                            )
                                                          );
                                                        }
                                                        this.setState(
                                                          (prevState) => ({
                                                            ...prevState,
                                                            cartItems,
                                                            editCustomizationNote,
                                                          })
                                                        );
                                                      }}
                                                    />
                                                  </span>
                                                  <span>
                                                    <i
                                                      className="fas fa-times"
                                                      onClick={() => {
                                                        let editCustomizationNote =
                                                          [
                                                            ...this.state
                                                              .editCustomizationNote,
                                                          ];
                                                        editCustomizationNote[
                                                          index
                                                        ] = false;
                                                        this.setState(
                                                          (prevState) => ({
                                                            ...prevState,
                                                            editCustomizationNote,
                                                          })
                                                        );
                                                      }}
                                                    />
                                                  </span>
                                                  <textarea
                                                    rows="4"
                                                    value={
                                                      this.state
                                                        .tempCustomizationNote[
                                                        index
                                                      ]
                                                    }
                                                    onChange={(e) =>
                                                      this.handleChange(
                                                        e,
                                                        "tempCustomizationNote",
                                                        index
                                                      )
                                                    }
                                                  />
                                                </div>
                                              )}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                      <div className="cart-box-item-price">
                                        {item.is_sale !== 0 ? (
                                          <p>
                                            <del>
                                              $
                                              {(
                                                (item[`actualPrice`] * 100) /
                                                100
                                              )
                                                .toFixed(2)
                                                .replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ","
                                                )}
                                              x{" "}
                                              <span>
                                                {item.selectedQuantity}
                                              </span>
                                            </del>
                                          </p>
                                        ) : null}
                                        <p>
                                          <span>
                                            $
                                            {parseFloat(item[`price`]).toFixed(
                                              2
                                            )}
                                          </span>
                                          x <span>{item.selectedQuantity}</span>
                                        </p>
                                        {/* <p>${(item.price * item.selectedQuantity).toFixed(2)}</p> */}
                                      </div>
                                      {item.errorMsg && (
                                        <div className="error-out">
                                          <p>{item.errorMsg}</p>
                                        </div>
                                      )}
                                      {item.deliveryPlanErrorMsg && (
                                        <div className="error-out">
                                          <p>{item.deliveryPlanErrorMsg}</p>
                                        </div>
                                      )}
                                    </div>

                                    {item.isCustomizeCategoryAvailable == 1 && (
                                      <div className="customize-box-item-detail form-group">
                                        {
                                          <img
                                            src={
                                              item.customizationImage &&
                                              item.customizationImage.length > 1
                                                ? item.customizationImage
                                                : noImage
                                            }
                                            alt=""
                                          />
                                        }
                                        <p>
                                          <strong>Customization Image:</strong>
                                        </p>
                                        <div className="custom-image-preview">
                                          <i
                                            className="fas fa-edit"
                                            onClick={() =>
                                              this.setState({
                                                showModal: true,
                                                itemIndex: index,
                                              })
                                            }
                                          />
                                          {/* <input
                                            type="file"
                                            accept="image/png, image/gif, image/jpeg"
                                            onChange={(e) => this.handleUpload(e)}
                                        />*/}
                                        </div>
                                      </div>
                                    )}
                                  </div>

                                  {JSON.parse(item.couriers_option).length >
                                    0 &&
                                    (!JSON.parse(
                                      localStorage.getItem(this.cartType)
                                    )[0]?.isAccessCategoryAvailable ||
                                      !JSON.parse(
                                        localStorage.getItem(this.cartType)
                                      )[0]?.is_digital) && (
                                      <>
                                        <select
                                          className="unique-select"
                                          name="DeliveryPlan"
                                          onChange={(event) =>
                                            this.handleServicePlanChange(
                                              event,
                                              item.id,
                                              index
                                            )
                                          }
                                        >
                                          <option
                                            value=""
                                            selected={
                                              !item.selectedPlan ||
                                              !(
                                                this.state.address !== "" &&
                                                this.state.country !== "" &&
                                                this.state.state !== "" &&
                                                this.state.city !== "" &&
                                                this.state.zipCode !== ""
                                              )
                                            }
                                            disabled
                                          >
                                            Select Delivery Plan
                                          </option>

                                          {this.state.address !== "" &&
                                            this.state.country !== "" &&
                                            this.state.state !== "" &&
                                            this.state.city !== "" &&
                                            this.state.zipCode !== "" &&
                                            item.planList?.map(
                                              (plan, index) => {
                                                return (
                                                  <option
                                                    key={index}
                                                    selected={
                                                      plan === item.selectedPlan
                                                    }
                                                    value={plan}
                                                    id={item.id}
                                                  >
                                                    {plan
                                                      ? plan
                                                          .split("_")
                                                          .join(" ")
                                                      : null}
                                                  </option>
                                                );
                                              }
                                            )}
                                        </select>
                                      </>
                                    )}
                                  {(() => {
                                    if (
                                      index ===
                                      this.state.showCourierLoaderByItemIndex
                                    ) {
                                      return (
                                        <span className="spinner-border spinner-border-sm ml-1"></span>
                                      );
                                    }
                                  })()}

                                  <div className="checkout-payment">
                                    <div className="checkout-payment-box-top">
                                      <div className="checkout-payment-box-detail">
                                        <p>Subtotal</p>
                                        <span>
                                          $
                                          {(item.price * item.selectedQuantity)
                                            .toFixed(2)
                                            .replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ","
                                            )}
                                        </span>
                                      </div>
                                      {JSON.parse(item.couriers_option).length >
                                        0 && (
                                        <div className="checkout-payment-box-detail">
                                          <p>Shipping</p>

                                          <span>
                                            {!item.is_shipping_free
                                              ? item.shippingCharge
                                                ? `$${item.shippingCharge
                                                    .toFixed(2)
                                                    .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                    )}`
                                                : "$" + (0).toFixed(2)
                                              : "Free"}
                                          </span>
                                        </div>
                                      )}
                                      <div className="checkout-payment-box-detail">
                                        <p>Discount</p>
                                        <span>
                                          -$
                                          {discount
                                            .toFixed(2)
                                            .replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ","
                                            )}
                                        </span>
                                      </div>

                                      <div className="checkout-payment-box-detail">
                                        <p>Tax</p>
                                        <span>
                                          $
                                          {item.tax
                                            ? item.tax
                                                .toFixed(2)
                                                .replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ","
                                                )
                                            : (0).toFixed(2)}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="checkout-payment-box-bottom">
                                      <div className="checkout-payment-box-detail">
                                        <p>Total</p>
                                        <span>
                                          <b>
                                            $
                                            {(
                                              item.price *
                                                item.selectedQuantity +
                                              (item.shippingCharge
                                                ? item.shippingCharge
                                                : 0) -
                                              discount +
                                              (item.tax ? item.tax : 0)
                                            )
                                              .toFixed(2)
                                              .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )}
                                          </b>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                            .reverse()
                        ) : (
                          <div className="cart-box">
                            You have no item in your cart
                          </div>
                        )}
                      </div>
                      {!this.props.match.path.includes("paynow") && (
                        <div className="checkout-apply">
                          <div className="checkout-apply-input">
                            <input
                              type="text"
                              value={this.state?.discountCode}
                              onChange={(event) =>
                                this.handleChange(event, "discountCode")
                              }
                              className="form-control"
                              placeholder="enter gift voucher code"
                            />
                          </div>
                          <div className="checkout-apply-btn">
                            <button
                              type="button"
                              onClick={() => this.validateCoupon()}
                            >
                              apply
                              {(() => {
                                if (this.state.showCouponBtnLoader) {
                                  return (
                                    <span className="spinner-border spinner-border-sm ml-1"></span>
                                  );
                                }
                              })()}
                            </button>
                          </div>
                        </div>
                      )}

                      {(() => {
                        if (
                          this.state.couponSuccessResMessage !== "" &&
                          this.state.couponSuccessResMessage !== undefined
                        ) {
                          return (
                            <div className="alert alert-success">
                              {this.state.couponSuccessResMessage}
                            </div>
                          );
                        } else if (
                          this.state.couponErrorResMessage !== "" &&
                          this.state.couponErrorResMessage !== undefined
                        ) {
                          return (
                            <div className="alert alert-danger">
                              {this.state.couponErrorResMessage}
                            </div>
                          );
                        }
                      })()}

                      <div className="checkout-payment">
                        <div className="checkout-payment-box-top">
                          <div className="checkout-payment-box-detail">
                            <p>Subtotal</p>
                            <span>
                              $
                              {this.state.cartSubtotal
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </span>
                          </div>
                          <div className="checkout-payment-box-detail">
                            <p>Shipping</p>
                            <span>
                              {totalShippingCharges !== 0
                                ? `$${totalShippingCharges
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                : "$0.00"}
                            </span>
                          </div>
                          <div className="checkout-payment-box-detail">
                            <p>Discount</p>
                            <span>
                              -$
                              {this.state.totalDiscount
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </span>
                          </div>
                          <div className="checkout-payment-box-detail">
                            <p>Tax</p>
                            <span>
                              $
                              {totalTaxCharge
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </span>
                          </div>
                        </div>
                        <div className="checkout-payment-box-bottom">
                          <div className="checkout-payment-box-detail">
                            <p>Total</p>
                            <span>
                              <b>
                                $
                                {(
                                  this.state.cartSubtotal +
                                  totalShippingCharges -
                                  this.state.totalDiscount +
                                  totalTaxCharge
                                )
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </b>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {this.imageModal()}
        {this.groupTimeSlotsModal()}
        <input
          style={{ display: "none" }}
          type="file"
          ref={this.fileUpload}
          key={Math.random()}
          accept="image/png, image/gif, image/jpeg"
          onChange={(e) => this.handleUpload(e)}
        />
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    checkoutRes: state.cartReducer,
    errorData: state.commonReducer.errorData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getUserDetail,
      setUserInfo,
      validateAppliedCoupon,
      getProductsCurrentStatus,
      checkoutItem,
      validateUserCheckoutInfo,
      removeItemFromCart,
      getCountriesList,
      getStatesList,
      getCitiesList,
      getPlans,
      getUSPSPlanPrice,
      getUpsPlanPrice,
      getTax,
      getSavedCardsList,
      deleteSavedCard,
      getSavedCardsFromStripe,
      getDobaDeliveryOption,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Checkout)
);
