import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const getDisputeDetailByOrderId = (orderId) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/dispute/get-dispute/${orderId}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        return res.data
    } catch (error) {
        dispatch(handleError(error));
    }
}

export const getDisputeConversationById = (disputeId, offset) => async (dispatch) => {
    try {
        dispatch({ type: "LOADING_STARTED" });
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/dispute/get-dispute-conversation/${disputeId}?offset=${offset}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        dispatch({ type: "LOADING_COMPLETED" });
        return res.data;
    } catch (error) {
        dispatch(handleError(error));
    }
};

export const createDisputeMessage = (disputeId, body) => async (dispatch) => {
    try {
        dispatch({ type: "LOADING_STARTED" });
        const res = await axios({
            method: "post",
            url: `${URL}/api/v1/dispute/create-message/${disputeId}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            data : body
        });
        dispatch({ type: "LOADING_COMPLETED" });
        return res.data;
    } catch (error) {
        dispatch(handleError(error));
    }
};

export const closeDispute = (disputeId) => async (dispatch) => {
    try {
        dispatch({ type: "LOADING_STARTED" });
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/dispute/close/${disputeId}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        dispatch({ type: "LOADING_COMPLETED" });
        return res.data;
    } catch (error) {
        dispatch(handleError(error));
    }
};
export const createDispute = (userId, body) => async (dispatch) => {
    try {
        dispatch({ type: "LOADING_STARTED" });
        const res = await axios({
            method: "post",
            url: `${URL}/api/v1/dispute/create/${userId}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            data : body
        });
        dispatch({ type: "LOADING_COMPLETED" });
        return res.data;
    } catch (error) {
        dispatch(handleError(error));
    }
};
export const reportToSubsciety = (disputeId, role) => async (dispatch) => {
    try {
        dispatch({ type: "LOADING_STARTED" });
        const res = await axios({
            method: "patch",
            url: `${URL}/api/v1/dispute/report-to-subsciety/${disputeId}/${role}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        dispatch({ type: "LOADING_COMPLETED" });
        return res.data;
    } catch (error) {
        dispatch(handleError(error));
    }
};
