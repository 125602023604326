import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

// const setDataForCalendar = (formData) => {
//     var timeByDay = moment().isoWeekday(day).format();
//     const startDateStr = new Date(timeByDay).getFullYear() + "-" + (parseInt(new Date(timeByDay).getMonth()) + 1) + "-" + new Date(timeByDay).getDate() + " " + start;
//     slot['start'] = startDateStr;
//     const endDateStr = new Date(timeByDay).getFullYear() + "-" + (parseInt(new Date(timeByDay).getMonth() + 1)) + "-" + new Date(timeByDay).getDate() + " " + end;
//     slot['end'] = endDateStr;
//     return { end: new Date(endDateStr), start: new Date(startDateStr) }
// }

export const getGlobalMeetings =
  (
    productId,
    sellerId,
    startOfWeekTime,
    endOfWeekTime,
    timezone,
    view,
    userId,
    isPurchased
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/meeting-request/global-meeting-list/${sellerId}?startOfWeekTime=${startOfWeekTime}&endOfWeekTime=${endOfWeekTime}&timezone=${timezone}&view=${view}&type=all`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      dispatch({ type: "LOADING_COMPLETED" });
      dispatch({
        type: "GET_ZOOM_MEETING_LIST",
        payload: { data: res.data.data },
      });
    } catch (error) {
      dispatch(handleError(error));
    }
  };
export const getMeetings =
  (
    productId,
    sellerId,
    startOfWeekTime,
    endOfWeekTime,
    timezone,
    view,
    userId,
    isPurchased,
    cancelToken
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      dispatch({ type: "CALENDAR_LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/zoom-service/schedule?productId=${productId}&userId=${userId}&sellerId=${sellerId}&startOfWeekTime=${startOfWeekTime}&endOfWeekTime=${endOfWeekTime}&timezone=${timezone}&isPurchased=${isPurchased}`,
        cancelToken: cancelToken,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      dispatch({ type: "LOADING_COMPLETED" });
      dispatch({ type: "CALENDAR_LOADING_COMPLETED" });
      
      dispatch({
        type: "GET_ZOOM_MEETING_LIST",
        payload: { data: res.data.data },
      });
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const createMeeting =
  (product_id, user_id, formData, date) => async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/meeting-request/create`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        data: {
          product_id,
          user_id,
          formData,
          date,
        },
      });
      dispatch({ type: "LOADING_COMPLETED" });
      if (res.data) {
        dispatch({
          type: "GET_MEETING_REQUEST_LIST",
          payload: { data: [] },
        });
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const getMeetingRequestList =
  (user_id, product_id, startOfWeekTime, endOfWeekTime) => async (dispatch) => {
    try {
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/meeting-request/get-list/${user_id}/${product_id}?startOfWeekTime=${startOfWeekTime}&endOfWeekTime=${endOfWeekTime}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      dispatch({
        type: "GET_MEETING_REQUEST_LIST",
        payload: { data: res.data.data },
      });
    } catch (error) {
      dispatch(handleError(error));
    }
  };

// /api/v1/meeting-request/getAllMeetings

export const getAllMeetingsForCustomer =
  (user_id, startOfWeekTime, endOfWeekTime, timezone, cancelToken) => async (dispatch) => {
    try {
      dispatch({ type: "CALENDAR_LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/meeting-request/getAllMeetings/${user_id}?startOfWeekTime=${startOfWeekTime}&endOfWeekTime=${endOfWeekTime}&timezone=${timezone}`,
        cancelToken: cancelToken,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      dispatch({ type: "CALENDAR_LOADING_COMPLETED" });
dispatch({
  type: "GET_ALL_MEETINGS_FOR_CUSTOMER",
  payload: { data: res.data.data },
});
} catch (error) {
  dispatch(handleError(error));
} 
  };

export const createOneOnOneMeeting = (body) => async (dispatch) => {
  const { productId, userId } = body;
  try {
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/meeting-request/create-one-on-one-meeting/${userId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: body,
    });

    return res.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};
// export const updateOneOnOneMeeting = (body) => async (dispatch) => {
//   const { meeting_id } = body;
//   try {
//     const res = await axios({
//       method: "post",
//       url: `${URL}/api/v1/meeting-request/update-one-on-one-meeting/${meeting_id}`,
//       headers: {
//         Authorization: "Bearer " + localStorage.getItem("token"),
//       },
//       data: body,
//     });

//     return res.data;
//   } catch (error) {
//     dispatch(handleError(error));
//   }
// };
